import "./App.css";
import AppRoutes from "./Components/Navigation/Routes";
import { BrowserRouter as Router } from "react-router-dom";
import { RecoilRoot } from "recoil";
import RecoilNexus from "recoil-nexus";
import Footer from "./Widgets/Footer";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { LoaderProvider } from "./Utility/loaderProvider";
import { FeatureFlagProvider } from "./Utility/FeatureFlags/featureFlagProvider";
import config from "./config";

console.log(config.BuildVersion);

function App() {
  return (
    <RecoilRoot>
      <Router>
        <FeatureFlagProvider>
          <LoaderProvider>
            <RecoilNexus />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <AppRoutes />
            </LocalizationProvider>
          </LoaderProvider>
        </FeatureFlagProvider>
      </Router>
    </RecoilRoot>
  );
}

export default App;
