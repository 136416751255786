import React from "react";

//Customable default Button
export const ButtonCustom = (props) => {
  return (
    <>
      <button
        id={props.id}
        name={props.btnName}
        data-cy={props.dataCy}
        title={props.title}
        onClick={(e) => {
          if (!props.disabled) {
            props.onClick(e);
          }
        }}
        disabled={props.disabled}
        className={
          props.className +
          ` ${
            props.disabled && "opacity-60 cursor-not-allowed"
          } border rounded shadow w-fit focus:shadow-lg 
          hover:shadow-lg active:scale-95 transform transition duration-100 cursor-pointer`
        }
      >
        {props.name}
      </button>
    </>
  );
};

//Customised Blue Outline Button
export const ButtonBlueOutline = (props) => {
  return (
    <button
      type="button"
      id={props.id}
      name={props.btnName}
      data-cy={props.dataCy}
      title={props.title}
      onClick={(e) => {
        e.preventDefault();
        if (!props.disabled) {
          props.onClick(e);
        }
        e.target.blur();
      }}
      disabled={props.disabled}
      hidden={props.hidden}
      className={
        props.className +
        ` w-24 h-10 px-2 text-blue-52 font-medium border border-blue-52 rounded shadow focus:shadow-lg 
        hover:shadow-lg hover:bg-blue-52 focus:bg-blue-52 hover:text-white focus:text-white 
        active:scale-95 transform transition duration-100 ${
          props.disabled && "opacity-60 cursor-not-allowed"
        }`
      }
    >
      {props.name}
    </button>
  );
};

//Customised Blue Button
export const ButtonBlue = (props) => {
  return (
    <>
      <button
        id={props.id}
        data-cy={props.dataCy}
        name={props.btnName}
        title={props.title}
        onClick={(e) => {
          if (!props.disabled) {
            props.onClick(e);
          }
        }}
        disabled={props.disabled}
        hidden={props.hidden}
        className={
          props.className +
          ` bg-blue-52 text-white font-semibold px-2 w-24 h-10 border border-gray-400 rounded shadow 
          focus:shadow-lg hover:shadow-lg hover:bg-blue-53 focus:bg-blue-53 active:scale-95 transform 
          transition duration-100 ${
            props.disabled && "opacity-60 cursor-not-allowed"
          }`
        }
      >
        {props.name}
      </button>
    </>
  );
};

//Customised Gray Outline Button
export const ButtonGrayOutline = (props) => {
  return (
    <>
      <button
        id={props.id}
        name={props.btnName}
        data-cy={props.dataCy}
        title={props.title}
        onClick={(e) => {
          if (!props.disabled) {
            props.onClick(e);
          }
        }}
        disabled={props.disabled}
        hidden={props.hidden}
        className={
          props.className +
          ` w-24 h-10 px-2 text-gray-400 font-medium border border-gray-400 rounded shadow focus:shadow-lg 
          hover:shadow-lg hover:bg-gray-500 focus:bg-gray-500 hover:text-white focus:text-white 
          active:scale-95 transform transition duration-100 ${
            props.disabled && "opacity-60 cursor-not-allowed"
          }`
        }
      >
        {props.name}
      </button>
    </>
  );
};

//Customised Blue Outline Button
export const ButtonYellowOutline = (props) => {
  return (
    <>
      <button
        id={props.id}
        title={props.title}
        onClick={(e) => {
          if (!props.disabled) {
            props.onClick(e);
          }
        }}
        disabled={props.disabled}
        hidden={props.hidden}
        className={
          props.className +
          ` w-24 h-10 px-2 text-[#FFAF09] font-medium border border-[#FFAF09] rounded shadow focus:shadow-lg 
          hover:shadow-lg hover:bg-[#FFAF09] focus:bg-[#FFAF09] hover:text-white focus:text-white 
          active:scale-95 transform transition duration-100 ${
            props.disabled && "opacity-60 cursor-not-allowed"
          }`
        }
      >
        {props.name}
      </button>
    </>
  );
};

//Customised Remove or Delete button
export const ButtonDelete = (props) => {
  return (
    <>
      <button
        id={props.id}
        name={props.btnName}
        data-cy={props.dataCy}
        title={props.title}
        onClick={(e) => {
          if (!props.disabled) {
            props.onClick(e);
          }
        }}
        disabled={props.disabled}
        hidden={props.hidden}
        className={`${
          props.className
        } flex justify-center items-center  gap-2 w-fit h-10 px-2 text-gray-400 
        font-medium border border-gray-400 rounded shadow focus:shadow-lg hover:shadow-lg hover:text-white
         focus:text-white hover:bg-red-500 focus:bg-red-500 active:scale-95 transform transition 
         duration-100 ${props.disabled && "opacity-60 cursor-not-allowed"}`}
      >
        {props.name}
      </button>
    </>
  );
};

//Custom Icon Button
export const ButtonIcon = (props) => {
  return (
    <>
      <span
        title={props.title}
        hidden={props.hidden}
        onClick={(e) => {
          if (!props.disabled) {
            props.onClick(e);
          }
        }}
        disabled={props.disabled}
        className={`${
          props.className
        } p-2 rounded flex justify-center items-center cursor-pointer hover:bg-white
         hover:text-blue-52 hover:shadow-lg active:scale-95 transform transition duration-100 ${
           props.disabled && "opacity-60 cursor-not-allowed"
         }`}
        id={props.id}
        name={props.btnName}
        data-cy={props.dataCy}
      >
        {props.icon}
      </span>
    </>
  );
};

//Customised white Outline Button
export const ButtonWhiteOutline = (props) => {
  return (
    <button
      type="button"
      id={props.id}
      name={props.btnName}
      data-cy={props.dataCy}
      title={props.title}
      onClick={(e) => {
        e.preventDefault();
        if (!props.disabled) {
          props.onClick(e);
        }
        e.target.blur();
      }}
      disabled={props.disabled}
      hidden={props.hidden}
      className={
        props.className +
        ` w-24 h-10 px-2 text-white font-medium border border-white rounded shadow focus:shadow-lg 
        hover:shadow-lg hover:bg-white focus:bg-white hover:text-blue-52 focus:text-blue-52 
        active:scale-95 transform transition duration-100 ${
          props.disabled && "opacity-60 cursor-not-allowed"
        }`
      }
    >
      {props.name}
    </button>
  );
};
