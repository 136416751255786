import React, { useState } from "react";

//Reusable Component
import { clickEffect, DateFormatForAPI } from "../../../Widgets/CommonWidgets";
import { ButtonIcon } from "../../../Widgets/Buttons";
import {
  InputDateField,
  InputSelectField,
} from "../../../Widgets/Forms/InputFields";
import { TbFileArrowRight } from "react-icons/tb";
//External Imports
import { BsDownload, BsFillArrowLeftCircleFill } from "react-icons/bs";
import { TfiArrowCircleLeft, TfiArrowCircleRight } from "react-icons/tfi";
import { AiOutlineDownload } from "react-icons/ai";
import dayjs from "dayjs";
import { GetCompleteAddress } from "../../../Widgets/Forms/FieldDependants";

const ProfitandLossSummary = ({
  trigger,
  summaryType,
  parent,
  children,
  handleSummaryFilter,
  handlePagination,
  handleSummaryPageClose,
  handleReportDownload,
  handlePdfReportDownload
}) => {
  const [nextPage, setNextPage] = useState(children?._links?.next || "");
  const [prevPage, setPrevPage] = useState(children?._links?.prev || "");

  const ReportPeriodOptions = [
    "Custom",
    "Last 3 Months",
    "Last 6 Months",
    "Last Year",
    "Year to Date",
  ];

  const [reportFilter, setReportFilter] = useState({
    period: "",
    startDate: "",
    endDate: "",
  });

  const truncateString = (str, maxLength = 15) => {
    if (str.length <= maxLength) return str;
    return str.substring(0, maxLength) + "...";
  };

  const handleReportPeriodChange = (period) => {
    const currentDate = dayjs();
    let from = "";
    let to = "";
    if (period === "Custom") {
      setReportFilter({
        period: period,
        startDate: from,
        endDate: to,
      });
      return;
    } else if (period === "Last 3 Months") {
      from = currentDate.subtract(3, "month").startOf("month");
      to = currentDate.endOf("day");
    } else if (period === "Last 6 Months") {
      from = currentDate.subtract(6, "month").startOf("month");
      to = currentDate.endOf("day");
    } else if (period === "Last Year") {
      from = dayjs().subtract(1, "year").startOf("year");
      to = from.endOf("year");
    } else if (period === "Year to Date") {
      from = currentDate.startOf("year");
      to = currentDate.endOf("day");
    }

    handleCustomReportFilter(DateFormatForAPI(from), DateFormatForAPI(to));
    setReportFilter({
      period: period,
      startDate: from,
      endDate: to,
    });
  };

  const handleCustomReportFilter = (from, to) => {
    if (from && to) {
      handleSummaryFilter(
        summaryType,
        DateFormatForAPI(from),
        DateFormatForAPI(to)
      );
    }
  };

  return (
    trigger && (
      <div className="overflow-x-auto w-5/6 m-auto font-normal text-base">
        <span
          className={`flex justify-start pl-2 gap-2 items-center bg-blue-52 text-white text-center rounded h-10`}
          id="title"
        >
          <BsFillArrowLeftCircleFill
            title="Back"
            id="Back"
            onClick={(e) => handleSummaryPageClose()}
            className={`justify-self-start text-white w-6 h-6 ${clickEffect}`}
          />

          {summaryType === "Properties" && `Portfolio: ${parent.portfolioName}`}
          {summaryType === "SingleProperty" &&
            `Property: ${
              children.results[0].propertyName
            }, ${GetCompleteAddress(children.results[0].businessAddress)}`}
          {summaryType === "Units" && `Property: ${parent.propertyName}`}
          {summaryType === "SingleUnit" &&
            `Unit: ${children.results[0].unitNumber}`}
        </span>

        <section
          name="Searchbar and Options"
          className={"flex justify-between items-end my-4 mx-4"}
        >
          <div className="flex items-end gap-1">
            <InputSelectField
              label="Report Period: "
              name="reportPeriods"
              id="reportPeriods"
              notImp
              placeholder="Select Report Period"
              value={reportFilter.period}
              onChange={(e) => handleReportPeriodChange(e.target.value)}
              options={ReportPeriodOptions}
              className="!w-fit"
              style="flex items-center gap-1 !w-fit"
            />

            <InputDateField
              label="From"
              name="fromDate"
              id="fromDate"
              notImp
              btnName="fromDate"
              dataCy="fromDate"
              disabled={reportFilter.period !== "Custom"}
              value={reportFilter.startDate}
              onChange={(value) => {
                setReportFilter({
                  ...reportFilter,
                  startDate: value,
                });
                handleCustomReportFilter(value, reportFilter.endDate);
              }}
              className="!w-44"
              style="flex items-center gap-1 !w-fit"
            />
            <InputDateField
              label="To"
              name="toDate"
              id="toDate"
              notImp
              btnName="toDate"
              dataCy="toDate"
              disabled={reportFilter.period !== "Custom"}
              value={reportFilter.endDate}
              onChange={(value) => {
                setReportFilter({
                  ...reportFilter,
                  endDate: value,
                });
                handleCustomReportFilter(reportFilter.startDate, value);
              }}
              className="!w-44"
              style="flex items-center gap-1 !w-fit"
            />
            <button
              id="reset"
              onClick={() => {
                if (reportFilter.period !== "") {
                  handleSummaryFilter(summaryType, "", "");
                }
                setReportFilter({
                  period: "",
                  startDate: "",
                  endDate: "",
                });
              }}
              className={`underline text-blue-52 ` + clickEffect}
            >
              Reset
            </button>
          </div>
          
          <div className="flex flex-row gap-2">
          <ButtonIcon
            id="download"
            btnName="download"
            dataCy="download"
            title="Export CSV"
            onClick={(e) =>
              handleReportDownload(
                summaryType,
                reportFilter.startDate,
                reportFilter.endDate
              )
            }
            icon={<TbFileArrowRight className="w-6 h-6" />}
            className="hover:!bg-blue-52 hover:!text-white shadow-md"
          />
          <ButtonIcon
            id="download"
            btnName="download"
            dataCy="download"
            title="Download PDF"
            onClick={(e) =>
              handlePdfReportDownload(
                summaryType,
                reportFilter.startDate,
                reportFilter.endDate
              )
            }
            icon={<BsDownload className="w-6 h-6" />}
            className="hover:!bg-blue-52 hover:!text-white shadow-md"
          />
          </div>
          
        </section>

        <table className="min-w-full border border-gray-200 mt-4 text-left text-sm">
          <thead className="bg-[#D5EAF0]">
            <tr className="font-semibold">
              <th id="Description" className="px-4 py-2 border-b w-1/5">
                Description
              </th>
              <th
                id="parentTitle"
                title={
                  summaryType === "Properties" ||
                  summaryType === "SingleProperty"
                    ? `Portfolio: ${parent.portfolioName}`
                    : summaryType === "SingleUnit" || summaryType === "Units"
                    ? `Property: ${parent.propertyName}`
                    : ""
                }
                className="px-4 py-2 border-b border-x-4 border-x-white text-blue-53 w-1/5"
              >
                {(summaryType === "Properties" ||
                  summaryType === "SingleProperty") &&
                  `Portfolio: ${truncateString(parent.portfolioName, 8)}`}
                {(summaryType === "SingleUnit" || summaryType === "Units") &&
                  `Property: ${parent.propertyName}`}
              </th>
              {children.results.map((child, index) => (
                <th
                  id={"Child" + index}
                  key={"Child" + index}
                  className="px-4 py-2 border-b relative w-1/5"
                >
                  {index === 0 && prevPage && (
                    <ButtonIcon
                      disabled={!prevPage}
                      hidden={!prevPage}
                      id="PrevPage"
                      onClick={() =>
                        handlePagination(
                          prevPage,
                          summaryType,
                          reportFilter.startDate,
                          reportFilter.endDate
                        )
                      }
                      icon={<TfiArrowCircleLeft className="w-5 h-5" />}
                      className="absolute left-0 top-0 bg-blue-500 text-white rounded-full disabled:opacity-50 !w-fit"
                    />
                  )}
                  <div
                    id="ChildTitle"
                    title={
                      summaryType === "Properties" ||
                      summaryType === "SingleProperty"
                        ? GetCompleteAddress(child.businessAddress)
                        : summaryType === "SingleUnit" ||
                          summaryType === "Units"
                        ? child.unitNumber
                        : ""
                    }
                    className={`flex ${
                      prevPage || nextPage ? `text-center` : `text-left`
                    } items-center px-1 truncate`}
                  >
                    {(summaryType === "Properties" ||
                      summaryType === "SingleProperty") &&
                      truncateString(GetCompleteAddress(child.businessAddress))}
                    {(summaryType === "SingleUnit" ||
                      summaryType === "Units") &&
                      `${truncateString(child.unitNumber, 8)}`}
                  </div>
                  {index === children.results.length - 1 && nextPage && (
                    <ButtonIcon
                      disabled={!nextPage}
                      hidden={!nextPage}
                      id="NextPage"
                      onClick={() =>
                        handlePagination(
                          nextPage,
                          summaryType,
                          reportFilter.startDate,
                          reportFilter.endDate
                        )
                      }
                      icon={<TfiArrowCircleRight className="w-5 h-5" />}
                      className="absolute right-0 top-0 bg-blue-500 text-white rounded-full disabled:opacity-50 !w-fit"
                    />
                  )}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            <tr>
              <td
                id="Income/Expense"
                className="px-4 py-2 font-semibold bg-[#F7F7F7]"
              >
                Income/Expense
              </td>
              <td
                colSpan={children.results.length + 1}
                className="px-4 py-2 bg-[#F7F7F7] border-b border-l-4 border-l-white"
              ></td>
            </tr>
            {/* Income rows */}

            <tr>
              <td
                id="IncomeTitle"
                className="px-4 py-2 font-semibold underline"
              >
                Income
              </td>
              <td
                colSpan={children.results.length + 1}
                className="px-4 py-2"
              ></td>
            </tr>

            {Object.entries(parent.income).map(
              ([key, value], index) =>
                key !== "totalIncome" &&
                key !== "netOtherIncome" && (
                  <tr key={key + index}>
                    <td className="px-4 py-2">{key}</td>
                    <td className="px-4 py-2">${value}</td>
                    {children.results?.map((item, childIndex) => (
                      <td key={key + childIndex} className="px-4 py-2">
                        ${item.income[key] !== undefined ? item.income[key] : 0}
                      </td>
                    ))}
                  </tr>
                )
            )}

            <tr className="font-semibold">
              <td id="TotalIncome" className="px-4 py-2">
                Total Income
              </td>
              <td id="ParentTotalIncome" className="px-4 py-2">
                ${parent.income.totalIncome}
              </td>
              {children.results.map((item, index) => (
                <td
                  id="ChildTotalIncome"
                  key={"TotalIncome" + index}
                  className="px-4 py-2"
                >
                  ${item.income.totalIncome}
                </td>
              ))}
            </tr>

            <tr className="font-semibold">
              <td id="NetOtherIncome" className="px-4 py-2 border-b">
                Net Other Income
              </td>
              <td id="ParentnetOtherIncomeValue" className="px-4 py-2 border-b">
                ${parent.income.netOtherIncome}
              </td>
              {children.results.map((item, index) => (
                <td
                  id="ChildNetOtherIncomeValue"
                  key={"NetOtherIncome" + index}
                  className="px-4 py-2 border-b"
                >
                  ${item.income.netOtherIncome}
                </td>
              ))}
            </tr>

            {/* Expenses rows */}
            <tr>
              <td id="Expense" className="px-4 py-2 font-semibold underline">
                Expense
              </td>
              <td
                colSpan={children.results.length + 1}
                className="px-4 py-2"
              ></td>
            </tr>
            {parent.expenses.map((expense, index) => (
              <tr key={index}>
                <td id="ExpenseKey" className="px-4 py-2">
                  {expense.name}
                </td>
                <td id="ExpenseAmount" className="px-4 py-2">
                  ${expense.amount}
                </td>
                {children.results.map((item, index) => (
                  <td
                    id="ChildExpenseValue"
                    key={expense.name + index}
                    className="px-4 py-2"
                  >
                    $
                    {item.expenses.find((e) => e.name === expense.name)
                      ?.amount || 0}
                  </td>
                ))}
              </tr>
            ))}

            <tr className="font-semibold ">
              <td id="TotalExpenseTitle" className="px-4 py-2">
                Total Expense
              </td>
              <td id="ParentTotalExpenseValue" className="px-4 py-2">
                ${parent.totalExpense.totalExpense || 0}
              </td>
              {children.results.map((item, index) => (
                <td
                  id="childExpenseValue"
                  key={"TotalExpense" + index}
                  className="px-4 py-2"
                >
                  ${item.totalExpense.totalExpense || 0}
                </td>
              ))}
            </tr>

            <tr className="font-semibold border-b-2">
              <td id="NetOrdinaryIncome" className="px-4 py-2 border-b">
                Net Ordinary Expense
              </td>
              <td
                id="parentNetOrdinaryIncomeTitleValue"
                className="px-4 py-2 border-b"
              >
                ${parent.totalExpense.netOrdinaryExpense || 0}
              </td>
              {children.results.map((item, index) => (
                <td
                  id="ChildNetOrdinaryExpenseValue"
                  key={"NetOrdinaryExpense" + index}
                  className="px-4 py-2 border-b"
                >
                  ${item.totalExpense.netOrdinaryExpense || 0}
                </td>
              ))}
            </tr>

            <tr className="bg-gray-200 font-semibold border-y-4 border-y-white">
              <td id="NetOperatingIncome" className="px-4 py-2">
                Net Operating Income
              </td>
              <td
                id="ParentNetOperatingIncomeValue"
                className="px-4 py-2 border-b border-x-4 border-x-white"
              >
                ${parent.netOperatingIncome}
              </td>
              {children.results.map((item, index) => (
                <td
                  id="ChildNetOperatingIncomeValue"
                  key={"NetOperatingIncome" + index}
                  className="px-4 py-2"
                >
                  ${item.netOperatingIncome}
                </td>
              ))}
            </tr>

            <tr className="bg-gray-200 font-semibold">
              <td id="netIncome" className="px-4 py-2">
                Net Income
              </td>
              <td
                id="parentNetIncomeValue"
                className="px-4 py-2 border-x-4 border-x-white"
              >
                ${parent.netIncome}
              </td>
              {children.results.map((item, index) => (
                <td
                  id="ChildNetIncomeValue"
                  key={"NetIncome" + index}
                  className="px-4 py-2"
                >
                  ${item.netIncome}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    )
  );
};

export default ProfitandLossSummary;
