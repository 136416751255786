//Harness Feature Flag
import { FFContextProvider } from "@harnessio/ff-react-client-sdk";
import { userData } from "../../atoms";
import { useRecoilValue } from "recoil";

import config from "../../config";

export const FeatureFlagProvider = ({ children }) => {
  const userInfo = useRecoilValue(userData);

  const harnessTarget = {
    name: `${userInfo.userRole ? userInfo.userRole : "unknown"}`,
    identifier: userInfo.ID || "0",
    attributes: {
      userType: `${userInfo.userRole + "_" + userInfo.clientType}`,
    },
  };

  return (
    <>
      <FFContextProvider
        apiKey={config.FeatureFlagAPIKey}
        target={harnessTarget}
      >
        {children}
      </FFContextProvider>
    </>
  );
};
