import config from "./config";
import Axios from "axios";
import SessionIdGenerator from "./Utility/SessionIdGenerator";
import { getRecoil } from "recoil-nexus";
import { idToken, accessToken } from "./atoms";
import swal from "sweetalert";

//Loader Provider
import { showLoader, hideLoader } from "./Utility/loaderProvider";

const redirectToLogin = () => {
  window.location.href = "/login";
};

export class WebApimanager {
  async request(method, url, data = {}, qs = null, customOptions = {}) {
    const accessTokenVal = getRecoil(accessToken);
    const headers = {
      Authorization: "Bearer " + accessTokenVal,
      "Content-Type": "application/json",
    };

    const requestHeaders = {
      method,
      url: config.apiGateway.URL + url,
      data,
      headers,
      params: qs,
    };

    const { loader, text } = customOptions;

    try {
      if (loader !== false) {
        showLoader(text);
      }
      const response = await Axios(requestHeaders);
      return response;
    } catch (error) {
      const customError = new Error(
        "An error occurred while processing your request."
      );

      // Check if error.response is defined
      if (error.response) {
        // Extract the status code and message from the response if available
        customError.status = error.response.status;
        customError.message = error.response.data?.message || "Server Error";

        // Handle specific status code for authorization failure
        if (error.response.status === 401) {
          customError.message = "Authorization failed. Please log in again.";
          redirectToLogin(); // Redirect user to login page
        }
      } else if (error.request) {
        // Handle cases where no response was received
        customError.message = "No response received from server.";
      } else {
        // Handle other errors
        customError.message = error.message || "An unexpected error occurred.";
      }

      // Show an alert with the error message
      swal("Error", customError.message, "error");

      // Throw the custom error to propagate it further if needed
      throw customError;
    } finally {
      hideLoader();
    }
  }

  get(url, qs, customOptions) {
    if (qs && qs.loader) {
      //If No query String
      return this.request("get", url, null, null, qs);
    }
    return this.request("get", url, null, qs, customOptions);
  }

  delete(url, data, customOptions) {
    if (data && data.loader) {
      //If no request data
      return this.request("delete", url, null, null, data);
    }

    return this.request("delete", url, data, null, customOptions);
  }

  put(url, data, customOptions) {
    if (data && data.loader) {
      //If no request data
      return this.request("put", url, null, null, data);
    }

    return this.request("put", url, data, null, customOptions);
  }

  post(url, data, customOptions) {
    if (data && data.loader) {
      //If no request data
      return this.request("post", url, null, null, data);
    }

    return this.request("post", url, data, null, customOptions);
  }

  patch(url, data, customOptions) {
    if (data && data.loader) {
      //If no request data
      return this.request("patch", url, null, null, data);
    }
    return this.request("patch", url, data, null, customOptions);
  }

  //Custom Image Upload APi
  async uploadFile(
    prefix,
    metaData,
    file,
    customOptions = { loader: true, text: "Uploading" }
  ) {
    // Show loader if customOptions.loader is true
    if (customOptions.loader) {
      showLoader(customOptions.text);
    }

    const data = {
      objectKey: prefix,
      metadata: metaData,
    };

    // Function to generate headers from metadata
    const generateHeadersFromMetadata = (metadata) => {
      const headers = {};
      for (const key in metadata) {
        headers[`x-amz-meta-${key}`] = metadata[key];
      }
      return headers;
    };

    try {
      // Make a POST request to get the upload URL
      const res = await this.post(`/documents/upload`, data, customOptions);

      if (res.data.errorCode) {
        throw new Error(res.data.errorMessage);
      }

      // Show loader again during file upload if customOptions.loader is true
      if (customOptions.loader) {
        showLoader(customOptions.text);
      }

      const fileHeaders = generateHeadersFromMetadata(data.metadata);

      // Make the actual file upload request to AWS
      const awsRes = await Axios({
        method: "PUT",
        url: res.data.objectKey,
        data: file,
        headers: fileHeaders,
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          // Display loader with progress if customOptions.loader is true
          if (customOptions.loader) {
            showLoader(`Uploading ${progress}% of ${file.name}!`);
          }
        },
      });

      // Hide loader after successful upload if customOptions.loader is true
      if (customOptions.loader) {
        hideLoader();
      }

      // Check the status of the AWS response
      if (awsRes.status === 200) {
        return awsRes;
      } else {
        throw new Error("AWS File Upload Failed");
      }
    } catch (error) {
      // Hide loader in case of error if customOptions.loader is true
      if (customOptions.loader) {
        hideLoader();
      }
      // Show error message using swal and rethrow the error
      swal(
        "Error",
        error.message || `${file.name} file upload failed`,
        "error"
      );
      throw error;
    }
  }
}
