import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TableComponent, Searchbar } from "../../Widgets/CommonWidgets";
import { WebApimanager } from "../../WebApiManager";
import { userData, navigatedNotif, navigatedNotifIndex } from "../../atoms";
import { useRecoilValue } from "recoil";
import { GetDateInMMDDYYYY } from "../../Widgets/CommonWidgets";
import { ButtonBlue, ButtonBlueOutline } from "../../Widgets/Buttons";
import CreateNotifications from "./createNotification";
import swal from "sweetalert";
import { Loader } from "../../Widgets/notificationFeedbacks";
import { RiDeleteBin7Line } from "react-icons/ri";
import { GiSettingsKnobs } from "react-icons/gi";
import { InfoIcon } from "../../Widgets/Icons";


const Notifications = () => {
  let webApi = new WebApimanager();
  let navigate = useNavigate();
  const userInfo = useRecoilValue(userData);
  const navNotif = useRecoilValue(navigatedNotif);
  const navNotifIndex = useRecoilValue(navigatedNotifIndex);
  const [tableData, setTableData] = useState([]);
  const [notificationsData, setNotificationsData] = useState([]);
  const [openNotifForm, setOpenNotifForm] = useState(false);
  const [readingNotifs, setReadingNotifs] = useState(false);
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const [showNotifs, setNotifs] = useState([]);
  const [selectedRowIndex, setSelectedRowIndex] = useState();
  const [activeMenuItem, setActiveMenuItem] = useState("received");
  const notifTabs = [
    { label: "Received", nickName: "received" },
    { label: "Sent", nickName: "sent" },
  ];
  const [isClientAdmin, setIsClientAdmin] = useState(false);

  useEffect(() => {
    setApiProcessing({
      loader: true,
      message: `Getting Notifications...`,
    });
    getNotifications();
    if (navNotif && Object.keys(navNotif).length > 0) {
      setReadingNotifs(true);
      setNotifs(navNotif);
      //console.log(navNotifIndex)
      setSelectedRowIndex(navNotifIndex);
    } else {
      setReadingNotifs(false);
      setNotifs([]);
      setSelectedRowIndex(null);
    }
    // console.log(userInfo.userRole)
    if (userInfo.userRole === "Client Admin") {
      setIsClientAdmin(true);
    }
  }, []);

  const gettingAfterComposing = () => {
    if (activeMenuItem === "received") {
      getNotifications();
    } else {
      getSentNotifications();
    }
  };

  const getNotifications = () => {
    setApiProcessing({
      loader: true,
      message: `Getting Notifications...`,
    });
    webApi
      .get("/notification/client/" + userInfo.ID + "&received")
      .then((res) => {
        if (res.data.errorCode) {
          //console.log("Failure", res.data.errorMessage, "error");
        } else {
          setTableData(res.data);
          setNotificationsData(res.data);
          // setNotifs(res.data[0]);
          // setSelectedRowIndex(0);
        }
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Unable to get the Notifications, Please try again later.`,
            "error"
          );
        }
      });
  };

  const notifTableActions = {
    deleteTitle: "Delete Notification",
    deleteOnClick: (row) => {
      deleteNotifications(row);
    },
  };

  const handleNotificationClick = (row, index) => {
    setReadingNotifs(true);
    setNotifs(row);
    //console.log(navNotifIndex)
    setSelectedRowIndex(index);
    if (row.isRead === false) {
      webApi
        .put("/notification/isRead/" + row.ID + "&" + true)
        .then((res) => {
          if (res.data.errorCode) {
            //console.log("Failure", res.data.errorMessage, "error");
          } else {
            //console.log("success in updatenotif");
            // getNotifications();
            gettingAfterComposing();
          }
        })
        .catch((error) => {
          if (error.customErrorMessage) {
            swal("Error", error.customErrorMessage, "error");
          } else {
            //console.log("Notification Is Read API Failed");
          }
        });
    }
  };

  const deleteNotifications = (notif) => {
    swal({
      title: "Are you sure?",
      text: "You want to delete this Notfication!",
      icon: "warning",
      buttons: ["Cancel", "Yes, delete it!"],
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        webApi
          .delete("/notification/" + notif.ID)
          .then((res) => {
            if (res.data.errorCode !== 200) {
              swal("Failure", res.data.errorMessage, "error");
            } else {
              // getNotifications();
              swal("Success", res.data.errorMessage, "success");
              gettingAfterComposing();
              setReadingNotifs(false);
            }
          })
          .catch((error) => {
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                "Failed to Delete Notification, Please try again later.",
                "error"
              );
            }
          });
      } else {
        return;
      }
    });
  };

  const handleTabClick = (e) => {
    //console.log(activeMenuItem, "activeitem")
    e.preventDefault();
    if (activeMenuItem === "received") {
      getSentNotifications();
    } else {
      getNotifications();
    }
  };

  const getSentNotifications = () => {
    setApiProcessing({
      loader: true,
      message: `Getting Notifications...`,
    });
    //console.log("sentNotifs")
    webApi
      .get("/notification/client/" + userInfo.ID + "&sent")
      .then((res) => {
        setApiProcessing({
          loader: false,
          message: `Getting Notifications...`,
        });
        if (res.data.errorCode) {
          //console.log("Failure", res.data.errorMessage, "error");
        } else {
          setTableData(res.data);
          setNotificationsData(res.data);
          // setNotifs(res.data[0]);
          // setSelectedRowIndex(0);
        }
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: `Getting Notifications...`,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Unable to get the Notifications, Please try again later.`,
            "error"
          );
        }
      });
  };
 
  return (
    <>
      {openNotifForm ? (
        <CreateNotifications
          trigger={openNotifForm}
          setTrigger={setOpenNotifForm}
          getAllNotifs={() => gettingAfterComposing()}
        />
      ) : null}

      <Loader open={apiProcessing.loader} message={apiProcessing.message} />

      <div className="w-5/6 m-auto">
        <span className="flex justify-center items-center bg-blue-52 text-white font-semibold text-center rounded h-10">
          Notifications 
          <InfoIcon
          message="Use the Notifications System to receive and manage important updates and messages!"
          zIndex="10"
          />
        </span>

        <section
          name="Searchbar and Options"
          className={"flex justify-between items-center mt-4 mb-2 mx-4"}
        >
          <span className="font-semibold text-xl">Notifications</span>
          <span className="flex items-center gap-4">
            {isClientAdmin && (
              <ButtonBlueOutline
                id="manageNotifications"
                btnName="manageNotifications"
                dataCy="manageNotifications"
                name={
                  <>
                    <GiSettingsKnobs className="w-5 h-5 mr-2" /> MANAGE
                  </>
                }
                onClick={() => {
                  navigate("/notifications/manageNotification");
                }}
                className={`flex justify-center items-center px-2 w-fit h-10 rounded-md`}
              />
            )}
            <ButtonBlueOutline
              id="compose"
              btnName="compose"
              dataCy="compose"
              name="Compose"
              onClick={() => setOpenNotifForm(true)}
              className={`rounded-lg font-semibold`}
            />
            <Searchbar
              styles="ml-2 mr-4"
              placeholder="Search Notifications..."
              data={notificationsData}
              matchProps={[
                { text: "name" },
                { text: "heading" },
                { text: "notificationType" },
                { date: "CreatedAt" },
              ]}
              setTableData={setTableData}
            />
          </span>
        </section>

        <section
          name="tabs"
          className="flex justify-around items-center h-10 font-semibold text-gray-600 cursor-pointer border-blue-52 w-52"
        >
          {notifTabs.map((tab, index) => (
            <p
              key={index}
              id={tab.nickName}
              data-cy={tab.nickName}
              className={`hover:text-[#003C9A] focus:text-[#003C9A] pb-2 w-fit text-center ${
                activeMenuItem === tab.nickName
                  ? `text-[#003C9A] border-[#003C9A] font-semibold w-fit border-b-2`
                  : `text-gray-600 border-gray-600`
              } 
                `}
              onClick={(e) => {
                setActiveMenuItem(tab.nickName);
                handleTabClick(e);
              }}
            >
              {tab.label}
            </p>
          ))}
        </section>

        <div>
          <TableComponent
            tableId="NotificationsTable"
            data={tableData}
            columns={[
              {
                key: "name",
                title: "From",
                getCellStyles: (row) => {
                  if (row.isRead === false) {
                    return { fontWeight: "600" };
                  }
                },
              },
              {
                key: "sentTo",
                title: "To",
                getCellStyles: (row) => {
                  if (row.isRead === false) {
                    return { fontWeight: "600" };
                  }
                },
              },
              {
                key: "heading",
                title: "Subject",
                getCellStyles: (row) => {
                  if (row.isRead === false) {
                    return { fontWeight: "600" };
                  }
                },
              },
              {
                key: "notificationType",
                title: "Notification Type",
                getCellStyles: (row) => {
                  if (row.isRead === false) {
                    return { fontWeight: "600" };
                  }
                },
              },
              {
                key: "CreatedAt",
                title: "Received On",
                applyMethod: (row) => {
                  return GetDateInMMDDYYYY(row.CreatedAt);
                },
                getCellStyles: (row) => {
                  if (row.isRead === false) {
                    return { fontWeight: "600" };
                  }
                },
              },
            ]}
            selectedRowIndex={selectedRowIndex}
            rowOnClick={(row, index) => {
              handleNotificationClick(row, index); // Update the selected row index
            }}
            actionHeader="Actions"
            actions={notifTableActions}
            //initialSort={{ key: "CreatedAt", direction: "descending" }}
          />

          {readingNotifs ? (
            <section className="flex flex-col bg-blue-100 w-full h-64 rounded-md">
              <div className="ml-5 mr-5 mt-4">
                <div className="flex flex-row justify-between mb-2">
                  <p className="text-gray-500">
                    Subject :{" "}
                    <span className="text-black">
                      {(showNotifs && showNotifs.heading) || "-"}
                    </span>
                  </p>
                  <RiDeleteBin7Line
                    id="deleteNotification"
                    name="deleteNotification"
                    data-cy="deleteNotification"
                    title="Delete"
                    className="w-4 h-5 cursor-pointer hover:text-red-500 focus:text-red-500"
                    onClick={() => deleteNotifications(showNotifs)}
                  />
                </div>
                <div className="flex flex-row justify-between mb-5">
                  <p className="text-gray-500">
                    From :{" "}
                    <span className="text-black">
                      {(showNotifs && showNotifs.name) || "-"}
                    </span>
                  </p>
                  <p>
                    {showNotifs && showNotifs.CreatedAt
                      ? GetDateInMMDDYYYY(showNotifs.CreatedAt)
                      : ""}
                  </p>
                </div>
                <p className="w-full h-36 overflow-x-auto">
                  {(showNotifs && showNotifs.message) || "-"}
                </p>
              </div>
            </section>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Notifications;
