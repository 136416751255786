import React, { useState } from "react";
import { IconInCircle, InfoIcon } from "../../Widgets/Icons";
import { Searchbar } from "../../Widgets/CommonWidgets";
import { useNavigate } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import { FaStar } from "react-icons/fa";

const ReportDashboard = () => {
  let navigate = useNavigate();
  const [activeMenuItem, setActiveMenuItem] = useState("property");
  const tabs = [
    // { label: "Standard", nickName: "standard" },
    { label: "Property", nickName: "property" },
    { label: "Tenant", nickName: "tenant" },
    { label: "Unit", nickName: "unit" },
    { label: "Work Orders", nickName: "workOrders" },
    { label: "Audit History", nickName: "auditHistory" },
    { label: "Accounting", nickName: "accounting" },
    // { label: "Vendor Reports", nickName: "vendorReports" },
    // { label: "More Reports", nickName: "moreReports" },
  ];

  const recentReports = [
    { label: "Tenant Directory Report", star: true },
    {
      label: "Unit Directory Report",
      star: true,
      goTo: "/reports/unitDirectory",
    },
    {
      label: "Rental Ledger Report",
      star: true,
      goTo: "/reports/rentalLedger",
    },
    { label: "Work Order Directory Report", star: false },
    { label: "Asset Directory Report", star: true },
  ];
  const favouriteReports = [
    { label: "Asset Directory Report", star: true },
    {
      label: "Rental Ledger Report",
      star: true,
      goTo: "/reports/rentalLedger",
    },
    { label: "Unit Directory Report", star: true },
  ];

  const reports = {
    property: [
      {
        label: "Rental Ledger Report",
        star: false,
        goTo: "/reports/rentalLedger",
      },
    ],

    tenant: [
      {
        label: "Tenant Directory Report",
        star: false,
        goTo: "/reports/tenantDirectory",
      },
      {
        label: "Delinquency Report",
        star: false,
        goTo: "/reports/delinquency",
      },
    ],

    unit: [
      {
        label: "Unit Directory Report",
        star: false,
        goTo: "/reports/unitDirectory",
      },
    ],

    workOrders: [
      {
        label: "Work Order Report",
        star: false,
        goTo: "/reports/workOrder",
      },
    ],

    auditHistory: [
      {
        label: "Rent Roll Report",
        star: false,
        goTo: "/reports/rentRoll",
      },
    ],

    accounting: [
      {
        label: "P&L Report",
        start: false,
        goTo: "/reports/profitandLoss",
      },
    ],
  };
  return (
    <>
      <div className="w-5/6 m-auto">
        <span className="flex justify-center items-center mb-12 bg-blue-52 text-white font-semibold text-center rounded h-10">
          REPORTS 
          <InfoIcon
          message="Use the advanced Report Generating System to track Cashflow, Payroll, and much more!"
          />

          
      
        </span>

        <section
          name="Header and SearchBar"
          className="flex justify-between items-center mb-8 mx-6"
        >
          <span className="flex items-center gap-4">
            <IconInCircle
              icon={<CgFileDocument className="w-9 h-9 text-[#2C88AE]" />}
              radius="4rem"
              className="shadow-xl"
            />
            <span className="font-semibold">
              Genarate Quick Reports!
              <p className="text-gray-400/100 font-normal">
                Use the advanced Report Generating System to track Cashflow,
                Payroll and much more!
              </p>
            </span>
          </span>
          {/* <Searchbar
            placeholder="Search..."
            data={[]}
            matchProps={[]}
            setTableData={[]}
          /> */}
        </section>

        <section
          name="tabs"
          className="flex justify-around items-center h-10 font-medium text-gray-600 cursor-pointer mb-4"
        >
          {tabs.map((tab, index) => (
            <p
              key={index}
              id={tab.nickName}
              className={`hover:text-[#003C9A] focus:text-[#003C9A] pb-2 border-b-2 w-full text-center ${
                activeMenuItem === tab.nickName
                  ? `text-[#003C9A] border-[#003C9A] font-semibold`
                  : `text-gray-600 border-gray-600`
              }`}
              onClick={(e) => setActiveMenuItem(tab.nickName)}
            >
              {tab.label}
            </p>
          ))}
        </section>

        {activeMenuItem === "standard" && (
          <section name="Recents and Favourites">
            <div name="Recent Reports" className="mb-8">
              <p className="border-l-4 border-[#326CBD] bg-[#DFEFF4] font-semibold py-2 pl-2">
                Recent
              </p>
              {recentReports.map((report, index) => (
                <span
                  key={index}
                  onClick={(e) => navigate(report.goTo || "")}
                  className="flex justify-between items-center px-2 text-gray-600 py-2 cursor-pointer hover:bg-[#FAFAFA] focus:[#FAFAFA]"
                >
                  <span>{report.label}</span>
                  <FaStar
                    color={report.star ? "#F8A700" : "#a9a9a9"}
                    className="w-4 h-5"
                  />
                </span>
              ))}
            </div>

            <div name="Favourite Reports" className="mb-8">
              <p className="border-l-4 border-[#326CBD] bg-[#DFEFF4] font-semibold py-2 pl-2">
                Favourites
              </p>
              {favouriteReports.map((report, index) => (
                <p
                  key={index}
                  onClick={(e) => navigate(report.goTo || "")}
                  className="flex justify-between items-center px-2 text-gray-600 py-2 cursor-pointer hover:bg-[#FAFAFA] focus:[#FAFAFA]"
                >
                  <span>{report.label}</span>
                  <FaStar
                    color={report.star ? "#F8A700" : "#a9a9a9"}
                    className="w-4 h-5"
                  />
                </p>
              ))}
            </div>
          </section>
        )}

        {activeMenuItem && reports[activeMenuItem] && (
          <div name={activeMenuItem + "Reports"}>
            {reports[activeMenuItem].map((report, index) => (
              <span
                key={index}
                onClick={(e) => navigate(report.goTo || "")}
                className={`flex justify-start items-center px-2 text-gray-600 py-2 cursor-pointer hover:shadow-md hover:bg-[#FAFAFA] focus:bg-[#FAFAFA] active:scale-95 transform transition duration-100`}
              >
                <span>{report.label}</span>
                {/* <FaStar
                  color={report.star ? "#F8A700" : "#a9a9a9"}
                  className="w-4 h-5"
                /> */}
              </span>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default ReportDashboard;
