import React, { useState, useEffect } from "react";
import { WebApimanager } from "../../WebApiManager";
import {
  TextReviewTile,
  FileReviewTile,
  MultiFileReviewTile,
} from "../../Widgets/Forms/FormReviewTiles";
import InputField, {
  InputSelectField,
  InputPhoneNumField,
  InputFileField,
  InputObjectSelectField,
  InputDateField,
  InputDollarsField,
} from "../../Widgets/Forms/InputFields";
import {
  ButtonCustom,
  ButtonBlue,
  ButtonGrayOutline,
  ButtonIcon,
} from "../../Widgets/Buttons";
import { PhoneNumFormat } from "../../Widgets/Forms/FieldDependants";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {
  AiOutlinePlusCircle,
  AiOutlineMinusCircle,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import { PetFeeDisclaimer } from "../../Widgets/Forms/FieldDependants";
import { SnackAlert } from "../../Widgets/notificationFeedbacks";
import {
  GetDateInMMDDYYYY,
  clickEffect,
  DateFormatForAPI,
} from "../../Widgets/CommonWidgets";
import { Loader } from "../../Widgets/notificationFeedbacks";
import swal from "sweetalert";
import dayjs from "dayjs";
import { BsFillInfoSquareFill } from "react-icons/bs";

const CreateTenant = (props) => {
  let webApi = new WebApimanager();
  const [allUnits, setAllUnits] = useState(props.allUnits);
  const editImport = props.operation === "Import" ? true : false;
  const [isSingleFamily, setIsSingleFamily] = useState(
    props.isSingleFamily ? true : false
  );
  const [tenantDetails, setTenantDetails] = useState([
    {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      tenantStatus: "",
    },
  ]);

  const [occupantDetails, setOccupantDetails] = useState([
    {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      age: "",
    },
  ]);

  const [leaseStartDate, setLeaseStartDate] = useState("");
  const [moveInDate, setMoveInDate] = useState("");
  const [leaseEndDate, setLeaseEndDate] = useState("");
  const [leaseDuration, setLeaseDuration] = useState("");
  const [leaseDocument, setLeaseDocument] = useState("");
  const [otherDocuments, setOtherDocuments] = useState([]);
  const [uploadDocuments, setUploadDocuments] = useState([]);
  const [uploadDocumentsNames, setUploadDocumentsNames] = useState([]);
  const [selectedUnitId, setSelectedUnitId] = useState(
    props.allUnits.length && isSingleFamily ? props.allUnits[0].ID : ""
  );
  const [selectedUnit, setSelectedUnit] = useState(
    props.allUnits.length && isSingleFamily ? props.allUnits[0] : ""
  );
  const [securityDeposit, setSecurityDeposit] = useState("");
  const [monthlyRent, setMonthlyRent] = useState("");
  const [lateFee, setLateFee] = useState("");
  const [dueDay, setDueDay] = useState("");
  const [rentableItems, setRentableItems] = useState("");
  const [petsAllowed, setPetsAllowed] = useState("");
  const [noOfPets, setNoOfPets] = useState("");
  const [typeOfPets, setTypeOfPets] = useState("");
  const [petDeposit, setPetDeposit] = useState("");
  const [petFee, setPetFee] = useState("");

  const [formStep1Errors, setFormStep1Errors] = useState([
    {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      tenantStatus: "",
    },
  ]);
  const [formStep2Errors, setFormStep2Errors] = useState([
    {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      age: "",
    },
  ]);
  const [formStep3Errors, setFormStep3Errors] = useState("");

  let goToStep2 = false;
  let goToStep3 = false;
  const [documentType, setDocumentType] = useState("lease");
  const [activeStep, setActiveStep] = useState(0);

  const [snackAlert, setSnackAlert] = useState({
    open: false,
    type: "info",
    message: "",
  });

  const [tenantStatusOptions, setTenantStatusOptions] = useState([]);

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  const inputNumCustomProps = {
    min: 0,
  };

  useEffect(() => {
    getTenantStatus();
    if (editImport) {
      getAllUnits();
      setInitialValues(props.tenantData);
    }
    // if (props.allUnits.length && !editImport) {
    //   checkAvailableUnits(props.allUnits);
    // }
  }, [editImport]);

  const checkAvailableUnits = (FilterUnits) => {
    const availableUnits = FilterUnits.filter(
      (unit) => unit.unitStatus === "Available"
    );
    if (!availableUnits.length) {
      swal(
        "Warning",
        "Oops! No Available Units to Link to Tenant, Please try again when there are Units Available.",
        "warning"
      );
    }
    setAllUnits(availableUnits);
  };

  const getTenantStatus = () => {
    webApi
      .get(`/tenant/list/status`)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setTenantStatusOptions(res.data.status);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Unable to load the Tenant Status options. Please try again later.",
            "error"
          );
        }
      });
  };

  const getAllUnits = () => {
    webApi
      .get("/units/property/" + props.Property.ID)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          // checkAvailableUnits(res.data);
          setAllUnits(res.data);

          if (isSingleFamily && res.data.length)
            setSelectedUnitId(res.data[0].ID);
          setSelectedUnit(res.data[0]);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops!, Unable to get Units! Please try again later.`,
            "error"
          );
        }
      });
  };

  const setInitialValues = (data) => {
    // Creating Errors objects based on the length of the tenants array
    if (data.Tenant.length > 1) {
      const errors = [];
      for (let i = 0; i < data.Tenant.length; i++) {
        errors.push({
          firstName: "",
          middleName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          tenantStatus: "",
        });
      }
      setFormStep1Errors(errors);
    }

    setTenantDetails(data.Tenant);
    setLeaseStartDate(data.LeaseTerms.leaseStartDate);
    setMoveInDate(data.LeaseTerms.moveInDate);
    setLeaseEndDate(data.LeaseTerms.leaseEndDate);
    getMonths(data.LeaseTerms.leaseStartDate, data.LeaseTerms.leaseEndDate);
    setSecurityDeposit(data.LeaseTerms.securityDeposit);
    setMonthlyRent(data.LeaseTerms.monthlyRent);
    setLateFee(data.LeaseTerms.lateFee);
    setDueDay(data.LeaseTerms.dueDay);
    setRentableItems(data.LeaseTerms.rentableItems);
    setPetsAllowed(data.LeaseTerms.petsAllowed);
    if (data.LeaseTerms.petsAllowed) {
      setNoOfPets(data.LeaseTerms.numberOfPets);
      setTypeOfPets(data.LeaseTerms.typeOfPets);
      setPetDeposit(data.LeaseTerms.petDeposite);
      setPetFee(data.LeaseTerms.petFee);
    }
  };

  const tenantValidate = () => {
    const errorsArr = [];
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    goToStep2 = true;
    for (var i = 0; i < tenantDetails.length; i++) {
      let errors = {};
      if (!tenantDetails[i].email) {
        errors.email = "Email is required!";
      } else if (!emailRegex.test(tenantDetails[i].email)) {
        errors.email = "Email is not valid!";
      }
      if (!tenantDetails[i].firstName) {
        errors.firstName = "First Name is required!";
      }
      if (!tenantDetails[i].lastName) {
        errors.lastName = "Last Name is required!";
      }
      if (!tenantDetails[i].phoneNumber) {
        errors.phoneNumber = "Contact Number is required!";
      } else if (tenantDetails[i].phoneNumber.length !== 12) {
        errors.phoneNumber = "Contact Number is not valid!";
      }
      if (!tenantDetails[i].tenantStatus) {
        errors.tenantStatus = "Status is required!";
      }

      if (Object.keys(errors).length) {
        goToStep2 = false;
      }
      errorsArr.push(errors);
    }

    return errorsArr;
  };

  const OccupantValidate = () => {
    const errorsArr = [];
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    goToStep3 = true;

    for (var index = 0; index < occupantDetails.length; index++) {
      const errors = {};

      // Check if any field is entered before applying validation
      const isAnyFieldEntered =
        occupantDetails[index].firstName ||
        occupantDetails[index].lastName ||
        occupantDetails[index].email ||
        occupantDetails[index].phoneNumber ||
        occupantDetails[index].age;

      if (isAnyFieldEntered) {
        if (!occupantDetails[index].email) {
          errors.email = "Email is required!";
        } else if (!emailRegex.test(occupantDetails[index].email)) {
          errors.email = "Email is not valid!";
        }

        if (!occupantDetails[index].firstName) {
          errors.firstName = "First Name is required!";
        }

        if (!occupantDetails[index].lastName) {
          errors.lastName = "Last Name is required!";
        }

        if (!occupantDetails[index].age) {
          errors.age = "Age is required!";
        }

        if (!occupantDetails[index].phoneNumber) {
          errors.phoneNumber = "Contact Number is required!";
        } else if (occupantDetails[index].phoneNumber.length !== 12) {
          errors.phoneNumber = "Contact Number is not valid!";
        }

        if (Object.keys(errors).length) {
          goToStep3 = false;
        }
      }

      errorsArr.push(errors);
    }

    return errorsArr;
  };

  const finalValidate = () => {
    const errors = {};
    if (!leaseStartDate) {
      errors.leaseStartDate = "Date is required!";
    }
    if (!moveInDate) {
      errors.moveInDate = "Date is required!";
    } else if (
      leaseStartDate &&
      new Date(moveInDate) < new Date(leaseStartDate)
    ) {
      errors.moveInDate =
        "Move In date should be later than the Lease Start date!";
    }
    if (!leaseEndDate) {
      errors.leaseEndDate = "Date is required!";
    } else if (
      leaseStartDate &&
      new Date(leaseEndDate) < new Date(leaseStartDate)
    ) {
      errors.leaseEndDate =
        "Lease End date should be later than the Lease Start date!";
    } else if (
      leaseStartDate &&
      new Date(leaseEndDate) < new Date(moveInDate)
    ) {
      errors.leaseEndDate =
        "Lease End date should be later than the Move In date!";
    }
    if (!leaseDuration) {
      errors.leaseDuration = "Duration is required!";
    }
    if (!leaseDocument) {
      errors.leaseDocument = "Lease Document is required!";
    }
    if (!selectedUnitId && !isSingleFamily) {
      errors.selectedUnitId = "Unit selection is required!";
    }
    if (!securityDeposit) {
      errors.securityDeposit = "Deposit is required!";
    }
    if (!monthlyRent) {
      errors.monthlyRent = "Monthly rent is required!";
    }
    if (!dueDay) {
      errors.dueDay = "Due Day is required!";
    }
    if (!lateFee) {
      errors.lateFee = "Late Fee is required!";
    }
    if (selectedUnit && selectedUnit.isPetsAllowed && petsAllowed === "") {
      errors.petsAllowed = "Pets Allowed is required!";
    }
    if (petsAllowed && !noOfPets) {
      errors.noOfPets = "No of Pets is required!";
    }
    if (petsAllowed && !typeOfPets) {
      errors.typeOfPets = "Type of Pets is required!";
    }
    if (petsAllowed && !petDeposit) {
      errors.petDeposit = "Deposit is required!";
    }
    if (petsAllowed && !petFee) {
      errors.petFee = "Pet Fee is required!";
    }
    return errors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (apiProcessing.loader) {
      setSnackAlert({
        open: true,
        type: "warning",
        message: "Tenant Creation is in Progress!",
      });
    } else {
      setApiProcessing({
        loader: true,
        message: "Creating Tenant...",
      });

      const shouldSendOccupantDetails = Object.values(occupantDetails[0]).some(
        (value) => value !== ""
      );
      const occupantDetailsToSend = shouldSendOccupantDetails
        ? occupantDetails
        : [];

      let data = {
        tenants: tenantDetails,
        occupants: occupantDetailsToSend,
        leaseTerms: {
          unitID: selectedUnitId,
          leaseStartDate: DateFormatForAPI(leaseStartDate),
          leaseEndDate: DateFormatForAPI(leaseEndDate),
          moveInDate: DateFormatForAPI(moveInDate),
          leaseDuration: leaseDuration,
          securityDeposit: securityDeposit,
          monthlyRent: monthlyRent,
          lateFee: lateFee,
          rentableItems: rentableItems,
          petsAllowed: petsAllowed,
          numberOfPets: noOfPets,
          typeOfPets: typeOfPets,
          petDeposite: petDeposit,
          petFee: petFee,
          dueDay: dueDay,
          documents: uploadDocumentsNames,
        },
      };
      webApi
        .post("/tenant", data)
        .then(async (res) => {
          if (res.data.errorCode) {
            setApiProcessing({
              loader: false,
              message: "Loading...",
            });
            swal("Error", res.data.errorMessage, "error");
          } else {
            const uploadPromises = uploadDocuments.map(async (document, i) => {
              setApiProcessing({
                loader: true,
                message: "Uploading Documents...",
              });
              setSnackAlert({
                open: true,
                type: "info",
                message:
                  "Uploading " +
                  (i + 1) +
                  "/" +
                  uploadDocuments.length +
                  " Documents",
              });

              const metaData = {
                filename: document.name,
                mediatype: document.type,
              };

              return webApi
                .uploadFile(
                  res.data.leaseTerms.documents[i],
                  metaData,
                  document
                )
                .then((res) => {})
                .catch((error) => {
                  if (error.customErrorMessage) {
                    swal("Error", error.customErrorMessage, "error");
                  } else {
                    swal(
                      "Error",
                      `Failed to upload ${document.name}. Please try again later.`,
                      "error"
                    );
                  }
                });
            });

            Promise.all(uploadPromises)
              .then((results) => {
                console.log("setteled Success");
                props.getTenants(res.data);
                setApiProcessing({
                  loader: false,
                  message: "Loading...",
                });
                swal("Success", "Successfully Created a Tenant", "success");
                props.setTenantFormPage(false);
              })
              .catch((error) => {
                console.log("setteled error");
                props.getTenants(res.data);
                setApiProcessing({
                  loader: false,
                  message: "Loading...",
                });
                swal("Success", "Successfully Created a Tenant", "success");
                props.setTenantFormPage(false);
              });
          }
        })
        .catch((error) => {
          setApiProcessing({
            loader: false,
            message: "Loading...",
          });
          if (error.customErrorMessage) {
            swal("Error", error.customErrorMessage, "error");
          } else {
            swal(
              "Error",
              `Oops! Failed to Create Tenant, Please try again later.`,
              "error"
            );
          }
        });
    }
  };

  const handleTenantDetails = (i, name, value) => {
    let details = [...tenantDetails];
    details[i][name] = value;
    setTenantDetails(details);
  };

  const handleOccupantDetails = (i, name, value) => {
    let details = [...occupantDetails];
    details[i][name] = value;
    setOccupantDetails(details);
  };

  const addNewTenant = (event) => {
    event.preventDefault();
    setTenantDetails([
      ...tenantDetails,
      {
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        tenantStatus: "",
      },
    ]);
    setFormStep1Errors([
      ...formStep1Errors,
      {
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        tenantStatus: "",
      },
    ]);
  };

  const addNewOccupant = (event) => {
    event.preventDefault();
    setOccupantDetails([
      ...occupantDetails,
      {
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        age: "",
      },
    ]);
    setFormStep2Errors([
      ...formStep2Errors,
      {
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        age: "",
      },
    ]);
  };

  const removeTenant = (i, event) => {
    event.preventDefault();
    // let details = [...tenantDetails];
    // details.splice(i, 1);
    // setTenantDetails(details);
    setTenantDetails(tenantDetails.filter((_, index) => index !== i));
    setFormStep1Errors(formStep1Errors.filter((_, index) => index !== i));
  };

  const removeOccupant = (i, event) => {
    event.preventDefault();
    // let details = [...occupantDetails];
    // details.splice(i, 1);
    // setOccupantDetails(details);
    // setFormStep2Errors([...formStep2Errors, formStep2Errors.splice(i, 1)]);

    setOccupantDetails(occupantDetails.filter((_, index) => index !== i));
    setFormStep2Errors(formStep2Errors.filter((_, index) => index !== i));
  };

  const handleStepOne = (event) => {
    event.preventDefault();
    const temp = tenantValidate();
    setFormStep1Errors(temp);
    if (!goToStep2) {
      return;
    } else {
      setActiveStep((currentStep) => currentStep + 1);
    }
  };

  const handleStepTwo = (event) => {
    event.preventDefault();
    const temp = OccupantValidate();
    setFormStep2Errors(temp);
    if (!goToStep3) {
      return;
    } else {
      setActiveStep((currentStep) => currentStep + 1);
    }
    //setActiveStep((currentStep) => currentStep + 1);
  };

  const handleStepThree = (event) => {
    event.preventDefault();
    const temp = finalValidate();
    setFormStep3Errors(temp);
    if (Object.keys(temp).length) {
      return;
    } else {
      setUploadDocuments([leaseDocument].concat(otherDocuments));
      let tempDocs = [leaseDocument].concat(otherDocuments);
      let tempDocsNames = [];
      for (var i = 0; i < tempDocs.length; i++) {
        tempDocsNames.push(tempDocs[i].name);
      }
      setUploadDocumentsNames(tempDocsNames);
      setActiveStep((currentStep) => currentStep + 1);
    }
  };

  const handleTenantPhoneNumInputChange = (index, number) => {
    let details = [...tenantDetails];
    const formattedNumber = PhoneNumFormat(number);
    const rawPhoneNumber = formattedNumber.replace(/[^\d]/g, "");
    if (rawPhoneNumber) {
      details[index]["phoneNumber"] = "+1" + rawPhoneNumber;
    } else {
      details[index]["phoneNumber"] = "";
    }
    setTenantDetails(details);
  };

  const handleOccupantPhoneNumInputChange = (index, number) => {
    let details = [...occupantDetails];

    const formattedNumber = PhoneNumFormat(number);
    const rawPhoneNumber = formattedNumber.replace(/[^\d]/g, "");
    if (rawPhoneNumber) {
      details[index]["phoneNumber"] = "+1" + rawPhoneNumber;
    } else {
      details[index]["phoneNumber"] = "";
    }
    setOccupantDetails(details);
  };

  const getMonths = (startDate, endDate) => {
    if (startDate && endDate) {
      setLeaseDuration(
        new Date(endDate).getMonth() -
          new Date(startDate).getMonth() +
          1 +
          12 *
            (new Date(endDate).getFullYear() -
              new Date(startDate).getFullYear()) +
          " Months"
      );
    }
  };

  const handleBack = (event) => {
    event.preventDefault();
    setActiveStep((currentStep) => currentStep - 1);
  };

  const handleSelectedUnit = (unitId) => {
    const SelectedUnit = allUnits.filter((unit) => unit.ID === unitId);
    setSelectedUnit(SelectedUnit[0]);
  };

  return props.openTenantFormPage ? (
    <>
      <Dialog
        open={true}
        aria-labelledby="Add Tenant Form"
        fullWidth
        maxWidth="lg"
        //PaperProps={{ sx: { height: "80%" } }}
        //scroll="body"
        className="flex flex-col justify-center w-full h-screen"
      >
        <SnackAlert
          open={snackAlert.open}
          type={snackAlert.type}
          message={snackAlert.message}
          handleSnackbar={snackAlert}
          handleClose={() => setSnackAlert({ ...snackAlert, open: false })}
        />

        <Loader open={apiProcessing.loader} message={apiProcessing.message} />

        <div className="flex justify-center items-center bg-blue-52 text-white font-semibold rounded-sm h-10 pr-2">
          <span className="flex-grow text-center">Add Tenant</span>
          <ButtonIcon
            title="Close Tenant Form"
            id="closeTenantForm"
            btnName="closeTenantForm"
            dataCy="closeTenantForm"
            onClick={(e) => props.setTenantFormPage(false)}
            icon={<AiOutlineCloseCircle className="w-5 h-5" />}
          />
        </div>

        <Stepper activeStep={activeStep} alternativeLabel className="my-4">
          <Step>
            <StepLabel>Tenant Details</StepLabel>
          </Step>
          <Step>
            <StepLabel>Occupant Details</StepLabel>
          </Step>
          <Step>
            <StepLabel>Lease Terms & Rental Details</StepLabel>
          </Step>
          <Step>
            <StepLabel>Confirm Details</StepLabel>
          </Step>
        </Stepper>

        <DialogContent dividers>
          {activeStep === 0 && (
            <section className="w-4/5 m-auto md:w-11/12">
              {tenantDetails.map((tenant, index) => (
                <React.Fragment key={index}>
                  <div className="flex justify-between items-end border-b-2 border-blue-52 pb-1 mb-3 text-blue-52">
                    Tenant {index ? index + 1 : ""} Details
                    {index ? (
                      <button
                        onClick={(e) => removeTenant(index, e)}
                        key={"removeTenant" + index}
                        id="removeTenant"
                        name="removeTenant"
                        data-cy="removeTenant"
                        className="flex justify-center items-center gap-2 w-fit h-10 px-2 text-red-600 font-medium border border-red-600 rounded shadow focus:shadow-lg hover:shadow-lg hover:text-white focus:text-white hover:bg-red-600 focus:bg-red-600"
                      >
                        <AiOutlineMinusCircle /> Remove Tenant {index + 1}
                      </button>
                    ) : null}
                  </div>
                  <div className="grid grid-cols-3 grid-rows-2 justify-center items-center gap-4 mb-4">
                    <div className="col-span-3 flex items-center gap-8">
                      <InputField
                        label="First Name"
                        name="firstName"
                        id="firstName"
                        dataCy="firstName"
                        type="text"
                        placeholder="Enter First Name"
                        value={tenant.firstName}
                        onChange={(e) =>
                          handleTenantDetails(
                            index,
                            "firstName",
                            e.target.value.replace(/[^\w\s]/gi, "")
                          )
                        }
                        errorText={formStep1Errors[index].firstName}
                        style="!w-full"
                      />

                      <InputField
                        label="Last Name"
                        name="lastName"
                        id="lastName"
                        dataCy="lastName"
                        type="text"
                        placeholder="Enter Last Name"
                        value={tenant.lastName}
                        onChange={(e) =>
                          handleTenantDetails(
                            index,
                            "lastName",
                            e.target.value.replace(/[^\w\s]/gi, "")
                          )
                        }
                        errorText={formStep1Errors[index].lastName}
                        style="!w-full"
                      />
                    </div>

                    <InputField
                      label="Email Address"
                      name="email"
                      id="email"
                      dataCy="email"
                      type="text"
                      placeholder="Enter Email ID"
                      value={tenant.email}
                      onChange={(e) =>
                        handleTenantDetails(index, "email", e.target.value)
                      }
                      errorText={formStep1Errors[index].email}
                      style="!w-full"
                    />

                    <label
                      htmlFor="phoneNumber"
                      className={` text-gray-700 text-sm`}
                    >
                      Contact Number
                      <span className="text-red-600">*</span>
                      <span className="flex mt-1 items-center">
                        <select
                          name="countryCode"
                          className="bg-gray-50 border rounded text-gray-900 text-sm focus:border-blue-52 w-fit p-2"
                        >
                          <option value="+1"> US (+1) </option>
                        </select>
                        <input
                          className={` shadow border appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none`}
                          name="phoneNumber"
                          id="phoneNumber"
                          data-cy="phoneNumber"
                          type="text"
                          placeholder="Enter Contact Number"
                          value={PhoneNumFormat(
                            tenant.phoneNumber.replace(/^\+1/, "")
                          )}
                          onChange={(e) =>
                            handleTenantPhoneNumInputChange(
                              index,
                              e.target.value
                            )
                          }
                        />
                      </span>
                      <span className="text-red-10 text-sm">
                        {formStep1Errors[index].phoneNumber}
                      </span>
                    </label>

                    <InputSelectField
                      label="Status"
                      name="tenantStatus"
                      id="tenantStatus"
                      dataCy="tenantStatus"
                      placeholder="Choose a Status"
                      options={tenantStatusOptions}
                      value={tenant.tenantStatus}
                      onChange={(e) =>
                        handleTenantDetails(
                          index,
                          "tenantStatus",
                          e.target.value
                        )
                      }
                      errorText={formStep1Errors[index].tenantStatus}
                      style="!w-full"
                    />
                  </div>
                </React.Fragment>
              ))}

              <div className="flex justify-center items-center mt-6">
                <ButtonCustom
                  id="addTenant"
                  btnName="addTenant"
                  dataCy="addTenant"
                  name={
                    <span className="flex items-center gap-1">
                      <AiOutlinePlusCircle /> Add Tenant
                    </span>
                  }
                  onClick={(e) => addNewTenant(e)}
                  className="!w-fit h-10 px-2 text-blue-53 font-medium border border-blue-53 rounded shadow focus:shadow-lg hover:shadow-lg hover:text-white focus:text-white hover:bg-blue-53 focus:bg-blue-53"
                />
              </div>
              <div className="flex justify-center items-center gap-16 my-6">
                <ButtonGrayOutline
                  id="cancel"
                  btnName="cancel"
                  dataCy="cancel"
                  name="Cancel"
                  title="Close the Form"
                  onClick={(e) => props.setTenantFormPage(false)}
                  className="justify-self-end"
                />
                <ButtonBlue
                  id="next"
                  btnName="next"
                  dataCy="next"
                  name="Next"
                  onClick={(e) => handleStepOne(e)}
                  className=""
                />
              </div>
            </section>
          )}

          {activeStep === 1 && (
            <section className="w-4/5 m-auto md:w-11/12">
              {occupantDetails.map((occupant, index) => (
                <React.Fragment key={index}>
                  <div className="flex justify-between items-end border-b-2 border-amber-600 pb-1 mb-3 text-amber-600">
                    Occupant {index ? index + 1 : ""} Details
                    {index ? (
                      <button
                        id="removeOccupant"
                        name="removeOccupant"
                        data-cy="removeOccupant"
                        onClick={(e) => removeOccupant(index, e)}
                        className="flex justify-center items-center gap-2 w-fit h-10 px-2 text-red-600 font-medium border border-red-600 rounded shadow focus:shadow-lg hover:shadow-lg hover:text-white focus:text-white hover:bg-red-600 focus:bg-red-600"
                      >
                        <AiOutlineMinusCircle /> Remove Occupant {index + 1}
                      </button>
                    ) : null}
                  </div>
                  <div className="grid grid-cols-3 grid-rows-2 justify-center items-center gap-4 mb-4">
                    <div className="col-span-3 flex items-center gap-8">
                      <InputField
                        label="First Name"
                        name="firstName"
                        id="firstName"
                        dataCy="firstName"
                        notImp
                        type="text"
                        placeholder="Enter First Name"
                        value={occupant.firstName}
                        onChange={(e) =>
                          handleOccupantDetails(
                            index,
                            "firstName",
                            e.target.value.replace(/[^\w\s]/gi, "")
                          )
                        }
                        errorText={formStep2Errors[index].firstName}
                        style="!w-full"
                      />

                      <InputField
                        label="Last Name"
                        name="lastName"
                        id="lastName"
                        dataCy="lastName"
                        notImp
                        type="text"
                        placeholder="Enter Last Name"
                        value={occupant.lastName}
                        onChange={(e) =>
                          handleOccupantDetails(
                            index,
                            "lastName",
                            e.target.value.replace(/[^\w\s]/gi, "")
                          )
                        }
                        errorText={formStep2Errors[index].lastName}
                        style="!w-full"
                      />
                    </div>

                    <InputField
                      label="Email Address"
                      name="email"
                      id="email"
                      dataCy="email"
                      notImp
                      type="text"
                      placeholder="Enter Email ID"
                      value={occupant.email}
                      onChange={(e) =>
                        handleOccupantDetails(index, "email", e.target.value)
                      }
                      errorText={formStep2Errors[index].email}
                      style="!w-full"
                    />

                    <label
                      htmlFor="phoneNumber"
                      className={` text-gray-700 text-sm font-semibold`}
                    >
                      Contact Number
                      {/* <span className="text-red-600">*</span> */}
                      <span className="flex mt-1 items-center">
                        <select
                          name="countryCode"
                          className="bg-gray-50 border rounded text-gray-900 text-sm focus:border-blue-52 w-fit p-2"
                        >
                          <option value="+1"> US (+1) </option>
                        </select>
                        <input
                          className={` shadow border appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none`}
                          name="phoneNumber"
                          id="phoneNumber"
                          data-cy="phoneNumber"
                          type="text"
                          placeholder="Enter Contact Number"
                          value={PhoneNumFormat(
                            occupant.phoneNumber.replace(/^\+1/, "")
                          )}
                          onChange={(e) =>
                            handleOccupantPhoneNumInputChange(
                              index,
                              e.target.value
                            )
                          }
                        />
                      </span>
                      <span className="text-red-10 text-sm">
                        {formStep2Errors[index].phoneNumber}
                      </span>
                    </label>

                    <InputField
                      label="Age(In Years)"
                      name="age"
                      id="age"
                      dataCy="age"
                      notImp
                      type="text"
                      placeholder="Enter Age In Years"
                      value={occupant.age}
                      onChange={(e) => {
                        let value = "";
                        if (e.target.value.replace(/[^\d]/g, "")) {
                          value = parseInt(
                            e.target.value.replace(/[^\d]/g, "")
                          );
                        }
                        handleOccupantDetails(index, "age", value);
                      }}
                      errorText={formStep2Errors[index].age}
                      style="!w-full"
                    />
                  </div>
                </React.Fragment>
              ))}

              <div className="flex justify-center items-center mt-6">
                <ButtonCustom
                  id="addOccupant"
                  btnName="addOccupant"
                  dataCy="addOccupant"
                  name={
                    <span className="flex items-center gap-1">
                      <AiOutlinePlusCircle /> Add Occupant
                    </span>
                  }
                  onClick={(e) => addNewOccupant(e)}
                  className="!w-fit h-10 px-2 text-blue-53 font-medium border border-blue-53 rounded shadow focus:shadow-lg hover:shadow-lg hover:text-white focus:text-white hover:bg-blue-53 focus:bg-blue-53"
                />
              </div>
              <div className="flex justify-center items-center gap-16 my-6">
                <ButtonGrayOutline
                  id="back"
                  btnName="back"
                  dataCy="back"
                  title="Back to Tenant Details"
                  name="Back"
                  onClick={(e) => handleBack(e)}
                  className="justify-self-end"
                />
                <ButtonBlue
                  id="next"
                  btnName="next"
                  dataCy="next"
                  name="Next"
                  onClick={(e) => handleStepTwo(e)}
                  className=""
                />
              </div>
            </section>
          )}

          {activeStep == 2 && (
            <section className="w-4/5 m-auto md:w-11/12">
              <div className="flex justify-between items-end border-b-2 border-blue-52 pb-1 mb-3 text-blue-52">
                Lease Details
              </div>
              <div className="grid grid-cols-2 justify-center items-center gap-x-8 gap-y-4 mb-4">
                <InputDateField
                  label="Lease Start Date"
                  name="leaseStartDate"
                  id="leaseStartDate"
                  dataCy="leaseStartDate"
                  value={leaseStartDate}
                  onChange={(value) => {
                    setLeaseStartDate(value);
                    getMonths(value, leaseEndDate);
                  }}
                  //maxDate={moveInDate}
                  errorText={formStep3Errors.leaseStartDate}
                  style="!w-full"
                />

                <InputDateField
                  label="Move In Date"
                  name="moveInDate"
                  id="moveInDate"
                  dataCy="moveInDate"
                  minDate={leaseStartDate}
                  //maxDate={leaseEndDate}
                  value={moveInDate}
                  onChange={(value) => setMoveInDate(value)}
                  errorText={formStep3Errors.moveInDate}
                  style="!w-full"
                />

                <InputDateField
                  label="Lease End Date"
                  name="leaseEndDate"
                  id="leaseEndDate"
                  dataCy="leaseEndDate"
                  minDate={moveInDate}
                  value={leaseEndDate}
                  onChange={(value) => {
                    setLeaseEndDate(value);
                    getMonths(leaseStartDate, value);
                  }}
                  errorText={formStep3Errors.leaseEndDate}
                  style="!w-full"
                />

                <InputField
                  label="Lease Duration"
                  name="leaseDuration"
                  id="leaseDuration"
                  dataCy="leaseDuration"
                  notImp
                  readOnly
                  type="text"
                  placeholder="Lease Duration"
                  value={leaseDuration}
                  errorText={formStep3Errors.leaseDuration}
                  style="!w-full"
                />

                <InputFileField
                  label="Lease Document"
                  name="leaseDocument"
                  id="leaseDocument"
                  dataCy="leaseDocument"
                  accept=".pdf"
                  maxSizeinMB={10}
                  placeholder="Select Lease Document"
                  value={leaseDocument}
                  setValue={setLeaseDocument}
                  errorText={formStep3Errors.leaseDocument}
                  hintText="Lease Document should be less than 10 MB"
                  style="!w-full"
                />

                <InputFileField
                  label="Other Documents"
                  name="media"
                  id="media"
                  dataCy="tenantMedia"
                  accept=".pdf"
                  multiple
                  placeholder="Select Other Documents"
                  notImp
                  maxSizeinMB={10}
                  value={otherDocuments}
                  setValue={setOtherDocuments}
                  hintText="Each document should be less than 10MB"
                  style="!w-full"
                />

                <InputObjectSelectField
                  label="Unit"
                  name="unit"
                  id="unit"
                  dataCy="unit"
                  placeholder="Choose a Unit"
                  value={selectedUnitId}
                  onChange={(e) => {
                    setSelectedUnitId(parseInt(e.target.value));
                    handleSelectedUnit(parseInt(e.target.value));
                  }}
                  options={allUnits}
                  optionValue="ID"
                  optionName="unitNumber"
                  style="!w-full"
                  errorText={formStep3Errors.selectedUnitId}
                />
              </div>
              <div className="flex justify-between items-end col-span-2 border-b-2 border-blue-52 pb-1 mb-3 text-blue-52">
                Rental Details
              </div>
              <div className="grid grid-cols-2 justify-center items-center gap-x-8 gap-y-4 mb-4">
                <InputDollarsField
                  label="Minimum Security Deposit"
                  name="securityDeposit"
                  id="securityDeposit"
                  dataCy="securityDeposit"
                  placeholder="Enter Minimum Security Deposit Amount"
                  value={securityDeposit}
                  setValue={(value) => setSecurityDeposit(value)}
                  //onChange={(e) => setSecurityDeposit(parseInt(e.target.value))}
                  errorText={formStep3Errors.securityDeposit}
                  style="!w-full"
                />

                <InputDollarsField
                  label="Monthly Rent"
                  name="monthlyRent"
                  id="monthlyRent"
                  dataCy="monthlyRent"
                  placeholder="Enter Monthly Rent"
                  value={monthlyRent}
                  setValue={(value) => setMonthlyRent(value)}
                  //onChange={(e) => setMonthlyRent(parseInt(e.target.value))}
                  errorText={formStep3Errors.monthlyRent}
                  style="!w-full"
                />

                <InputDollarsField
                  label="Late Fee"
                  name="lateFee"
                  id="lateFee"
                  dataCy="lateFee"
                  placeholder="Enter Late Fee"
                  value={lateFee}
                  setValue={(value) => setLateFee(value)}
                  //onChange={(e) => setLateFee(parseInt(e.target.value))}
                  errorText={formStep3Errors.lateFee}
                  style="!w-full"
                />

                <InputField
                  label="No. of Due Days"
                  name="dueDay"
                  id="dueDay"
                  dataCy="dueDay"
                  type="text"
                  customProps={inputNumCustomProps}
                  placeholder="Enter No. Due Days"
                  value={dueDay}
                  onChange={(e) => {
                    let value = "";
                    if (e.target.value.replace(/[^\d]/g, "")) {
                      value = parseInt(e.target.value.replace(/[^\d]/g, ""));
                    }
                    setDueDay(value);
                  }}
                  errorText={formStep3Errors.dueDay}
                  style="!w-full"
                />

                <InputField
                  label="Rentable Items"
                  name="rentableItems"
                  id="rentableItems"
                  dataCy="rentableItems"
                  notImp
                  type="text"
                  placeholder="Enter Rentable Items"
                  value={rentableItems}
                  onChange={(e) => setRentableItems(e.target.value)}
                  style="!w-full"
                />

                {selectedUnit && selectedUnit.isPetsAllowed && (
                  <>
                    <InputObjectSelectField
                      label="Pets Allowed"
                      name="petsAllowed"
                      id="petsAllowed"
                      dataCy="petsAllowed"
                      placeholder="Choose a value"
                      value={petsAllowed.toString()}
                      onChange={(e) => {
                        const isPetsAllowed = JSON.parse(e.target.value);
                        if (isPetsAllowed) {
                          setPetsAllowed(isPetsAllowed);
                        } else {
                          setPetsAllowed(isPetsAllowed);
                          setPetDeposit("");
                          setNoOfPets("");
                          setTypeOfPets("");
                          setPetFee("");
                        }
                      }}
                      options={[
                        { name: "Yes", value: "true" },
                        { name: "No", value: "false" },
                      ]}
                      optionValue="value"
                      optionName="name"
                      style="!w-full"
                      errorText={formStep3Errors.petsAllowed}
                    />

                    {petsAllowed && (
                      <InputField
                        label="No. of Pets"
                        name="noOfPets"
                        id="noOfPets"
                        dataCy="noOfPets"
                        type="text"
                        customProps={inputNumCustomProps}
                        placeholder="Enter No of Pets"
                        value={noOfPets}
                        onChange={(e) => {
                          let value = "";
                          if (e.target.value.replace(/[^\d]/g, "")) {
                            value = parseInt(
                              e.target.value.replace(/[^\d]/g, "")
                            );
                          }
                          setNoOfPets(value);
                        }}
                        errorText={formStep3Errors.noOfPets}
                        style="!w-full"
                      />
                    )}

                    {petsAllowed && (
                      <InputField
                        label="Type of Pets"
                        name="typeOfPets"
                        id="typeOfPets"
                        dataCy="typeOfPets"
                        type="text"
                        placeholder="Enter Type Of Pets"
                        value={typeOfPets}
                        onChange={(e) => setTypeOfPets(e.target.value)}
                        errorText={formStep3Errors.typeOfPets}
                        style="!w-full"
                      />
                    )}

                    {petsAllowed && (
                      <InputDollarsField
                        label="Pet Deposit"
                        name="petDeposit"
                        id="petDeposit"
                        dataCy="petDeposit"
                        placeholder="Enter Pet Deposit"
                        value={petDeposit}
                        setValue={(value) => setPetDeposit(value)}
                        //onChange={(e) => setPetDeposit(parseInt(e.target.value))}
                        errorText={formStep3Errors.petDeposit}
                        style="!w-full"
                      />
                    )}

                    {petsAllowed && (
                      <InputDollarsField
                        label={
                          <span className="flex gap-2">
                            Monthly Pet Fee
                            <span
                              hidden={props.notImp}
                              className="text-red-600"
                            >
                              *
                            </span>
                            <BsFillInfoSquareFill
                              className="w-5 h-5 text-blue-53 cursor-pointer"
                              onClick={() =>
                                swal("Info!", PetFeeDisclaimer(), "info")
                              }
                            />
                          </span>
                        }
                        name="petFee"
                        notImp
                        id="petFee"
                        dataCy="petFee"
                        placeholder="Enter Pet Fee"
                        value={petFee}
                        setValue={(value) => setPetFee(value)}
                        //onChange={(e) => setPetFee(parseInt(e.target.value))}
                        errorText={formStep3Errors.petFee}
                        style="!w-full"
                      />
                    )}
                  </>
                )}
              </div>
              <div className="flex justify-center items-center gap-16 my-6">
                <ButtonGrayOutline
                  id="Back to Occupant Details"
                  btnName="Back to Occupant Details"
                  dataCy="Back to Occupant Details"
                  title="Back to Occupant Details"
                  name="Back"
                  onClick={(e) => handleBack(e)}
                  className="justify-self-end"
                />
                <ButtonBlue
                  id="next"
                  btnName="next"
                  dataCy="next"
                  name="Next"
                  onClick={(e) => handleStepThree(e)}
                  className=""
                />
              </div>
            </section>
          )}

          {activeStep === 3 && (
            <section className="w-4/5 m-auto md:w-11/12">
              {tenantDetails.map((tenant, index) => (
                <>
                  <React.Fragment key={index}>
                    <div className="border-b-2 border-blue-52 pb-1 mb-3 text-blue-52">
                      Tenant {index ? index + 1 : ""} Details
                    </div>
                    <div className="grid grid-cols-3 grid-rows-2 justify-center items-center gap-4 mb-4">
                      <TextReviewTile
                        title="Tenant First Name"
                        body={tenant.firstName}
                      />
                      <TextReviewTile
                        title="Tenant Last Name"
                        body={tenant.lastName}
                      />

                      <TextReviewTile
                        title="Tenant Email"
                        body={tenant.email}
                      />
                      <TextReviewTile
                        title="Contact Number"
                        body={PhoneNumFormat(tenant.phoneNumber, true)}
                      />
                      <TextReviewTile
                        title="Tenant Status"
                        body={tenant.tenantStatus}
                      />
                    </div>
                  </React.Fragment>
                </>
              ))}

              {occupantDetails.map((occupant, index) => (
                <>
                  <React.Fragment key={index}>
                    {Object.values(occupant).some((value) => value !== "") && (
                      <>
                        <div className="border-b-2 border-blue-52 pb-1 mb-3 text-blue-52">
                          Occupant {index ? index + 1 : ""} Details
                        </div>

                        <div className="grid grid-cols-3 grid-rows-2 justify-center items-center gap-4 mb-4">
                          <TextReviewTile
                            title="Occupant First Name"
                            body={occupant.firstName || "N/A"}
                          />
                          <TextReviewTile
                            title="Occupant Last Name"
                            body={occupant.lastName || "N/A"}
                          />

                          <TextReviewTile
                            title="Occupant Email"
                            body={occupant.email || "N/A"}
                          />
                          <TextReviewTile
                            title="Occupant Number"
                            body={
                              PhoneNumFormat(occupant.phoneNumber, true) ||
                              "N/A"
                            }
                          />
                          <TextReviewTile
                            title="Occupant Age"
                            body={occupant.age || "N/A"}
                          />
                        </div>
                      </>
                    )}
                  </React.Fragment>
                </>
              ))}

              <div className="border-b-2 border-blue-52 pb-1 mb-3 text-blue-52">
                Lease terms
              </div>
              <div className="grid grid-cols-3 grid-rows-2 justify-center items-center gap-4 mb-4">
                <TextReviewTile
                  title="Lease Start Date"
                  body={GetDateInMMDDYYYY(leaseStartDate)}
                />
                <TextReviewTile
                  title="Move In Date"
                  body={GetDateInMMDDYYYY(moveInDate)}
                />
                <TextReviewTile
                  title="Lease End Date"
                  body={GetDateInMMDDYYYY(leaseEndDate)}
                />
                <TextReviewTile title="Lease Duration" body={leaseDuration} />
                <TextReviewTile
                  title="Selected Unit No."
                  hide={isSingleFamily}
                  body={selectedUnit.unitNumber}
                />
                <FileReviewTile
                  title="Lease Document"
                  body={uploadDocumentsNames[0]}
                />

                <MultiFileReviewTile
                  title="Other Documents"
                  body={uploadDocuments.slice(1) || "N/A"}
                />
              </div>

              <div className="border-b-2 border-blue-52 pb-1 mb-3 text-blue-52">
                Rental details
              </div>
              <div className="grid grid-cols-3 grid-rows-2 justify-center items-center gap-4 mb-4">
                <TextReviewTile
                  title="Minimum Security Deposit"
                  body={"$" + securityDeposit.toLocaleString()}
                />
                <TextReviewTile
                  title="Monthly Rent"
                  body={"$" + monthlyRent.toLocaleString()}
                />
                <TextReviewTile
                  title="Late Fee"
                  body={"$" + lateFee.toLocaleString()}
                />
                <TextReviewTile title="No OF Due Days" body={dueDay} />
                <TextReviewTile title="Rentable Items" body={rentableItems} />
                <TextReviewTile
                  title="Pets Allowed"
                  body={petsAllowed ? "Yes" : "No"}
                />
                {petsAllowed && (
                  <>
                    <TextReviewTile title="No Of Pets" body={noOfPets} />
                    <TextReviewTile title="Type Of Pets" body={typeOfPets} />
                    <TextReviewTile
                      title="Pet Deposit"
                      body={"$" + petDeposit.toLocaleString()}
                    />
                    <TextReviewTile
                      title="Monthly Pet Fee"
                      body={"$" + petFee.toLocaleString()}
                    />
                  </>
                )}
              </div>

              <div className="flex justify-center items-center gap-16 my-6">
                <ButtonGrayOutline
                  id="Back to Lease and Rental Details"
                  btnName="Back to Lease and Rental Details"
                  dataCy="Back to Lease and Rental Details"
                  title="Back to Lease and Rental Details"
                  name="Back"
                  onClick={(e) => handleBack(e)}
                  hidden={apiProcessing.loader}
                  className="justify-self-end"
                />
                <ButtonBlue
                  id="confirm"
                  btnName="confirm"
                  dataCy="confirm"
                  name="Confirm"
                  onClick={(e) => handleSubmit(e)}
                  className=""
                />
              </div>
            </section>
          )}
        </DialogContent>
      </Dialog>
    </>
  ) : (
    ""
  );
};

export default CreateTenant;
