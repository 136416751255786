import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Amplify from "aws-amplify";
import SessionIdGenerator from "./Utility/SessionIdGenerator";
import config from "./config";

let Auth = {
  mandatorySignIn: true,
  region: config.cognito.REGION,
  userPoolId: config.cognito.USER_POOL_ID,
  userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  clientMetaData: {
    sessionIdToken: SessionIdGenerator.sessionIdTokenGenerator(),
  },
};

Amplify.configure({
  Auth,
  API: {
    endpoints: [
      {
        name: "notes",
        endPoint: "",
        region: config.cognito.REGION,
      },
    ],
  },
});

const Index = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {!isLargeScreen ? (
        <div class="flex flex-col justify-center items-center w-full">
          <img
            id="YvooaLogo"
            class="h-auto w-auto"
            src="/DASHLOGO.png"
            alt="Yvooa Logo"
          />

          <h1 class="text-4xl font-bold mt-6 mb-4">Oops!</h1>

          <p class="text-lg text-center">
            This website is designed for larger screens. Please use a device
            with a wider screen for the best experience.
          </p>
        </div>
      ) : (
        <App />
      )}
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Index />);

reportWebVitals();
