import React, { useEffect, useState } from "react";
import { WebApimanager } from "../../WebApiManager";
import { userData } from "../../atoms.js";
import { useRecoilValue } from "recoil";

import { Loader } from "../../Widgets/notificationFeedbacks";
import {
  ButtonIcon,
  ButtonCustom,
  ButtonGrayOutline,
  ButtonBlue,
} from "../../Widgets/Buttons";
import InputField, {
  InputSelectField,
  InputObjectSelectField,
  InputFileField,
  InputDateField,
  InputDollarsField,
} from "../../Widgets/Forms/InputFields";
import { DateFormatForAPI } from "../../Widgets/CommonWidgets.js";
import { PetFeeDisclaimer } from "../../Widgets/Forms/FieldDependants.js";

import swal from "sweetalert";
import { Dialog, DialogContent } from "@mui/material";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BsFillInfoSquareFill } from "react-icons/bs";

const AddLeaseAmendment = (props) => {
  let webApi = new WebApimanager();
  const editForm = props.operation === "Edit" ? true : false;
  const userInfo = useRecoilValue(userData);

  const [leaseTypeOptions, setLeaseTypeOptions] = useState(["Renewal"]);
  const [leaseType, setLeaseType] = useState("Renewal");
  const [newRentAmount, setNewRentAmount] = useState("");
  const [deposit, setDeposit] = useState("");
  const [effectiveDate, setEffectiveDate] = useState("");
  const [leaseEndDate, setLeaseEndDate] = useState("");
  const [leaseTerm, setLeaseTerm] = useState("");
  const [isPetsAllowed, setIsPetsAllowed] = useState("");
  const [petDeposit, setPetDeposit] = useState("");
  const [monthlyPetFee, setMonthlyPetFee] = useState("");
  const [formErrors, setFormErrors] = useState("");

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  useEffect(() => {
    if (editForm && props.editData) {
      setNewRentAmount(props.editData.rent);
      setEffectiveDate(props.editData.leaseStartDate);
      setLeaseEndDate(props.editData.leaseEndDate);
      setLeaseTerm(props.editData.leaseDuration);
      setDeposit(props.editData.deposit);
      if (props.editData.petDeposit) {
        setIsPetsAllowed(true);
      } else setIsPetsAllowed(false);
      setPetDeposit(props.editData.petDeposit);
      setMonthlyPetFee(props.editData.petFee);
    }
  }, []);

  const validate = () => {
    const errors = {};
    if (!leaseType) {
      errors.leaseType = "Lease Type is required!";
    }
    if (!newRentAmount) {
      errors.newRentAmount = "New Rent Amount is required!";
    }
    if (!effectiveDate.trim()) {
      errors.effectiveDate = "Lease Start Date is required!";
    }
    if (!leaseEndDate) {
      errors.leaseEndDate = "Lease End Date is required!";
    } else if (
      effectiveDate &&
      new Date(leaseEndDate) < new Date(effectiveDate)
    ) {
      errors.leaseEndDate =
        "Lease End date should be later than the Lease Start date!";
    }
    if (!isPetsAllowed.toString()) {
      errors.isPetsAllowed = "Pets Allowed is required!";
    }
    if (isPetsAllowed && !petDeposit) {
      errors.petDeposit = "Pet Deposit is required!";
    }
    return errors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const temp = validate();
    setFormErrors(temp);
    if (Object.keys(temp).length) {
      return;
    } else {
      setApiProcessing({
        loader: true,
        message: editForm
          ? `Updating Lease Amendment...`
          : `Creating Lease Amendment...`,
      });

      let data;

      if (editForm) {
        data = {
          ...props.editData,
        };
        data.createdByID = userInfo.ID;
        data.createdByName = userInfo.firstName + " " + userInfo.lastName;
        data.rent = newRentAmount;
        data.leaseStartDate = DateFormatForAPI(effectiveDate);
        data.leaseEndDate = DateFormatForAPI(leaseEndDate);
        data.leaseDuration = leaseTerm;
        data.petDeposit = petDeposit || 0;
        data.petFee = monthlyPetFee || 0;
      } else {
        data = {
          leaseId: props.leaseId,
          createdByID: userInfo.ID,
          rent: newRentAmount,
          leaseStartDate: DateFormatForAPI(effectiveDate),
          leaseEndDate: DateFormatForAPI(leaseEndDate),
          leaseDuration: leaseTerm,
          petDeposit: petDeposit || 0,
          petFee: monthlyPetFee || 0,
        };
      }

      if (editForm) {
        webApi
          .put(`/leaseAmendments/${props.editData.ID}`, data)
          .then(async (res) => {
            if (res.data.errorCode) {
              swal("Failure", res.data.errorMessage, "error");
            } else {
              props.getAllLeaseAmendments(res.data);
              swal(
                "Success",
                "Lease Amendment Updated successfully",
                "success"
              );
              props.setTrigger(false);
            }
            setApiProcessing({ loader: false, message: `` });
          })
          .catch((error) => {
            setApiProcessing({ loader: false, message: `` });
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                "Failed to Update Lease Amendment! Please try again later.",
                "error"
              );
            }
          });
      } else {
        webApi
          .post(`/leaseAmendments/renewal`, data)
          .then(async (res) => {
            if (res.data.errorCode) {
              swal("Failure", res.data.errorMessage, "error");
            } else {
              props.getAllLeaseAmendments(res.data);
              swal(
                "Success",
                "Your Lease Renewal request has been sent successfully!",
                "success"
              );
              props.setTrigger(false);
            }
            setApiProcessing({ loader: false, message: `` });
          })
          .catch((error) => {
            setApiProcessing({ loader: false, message: `` });
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                "Failed to Create Lease Amendment! Please try again later.",
                "error"
              );
            }
          });
      }
    }
  };

  const getMonths = (startDate, endDate) => {
    if (startDate && endDate) {
      let duration =
        new Date(endDate).getMonth() -
        new Date(startDate).getMonth() +
        1 +
        12 *
          (new Date(endDate).getFullYear() -
            new Date(startDate).getFullYear()) +
        " Months";
      setLeaseTerm(duration);
    }
  };

  return props.trigger ? (
    <Dialog
      open={true}
      aria-labelledby="Create Amenity Form"
      fullWidth
      maxWidth="xs"
      //PaperProps={{ sx: { height: "100%" } }}
      //scroll="body"
      className="flex flex-col justify-center w-full"
    >
      <Loader open={apiProcessing.loader} message={apiProcessing.message} />

      <div className="flex justify-center items-center bg-blue-52 text-white font-semibold rounded-sm h-10 pr-2">
        <span className="flex-grow text-center">
          {" "}
          {editForm ? "Edit Lease Amendment" : "Lease Amendment"}
        </span>
        <ButtonIcon
          title="Close"
          onClick={(e) => props.setTrigger(false)}
          icon={<AiOutlineCloseCircle className="w-5 h-5" />}
          className="hover:!text-red-600"
        />
      </div>

      <DialogContent dividers>
        <section className="flex flex-col items-center justify-center gap-3 mx-auto">
          <InputSelectField
            label="Lease Type"
            name="leaseType"
            id="leaseType"
            dataCy="leaseType"
            placeholder="Choose a Lease Type"
            value={leaseType}
            onChange={(e) => setLeaseType(e.target.value)}
            options={leaseTypeOptions}
            errorText={formErrors.leaseType}
            style="!w-full"
          />

          <InputDollarsField
            label="New Rent Amount"
            name="newRentAmount"
            id="newRentAmount"
            dataCy="newRentAmount"
            placeholder="Enter New Rent Amount"
            value={newRentAmount}
            setValue={(value) => setNewRentAmount(value)}
            //onChange={(e) => setNewRentAmount(parseInt(e.target.value))}
            errorText={formErrors.newRentAmount}
            style="!w-full"
          />

          <InputDateField
            label="Lease Effective Date"
            name="effectiveDate"
            id="effectiveDate"
            dataCy="effectiveDate"
            value={effectiveDate}
            onChange={(value) => {
              setEffectiveDate(value);
              getMonths(value, leaseEndDate);
            }}
            //maxDate={moveInDate}
            errorText={formErrors.effectiveDate}
            style="!w-full"
          />

          <InputDateField
            label="Lease End Date"
            name="leaseEndDate"
            id="leaseEndDate"
            dataCy="leaseEndDate"
            minDate={effectiveDate}
            value={leaseEndDate}
            onChange={(value) => {
              setLeaseEndDate(value);
              getMonths(effectiveDate, value);
            }}
            errorText={formErrors.leaseEndDate}
            style="!w-full"
          />

          <InputField
            label="Lease Term"
            name="leaseTerm"
            id="leaseTerm"
            dataCy="leaseTerm"
            notImp
            readOnly
            type="text"
            placeholder="Lease Duration"
            value={leaseTerm}
            errorText={formErrors.leaseTerm}
            style="!w-full"
          />

          <InputObjectSelectField
            label="Pets Allowed"
            name="isPetsAllowed"
            id="isPetsAllowed"
            dataCy="petsAllowed"
            placeholder="Choose a value"
            value={isPetsAllowed.toString()}
            onChange={(e) => {
              const petsAllowed = JSON.parse(e.target.value);
              if (petsAllowed) {
                setIsPetsAllowed(petsAllowed);
              } else {
                setIsPetsAllowed(petsAllowed);
                setPetDeposit("");
                setMonthlyPetFee("");
              }
            }}
            options={[
              { name: "Yes", value: "true" },
              { name: "No", value: "false" },
            ]}
            optionValue="value"
            optionName="name"
            style="!w-full"
            errorText={formErrors.isPetsAllowed}
          />

          {isPetsAllowed && (
            <InputDollarsField
              label="Pet Deposit"
              name="petDeposit"
              id="petDeposit"
              dataCy="petDeposit"
              placeholder="Enter Pet Deposit Amount"
              value={petDeposit}
              setValue={(value) => setPetDeposit(value)}
              //onChange={(e) => setPetDeposit(parseInt(e.target.value))}
              errorText={formErrors.petDeposit}
              style="!w-full"
            />
          )}

          {isPetsAllowed && (
            <InputDollarsField
              label={
                <span className="flex gap-2">
                  Monthly Pet Fee
                  <BsFillInfoSquareFill
                    className="w-5 h-5 text-blue-53"
                    onClick={() => swal("Info!", PetFeeDisclaimer(), "info")}
                  />
                </span>
              }
              name="monthlyPetFee"
              id="monthlyPetFee"
              dataCy="monthlyPetFee"
              notImp
              placeholder="Enter Monthly Pet Fee"
              value={monthlyPetFee}
              setValue={(value) => setMonthlyPetFee(value)}
              //onChange={(e) => setMonthlyPetFee(parseInt(e.target.value))}
              errorText={formErrors.monthlyPetFee}
              style="!w-full"
            />
          )}
        </section>

        <div className="flex justify-center items-center gap-16 mb-4">
          <ButtonGrayOutline
            id="cancel"
            btnName="cancel"
            dataCy="cancel"
            name="Cancel"
            onClick={() => props.setTrigger(false)}
            className="mt-6 justify-self-end"
          />
          <ButtonBlue name="Confirm" onClick={handleSubmit} className="mt-6" />
        </div>
      </DialogContent>
    </Dialog>
  ) : null;
};

export default AddLeaseAmendment;
