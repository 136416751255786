import React, { useState } from "react";
import { WebApimanager } from "../../WebApiManager";
import {
  TextReviewTile,
  FileReviewTile,
} from "../../Widgets/Forms/FormReviewTiles";
import { FileSizeValidation } from "../../Widgets/Forms/FieldDependants";
import {
  ButtonBlue,
  ButtonGrayOutline,
  ButtonIcon,
} from "../../Widgets/Buttons";
import SnackAlert from "../../Widgets/SnackbarAlert";
import { Snackbar, Alert } from "@mui/material";
import {
  Dialog,
  Stepper,
  StepLabel,
  Step,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import swal from "sweetalert";
import { AiOutlineCloseCircle } from "react-icons/ai";

import InputField, {
  InputSelectField,
  InputPhoneNumField,
  InputFileField,
  InputObjectSelectField,
  InputDateField,
  InputDollarsField,
} from "../../Widgets/Forms/InputFields";
import { Loader } from "../../Widgets/notificationFeedbacks";

const CreateFloorPlan = (props) => {
  let webApi = new WebApimanager();
  const [buildingNo, setBuildingNo] = useState();
  const [floorPlanName, setFloorPlanName] = useState("");
  const [floorPlanCode, setFloorPlanCode] = useState();
  // const [noOfRooms, setNoOfRooms] = useState();
  const [noOfBeds, setNoOfBeds] = useState();
  const [noOfBathrooms, setNoOfBathrooms] = useState();
  const [layout, setLayout] = useState("");

  const [formErrors, setFormErrors] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState("error");
  const [snackbarMessage, setSnackbarMessage] = useState(
    "New Floor Plan Created"
  );

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  const inputNumCustomProps = {
    min: 0,
  };

  const validate = () => {
    const errors = {};
    // if (!buildingNo) {
    //   errors.buildingNo = "Building No is required!";
    // }
    if (!floorPlanName) {
      errors.floorPlanName = "Floor Plan Name is required!";
    }
    if (!floorPlanCode) {
      errors.floorPlanCode = "Floor Plan Code is required!";
    }
    // if (!noOfRooms) {
    //   errors.noOfRooms = "No. Of Rooms is required!";
    // }
    if (!noOfBeds) {
      errors.noOfBeds = "No. Of Beds is required!";
    }
    if (!noOfBathrooms) {
      errors.noOfBathrooms = "No. Of Bathrooms is required!";
    }
    if (!layout) {
      errors.layout = "Layout is required!";
    }
    return errors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (apiProcessing.loader) {
      handleSnackbar("warning", "Operation in Progress..!");
    } else {
      setApiProcessing({
        loader: true,
        message: `Creating FloorPlan...`,
      });

      let data = {
        unitTypeName: floorPlanName,
        unitCode: floorPlanCode,
        //buildingNumber: buildingNo,
        numberOfBeds: noOfBeds,
        numberOfBaths: noOfBathrooms,
        //numberOfRooms: noOfRooms,
        layouts: [layout.name],
        propertyId: props.PropertyId,
      };

      webApi
        .post("/unitTypes", data)
        .then(async (res) => {
          if (res.data.errorCode) {
            setApiProcessing({
              loader: false,
              message: "Loading...",
            });
            swal("Error", res.data.errorMessage, "error");
          } else {
            const metaData = {
              filename: layout.name,
              mediatype: layout.type,
            };

            if (res.data.layouts.length) {
              setApiProcessing({
                loader: true,
                message: "Uploding Layout...",
              });

              webApi
                .uploadFile(res.data.layouts[0], metaData, layout)
                .then((res) => {
                  handleFloorPlanCallback();
                })
                .catch((error) => {
                  if (error.customErrorMessage) {
                    swal("Error", error.customErrorMessage, "error");
                  } else {
                    swal(
                      "Error",
                      `Failed to upload ${layout.name}. Please try again later.`,
                      "error"
                    );
                  }
                  handleFloorPlanCallback();
                });
            } else {
              handleFloorPlanCallback();
            }
          }
        })
        .catch((error) => {
          setApiProcessing({
            loader: false,
            message: "Loading...",
          });
          if (error.customErrorMessage) {
            swal("Error", error.customErrorMessage, "error");
          } else {
            swal(
              "Error",
              `Oops! Failed to Create ${data.unitTypeName} Floor Plan, Please try again later.`,
              "error"
            );
          }
        });
    }
  };

  const handleFloorPlanCallback = () => {
    props.getFloorPlans();
    swal("Success", "Floor Plan Created", "success");
    setApiProcessing({
      loader: false,
      message: "Loading...",
    });
    props.SetOpenFloorPlanForm(false);
  };

  const handleSteps = (event) => {
    event.preventDefault();
    const temp = validate();
    setFormErrors(temp);
    if (Object.keys(temp).length) {
      return;
    } else {
      setActiveStep((currentStep) => currentStep + 1);
    }
  };

  const handleBack = (event) => {
    event.preventDefault();
    setActiveStep((currentStep) => currentStep - 1);
  };

  const handleSnackbar = (type, message) => {
    setOpenSnackbar(true);
    setSnackbarType(type);
    setSnackbarMessage(message);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return props.openFloorPlanForm ? (
    <>
      <Dialog
        open={true}
        aria-labelledby="Create FloorPlan Form"
        fullWidth
        maxWidth="md"
        PaperProps={{ sx: { height: "80%" } }}
        //scroll="body"
        className="w-full rounded mx-auto"
      >
        <SnackAlert
          open={openSnackbar}
          close={handleSnackbarClose}
          type={snackbarType}
          message={snackbarMessage}
        />

        <Loader open={apiProcessing.loader} message={apiProcessing.message} />

        <div className="flex justify-center items-center bg-blue-52 text-white font-semibold rounded-sm h-10 pr-2">
          <span className="flex-grow text-center">Add Floor Plan</span>
          <ButtonIcon
            id="closeFloorPlamForm"
            btnName="closeFloorPlamForm"
            dataCy="closeFloorPlamForm"
            title="Close Floor Plan Form"
            onClick={(e) => props.SetOpenFloorPlanForm(false)}
            icon={<AiOutlineCloseCircle className="w-5 h-5" />}
            className="hover:!text-red-600"
          />
        </div>

        <Stepper activeStep={activeStep} alternativeLabel className="my-4">
          <Step>
            <StepLabel>Floor Plan Details</StepLabel>
          </Step>
          <Step>
            <StepLabel>Confirm Details</StepLabel>
          </Step>
        </Stepper>

        <DialogContent dividers>
          <form className="flex flex-col justify-center items-center">
            {activeStep == 0 && (
              <>
                <span className="border-b-2 border-blue-52 pb-1 mb-4 text-blue-52 w-5/6 ">
                  Floor Plan Details
                </span>
                <section className="grid grid-cols-2 gap-x-16 gap-y-6 w-5/6 mb-4">
                  <InputField
                    label="Floor Plan Name"
                    name="floorPlanName"
                    id="floorPlanName"
                    dataCy="floorPlanName"
                    type="text"
                    placeholder="Enter Floor Plan Name"
                    value={floorPlanName}
                    onChange={(e) => setFloorPlanName(e.target.value)}
                    errorText={formErrors.floorPlanName}
                    style="!w-full"
                  />

                  <InputField
                    label="Floor Plan Code"
                    name="floorPlanCode"
                    id="floorPlanCode"
                    dataCy="floorPlanCode"
                    type="text"
                    placeholder="Enter Floor Plan Code"
                    value={floorPlanCode}
                    onChange={(e) => setFloorPlanCode(e.target.value)}
                    errorText={formErrors.floorPlanCode}
                    style="!w-full"
                  />

                  {/* <InputField
                    label="No. Of Rooms"
                    name="noOfRooms"
                    id="noOfRooms"
                    dataCy="noOfRooms"
                    type="text"
                    customProps={inputNumCustomProps}
                    placeholder="Enter No. of Rooms"
                    value={noOfRooms}
                    onChange={(e) => {
                      let value = "";
                      if (e.target.value.replace(/[^\d]/g, "")) {
                        value = parseInt(e.target.value.replace(/[^\d]/g, ""));
                      }
                      setNoOfRooms(value);
                    }}
                    errorText={formErrors.noOfRooms}
                    style="!w-full"
                  /> */}

                  <InputField
                    label="No. Of Beds"
                    name="noOfBeds"
                    id="noOfBeds"
                    dataCy="noOfBeds"
                    type="text"
                    customProps={inputNumCustomProps}
                    placeholder="Enter No. of Beds"
                    value={noOfBeds}
                    onChange={(e) => {
                      let value = "";
                      if (e.target.value.replace(/[^\d]/g, "")) {
                        value = parseInt(e.target.value.replace(/[^\d]/g, ""));
                      }
                      setNoOfBeds(value);
                    }}
                    errorText={formErrors.noOfBeds}
                    style="!w-full"
                  />

                  <InputField
                    label="No. Of Bathrooms"
                    name="noOfBathrooms"
                    id="noOfBathrooms"
                    dataCy="noOfBathrooms"
                    type="text"
                    customProps={inputNumCustomProps}
                    placeholder="Enter No. of Bathrooms"
                    value={noOfBathrooms}
                    onChange={(e) => {
                      let value = "";
                      if (e.target.value.replace(/[^\d]/g, "")) {
                        value = parseInt(e.target.value.replace(/[^\d]/g, ""));
                      }
                      setNoOfBathrooms(value);
                    }}
                    errorText={formErrors.noOfBathrooms}
                    style="!w-full"
                  />

                  <InputFileField
                    label="Layouts"
                    name="layout"
                    id="layout"
                    dataCy="layout"
                    accept=".png, .jpg, .pdf"
                    maxSizeinMB={10}
                    placeholder="Attach .png, .jpg, .pdf only"
                    value={layout}
                    setValue={setLayout}
                    hintText="*File Size should be less than 10MB"
                    style="!w-full"
                    errorText={formErrors.layout}
                  />
                </section>
                <div className="flex justify-center items-center gap-16 mt-6 mb-4 w-3/4">
                  <ButtonGrayOutline
                    name="Cancel"
                    btnName="cancel"
                    dataCy="cancel"
                    onClick={() => props.SetOpenFloorPlanForm(false)}
                    className="mt-6 justify-self-end"
                  />
                  <ButtonBlue
                    name="Next"
                    btnName="next"
                    dataCy="next"
                    onClick={(e) => handleSteps(e)}
                    className="mt-6"
                  />
                </div>
              </>
            )}

            {activeStep === 1 ? (
              <>
                <span className="border-b-2 border-blue-52 pb-1 mb-4 text-blue-52 w-3/4 md:gap-x-8 md:w-11/12">
                  Review Floor Plan Details
                </span>
                <section className="grid grid-cols-3 gap-x-16 gap-y-6 w-3/4 justify-center items-center md:gap-x-8 md:w-11/12">
                  <TextReviewTile
                    title="Floor Plan Name"
                    body={floorPlanName}
                  />
                  <TextReviewTile
                    title="Floor Plan Code"
                    body={floorPlanCode}
                  />
                  {/* <TextReviewTile title="Building No." body={buildingNo} /> */}
                  {/* <TextReviewTile title="No.of Rooms" body={noOfRooms} /> */}
                  <TextReviewTile title="No.of Beds" body={noOfBeds} />
                  <TextReviewTile
                    title="No.of BathRooms"
                    body={noOfBathrooms}
                  />
                  <FileReviewTile title="Layouts" body={layout.name} />
                </section>
                <div className="flex justify-center items-center gap-16 mt-6 mb-4 w-3/4">
                  <ButtonGrayOutline
                    name="Back"
                    id="back"
                    btnName="back"
                    dataCy="back"
                    onClick={(e) => handleBack(e)}
                    hidden={apiProcessing.loader}
                  />
                  <ButtonBlue
                    name="Confirm"
                    id="confirm"
                    btnName="confirm"
                    dataCy="confirm"
                    onClick={handleSubmit}
                  />
                </div>
              </>
            ) : null}
          </form>
        </DialogContent>
      </Dialog>
    </>
  ) : null;
};

export default CreateFloorPlan;
