import React, { useState, useCallback, useEffect } from "react";
import { Auth, label } from "aws-amplify";
import { WebApimanager } from "../../WebApiManager";
import swal from "sweetalert";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import SelectCountryCode, {
  PhoneNumFormat,
  TaxIdFormat,
} from "../../Widgets/Forms/FieldDependants";
import InputField, {
  InputObjectSelectField,
  InputPasswordField,
  InputPhoneNumField,
  InputSelectField,
} from "../../Widgets/Forms/InputFields";
import AddressComponent from "../../Widgets/Forms/AddressComponent";
import { ButtonBlue } from "../../Widgets/Buttons";
import Footer from "../../Widgets/Footer";
import { Loader } from "../../Widgets/notificationFeedbacks";

const SignUp = () => {
  let webApi = new WebApimanager();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  //const userRoleOptions = ["CLIENT ADMIN", "PROPERTY MANAGER"]
  const [profile, setProfile] = useState("Client Admin");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [taxid, setTaxId] = useState("");
  const [companyName, setCompanyName] = useState("");

  const [completeAddress, setCompleteAddress] = useState({
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",
  });

  const [checkTerms, setCheckTerms] = useState(false);
  const [formErrors, setFormErrors] = useState("");
  const [confirmUser, setConfirmUser] = useState(false);
  const [code, setCode] = useState("");
  const [mobVerify, setmobVerify] = useState(false);

  const [allProductLines, setAllProductLines] = useState([]);
  const [selectedProductLine, setSelectedProductLine] = useState("");

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  let navigate = useNavigate();

  useEffect(() => {
    console.log("inside useEffect");
    getProductLines();
  }, []);

  const getProductLines = async () => {
    console.log("Calling API");
    let headers = {
      "Content-Type": "application/json",
    };
    await axios({
      method: "get",
      url: config.apiGateway.URL + "/client/productLines", //using different URL for only sign up, as we cannnot pass id token in signup process.
      headers: headers,
    })
      .then((res) => {
        console.log("Checking API Response");
        if (res.data.errorCode) {
          swal("", res.data.errorMessage, "error");
        } else {
          setAllProductLines(res.data);
        }
      })
      .catch((error) => {
        swal(
          "",
          `Unable to load Product Lines, Please try again later.`,
          "error"
        );
      });
  };

  const validate = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    //let Number = phoneNum.replace(/[^\+1]/g, "");
    let Id = taxid.replace(/[^\d]/g, "");
    if (!firstName.trim()) {
      errors.firstName = "First name is required!";
    }
    if (!lastName.trim()) {
      errors.lastName = "Last name is required!";
    }
    if (!email.trim()) {
      errors.email = "Email is required!";
    } else if (!emailRegex.test(email)) {
      errors.email = "Email is not valid!";
    }
    if (!phoneNum.trim()) {
      errors.phoneNum = "Contact number is required!";
    } else if (phoneNum.length !== 12) {
      errors.phoneNum = "Contact number is not valid!";
    }
    if (!Id.trim()) {
      errors.taxid = "Tax ID is required!";
    } else if (Id.length !== 9) {
      errors.taxid = "Tax ID is not valid!";
    }
    if (!selectedProductLine.trim()) {
      errors.selectedProductLine = "Product Line is required!";
    }
    // if (!profile) {
    //   errors.profile = "Role Type is required!";
    // }
    if (!companyName) {
      errors.companyName = "Company Name is required!";
    }

    if (!completeAddress.address1.trim()) {
      errors.address1 = "Address 1 is required!";
    }
    if (!completeAddress.city.trim()) {
      errors.city = "City is required!";
    }
    if (!completeAddress.state.trim()) {
      errors.state = "State is required!";
    }
    if (!completeAddress.country.trim()) {
      errors.country = "Country is required!";
    }
    if (!completeAddress.zipCode.trim()) {
      errors.zipCode = "Zipcode is required!";
    } else if (completeAddress.zipCode.toString().length !== 5) {
      errors.zipCode = "Zipcode is not valid!";
    }

    if (!password.trim()) {
      errors.password = "Password is required!";
    } else if (!passwordRegex.test(password)) {
      errors.password = "Password is not valid!";
    }
    if (!confirmPass.trim()) {
      errors.confirmPass = "Confirm Password is required!";
    } else if (!passwordRegex.test(confirmPass)) {
      errors.confirmPass = " Confirm Password is not valid!";
    } else if (confirmPass !== password) {
      errors.confirmPass = "Password &  Confirm Password must be same!";
    }
    if (!checkTerms) {
      errors.checkTerms = "Accept terms & conditions!";
    }
    return errors;
  };

  const handlesubmit = async (event) => {
    event.preventDefault();
    const temp = validate();
    setFormErrors(temp);

    if (Object.keys(temp).length) {
      return;
    } else {
      const isValidated = await validateTaxId();
      if (isValidated) {
        setApiProcessing({
          loader: true,
          message: `Creating User ${firstName + " " + lastName}...`,
        });
        let headers = {
          "Content-Type": "application/json",
        };
        let data = {
          email: email,
          firstName: firstName,
          lastName: lastName,
          companyName: companyName,
          phoneNumber: phoneNum,
          taxId: taxid,
          clientType: selectedProductLine,
          userRole: profile,
          businessAddress: {
            address1: completeAddress.address1,
            address2: completeAddress.address2,
            city: completeAddress.city,
            state: completeAddress.state,
            country: completeAddress.country,
            zipCode: completeAddress.zipCode,
          },
        };

        await axios({
          method: "POST",
          url: config.apiGateway.URL + "/clients", //using different URL for only sign up, as we cannnot pass id token in signup process.
          headers: headers,
          data: data,
        })
          .then((res) => {
            console.log("res", res);
            if (res.status === 200 || res.status === 204) {
              if (res.data.errorCode) {
                setApiProcessing({ loader: false, message: `` });
                swal("Failure", res.data.errorMessage, "error");
              } else {
                confirmSignUp();
              }
            } else {
              setApiProcessing({ loader: false, message: `` });
              throw new Error();
            }
          })
          .catch((error) => {
            console.log("error ", error.message);
            setApiProcessing({ loader: false, message: `` });
            swal(
              "Error",
              `Apologies, Failed to Create new Account, Please try again later.`,
              "error"
            );
          });
      }
    }
  };

  const validateTaxId = async () => {
    setApiProcessing({
      loader: true,
      message: `Validating Tax ID...`,
    });

    try {
      const response = await webApi.get(
        "/validate/tax/" + taxid.replace(/[^\d]/g, "")
      );

      if (response.data.errorCode) {
        swal("Error", response.data.errorMessage, "error");
        return false;
      } else {
        if (response.data.is_valid) {
          return true;
        } else {
          swal(
            "Warning",
            `Oops! The Tax Id is not Valid, Please try again with a Valid ID.`,
            "warning"
          );
          setApiProcessing({ loader: false, message: `` });
          return false;
        }
      }
    } catch (error) {
      setApiProcessing({ loader: false, message: `` });
      if (error.customErrorMessage) {
        swal("Error", error.customErrorMessage, "error");
      } else {
        swal(
          "Error",
          `Oops! Failed to Validate Tax ID, Please try again later.`,
          "error"
        );
      }
      return false;
    }
  };

  const confirmSignUp = async () => {
    setApiProcessing({
      loader: true,
      message: `Signing Up...`,
    });
    Auth.signUp({
      username: email,
      password: password,
      attributes: {
        email,
        phone_number: "+1" + phoneNum.replace(/[^\d]/g, ""),
        // email_verified: "true",
        // phone_number_verified: "true",
        // "custom:profile": prof,
      },
    })
      .then((user) => {
        //swal("Success", "Sign up successful", "success");
        setConfirmUser(true);
        setApiProcessing({ loader: false, message: `` });
      })
      .catch((e) => {
        console.log("e", e);
        setApiProcessing({ loader: false, message: `` });
        swal("Error", e.message, "error");
      });
  };

  const handlesubmitVerificationCode = async (event) => {
    event.preventDefault();
    setApiProcessing({
      loader: true,
      message: `Verifying ${email}...`,
    });
    await Auth.confirmSignUp(email, code)
      .then((user) => {
        console.log("user", user);
        setApiProcessing({ loader: false, message: `` });
        //swal("Success", "Verification Successful", "success");
        swal({
          title: "Success",
          text: "Verification Successful",
          icon: "success",
          button: {
            text: "OK",
            id: "signupSuccessNotifButton", // Add an ID to the button
          },
        });

        navigate("/login");
      })
      .catch((e) => {
        console.log("e", e);
        setApiProcessing({ loader: false, message: `` });
        swal("Error", e.message, "error");
      });
  };
  const ColoredLine = ({ color }) => (
    <hr
      style={{
        color,
        backgroundColor: color,
        height: 3,
      }}
    />
  );

  const setNewAddress = useCallback(
    (newAddress) => {
      setCompleteAddress(newAddress);
    },
    [completeAddress]
  );

  const resendCode = () => {
    Auth.resendSignUp(email)
      .then((user) => {
        console.log("user", user);
        swal("Success", "Verification code sent Successful", "success");
        //navigate("/login");
      })
      .catch((e) => {
        console.log("e", e);
        swal("Error", e.message, "error");
      });
  };

  return (
    <>
      <Loader open={apiProcessing.loader} message={apiProcessing.message} />

      <section
        className={`flex flex-col justify-center items-center w-1/4 fixed bg-blue-52 h-full md:w-full md:h-[20%] md:relative md:mb-8`}
      >
        <img
          id="signupPageLogo"
          className="w-64 h-20 md:h-14 md:w-40"
          src="logo/NEWLOGO.png"
          alt="Yvooa logo"
        />
        <span className="text-white text-xl text-center pl-5">
          <p>Your Residential Solutions;</p>
          <p>under one roof.</p>
        </span>
      </section>

      {!confirmUser && (
        <>
          <section className="flex flex-col justify-center items-center ml-[25%] md:ml-0">
            <h2 className="text-center font-bold text-gray-700 underline text-2xl">
              SIGN UP
            </h2>
            <form className="flex flex-col mt-4">
              <span className="pb-1 mb-2 border-b-2 border-blue-52 text-blue-52">
                Personal Information
              </span>
              <div className="grid grid-cols-2 gap-x-12 gap-y-6">
                <InputField
                  label="First Name"
                  name="firstName"
                  id="firstName"
                  dataCy="firstName"
                  type="text"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) =>
                    setFirstName(e.target.value.replace(/[^\w\s]/gi, ""))
                  }
                  errorText={formErrors.firstName}
                />
                <InputField
                  label="Last Name"
                  name="lastName"
                  id="lastName"
                  dataCy="lastName"
                  type="text"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) =>
                    setLastName(e.target.value.replace(/[^\w\s]/gi, ""))
                  }
                  errorText={formErrors.lastName}
                />

                <InputField
                  label="Email"
                  name="email"
                  id="signupEmail"
                  dataCy="email"
                  type="text"
                  placeholder="example@mail.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  errorText={formErrors.email}
                />

                <InputPhoneNumField
                  label="Contact Number"
                  name="phoneNumber"
                  id="phoneNumber"
                  dataCy="phoneNumber"
                  placeholder="Enter Contact Number"
                  value={phoneNum}
                  setValue={setPhoneNum}
                  // onChange={(e) => {
                  //   let num = PhoneNumFormat(e.target.value);
                  //   setPhoneNum(num);
                  // }}
                  errorText={formErrors.phoneNum}
                />

                {/* <label
                  className="text-gray-700 text-sm font-semibold"
                  htmlFor="profileType"
                >
                  Choose a Profile Type<span className="text-red-600">*</span>
                  <select
                    name="profileType"
                    id="profileType"
                    key="profileType"
                    value={profile}
                    onChange={(e) => setProfile(e.target.value)}
                    className="bg-gray-50 border text-gray-900 text-sm focus:border-blue-52 w-full p-2.5 mt-0.5"
                  >
                    <option value="">Choose a value</option>
                    <option value="Client Admin">Client Admin</option>
                    <option value="Property Manager">Property Manager</option>
                  </select>
                  <span className="text-red-10 text-sm">
                    {formErrors.profile}
                  </span>
                </label> */}

                {/* <InputSelectField
                  label="User Role"
                  name="userRole"
                  id="userRole"
                  dataCy="userRole"
                  placeholder="Choose a Role"
                  options={userRoleOptions}
                  value={profile}
                  onChange={(e) =>
                    setProfile(e.target.value)
                  }
                  errorText={formErrors.profile}
                  style="!w-full"
                /> */}

                <InputField
                  label="Company Name"
                  name="companyName"
                  id="companyName"
                  dataCy="companyName"
                  type="text"
                  placeholder="Company Name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  errorText={formErrors.companyName}
                />

                <InputField
                  label="Tax ID"
                  name="taxId"
                  id="taxId"
                  dataCy="taxId"
                  type="text"
                  placeholder="xx-xxxxxxx"
                  value={taxid}
                  onChange={(e) => {
                    let id = TaxIdFormat(e.target.value);
                    setTaxId(id);
                  }}
                  errorText={formErrors.taxid}
                />

                <InputObjectSelectField
                  label="Product Type"
                  name="productType"
                  id="productType"
                  dataCy="productType"
                  placeholder="Select a Product Line"
                  value={selectedProductLine}
                  onChange={(e) => setSelectedProductLine(e.target.value)}
                  errorText={formErrors.selectedProductLine}
                  options={allProductLines}
                  optionValue="productName"
                  optionName="productName"
                  style="!w-80"
                />
              </div>

              <span className="pb-1 mb-2 mt-4 border-b-2 border-blue-52 text-blue-52">
                Address
              </span>
              <div className="grid grid-cols-2 gap-x-12 gap-y-6">
                <AddressComponent
                  address={completeAddress}
                  setNewAddress={setNewAddress}
                  for="signup"
                  formErrors={formErrors}
                />
              </div>
              <span className="pb-1 mb-2 mt-4 border-b-2 border-blue-52 text-blue-52">
                Password
              </span>
              <div className="grid grid-cols-2 gap-x-12 gap-y-6">
                <InputPasswordField
                  label="Password"
                  name="password"
                  id="signupPassword"
                  dataCy="signupPassword"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  errorText={formErrors.password}
                />
                <InputPasswordField
                  label="Confirm Password"
                  name="confirmPassword"
                  id="confirmSignUpPassword"
                  dataCy="confirmSignUpPassword"
                  placeholder="Confirm Password"
                  value={confirmPass}
                  onChange={(e) => setConfirmPass(e.target.value)}
                  errorText={formErrors.confirmPass}
                />
              </div>
              <div className="flex flex-col justify-center items-center mt-8">
                <label
                  htmlFor="termsAndConditions"
                  className="text-gray-800 text-sm"
                >
                  <input
                    className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    name="termsAndConditions"
                    id="termsAndConditionsCheckbox"
                    data-cy="termsAndConditionsCheckbox"
                    type="checkbox"
                    value={checkTerms}
                    onChange={(e) => setCheckTerms(e.target.checked)}
                  />
                  I Agree to the{" "}
                  <a
                    href="#"
                    id="termsAndConditions"
                    className="text-blue-500 focus:outline-none focus:underline hover:underline"
                  >
                    Terms & Conditions
                  </a>
                </label>
                <span className="text-red-10 text-sm">
                  {formErrors.checkTerms}
                </span>
                <ButtonBlue
                  name="SIGN UP"
                  id="signupButton"
                  className="w-96 h-12 mt-4"
                  onClick={handlesubmit}
                />
                <p className="mt-6 text-sm text-center text-gray-800">
                  Already have an account{" "}
                  <u
                    id="loginPageRedirectLink"
                    onClick={(e) => {
                      window.scrollTo(0, 0);
                      navigate("/login");
                    }}
                    className="text-blue-500 cursor-pointer"
                  >
                    LOG-IN HERE
                  </u>
                </p>
                <span className=" flex flex-row text-center mt-4 mb-8">
                  Questions? Contact Customer Support at{" "}
                  <p className=" text-blue-52 pl-1">
                    support@rapidviewtech.com
                  </p>
                </span>
              </div>
            </form>
            <Footer />
          </section>
        </>
      )}

      {confirmUser && (
        <section className="flex flex-col items-center justify-center h-screen ml-[25%] md:ml-0">
          <img
            id="confirmUserEmailPageLogo"
            className="w-14 h-14 mb-4"
            src="logo/LOCKICON.png"
            alt="Lock Icon"
          />
          <form onSubmit={handlesubmitVerificationCode}>
            <p className="text-lg p-5 tracking-[0.75em] font-bold text-center">
              ENTER CODE
            </p>
            <p className="text-sm pb-2 text-center">
              Enter the code sent to your registered Email
            </p>

            <input
              className="shadow appearance-none border rounded w-96 py-2 px-3 text-gray-700 leading-tight focus:outline-none xs:w-[19rem]"
              name="emailCode"
              id="emailCode"
              data-cy="emailCode"
              type="text"
              placeholder="Enter the code sent to your registered Email"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />

            <div
              className="text-blue-500 text-right mt-1 text-sm focus:outline-none focus:underline hover:underline cursor-pointer"
              id="resendCode"
              name="resendCodeBtn"
              data-cy="resendCodeBtn"
              onClick={() => resendCode()}
            >
              Resend code?
            </div>

            <button
              className="bg-blue-52 mt-6 w-96 h-10  text-white font-semibold border border-gray-400 rounded shadow xs:w-[19rem]"
              id="confirmBtn"
              name="confirmBtn"
              data-cy="confirmBtn"
            >
              CONFIRM
            </button>
          </form>
          <Footer />
        </section>
      )}

      {mobVerify && (
        <div className="flex flex-col items-center justify-center h-screen ml-[25%] md:ml-0">
          <img
            id="confirmUserPhoneNumPageLogo"
            className="w-14 h-14 mb-4"
            src="logo/LOCKICON.png"
            alt="Lock Icon"
          />

          <form onSubmit={handlesubmitVerificationCode}>
            <p className="text-lg p-5 tracking-[0.75em] font-bold text-center">
              ENTER CODE
            </p>
            <p className="text-sm pb-2 text-center">
              Enter the code sent to your registered Mobile
            </p>

            <input
              className="shadow appearance-none border rounded w-96 py-2 px-3 text-gray-700 leading-tight focus:outline-none xs:w-[19rem]"
              name="mobileCode"
              id="mobileCode"
              data-cy="mobileCode"
              type="text"
              placeholder="Code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />

            <div
              className="text-blue-500 text-right mt-1 text-sm focus:outline-none focus:underline hover:underline cursor-pointer"
              id="resendCode"
              name="resendCodeBtn"
              data-cy="resendCodeBtn"
            >
              Resend code?
            </div>

            <button
              className="bg-blue-52 w-96 h-10 mt-4 text-white font-semibold border border-gray-400 rounded shadow xs:w-[19rem]"
              id="confirmBtn"
              name="confirmBtn"
              data-cy="confirmBtn"
            >
              CONFIRM
            </button>
          </form>
          <Footer />
        </div>
      )}
    </>
  );
};

export default SignUp;
