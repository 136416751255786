import React from "react";
import { Snackbar, Alert } from "@mui/material";

const SnackAlert = (props) => {
  return (
    <>
      <Snackbar open={props.open} onClose={props.close} autoHideDuration={5000}>
        <Alert
          onClose={props.close}
          variant="filled"
          severity={props.type}
          sx={{ width: "100%" }}
        >
          {props.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SnackAlert;
