import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";

import { InputTextArea } from "../Forms/InputFields";
import { ButtonGrayOutline, ButtonBlue } from "../Buttons";
import { IconInCircle } from "../Icons";

import swal from "sweetalert";
import { PiWarningOctagon } from "react-icons/pi";

const StatusUpdateDialog = ({
  open,
  onClose,
  title,
  message,
  confirmButtonText,
  cancelButtonText,
  onConfirm,
  onCancel,
  textAreaTitle,
  textAreaPlaceholder,
  textAreaRequired = false,
  errorText,
  extendData,
}) => {
  const [textAreaValue, setTextAreaValue] = useState("");

  const handleConfirm = (e) => {
    e.preventDefault();
    if (textAreaRequired && !textAreaValue.trim()) {
      swal(
        "",
        errorText ? `${errorText}` : `Please Enter the description!`,
        "warning"
      );
    } else {
      onConfirm({ ...extendData, textAreaValue: textAreaValue });
      setTextAreaValue("");
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-title"
      fullWidth
      maxWidth="sm"
      PaperProps={{ sx: { height: "auto" } }}
      scroll="body"
      className="mx-auto w-full rounded"
    >
      <div className="flex flex-col items-center pb-6">
        <span className="mb-6 bg-blue-52 text-white font-semibold text-center rounded-sm h-10 w-full flex items-center justify-center">
          {title}
        </span>

        <PiWarningOctagon className="w-12 h-12 text-blue-52 mb-4" />

        <p className="text-center mb-4">{message}</p>

        {textAreaPlaceholder && (
          <InputTextArea
            label={
              <span>
                {textAreaTitle}
                {textAreaRequired && <span className="text-red-600">*</span>}
              </span>
            }
            name="Comment"
            notImp
            id="Comment"
            type="text"
            placeholder={textAreaPlaceholder}
            value={textAreaValue}
            onChange={(e) => setTextAreaValue(e.target.value)}
            style="!mt-4 !mb-6"
            className="h-16 rounded-lg w-full max-w-md"
          />
        )}

        <div className="flex justify-center w-full gap-4 mt-4">
          <ButtonGrayOutline
            name={cancelButtonText}
            onClick={(e) => {
              if (onCancel) onCancel();
              setTextAreaValue("");
              onClose();
            }}
            className="w-auto"
          />
          <ButtonBlue
            name={confirmButtonText}
            onClick={(e) => handleConfirm(e)}
            className="w-auto"
          />
        </div>
      </div>
    </Dialog>
  );
};

export default StatusUpdateDialog;
