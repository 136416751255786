import React, { useState } from "react";
import { Auth } from "aws-amplify";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import classes from "./index.module.css";
import { Link } from "react-router-dom";
import InputField, {
  InputPasswordField,
} from "../../Widgets/Forms/InputFields";
import axios from "axios";
import config from "../../config";
import Footer from "../../Widgets/Footer";

const ForgotPass = () => {
  const [email, setEmail] = useState("");
  const [newPass, setNewPass] = useState("");
  const [conPass, setConPass] = useState("");
  const [shownComp, setShownComp] = useState("FP");
  const [code, setCode] = useState("");
  const [regdEmail, setRegdEmail] = useState("");
  const [formErrors, setFormErrors] = useState("");
  const [maskedNumber, setMaskedNumber] = useState("");
  const [emailOrMob, setEmailOrMob] = useState(false);
  const [userOptedVal, setUserOptedVal] = useState("email");
  const [codeID, setCodeID] = useState("");

  let navigate = useNavigate();

  const handlesubmit = async (event) => {
    event.preventDefault();
    const temp = validate();
    setFormErrors(temp);
    if (Object.keys(temp).length) {
      return;
    } else {
      let headers = {
        "Content-Type": "application/json",
      };

      await axios({
        method: "GET",
        url: config.apiGateway.URL + "/clients/check/" + email, //using different URL for only sign up, as we cannnot pass id token in signup process.
        headers: headers,
      })
        .then((res) => {
          //console.log("res", res);
          if (res.status === 200 || res.status === 204) {
            if (res.data.errorCode) {
              swal("Failure", res.data.errorMessage, "error");
            } else {
              setMaskedNumber(res.data.phoneNumberMask);
              if (res.data.isEmailexists === true) {
                Auth.forgotPassword(email)
                  .then((res) => {
                    console.log("res", res);
                    if (res && res.CodeDeliveryDetails) {
                      swal(
                        "Success",
                        "Verification code is sent to respective email id.",
                        "success"
                      );
                      setShownComp("RP");
                      setRegdEmail(
                        res.CodeDeliveryDetails &&
                          res.CodeDeliveryDetails.Destination
                      );
                    }
                  })
                  .catch((error) => {
                    console.log("error", error);
                    swal(
                      "Error",
                      "Failed to send verification code. Please try again later.",
                      "error"
                    );
                  });
              } else {
                swal(
                  "Failure",
                  "User does not exist, Please try again with a valid Email",
                  "error"
                );
              }
            }
          } else {
            throw new Error();
          }
        })
        .catch((error) => {
          swal(
            "Error",
            `Apologies, Failed to Get User details, Please try again later.`,
            "error"
          );
        });
    }
  };

  const resetPassword = async (event) => {
    event.preventDefault();
    const temp = validate();
    setFormErrors(temp);
    if (Object.keys(temp).length) {
      return;
    } else {
      if (userOptedVal === "email") {
        await Auth.forgotPasswordSubmit(email, code, newPass)
          .then((res) => {
            console.log("res", res);
            swal("Success", "Password changed successfully", "success");
            navigate("/login");
          })
          .catch((error) => {
            console.log("error", error);
            swal("Error", error.message, "error");
          });
      } else if (userOptedVal === "mobileNumber") {
        let headers = {
          "Content-Type": "application/json",
        };

        let data = {
          id: codeID,
          otp: code,
          newPassword: newPass,
        };

        await axios({
          method: "POST",
          url: config.apiGateway.URL + "/verifyOTP",
          headers: headers,
          data: data,
        })
          .then((res) => {
            if (res.status === 200 || res.status === 204) {
              if (res.data.errorCode) {
                swal("Failure", res.data.errorMessage, "error");
              } else {
                if (res.data.status === true) {
                  swal("Success", res.data.message, "success");
                  navigate("/login");
                } else if (res.data.status === false) {
                  swal("Warning", res.data.message, "warning");
                }
              }
            } else {
              throw new Error();
            }
          })
          .catch((error) => {
            swal(
              "Error",
              `Apologies, Failed to Reset Password, Please try again later.`,
              "error"
            );
          });
      }
    }
  };

  const validate = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

    if (shownComp === "FP") {
      if (!email) {
        errors.email = "Email is required!";
      } else if (!emailRegex.test(email)) {
        errors.email = "Email is not valid!";
      }
    } else if (shownComp === "RP") {
      if (!code) {
        errors.selectedFloorPlanId = "Floor Plan is required!";
      }
      if (!newPass) {
        errors.newPass = "Password is required!";
      } else if (!passwordRegex.test(newPass)) {
        errors.newPass = "Password is not valid!";
      }
      if (!conPass) {
        errors.conPass = "Confirm Password is required!";
      } else if (!passwordRegex.test(conPass)) {
        errors.conPass = " Confirm Password is not valid!";
      } else if (conPass !== newPass) {
        errors.conPass = "Password &  Confirm Password must be same!";
      }
    }

    return errors;
  };

  const resendCode = () => {
    setEmailOrMob(true);
    setShownComp("");
  };

  const sendCode = () => {
    if (userOptedVal === "email") {
      Auth.forgotPassword(email)
        .then((res) => {
          if (res && res.CodeDeliveryDetails) {
            swal(
              "Success",
              "Verification code is sent to respective email id.",
              "success"
            );
            setEmailOrMob(false);
            setShownComp("RP");
          }
        })
        .catch((error) => {
          console.log("error", error);
          swal(
            "Error",
            "Failed to re-send verification code. Please try again later.",
            "error"
          );
        });
    } else if (userOptedVal === "mobileNumber") {
      let headers = {
        "Content-Type": "application/json",
      };

      axios({
        method: "GET",
        url:
          config.apiGateway.URL +
          "/forgotPassword/role/Client Admin/email/" +
          email, //using different URL for only sign up, as we cannnot pass id token in signup process.
        headers: headers,
      })
        .then((res) => {
          //console.log("res", res);
          if (res.status === 200 || res.status === 204) {
            if (res.data.errorCode) {
              swal("Failure", res.data.errorMessage, "error");
            } else {
              swal("Success", res.data.message, "success");
              setCodeID(res.data.id);
              setEmailOrMob(false);
              setShownComp("RP");
            }
          } else {
            throw new Error();
          }
        })
        .catch((error) => {
          swal(
            "Error",
            `Apologies, Failed to send verification code, Please try again later.`,
            "error"
          );
        });
    }
  };

  return (
    <>
      <div className="flex min-h-screen w-full xs:flex-col sm:flex-col">
        <div className="flex w-1/4 bg-blue-52 justify-center items-center xs:w-full xs:pb-3 sm:w-full sm:pb-3">
          <img
            id="forgotPasswordLogo"
            src="logo/NEWLOGO.png"
            alt="Yvooa Logo"
            className="w-64 h-20 lg:w-60 md:h-14 md:w-40 xs:w-48 xs:h-16 sm:w-48 sm:h-16"
          />
        </div>

        {shownComp === "FP" ? (
          <div className="w-3/4 flex items-center justify-center flex-col xs:w-full sm:w-full xs:mt-8 sm:mt-8">
            <h2 className="text-2xl font-bold text-center text-gray-700 underline xs:font-semibold sm:font-semibold">
              Forgot Password
            </h2>

            <form onSubmit={handlesubmit} className="mt-8 xs:w-72 w-96">
              <InputField
                label="Email"
                name="email"
                id="email"
                dataCy="email"
                type="text"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                errorText={formErrors.email}
                style="!w-full"
              />

              <button
                className="bg-blue-52 mt-6 w-full h-10 text-white font-semibold border border-gray-400 rounded shadow"
                id="submitBtn"
                name="submitBtn"
                data-cy="submitBtn"
              >
                SUBMIT
              </button>
              <Link
                to="/login"
                className="text-blue-500 text-sm float-right hover:underline"
                id="backToLogin"
                name="backToLogin"
              >
                Back to LOG-IN
              </Link>
            </form>

            <span className=" flex flex-row mt-4 mb-8">
              Questions? Contact Customer Support at{" "}
              <p className=" text-blue-52 pl-1">support@rapidviewtech.com</p>
            </span>
            <Footer />
          </div>
        ) : null}

        {emailOrMob ? (
          <div className="w-3/4 flex items-center justify-center flex-col xs:w-full sm:w-full xs:mt-8 sm:mt-8">
            <h2 className="text-2xl font-bold text-center text-gray-700 underline xs:font-semibold sm:font-semibold">
              Forgot Password
            </h2>

            <div className="flex flex-col mt-10 items-center justify-center mb-10">
              <h3>Do you want receive code to Email or Phone Number?</h3>
              <div className="flex flex-col place-content-center mt-4 pl-6 sm:flex-col gap-y-6">
                <div className="flex items-center mr-10">
                  <input
                    id="inline-radio1"
                    type="radio"
                    value="email"
                    data-cy="email"
                    name="inline-radio-group1"
                    className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                    onChange={(e) => setUserOptedVal("email")}
                  />
                  <label
                    htmlFor="email"
                    className="ml-2 text-sm font-medium text-gray-900"
                  >
                    Email {email}
                  </label>
                </div>
                <div className="flex items-center mr-10 sm:mt-2">
                  <input
                    id="inline-radio1"
                    type="radio"
                    value="mobileNumber"
                    data-cy="mobileNumber"
                    name="inline-radio-group1"
                    className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                    onChange={(e) => setUserOptedVal("mobileNumber")}
                  />
                  <label
                    htmlFor="mobileNumber"
                    className="ml-2 text-sm font-medium text-gray-900"
                  >
                    Mobile Number {maskedNumber}
                  </label>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center">
              <button
                className="bg-blue-52 w-96 h-10 text-white font-semibold border border-gray-400 rounded shadow"
                onClick={() => sendCode()}
                id="submit"
                name="submit"
                data-cy="submit"
              >
                SUBMIT
              </button>
            </div>
          </div>
        ) : null}

        {shownComp === "RP" ? (
          <div className="w-3/4 flex items-center justify-center flex-col xs:w-full sm:w-full xs:mt-8 sm:mt-8">
            <h2 className="text-2xl font-bold text-center text-gray-700 underline xs:font-semibold sm:font-semibold">
              Reset Password
            </h2>

            <form onSubmit={resetPassword} className="mb-4 mt-8 xs:w-72 w-96">
              {userOptedVal && userOptedVal === "mobileNumber" ? (
                <p className="flex items-center justify-center text-black mb-6 text-sm font-bold">{`Verification Code sent to ${maskedNumber}`}</p>
              ) : (
                <p className="flex items-center justify-center text-black mb-6 text-sm font-bold">{`Verification Code sent to ${regdEmail}`}</p>
              )}

              <div className="">
                <InputField
                  label="Verification Code"
                  placeholder="Enter Code"
                  name="code"
                  id="code"
                  dataCy="code"
                  type="text"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  errorText={formErrors.code}
                  style="mt-4"
                  className={`w-96 font-semibold text-sm`}
                  maxLength={6}
                />
                <div
                  className="text-blue-500 text-right mt-1 text-sm focus:outline-none focus:underline hover:underline cursor-pointer"
                  onClick={() => resendCode()}
                >
                  Resend code?
                </div>
              </div>
              <div className="mb-4">
                <InputPasswordField
                  label="New Password"
                  name="newPass"
                  id="newPass"
                  dataCy="newPass"
                  placeholder="Enter New Password"
                  value={newPass}
                  onChange={(e) => setNewPass(e.target.value)}
                  errorText={formErrors.newPass}
                  style="mb-4"
                />
              </div>
              <div className="mb-4">
                <InputPasswordField
                  label="Confirm Password"
                  name="conPass"
                  id="conPass"
                  dataCy="conPass"
                  placeholder="Re-Enter New Password"
                  value={conPass}
                  onChange={(e) => setConPass(e.target.value)}
                  errorText={formErrors.conPass}
                  style="mb-4"
                />
              </div>

              <div className="flex items-center justify-center">
                <button
                  className="bg-blue-52 w-96 h-10 text-white font-semibold border border-gray-400 rounded shadow"
                  id="resetBtn"
                  name="resetBtn"
                  data-cy="resetBtn"
                >
                  RESET
                </button>
              </div>

              <Link
                to="/login"
                className="text-blue-500 text-sm float-right hover:underline"
                id="backToLgn"
                name="backToLgn"
                data-cy="backToLgn"
              >
                Back to LOG-IN
              </Link>

              <div className="flex items-center justify-center text-center text-black text-sm mt-12">
                <span>
                  Questions? Contact Customer Support at{" "}
                  <p className=" text-blue-500">support@rapidviewtech.com</p>
                </span>
              </div>
              <Footer />
            </form>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default ForgotPass;
