let REGION = `${process.env.REACT_APP_REGION}`;
//console.log("Build 18/07/2022");

let cognito = {
  REGION: REGION,
  USER_POOL_ID: `${process.env.REACT_APP_USER_POOL_ID}`,
  APP_CLIENT_ID: `${process.env.REACT_APP_APP_CLIENT_ID}`,
  IDENTITY_POOL_ID: `${process.env.REACT_APP_POOL_ID}`,
};

let apiGateway = {
  URL: `${process.env.REACT_APP_API_GATEWAY_URL}`,
};

let googleMap = {
  API: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
};

let BuildVersion = `${process.env.React_APP_Build_Version}`;

let FeatureFlagAPIKey = `${process.env.React_APP_Feature_Flag_API_Key}`;

export default {
  cognito,
  apiGateway,
  googleMap,
  BuildVersion,
  FeatureFlagAPIKey,
};
