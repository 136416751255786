import React, { useState, useEffect } from "react";
import { WebApimanager } from "../../WebApiManager";

import { Loader } from "../../Widgets/notificationFeedbacks";
import {
  ButtonIcon,
  ButtonCustom,
  ButtonGrayOutline,
  ButtonBlue,
} from "../../Widgets/Buttons";
import InputField, {
  InputObjectSelectField,
  InputSelectField,
  InputFileField,
  InputDateField,
} from "../../Widgets/Forms/InputFields";
import {
  GetDateInMMDDYYYY,
  DateFormatForAPI,
} from "../../Widgets/CommonWidgets";

import swal from "sweetalert";
import { Dialog, DialogContent } from "@mui/material";
import {
  AiOutlineCloseCircle,
  AiOutlineMinusCircle,
  AiOutlinePlusCircle,
} from "react-icons/ai";

const AddInspection = (props) => {
  let webApi = new WebApimanager();
  const unitId = props.unitId || "";
  const editForm = props.operation === "Edit" ? true : false;

  const [inspectionTypes, setInspectionTypes] = useState([]);
  const [allTenants, setAllTenants] = useState({
    current: [],
    future: [],
  });
  const [tenantOptions, setTenantOptions] = useState([]);
  const [inspectionDetails, setInspectionDetails] = useState({
    selectedInspectionType: "",
    inspectionDate: "",
    inspectionPerformedBy: "",
    tenantId: "",
  });

  const [formErrors, setFormErrors] = useState("");
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  useEffect(() => {
    //Checking Tenants List
    // if (props.tenants.length) {
    //   setAllTenants(props.tenants);
    // } else {
    //   getAllTenants(props.unitId);
    // }
    getAllTenants(props.unitId);
    //Checking Inspection Types
    if (props.inspectionTypes.length) {
      const types = props.inspectionTypes.filter((item) => item !== "All");
      setInspectionTypes(types);
    } else {
      getInspectionTypes();
    }
    //Edit Operation
    if (editForm && props.editData) {
      setInspectionDetails({
        selectedInspectionType: props.editData.inspectionType,
        inspectionDate: GetDateInMMDDYYYY(props.editData.inspectionDate),
        inspectionPerformedBy: props.editData.inspectionPerformedBy,
        tenantId: props.editData.tenantID,
      });
    }
  }, []);

  const getInspectionTypes = () => {
    setApiProcessing({
      loader: true,
      message: `Gathering Inspection Types...`,
    });
    webApi
      .get("/inspection/types")
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setInspectionTypes(res.data.inspectionType);
        }
        setApiProcessing({
          loader: false,
          message: ``,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: ``,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops, Unable to get Inspection Types. Please try again later.",
            "error"
          );
        }
      });
  };

  const getAllTenants = (unitId) => {
    setApiProcessing({
      loader: true,
      message: `Gathering Tenants...`,
    });
    webApi
      .get(`/tenant/status/unit/${unitId}`)
      .then((res) => {
        if (res.data !== null) {
          if (res.data.errorCode) {
            swal("Error", res.data.errorMessage, "error");
          } else {
            setAllTenants({
              current: res.data.current,
              future: res.data.future,
            });
          }
        }
        setApiProcessing({
          loader: false,
          message: ``,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: ``,
        });
        swal(
          "Error",
          `Oops! Failed to get Tenants, Please try again later.`,
          "error"
        );
      });
  };

  const validate = () => {
    const errors = {};
    if (!inspectionDetails.selectedInspectionType) {
      errors.selectedInspectionType = "Inspection Type is required!";
    }
    if (!inspectionDetails.inspectionDate.trim()) {
      errors.inspectionDate = "Inspection Date is required!";
    }
    if (!inspectionDetails.inspectionPerformedBy.trim()) {
      errors.inspectionPerformedBy = "Inspection Performed By is required!";
    }
    if (!inspectionDetails.tenantId) {
      errors.tenantId = "Tenant Name is required!";
    }
    return errors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const temp = validate();
    setFormErrors(temp);
    if (Object.keys(temp).length) {
      return;
    } else {
      setApiProcessing({
        loader: true,
        message: editForm
          ? `Updating Inspection Details...`
          : `Creating Inspection...`,
      });
      let data = {
        unitID: unitId,
        inspectionType: inspectionDetails.selectedInspectionType,
        inspectionPerformedBy: inspectionDetails.inspectionPerformedBy,
        inspectionDate: DateFormatForAPI(inspectionDetails.inspectionDate),
        tenantID: inspectionDetails.tenantId,
      };

      if (editForm) {
        webApi
          .put("/inspection/" + props.editData.ID, data)
          .then(async (res) => {
            if (res.data.errorCode) {
              swal("Failure", res.data.errorMessage, "error");
            } else {
              props.getAllInspections(res.data);
              swal("Success", "Inspection is Updated successfully", "success");
              props.setTrigger(false);
            }
            setApiProcessing({
              loader: false,
              message: ``,
            });
          })
          .catch((error) => {
            setApiProcessing({
              loader: false,
              message: ``,
            });
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                "Failed to Update Utility Bill! Please try again later.",
                "error"
              );
            }
          });
      } else {
        webApi
          .post("/inspection", data)
          .then(async (res) => {
            if (res.data.errorCode) {
              swal("Failure", res.data.errorMessage, "error");
            } else {
              props.getAllInspections(res.data);
              swal("Success", "New Inspection Created successfully", "success");
              props.setTrigger(false);
            }
            setApiProcessing({
              loader: false,
              message: ``,
            });
          })
          .catch((error) => {
            setApiProcessing({ loader: false, message: `` });
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                "Failed to Create New Inspection! Please try again later.",
                "error"
              );
            }
          });
      }
    }
  };

  const handleInspectionDetails = (name, value) => {
    setInspectionDetails({
      ...inspectionDetails,
      [name]: value,
    });
  };

  return (
    props.trigger && (
      <>
        <Dialog
          open={true}
          aria-labelledby="Add Inspection Form"
          fullWidth
          maxWidth="xs"
          //PaperProps={{ sx: { height: "100%" } }}
          className="flex flex-col justify-center w-full h-fit"
        >
          <Loader open={apiProcessing.loader} message={apiProcessing.message} />

          <div className="flex justify-center items-center bg-blue-52 text-white font-semibold rounded-sm h-10 pr-2">
            <span className="flex-grow text-center">
              {" "}
              {editForm ? "Edit Inspection Details" : "Inspection Details"}{" "}
            </span>
            <ButtonIcon
              id="close"
              btnName="close"
              dataCy="close"
              title="Close"
              onClick={(e) => props.setTrigger(false)}
              icon={<AiOutlineCloseCircle className="w-5 h-5" />}
              className="hover:!text-red-600"
            />
          </div>

          <DialogContent dividers>
            <div className="flex flex-col items-center justify-center gap-3 mx-auto">
              <InputSelectField
                label="Inspection Type"
                name="inspectionType"
                id="inspectionType"
                dataCy="inspectionType"
                placeholder="Choose a Inspection Type"
                disabled={editForm}
                value={inspectionDetails.selectedInspectionType}
                onChange={(e) => {
                  setTenantOptions(
                    e.target.value === "Move In"
                      ? allTenants.future
                      : allTenants.current
                  );
                  handleInspectionDetails(
                    "selectedInspectionType",
                    e.target.value
                  );
                }}
                options={inspectionTypes}
                errorText={formErrors.selectedInspectionType}
                style="!w-full"
                hintText={editForm ? "*Non Editable" : ""}
              />

              <InputObjectSelectField
                label="Tenant"
                name="teanantName"
                id="teanantName"
                dataCy="tenantName"
                placeholder={
                  editForm ? props.editData.tenantName : "Select a Tenant"
                }
                value={inspectionDetails.tenantId}
                onChange={(e) =>
                  handleInspectionDetails("tenantId", parseInt(e.target.value))
                }
                options={tenantOptions}
                optionValue="tenantID"
                optionName="name"
                style="!w-full"
                errorText={formErrors.tenantId}
              />

              <InputDateField
                label="Inspection Date"
                name="inspectionDate"
                id="inspectionDate"
                dataCy="inspectionDate"
                value={inspectionDetails.inspectionDate}
                onChange={(value) =>
                  handleInspectionDetails("inspectionDate", value)
                }
                style="!w-full"
                errorText={formErrors.inspectionDate}
              />

              <InputField
                label="Inspection Performed by"
                name="performedBy"
                id="performedBy"
                dataCy="performedBy"
                type="text"
                placeholder="Enter Performer Name"
                value={inspectionDetails.inspectionPerformedBy}
                onChange={(e) =>
                  handleInspectionDetails(
                    "inspectionPerformedBy",
                    e.target.value.replace(/[^\w\s]/gi, "")
                  )
                }
                errorText={formErrors.inspectionPerformedBy}
                style="!w-full"
              />
            </div>

            <div className="flex justify-center items-center gap-16 my-6">
              <ButtonGrayOutline
                id="cancel"
                btnName="cancel"
                dataCy="cancel"
                name="Cancel"
                title="Close the Form"
                onClick={(e) => props.setTrigger(false)}
                className="justify-self-end"
              />
              <ButtonBlue
                name="Confirm"
                id="confirm"
                btnName="confirm"
                dataCy="confirm"
                onClick={handleSubmit}
                className=""
              />
            </div>
          </DialogContent>
        </Dialog>
      </>
    )
  );
};

export default AddInspection;
