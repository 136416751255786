import React from "react";
import { Navigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { isAuthenticated } from "../../atoms";

const PublicRoute = ({ child }) => {
  const userAuthenticated = useRecoilValue(isAuthenticated);

  return userAuthenticated ? <Navigate to="/dashboard" replace /> : child;
};

export default PublicRoute;
