import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { WebApimanager } from "../../WebApiManager";
import { clientId } from "../../atoms";
import { useRecoilState, useRecoilValue } from "recoil";
import { BsDownload } from "react-icons/bs";
//Reusable Components
import { InputObjectSelectField } from "../../Widgets/Forms/InputFields";
import {
  GetDateInMMDDYYYY,
  GetDenominationWithDollar,
  Searchbar,
  TableComponent,
} from "../../Widgets/CommonWidgets";
import { PhoneNumFormat } from "../../Widgets/Forms/FieldDependants";
import { portfolioTreeStore } from "../../atoms";
import ExportCSV from "../../Widgets/ExportCSV";
//External Imports
import swal from "sweetalert";
import { ButtonIcon } from "../../Widgets/Buttons";
import { AiOutlineDownload } from "react-icons/ai";

const DelinquencyReport = () => {
  let webApi = new WebApimanager();
  const clientIdVal = useRecoilValue(clientId);
  let navigate = useNavigate();
  const [portfolioTree, SetPortfolioTree] = useRecoilState(portfolioTreeStore);

  //Search Bar Ref
  const searchBarRef = useRef(null);
  const clearSearchField = () => {
    if (searchBarRef.current) {
      searchBarRef.current.clearSearch();
    }
  };

  //Portfolio Filter
  const [allPortfolios, setAllPortfolios] = useState([]);
  const [filteredPortfolioId, setFilteredPortfolioId] = useState(0);
  const [filteredPortfolioName, setFilteredPortfolioName] = useState("");

  //Delinquency Table
  const [allDelinquents, setAllDelinquents] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    getAllPortfolios();
    getDelinquentsReport(clientIdVal, 0);
  }, []);

  const getAllPortfolios = () => {
    webApi
      .get("/portfolios/client/" + clientIdVal, {
        loader: true,
      })
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setAllPortfolios(
            res.data.length > 1
              ? res.data.sort((a, b) =>
                  a.portfolioName > b.portfolioName ? 1 : -1
                )
              : res.data
          );
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops, Failed to load Portfolios, Please try again later.`,
            "error"
          );
        }
      });
  };

  const getDelinquentsReport = (clientId, portfolioID) => {
    webApi
      .get(
        `/reports/tenantDelinquency/client/${clientId}/portfolio/${portfolioID}`,
        {
          loader: true,
        }
      )
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setAllDelinquents(res.data);
          setTableData(res.data);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops, Failed to Generate Delinquency Report, Please try again later.`,
            "error"
          );
        }
      });
  };

  const handleSelectedPortfolio = (portfolioId) => {
    const selected = allPortfolios.filter((item) => item.ID === portfolioId);
    if (selected.length) {
      setFilteredPortfolioName(selected[0].portfolioName);
    } else setFilteredPortfolioName("");
  };

  const downloadPDF = () => {
    if(filteredPortfolioId === 0){
      let data = {
        clientId : clientIdVal
      }
      webApi
      .post("/report/delinquencyReport/pdf", data , { loader: true })
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          const link = document.createElement('a');
          link.href = res.data.url;
          link.setAttribute('download', 'filename.pdf'); // You can set a default filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Apologies, Failed to download Delinquency Report, Please try again later.`,
            "error"
          );
        }
      });
    } else {
      let data = {
        portfolioId : filteredPortfolioId
      }
      webApi
      .post("/report/delinquencyReport/pdf", data , { loader: true })
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          const link = document.createElement('a');
          link.href = res.data.url;
          link.setAttribute('download', 'filename.pdf'); // You can set a default filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Apologies, Failed to download Delinquency Report, Please try again later.`,
            "error"
          );
        }
      });
    }
  }

  return (
    <>
      <div className="w-5/6 m-auto">
        <span
          id="ReportTitle"
          className="flex justify-center items-center bg-blue-52 text-white font-semibold text-center rounded h-10"
        >
          Delinquency Report
        </span>

        <section
          name="Searchbar and Options"
          className={"flex justify-between items-end my-4 mx-4"}
        >
          <InputObjectSelectField
            label="Select Portfolio"
            name="portfolio"
            id="portfolios"
            notImp
            placeholder="Select Portfolio"
            value={filteredPortfolioId}
            onChange={(e) => {
              if (parseInt(e.target.value)) {
                getDelinquentsReport(0, parseInt(e.target.value));
              } else
                getDelinquentsReport(clientIdVal, parseInt(e.target.value));

              clearSearchField();
              handleSelectedPortfolio(parseInt(e.target.value));
              setFilteredPortfolioId(parseInt(e.target.value));
            }}
            options={[{ ID: 0, portfolioName: "All" }, ...allPortfolios]}
            optionValue="ID"
            optionName="portfolioName"
            defaultPlaceholderValue={0}
            style="!w-72"
          />

          <div className="flex gap-4">
          <ExportCSV
            data={tableData}
            keys={[
              {
                key: "address1",
                title: "Property Address/Unit No.",
                applyMethod: (row) => {
                  if (row.propertyType === "Single Family") {
                    return row.address1;
                  } else return row.unitNumber;
                },
              },
              {
                key: "portfolioName",
                title: "Portfolio Name",
              },
              {
                key: "tenantName",
                title: "Tenant Name",
              },
              {
                key: "phoneNumber",
                title: "Contact No.",
                applyMethod: (row) => PhoneNumFormat(row.phoneNumber, true),
              },
              {
                key: "email",
                title: "Email ID",
              },
              {
                key: "status",
                title: "Status",
                width: "100px",
              },
              {
                key: "currentBalance",
                title: "Current Balance",
              },
              {
                key: "rentPaid",
                title: "Rent Paid",
              },
              {
                key: "latePaymentCount",
                title: "Late Payment Count",
              },
              {
                key: "leaseStartDate",
                title: "Lease Start Date",
              },
              {
                key: "leaseEndDate",
                title: "Lease End Date",
                applyMethod: (row) => GetDateInMMDDYYYY(row.leaseEndDate),
              },
              {
                key: "moveOutDate",
                title: "Move Out Date",
                applyMethod: (row) => GetDateInMMDDYYYY(row.moveOutDate),
              },
              {
                title: "0-30 Days",
                key: "agedBalance.zeroToThirty",
                applyMethod: (row) => {
                  if (row.agedBalance.zeroToThirty) {
                    return "$" + row.agedBalance.zeroToThirty
                  }else {
                    return "$0"
                  }
                }
              },
              {
                title: "31-60 Days",
                key: "agedBalance.thirtyToSixty",
                applyMethod: (row) => {
                  if (row.agedBalance.thirtyToSixty) {
                    return "$" + row.agedBalance.thirtyToSixty
                  }else {
                    return "$0"
                  }
                }
              },
              {
                title: "61-90 Days",
                key: "agedBalance.sixtyToNinety",
                applyMethod: (row) => {
                  if (row.agedBalance.sixtyToNinety) {
                    return "$" + row.agedBalance.sixtyToNinety
                  }else {
                    return "$0"
                  }
                }
              },
              {
                title: "90+ Days",
                key: "agedBalance.overNinety",
                applyMethod: (row) => {
                  if (row.agedBalance.overNinety) {
                    return "$" + row.agedBalance.overNinety
                  }else {
                    return "$0"
                  }
                }
              },
            ]}
            fileName="Delinquency Report.csv"
          />
          <ButtonIcon
            id="Download"
            btnName="download"
            dataCy="download"
            title="Download PDF"
            onClick={downloadPDF}
            icon={<BsDownload className="w-5 h-5" />}
            className="hover:!bg-blue-50 hover:!text-white shadow-md"
          /> 
            <Searchbar
              ref={searchBarRef}
              id="DelinquencySearch"
              placeholder="Search..."
              data={allDelinquents}
              matchProps={[
                { text: "address1" },
                { text: "unitNumber" },
                { text: "portfolioName" },
                { text: "tenantName" },
                { text: "phoneNumber" },
                { text: "email" },
                { text: "status" },
                { text: "currentBalance" },
                { text: "rentPaid" },
                { text: "latePaymentCount" },
                { text: "agedBalance.zeroToThirty" },
                { text: "agedBalance.thirtyToSixty" },
                { text: "agedBalance.sixtyToNinety" },
                { text: "agedBalance.overNinety" },
                { date: "leaseStartDate" },
                { date: "leaseEndDate" },
                { date: "moveOutDate" },
              ]}
              setTableData={setTableData}
            />
          </div>
        </section>
        <div id="tableHeadlineText" className="font-semibold h-10 mx-4">
          Delinquent Tenants{" "}
          {filteredPortfolioName ? `for ${filteredPortfolioName}` : ``} As of{" "}
          {GetDateInMMDDYYYY(new Date())}
        </div>

        <TableComponent
          tableId="Delinquency Table"
          data={tableData}
          columns={[
            {
              key: "address1",
              title: "Property Address/Unit No.",
              applyMethod: (row) => {
                if (row.propertyType === "Single Family") {
                  return row.address1;
                } else return row.unitNumber;
              },
            },
            {
              key: "portfolioName",
              title: "Portfolio Name",
            },
            {
              key: "tenantName",
              title: "Tenant Name",
              getCellStyles: (row) => {
                return { color: "blue", textDecoration: "underline" };
              },
              dataOnClick: (row) => {
                SetPortfolioTree({
                  ...portfolioTree,
                  tenant: {
                    unitID: row.unitId,
                    tenantCode: row.tenantCode,
                    tenantID: row.tenantId,
                    activeMenuItem: "rentalLedger",
                  },
                });
                navigate("/tenants/tenantDetails");
              },
            },
            {
              key: "phoneNumber",
              title: "Contact No.",
              applyMethod: (row) => PhoneNumFormat(row.phoneNumber, true),
            },
            {
              key: "email",
              title: "Email ID",
            },
            {
              key: "status",
              title: "Status",
              width: "100px",
            },
            {
              key: "currentBalance",
              title: "Current Balance",
              applyMethod: (row) =>
                GetDenominationWithDollar(row.currentBalance),
            },
            {
              key: "rentPaid",
              title: "Rent Paid",
              width: "100px",
              applyMethod: (row) => GetDenominationWithDollar(row.rentPaid),
            },
            {
              key: "latePaymentCount",
              title: "Late Payment Count",
              width: "120px",
            },
            {
              key: "leaseStartDate",
              title: "Lease Start Date",
              applyMethod: (row) => GetDateInMMDDYYYY(row.leaseStartDate),
            },
            {
              key: "leaseEndDate",
              title: "Lease End Date",
              applyMethod: (row) => GetDateInMMDDYYYY(row.leaseEndDate),
            },
            {
              key: "moveOutDate",
              title: "Move Out Date",
              applyMethod: (row) => GetDateInMMDDYYYY(row.moveOutDate),
            },
            {
              title: "Aged Balance",
              key: "agedBalance",
              colSpan: 4,
              getColumnStyles: (row) => {
                return { border: "1px solid #3876A1" };
              },
              subColumns: [
                {
                  title: "0-30 Days",
                  key: "agedBalance.days0to30",
                  applyMethod: (row) =>
                    GetDenominationWithDollar(row.agedBalance.zeroToThirty),
                  getCellStyles: (row) => {
                    return { border: "1px solid #3876A1" };
                  },
                  getSubcolumnStyles: (row) => {
                    return { border: "1px solid #3876A1" };
                  },
                },
                {
                  title: "31-60 Days",
                  key: "agedBalance.days31to60",
                  applyMethod: (row) =>
                    GetDenominationWithDollar(row.agedBalance.thirtyToSixty),
                  getCellStyles: (row) => {
                    return { border: "1px solid #3876A1" };
                  },
                  getSubcolumnStyles: (row) => {
                    return { border: "1px solid #3876A1" };
                  },
                },
                {
                  title: "61-90 Days",
                  key: "agedBalance.days61to90",
                  applyMethod: (row) =>
                    GetDenominationWithDollar(row.agedBalance.sixtyToNinety),
                  getCellStyles: (row) => {
                    return { border: "1px solid #3876A1" };
                  },
                  getSubcolumnStyles: (row) => {
                    return { border: "1px solid #3876A1" };
                  },
                },
                {
                  title: "90+ Days",
                  key: "agedBalance.days90plus",
                  applyMethod: (row) =>
                    GetDenominationWithDollar(row.agedBalance.overNinety),
                  getCellStyles: (row) => {
                    return { border: "1px solid #3876A1" };
                  },
                  getSubcolumnStyles: (row) => {
                    return { border: "1px solid #3876A1" };
                  },
                },
              ],
            },
          ]}
          rowOnClick={(row) => {}}
        />
      </div>
    </>
  );
};

export default DelinquencyReport;
