import React, { useState } from "react";
import { Auth } from "aws-amplify";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import InputField, {
  InputPasswordField,
} from "../../Widgets/Forms/InputFields";
import Footer from "../../Widgets/Footer";

const TempUser = () => {
  const [email, setEmail] = useState("");
  const [tempPass, setTempPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [conPass, setConPass] = useState("");
  const [formErrors, setFormErrors] = useState("");

  let navigate = useNavigate();
  const validate = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    const errors = {};
    if (!email) {
      errors.email = "Email is required!";
    } else if (!emailRegex.test(email)) {
      errors.email = "Email is not valid!";
    }
    if (!tempPass) {
      errors.tempPass = "Temporary Password is required!";
    } else if (!passwordRegex.test(tempPass)) {
      errors.tempPass = "Temporary Password is not valid!";
    }
    if (!newPass) {
      errors.newPass = "New Password is required!";
    } else if (!passwordRegex.test(newPass)) {
      errors.newPass = "New Password is not valid!";
    } else if (tempPass === newPass) {
      errors.newPass = "New Password cannot be same as Temporary Password";
    }
    if (!conPass) {
      errors.conPass = "Confirm Password is required!";
    } else if (!passwordRegex.test(conPass)) {
      errors.conPass = "Confirm Password is not valid!";
    } else if (tempPass === conPass) {
      errors.conPass = "Confirm Password cannot be same as Temporary Password";
    } else if (newPass !== conPass) {
      errors.conPass = "Confirm Password Must be same as New Password";
    }
    return errors;
  };

  const handlesubmit = async (event) => {
    event.preventDefault();
    const temp = validate();
    setFormErrors(temp);

    if (Object.keys(temp).length) {
      return;
    } else {
      try {
        await Auth.signIn(email, tempPass)
          .then((user) => {
            console.log("user", user);
            Auth.completeNewPassword(user, newPass)
              .then((compUser) => {
                console.log("user", compUser);
                swal("Success", "Password Reset Successful", "success");
                navigate("/login");
              })
              .catch((e) => {
                console.log("e", e);
                swal("Error", e.message, "error");
                //   swal(
                //     "Error",
                //     `Apologies, Failed to Reset Password, Please try again later.`,
                //     "error"
                //   );
              });
          })
          .catch((e) => {
            console.log("e", e);
            // swal("Failure", "Incorrect email", "error");
            swal("Error", e.message, "error");
            // swal(
            //   "Error",
            //   `Apologies, Failed to Reset Password, Please try again later.`,
            //   "error"
            // );
          });
      } catch (e) {
        console.log("e", e);
        swal("Error", e.message, "error");
      }
    }
  };

  return (
    <>
      <section className="flex flex-col justify-center items-center w-1/4 fixed bg-blue-52 h-full">
        <img
          id="tempUserPageLogo"
          className="w-64 h-20"
          src="logo/NEWLOGO.png"
          alt="Yvooa logo"
        />
        <span className="text-white text-xl text-center pl-5">
          <p>Your Residential Solutions;</p>
          <p>under one roof.</p>
        </span>
      </section>

      <section className="flex flex-col items-center justify-center h-screen ml-[25%]">
        <h2 className="text-2xl font-semibold text-center text-gray-700 underline sm:text-xl my-6">
          Reset Temporary Password
        </h2>

        <form onSubmit={handlesubmit} className="w-96 flex flex-col gap-4">
          <InputField
            label="Email"
            name="email"
            id="email"
            dataCy="email"
            type="text"
            placeholder="Enter Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            errorText={formErrors.email}
            style="!w-full"
          />

          <InputPasswordField
            label="Temporary Password"
            name="temppass"
            id="temppass"
            dataCy="tempass"
            placeholder="Temporary Password"
            value={tempPass}
            onChange={(e) => setTempPass(e.target.value)}
            errorText={formErrors.tempPass}
            style="!w-full"
          />

          <InputPasswordField
            label="New Password"
            name="newpass"
            id="newpass"
            dataCy="newpass"
            placeholder="New Password"
            value={newPass}
            onChange={(e) => setNewPass(e.target.value)}
            errorText={formErrors.newPass}
            style="!w-full"
            hintText="Alphanumeric and contain One Uppercase and Special Charcter"
          />

          <InputPasswordField
            label="Confirm Password"
            name="conpass"
            id="conpass"
            dataCy="conpass"
            placeholder="Confirm Password"
            value={conPass}
            onChange={(e) => setConPass(e.target.value)}
            errorText={formErrors.conPass}
            style="!w-full"
          />

          <button
            className="bg-blue-52 w-96 h-10 mt-2 text-white font-semibold py-2 px-4 border border-gray-400 rounded shadow sm:w-72"
            id="submit"
            name="submit"
            data-cy="submit"
          >
            SUBMIT
          </button>
        </form>

        <span className="text-center my-6">
          Questions? Contact Customer Support at{" "}
          <p className=" text-blue-500">support@rapidviewtech.com</p>
        </span>

        <Footer />
      </section>
    </>
  );
};

export default TempUser;
