import React, { useState, useEffect } from "react";
import { WebApimanager } from "../../WebApiManager";
import { Dialog } from "@mui/material";
import { ButtonBlue } from "../../Widgets/Buttons";
import InputField, { InputSelectField } from "../../Widgets/Forms/InputFields";
import swal from "sweetalert";

const EditBankAccount = (props) => {
  let webApi = new WebApimanager();
  const [accountType, setAccountType] = useState(
    props.data.accountingType || ""
  );
  const [accTypeOptions, setAccTypeOptions] = useState([]);
  const [name, setName] = useState(props.data.name || "");
  const [detailType, setDetailType] = useState(props.data.detailType || "");
  const [detailtypeOptions, setDetailTypeOptions] = useState([]);
  const [desc, setDesc] = useState(props.data.description || "");

  useEffect(() => {
    getAccountTypes();
    getDetailTypes();
  }, []);

  const getAccountTypes = () => {
    webApi
      .get("/accounting/getAccountingBankAccountingType")
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          console.log("acc type opts", res.data);
          setAccTypeOptions(res.data);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops! Failed to get Account Types options, Please try again later.",
            "error"
          );
        }
      });
  };

  const getDetailTypes = () => {
    webApi
      .get("/accounting/getAccountingBankDetailType")
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          console.log("details type opts", res.data);
          setDetailTypeOptions(res.data);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops! Failed to get Detail Types options, Please try again later.",
            "error"
          );
        }
      });
  };

  const handleSubmit = () => {
    let data = {
      detailType: detailType,
      accountingType: accountType,
      name: name,
      description: desc,
    };

    webApi
      .put("/accounting/bank/" + props.data.ID + "/updateBankDetails", data)
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          swal("Success", "Updated successfully", "success");
          props.setName(res.data.name || res.data.accountName);
          props.getBanks();
          props.setTrigger(false);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to Update, Please try again later.`,
            "error"
          );
        }
      });
  };

  return props.trigger ? (
    <Dialog
      open={true}
      aria-labelledby="Edit Bank Account Form"
      fullWidth
      maxWidth="md"
      PaperProps={{ height: "70%" }}
      scroll="body"
      className="flex flex-col justify-center w-full"
    >
      <div className="flex flex-col m-10">
        <p className="text-xl fornt-bold text-blue-52 mb-6">EDIT</p>
        <div className="grid grid-cols-2 gap-x-16 gap-y-4 mb-16">
          <InputSelectField
            label="Account Type"
            name="accountType"
            id="accountType"
            dataCy="accountingType"
            placeholder="Choose a Type"
            options={accTypeOptions}
            value={accountType}
            onChange={(e) => setAccountType(e.target.value)}
            // errorText={formStep1Errors[index].tenantStatus}
            style="!w-full"
            notImp
          />

          <InputField
            label="Name"
            name="name"
            id="name"
            dataCy="name"
            type="text"
            placeholder="Enter Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            //errorText={formErrors.floorPlanCode}
            style="!w-full"
            notImp
          />

          <InputSelectField
            label="Detail Type"
            name="detailType"
            id="detailType"
            dataCy="detailType"
            placeholder="Choose a Type"
            options={detailtypeOptions}
            value={detailType}
            onChange={(e) => setDetailType(e.target.value)}
            //errorText={formStep1Errors[index].tenantStatus}
            style="!w-full"
            notImp
          />

          <InputField
            label="Description"
            name="description"
            id="description"
            dataCy="description"
            type="text"
            placeholder="Enter description"
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
            //errorText={formErrors.floorPlanCode}
            style="!w-full mb-6"
            notImp
          />
          <p className="text-lg font-semibold">
            Balance - {"$" + props.data.balanceAvailable.toLocaleString()}
          </p>
        </div>
        <div className="flex flex-row gap-4 justify-end">
          <ButtonBlue
            id="close"
            btnName="close"
            dataCy="close"
            name="Close"
            onClick={() => props.setTrigger(false)}
            className="w-24 h-10 cursor-pointer rounded-xl bg-white border-blue-52 text-blue-52 hover:text-white focus:text-white"
          />
          <ButtonBlue
            id="saveAndClose"
            btnName="saveAndClose"
            dataCy="saveAndClose"
            name="Save and Close"
            onClick={handleSubmit}
            className="w-32 h-10 cursor-pointer rounded-xl bg-white border-blue-52 text-blue-52 hover:text-white focus:text-white"
          />
        </div>
      </div>
    </Dialog>
  ) : null;
};

export default EditBankAccount;
