import { BsFillCheckCircleFill } from "react-icons/bs";

const BankSuccessPage = () => {
  return (
    <div className="flex justify-center items-center bg-slate-200 h-screen">
      <div class="flex justify-center content-center shadow-lg rounded-lg h-52">
        <div class="flex bg-white w-96 h-64 px-4 py-6 rounded-xl">
          <div class="flex flex-col w-auto items-center justify-center">
            <BsFillCheckCircleFill className="w-14 h-12 text-blue-52 mb-4" />

            <p className="text-black font-semibold">
              Thanks for cooperating with us, connected Bank successfully, you can now close the window!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankSuccessPage;
