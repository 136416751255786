import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogActions } from "@mui/material";
import InputField, {
  InputPhoneNumField,
  InputSelectField,
} from "../../Widgets/Forms/InputFields";
import {
  ButtonBlue,
  ButtonGrayOutline,
  ButtonIcon,
} from "../../Widgets/Buttons";
import { PhoneNumFormat } from "../../Widgets/Forms/FieldDependants";
import { TextReviewTile } from "../../Widgets/Forms/FormReviewTiles";
import { WebApimanager } from "../../WebApiManager";
import swal from "sweetalert";
import { clientId, companyId } from "../../atoms";
import { useRecoilValue, useRecoilState } from "recoil";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Loader } from "../../Widgets/notificationFeedbacks";

const CreateUser = (props) => {
  let webApi = new WebApimanager();
  const editForm = props.operation === "Edit" ? true : false;
  const clientIdVal = useRecoilValue(clientId);
  const companyIdVal = useRecoilValue(companyId);
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    firstName: editForm ? props.editData.firstName : "",
    emailId: editForm ? props.editData.email : "",
    lastName: editForm ? props.editData.lastName : "",
    profileType: editForm ? props.editData.userRole : "",
  });
  const [contactNo, setContactNo] = useState(
    editForm ? props.editData.phoneNumber : ""
  );
  const [formErrors, setFormErrors] = useState("");
  const [allprofileTypes, setAllProfileTypes] = useState([]);
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  useEffect(() => {
    getProfiletypes();
  }, []);

  const getProfiletypes = () => {
    webApi
      .get("/usermanagement/client/profiletype/list/role/Client Admin")
      .then((res) => {
        if (res.data.errorCode) {
          swal(
            "Error",
            "Apologies! Unable to load Profile types, please try again later."
          );
        } else {
          setAllProfileTypes(res.data.profile);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to get Profile type options, Please try again later.`,
            "error"
          );
        }
      });
  };

  const handleOnChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    //let Number = formData.contactNo.replace(/[^\d]/g, "");
    const errors = {};
    if (activeStep === 0) {
      if (!formData.firstName) {
        errors.firstName = "First Name is required!";
      } else if (/^\d+$/.test(formData.firstName)) {
        errors.firstName = "A Valid First Name is required!";
      }
      if (!formData.lastName) {
        errors.lastName = "Last Name is required!";
      } else if (/^\d+$/.test(formData.lastName)) {
        errors.lastName = "A Valid Last Name is required!";
      }
      if (!formData.emailId) {
        errors.emailId = "Email is required!";
      } else if (!emailRegex.test(formData.emailId)) {
        errors.emailId = "Email is not valid!";
      }
      if (!contactNo) {
        errors.contactNo = "Contact No is required!";
      } else if (contactNo.length !== 12) {
        errors.contactNo = "Contact number is not valid!";
      }

      if (!formData.profileType) {
        errors.profileType = "Profile Type is required!";
      }
    }
    return errors;
  };

  const handleSteps = (event) => {
    event.preventDefault();
    const temp = validate();
    setFormErrors(temp);
    if (Object.keys(temp).length) {
      return;
    } else {
      // console.log("formdata",formData)
      setActiveStep((currentStep) => currentStep + 1);
    }
  };

  const handleBack = (event) => {
    event.preventDefault();
    setActiveStep((currentStep) => currentStep - 1);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setApiProcessing({
      loader: true,
      message: ``,
    });
    let data = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      phoneNumber: contactNo,
      userRole: formData.profileType,
      email: formData.emailId,
      portfolioID: "",
      clientID: clientIdVal,
      companyID: companyIdVal,
    };
    //  console.log(data,"data")
    if (editForm) {
      setApiProcessing({
        loader: true,
        message: `Updating User ${
          formData.firstName + " " + formData.lastName
        }...`,
      });
      webApi
        .put("/clients/" + props.editData.ID, data)
        .then(async (res) => {
          if (res.data.errorCode) {
            swal("Error", res.data.errorMessage, "error");
          } else {
            swal("Success", "User updated successfully", "success");
            props.setTrigger(false);
            props.getUsers();
          }
          setApiProcessing({
            loader: false,
            message: `Loading...`,
          });
        })
        .catch((error) => {
          setApiProcessing({
            loader: false,
            message: `Loading...`,
          });
          if (error.customErrorMessage) {
            swal("Error", error.customErrorMessage, "error");
          } else {
            swal(
              "Error",
              `Oops! Failed to Update User ${data.firstName}, Please try again later.`,
              "error"
            );
          }
        });
    } else {
      setApiProcessing({
        loader: true,
        message: `Creating User ${
          formData.firstName + " " + formData.lastName
        }...`,
      });
      webApi
        .post("/usermanagement/client", data)
        .then(async (res) => {
          if (res.data.errorCode) {
            swal("Failure", res.data.errorMessage, "error");
          } else {
            swal("Success", "Successfully Created User", "success");
            closeDialog();
          }
          setApiProcessing({
            loader: false,
            message: `Loading...`,
          });
        })
        .catch((error) => {
          setApiProcessing({
            loader: false,
            message: `Loading...`,
          });
          if (error.customErrorMessage) {
            swal("Error", error.customErrorMessage, "error");
          } else {
            swal(
              "Error",
              `Oops! Failed to Create User ${data.firstName}, Please try again later.`,
              "error"
            );
          }
        });
    }
  };

  const closeDialog = () => {
    props.setTrigger(false);
    props.getUsers();
  };

  return props.trigger ? (
    <>
      <Dialog
        open={true}
        aria-labelledby="Create User Creation Form"
        fullWidth
        maxWidth="lg"
        //PaperProps={{ sx: { height: "80%" } }}
        //scroll="body"
        className="flex flex-col justify-center w-[70%] mx-auto"
      >
        <Loader open={apiProcessing.loader} message={apiProcessing.message} />
        <div className="flex justify-center items-center bg-blue-52 text-white font-semibold rounded-sm h-10 pr-2">
          <span className="flex-grow text-center">
            {editForm ? "Edit" : "Add"} User
          </span>
          <ButtonIcon
            id="closeUserForm"
            btnName="closeUserForm"
            dataCy="closeUserForm"
            title="Close User Form"
            onClick={(e) => props.setTrigger(false)}
            icon={<AiOutlineCloseCircle className="w-5 h-5" />}
          />
        </div>
        <DialogContent dividers>
          <form className="flex flex-col justify-center items-center">
            {activeStep === 0 ? (
              <>
                <span className="border-b-2 border-blue-52 pb-1 mt-4 mb-4 text-blue-52 w-5/6">
                  Personal Information
                </span>
                <section className="grid grid-cols-2 gap-x-16 gap-y-4 w-5/6 md:grid-cols-1">
                  <InputField
                    label="First Name"
                    name="firstName"
                    id="firstName"
                    dataCy="firstName"
                    type="text"
                    placeholder="First Name"
                    value={formData.firstName}
                    onChange={(e) =>
                      handleOnChange(
                        "firstName",
                        e.target.value.replace(/[^\w\s]/gi, "")
                      )
                    }
                    errorText={formErrors.firstName}
                    style="!w-full"
                  />

                  <InputField
                    label="Last Name"
                    name="lastName"
                    id="lastName"
                    dataCy="lastName"
                    type="text"
                    placeholder="Last Name"
                    value={formData.lastName}
                    onChange={(e) =>
                      handleOnChange(
                        "lastName",
                        e.target.value.replace(/[^\w\s]/gi, "")
                      )
                    }
                    errorText={formErrors.lastName}
                    style="!w-full"
                  />

                  {/* <InputPhoneNumField
                  label="Contact Number"
                  name="phoneNumber"
                  id="phoneNumber"
                  placeholder="8172635409"
                  value={formData.contactNo}
                  onChange={(e) => {
                    let num = PhoneNumFormat(e.target.value);
                    handleOnChange("contactNo", num);
                  }}
                  errorText={formErrors.contactNo}
                  style="!w-full"
                /> */}

                  <InputPhoneNumField
                    label="Contact Number"
                    name="phoneNumber"
                    id="phoneNumber"
                    dataCy="phoneNumber"
                    placeholder="Enter Contact No."
                    value={contactNo}
                    setValue={setContactNo}
                    errorText={formErrors.contactNo}
                    style="!w-full"
                  />

                  <InputField
                    label="Email ID"
                    name="emailId"
                    id="emailId"
                    dataCy="emailId"
                    type="text"
                    placeholder="abc@example.com"
                    //readOnly={editForm}
                    value={formData.emailId}
                    onChange={(e) => handleOnChange("emailId", e.target.value)}
                    errorText={formErrors.emailId}
                    style={`!w-full`}
                    //hintText={editForm ? "Non Editable" : ""}
                    className={editForm ? "bg-gray-50" : ""}
                  />

                  <InputSelectField
                    label="Choose Profile Type"
                    name="profileType"
                    id="profileType"
                    dataCy="profileType"
                    placeholder="Choose a Profile Type"
                    disabled={editForm}
                    options={allprofileTypes}
                    value={formData.profileType}
                    onChange={(e) =>
                      handleOnChange("profileType", e.target.value)
                    }
                    errorText={formErrors.profileType}
                    style="!w-full"
                    hintText={editForm ? "Non Editable" : ""}
                    className={editForm ? "bg-gray-50" : ""}
                  />
                </section>

                <div className="flex justify-center items-center gap-16 mt-6 mb-4 w-3/4">
                  <ButtonGrayOutline
                    id="cancel"
                    btnName="cancel"
                    dataCy="cancel"
                    name="Cancel"
                    onClick={closeDialog}
                    className="mt-6 justify-self-end"
                  />
                  <ButtonBlue
                    id="next"
                    btnName="next"
                    dataCy="next"
                    name="Next"
                    onClick={(e) => handleSteps(e)}
                    className="mt-6"
                  />
                </div>
              </>
            ) : null}

            {activeStep === 1 ? (
              <>
                <span className="border-b-2 border-blue-52 pb-1 mt-4 mb-4 text-blue-52 w-5/6">
                  Review Personal Details
                </span>
                <div className="grid grid-cols-2 gap-x-16 gap-y-6 w-5/6 mb-4">
                  <TextReviewTile
                    title="First Name"
                    body={formData.firstName}
                  />
                  <TextReviewTile title="Last Name" body={formData.lastName} />
                  <TextReviewTile title="Email ID" body={formData.emailId} />
                  <TextReviewTile
                    title="Contact No"
                    body={PhoneNumFormat(contactNo, true)}
                  />
                  <TextReviewTile
                    title="Profile Type"
                    body={formData.profileType}
                  />
                </div>

                <div className="flex justify-center items-center gap-16 mt-8 mb-4 w-3/4">
                  <ButtonGrayOutline
                    id="back"
                    btnName="back"
                    dataCy="back"
                    name="Back"
                    onClick={(e) => handleBack(e)}
                  />
                  <ButtonBlue
                    name="Confirm"
                    id="confirm"
                    btnName="confirm"
                    dataCy="confirm"
                    onClick={handleSubmit}
                  />
                </div>
              </>
            ) : null}
          </form>
        </DialogContent>
      </Dialog>
    </>
  ) : null;
};

export default CreateUser;
