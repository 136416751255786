import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TiEdit } from "react-icons/ti";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import {
  TableComponent,
  Searchbar,
  GetDateInMMDDYYYY,
} from "../../Widgets/CommonWidgets";
import {
  ButtonBlueOutline,
  ButtonGrayOutline,
  ButtonBlue,
} from "../../Widgets/Buttons";
import { BsPlusCircle } from "react-icons/bs";
import style from "./index.module.css";
import { useRecoilValue } from "recoil";
import { WebApimanager } from "../../WebApiManager";
import AddOwner from "./addOwner";
import swal from "sweetalert";
import { PhoneNumFormat } from "../../Widgets/Forms/FieldDependants";
import { DetailsTile } from "../../Widgets/Forms/FormReviewTiles";
import { InputObjectSelectField } from "../../Widgets/Forms/InputFields";
import { Dialog } from "@mui/material";
import { IconInCircle } from "../../Widgets/Icons";
import { PiWarningOctagon } from "react-icons/pi";
import { clientId, companyId } from "../../atoms";
// import { einFormat } from "../../Widgets/Forms/FieldDependants";
import { Loader } from "../../Widgets/notificationFeedbacks";
import { GetCompleteAddress } from "../../Widgets/Forms/FieldDependants";

const OwnerDetails = () => {
  let webApi = new WebApimanager();
  let navigate = useNavigate();
  let location = useLocation();
  let landlordID = location.state.landlordId;
  const companyIdVal = useRecoilValue(companyId);
  const clientIdVal = useRecoilValue(clientId);
  const [activeMenuItem, setActiveMenuItem] = useState("portfolio");

  const [fullLandlordDetails, setFullLandlordDetails] = useState("");
  const [tableData, setTableData] = useState("");
  const [assignedPortfolios, setAllllAssignedPortfolios] = useState([]);
  const [formOperation, setFormOperation] = useState("");
  const [addOwnerForm, setAddOwnerForm] = useState(false);
  const [editOwnerData, setEditOwnerData] = useState("");
  const tabs = [{ label: "PORTFOLIO", nickName: "portfolio" }];
  const [openAddPortfolio, setOpenAddPortfolio] = useState(false);

  const portfolioTableActions = {
    deleteTitle: "Unlink Portfolio",
    deleteOnClick: (row) => unassignPortfolio(row),
  };
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  useEffect(() => {
    setApiProcessing({
      loader: true,
      message: `Gathering details...`,
    });
    getLandlordDetails();
    getAssignedPortfolios();
  }, []);

  const getLandlordDetails = () => {
    setApiProcessing({
      loader: true,
      message: `Loading...`,
    });
    webApi
      .get("/landlord/" + landlordID + "/company/" + companyIdVal)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setFullLandlordDetails(res.data);
          setEditOwnerData(res.data);
        }
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
        if (error.customErrorMessage)
          swal("Error", error.customErrorMessage, "error");
        else
          swal(
            "Error",
            `Oops! Unable to get Owner Details, Please try again later.`,
            "error"
          );
      });
  };

  const getAssignedPortfolios = () => {
    webApi
      .get(`/landlord/assignedPortfolios/${landlordID}`)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setTableData(res.data);
          setAllllAssignedPortfolios(res.data);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops! Failed to get Assigned Portolios, Please try again later.",
            "error"
          );
        }
      });
  };

  const unassignPortfolio = (row) => {
    swal({
      title: "Are you sure?",
      text: "You want to Unlink this Portfolio!",
      icon: "warning",
      buttons: ["Cancel", "Yes"],
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        webApi
          .delete(`/landlord/portfolio/${row.ID}/landlord/${landlordID}`)
          .then((res) => {
            if (res.data.status) {
              swal("Success", res.data.message, "success");
              getAssignedPortfolios();
            } else {
              swal("Failure", res.data.message, "error");
            }
          })
          .catch((error) => {
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                "Failed to Delete Portfolio, Please try again later.",
                "error"
              );
            }
          });
      } else {
        return;
      }
    });
  };

  const getFullAddress = (data) => {
    const { address1, address2, city, state, country, zipCode } = data.address;
    return `${address1}${address2 ? `, ${address2}` : ""}${
      city ? `, ${city}` : ""
    } ${state ? `, ${state}` : ""}${country ? `, ${country}` : ""}${
      zipCode ? `, ${zipCode}` : ""
    }`;
  };

  return (
    <>
      <Loader open={apiProcessing.loader} message={apiProcessing.message} />
      {addOwnerForm && (
        <AddOwner
          trigger={addOwnerForm}
          setTrigger={setAddOwnerForm}
          operation={formOperation}
          data={editOwnerData}
          getOwnerData={() => getLandlordDetails()}
        />
      )}

      {openAddPortfolio && (
        <AddPortfolio
          trigger={openAddPortfolio}
          setTrigger={setOpenAddPortfolio}
          clientId={clientIdVal}
          llID={landlordID}
          getPortfolios={() => getAssignedPortfolios()}
        />
      )}
      <div className="flex justify-center items-center">
        <div className="flex flex-col h-full border w-11/12">
          <section
            name="Title and Options"
            className="flex justify-between items-center bg-blue-30 h-10"
          >
            <span className="flex gap-4">
              <BsFillArrowLeftCircleFill
                title="Back to Owner Dashboard"
                onClick={() => navigate("/directories/owners")}
                className="text-blue-52 w-6 h-6 ml-2 cursor-pointer"
              />
              <p className="font-semibold">
                {fullLandlordDetails &&
                  fullLandlordDetails.landlord.firstName +
                    " " +
                    fullLandlordDetails.landlord.lastName}
              </p>
            </span>

            <div name="editVendor" className="flex text-gray-700">
              <TiEdit
                id="editButton"
                name="editButton"
                data-cy="editButton"
                className="w-5 h-5 mr-4 hover:text-red-500 focus:text-red-500 cursor-pointer"
                onClick={() => {
                  setAddOwnerForm(true);
                  setFormOperation("Edit");
                  setEditOwnerData(fullLandlordDetails);
                }}
              />
            </div>
          </section>

          <section
            name="OwnerDetails"
            className="flex justify-around items-start mx-4 my-6 gap-4 md:overflow-x-auto"
          >
            <div className="w-96 h-52 border flex flex-col justify-center items-center bg-gray-50">
              {fullLandlordDetails &&
              fullLandlordDetails.landlord.image !== "" ? (
                <img
                  src={fullLandlordDetails.landlord.image}
                  alt="Landlord Photo"
                  className="w-full h-full object-cover cursor-pointer"
                />
              ) : (
                <>
                  <p className="text-gray-600 text-center">
                    No Image to Display
                  </p>
                </>
              )}
            </div>

            <div className="grid grid-cols-2 gap-4">
              <span className="flex flex-col gap-2">
                <DetailsTile
                  label="Landlord Status"
                  data={
                    fullLandlordDetails && fullLandlordDetails.landlord.status
                  }
                  labelClassName="text-gray-500"
                  dataClassName="text-green-500"
                />

                <DetailsTile
                  label="Company Name"
                  data={fullLandlordDetails && fullLandlordDetails.companyName}
                  labelClassName="text-gray-500"
                />

                {/* <DetailsTile
                      label="EIN/SSN No"
                      data={fullLandlordDetails && einFormat(fullLandlordDetails.landlord.ein)}
                      labelClassName="text-gray-500"
                    /> */}

                <DetailsTile
                  label="First Name"
                  data={
                    fullLandlordDetails &&
                    fullLandlordDetails.landlord.firstName
                  }
                  labelClassName="text-gray-500"
                />

                <DetailsTile
                  label="Last Name"
                  data={
                    fullLandlordDetails && fullLandlordDetails.landlord.lastName
                  }
                  labelClassName="text-gray-500"
                />

                <DetailsTile
                  label="
                      Owner Role"
                  data={fullLandlordDetails && fullLandlordDetails.role}
                  labelClassName="text-gray-500"
                />

                <DetailsTile
                  label="Contact Number"
                  data={
                    fullLandlordDetails &&
                    PhoneNumFormat(
                      fullLandlordDetails.landlord.phoneNumber,
                      true
                    )
                  }
                  labelClassName="text-gray-500"
                />

                <DetailsTile
                  label="
                      Email ID"
                  data={
                    fullLandlordDetails && fullLandlordDetails.landlord.email
                  }
                  labelClassName="text-gray-500"
                  className="break-words"
                />
              </span>

              <span className="flex flex-col gap-2">
                <DetailsTile
                  label="Street Address 1"
                  data={
                    fullLandlordDetails &&
                    fullLandlordDetails.landlord.businessAddress.address1
                  }
                  labelClassName="text-gray-500"
                />
                <DetailsTile
                  label="Street Address 2"
                  data={
                    (fullLandlordDetails &&
                      fullLandlordDetails.landlord.businessAddress.address2) ||
                    "-"
                  }
                  labelClassName="text-gray-500"
                />
                <DetailsTile
                  label="City"
                  data={
                    fullLandlordDetails &&
                    fullLandlordDetails.landlord.businessAddress.city
                  }
                  labelClassName="text-gray-500"
                />
                <DetailsTile
                  label="State"
                  data={
                    fullLandlordDetails &&
                    fullLandlordDetails.landlord.businessAddress.state
                  }
                  labelClassName="text-gray-500"
                />
                <DetailsTile
                  label="Country"
                  data={
                    fullLandlordDetails &&
                    fullLandlordDetails.landlord.businessAddress.country
                  }
                  labelClassName="text-gray-500"
                />
                <DetailsTile
                  label="ZIP Code"
                  data={
                    fullLandlordDetails &&
                    fullLandlordDetails.landlord.businessAddress.zipCode
                  }
                  labelClassName="text-gray-500"
                />
              </span>
            </div>
          </section>

          <div className="border-t-2 border-blue-52">
            <section
              name="tabs"
              className="flex w-52 justify-around items-center h-10 font-medium text-gray-600 cursor-pointer"
            >
              {tabs.map((tab, index) => (
                <p
                  key={index}
                  id={tab.nickName}
                  data-cy={tab.nickName}
                  className={`hover:text-[#003C9A] focus:text-[#003C9A] pb-2 border-b-4 w-full text-center ${
                    activeMenuItem === tab.nickName
                      ? `text-[#003C9A] border-blue-52 font-semibold`
                      : `text-gray-600 border-gray-600 `
                  }`}
                  onClick={(e) => setActiveMenuItem(tab.nickName)}
                >
                  {tab.label}
                </p>
              ))}
            </section>

            {activeMenuItem === "portfolio" && (
              <section name="Portfolios Table">
                <div className="flex justify-between items-center gap-4 m-4">
                  <p className="font-semibold text-gray-600">
                    Portfolios ({tableData.length})
                  </p>

                  <div className="flex flex-row gap-2 w-auto">
                    <ButtonBlueOutline
                      id="addNew"
                      btnName="addNew"
                      dataCy="addNew"
                      name={
                        <>
                          <BsPlusCircle className="w-5 h-5 mr-2" /> Add New
                        </>
                      }
                      onClick={(e) => {
                        setOpenAddPortfolio(true);
                      }}
                      className={`flex justify-center items-center px-2 w-fit h-10 rounded-md`}
                    />

                    <Searchbar
                      styles=""
                      placeholder="Search..."
                      data={assignedPortfolios}
                      matchProps={[
                        { text: "portfolioName" },
                        { text: "numberOfProperties" },
                        { text: "assignedPropertyManager.propertyManagerName" },
                        { text: "businessAddress.address1" },
                        { text: "businessAddress.address2" },
                        { text: "businessAddress.city" },
                        { text: "businessAddress.state" },
                        { text: "businessAddress.country" },
                        { text: "businessAddress.zipCode" },
                      ]}
                      setTableData={setTableData}
                    />
                  </div>
                </div>

                <TableComponent
                  tableId="OwnerPortfolioTable"
                  data={tableData}
                  columns={[
                    {
                      key: "portfolioName",
                      title: "Portfolio Name",
                    },
                    { key: "numberOfProperties", title: "No of properties" },
                    {
                      key: "assignedPropertyManager.propertyManagerName",
                      title: "Property Manager",
                      applyMethod: (row) => {
                        return (
                          row.assignedPropertyManager.propertyManagerName || "-"
                        );
                      },
                    },
                    {
                      key: "",
                      title: "Address",
                      applyMethod: (row) =>
                        GetCompleteAddress(row.businessAddress),
                    },
                  ]}
                  //initialSort={{ key: "landlordId", direction: "descending" }}
                  rowOnClick={() => {}}
                  actionHeader="Actions"
                  actions={portfolioTableActions}
                />
              </section>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OwnerDetails;

//Add Portfolio for Owner
const AddPortfolio = (props) => {
  let webApi = new WebApimanager();
  const [allPortfolios, setAllPortfolios] = useState([]);
  const [selectedPortfolioID, setSelectedPortfolioID] = useState("");
  const [formErrors, setFormErrors] = useState("");

  useEffect(() => {
    getUnassignedPortrfolios();
  }, []);

  const getUnassignedPortrfolios = () => {
    webApi
      .get(`/landlord/client/${props.clientId}/unassignedPortfolios`)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setAllPortfolios(res.data);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage)
          swal("Error", error.customErrorMessage, "error");
        else
          swal(
            "Error",
            `Oops! Unable to get Portfolios, Please try again later.`,
            "error"
          );
      });
  };

  const validate = () => {
    const errors = {};
    if (!selectedPortfolioID) {
      errors.portfolio = "Portfolio is required!";
    }
    return errors;
  };

  const confirm = (e) => {
    e.preventDefault();
    const temp = validate();
    setFormErrors(temp);
    if (Object.keys(temp).length) {
      return;
    } else {
      let data = {
        portfolioID: selectedPortfolioID,
        landlordId: props.llID,
        clientId: props.clientId,
      };
      webApi
        .post("/landlord/portfolio/assign", data)
        .then(async (res) => {
          if (res.data.errorCode) {
            swal("Failure", res.data.errorMessage, "error");
          } else {
            swal("Success", res.data.message, "success");
            props.setTrigger(false);
            props.getPortfolios();
          }
        })
        .catch((error) => {
          if (error.customErrorMessage) {
            swal("Error", error.customErrorMessage, "error");
          } else {
            swal(
              "Error",
              `Oops! Failed to Assign Portfolio, Please try again later.`,
              "error"
            );
          }
        });
    }
  };

  return (
    <>
      <Dialog
        open={props.trigger}
        aria-labelledby="Add Portfolio"
        fullWidth
        maxWidth="sm"
        PaperProps={{ sx: { height: "auto" } }}
        scroll="body"
        className="mx-auto w-full rounded"
      >
        <span className="flex justify-center items-center mb-6 bg-blue-52 text-white font-semibold text-center rounded-sm h-10">
          Add Portfolio
        </span>

        <section className="flex flex-col justify-center items-center m-10">
          <IconInCircle
            icon={<PiWarningOctagon className="w-9 h-9 text-white" />}
            radius="4rem"
            color=""
            className="bg-blue-52 mx-auto"
          />

          <p className="mt-4 mb-4">Would you like to add a Portfolio?</p>

          <InputObjectSelectField
            label="Select Portfolio"
            name="portfolio"
            id="portfolio"
            dataCy="portfolio"
            placeholder="Select a Portfolio"
            value={selectedPortfolioID}
            onChange={(e) => {
              setSelectedPortfolioID(parseInt(e.target.value));
            }}
            options={allPortfolios}
            optionValue="ID"
            optionName="portfolioName"
            style="!w-96"
            errorText={formErrors.portfolio}
          />

          <div className="flex gap-4 items-center mb-4 mt-4">
            <ButtonGrayOutline
              name="Cancel"
              onClick={(e) => {
                props.setTrigger(false);
              }}
              className="justify-self-end"
            />
            <ButtonBlue
              name={"Confirm"}
              onClick={(e) => confirm(e)}
              className=""
            />
          </div>
        </section>
      </Dialog>
    </>
  );
};
