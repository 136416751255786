import React, { useState, useEffect } from "react";
import { companyId } from "../../atoms";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { WebApimanager } from "../../WebApiManager";
import { InputObjectSelectField } from "../../Widgets/Forms/InputFields";
import { DetailsTile } from "../../Widgets/Forms/FormReviewTiles";
import { ButtonCustom } from "../../Widgets/Buttons";
import { BsDownload } from "react-icons/bs";
import {
  GetDateInMMDDYYYY,
  TableComponent,
  Searchbar,
} from "../../Widgets/CommonWidgets";
import { ButtonIcon } from "../../Widgets/Buttons";
import swal from "sweetalert";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import ExportCSV from "../../Widgets/ExportCSV";

const WorkOrderReport = () => {
  let webApi = new WebApimanager();
  let navigate = useNavigate();
  const companyID = useRecoilValue(companyId);
  const [selectedVendorId, setSelectedVendorId] = useState("");
  const [selectedVendor, setSelectedVendor] = useState({});
  const [allVendors, setAllVendors] = useState([]);
  const [allWorkOrderReportsMap, setAllWorkOrderReportsMap] = useState(
    new Map()
  );
  const [workOrderTableData, setWorkOrderTableData] = useState([]);
  const [viewWorkOrderTable, setViewWorkOrderTable] = useState(false);

  useEffect(() => {
    getAllVendors();
  }, []);

  const getAllVendors = () => {
    webApi
      .get(`/maintenance/tickets/task/assignee/${companyID}`)
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setAllVendors(res.data.vendors);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Apologies, Failed to load Vendors, Please try again later.`,
            "error"
          );
        }
      });
  };

  const getWorkOrderReport = (vendorId) => {
    if (allWorkOrderReportsMap.has(vendorId)) {
      setWorkOrderTableData(allWorkOrderReportsMap.get(vendorId));
      setViewWorkOrderTable(true);
      return;
    }
    setWorkOrderTableData([]);
    webApi
      .get(`/reports/workOrder/vendor/${vendorId}`)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setWorkOrderTableData(res.data);
          setAllWorkOrderReportsMap(
            allWorkOrderReportsMap.set(vendorId, res.data)
          );
          setViewWorkOrderTable(true);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Apologies, Failed to genarate Work Order Report, Please try again later.`,
            "error"
          );
        }
      });
  };

  const handleSelectedVendor = (vendorId) => {
    setSelectedVendorId(vendorId);
    const requiredVendor = allVendors.filter(
      (vendor) => vendor.roleId === vendorId
    );
    if (requiredVendor.length) setSelectedVendor(requiredVendor[0]);
  };

  const handleReportBuilderSearchButtonClick = (e) => {
    e.preventDefault();
    if (!selectedVendorId) {
      swal("Warning", "Please select a vendor to Genarate Report", "warning");
    } else {
      getWorkOrderReport(selectedVendorId);
    }
  };

  const handleClearReportBuilerClick = (e) => {
    e.preventDefault();
    setSelectedVendor({});
  };

  const getStatusCellColor = (row) => {
    return { color: row.statusColor };
  };

  const downloadPDF = () => {
    let data = {
      vendorId : selectedVendorId
    }
    webApi
      .post("/report/workOrder/pdf", data , { loader: true })
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          const link = document.createElement('a');
          link.href = res.data.url;
          link.setAttribute('download', 'filename.pdf'); // You can set a default filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Apologies, Failed to download Work Order Report, Please try again later.`,
            "error"
          );
        }
      });
  }

  return (
    <>
      <div className="w-5/6 m-auto">
        <span className="flex justify-center items-center bg-blue-52 text-white font-semibold text-center rounded h-10">
          {!viewWorkOrderTable && (
            <BsFillArrowLeftCircleFill
              title="Back to Previous page"
              onClick={(e) => {
                navigate(-1);
              }}
              className="w-6 h-6 ml-2 cursor-pointer"
            />
          )}
          <span className="flex-grow">Work Order</span>
        </span>

        {!viewWorkOrderTable && (
          <section
            name="Report builder"
            className={
              "grid grid-cols-2 items-center gap-4 px-6 py-4 mt-4 mb-8 bg-slate-100 rounded"
            }
          >
            <span className="font-semibold text-base">REPORT BUILDER:</span>
            {/*  this is a empty span to occupy one column in grid, Dont Remove */}
            <span></span>

            <DetailsTile
              label={
                <p className="flex">
                  Vendor<span className="text-red-600">*</span>
                </p>
              }
              data={
                <InputObjectSelectField
                  //label="Vendor"
                  name="vendor"
                  id="vendor"
                  placeholder="Select Vendor"
                  notImp
                  value={selectedVendorId}
                  onChange={(e) =>
                    handleSelectedVendor(parseInt(e.target.value))
                  }
                  options={allVendors}
                  optionValue="roleId"
                  optionName="name"
                  style="w-full"
                />
              }
              labelClassName="justify-self-end flex items-center"
            />

            <span className="flex justify-end items-center gap-4">
              <ButtonCustom
                id="clearAll"
                name="Clear All"
                onClick={handleClearReportBuilerClick}
                className="px-2 py-1 text-center w-24 bg-transparent rounded border-2 text-gray-600"
              />
              <ButtonCustom
                id="search"
                name="Search"
                onClick={handleReportBuilderSearchButtonClick}
                className="px-2 py-1 text-center w-24 text-white bg-[#FFAF09]"
              />
            </span>
          </section>
        )}

        {viewWorkOrderTable && (
          <section>
            <section
              name="Work Order Table and Options"
              className="flex justify-between items-center bg-blue-30 h-10 mb-4"
            >
              <span className="flex gap-4">
                <BsFillArrowLeftCircleFill
                  title="Back to Previous page"
                  onClick={(e) => {
                    setViewWorkOrderTable(false);
                  }}
                  className="text-blue-52 w-6 h-6 ml-2 cursor-pointer"
                />
                <span className="flex font-semibold items-center">
                  Vendor :
                  <p className="font-normal">&nbsp;{selectedVendor.name}</p>
                </span>
              </span>
            </section>

            <div className="flex justify-end items-center mb-4 mt-4 gap-4 mr-4">
               <ButtonIcon
                id="Download"
                btnName="download"
                dataCy="download"
                title="Download PDF"
                onClick={downloadPDF}
                icon={<BsDownload className="w-5 h-5" />}
                className="hover:!bg-blue-50 hover:!text-white shadow-md"
               /> 
                
                <ExportCSV
                  data={workOrderTableData}
                  keys={[
                    { key: "ID", title: "Work Order Id"},
                {
                  key: "unitDetails.unitNumber",
                  title: "Unit Number",
                },
                {
                  key: "status",
                  title: "Status",
                },
                {
                  key: "CreatedAt",
                  title: "Call Date",
                  applyMethod: (row) => GetDateInMMDDYYYY(row.CreatedAt),
                },
                {
                  key: "startDate",
                  title: "Start Date",
                  //applyMethod: (row) => GetDateInMMDDYYYY(row.UpdatedAt),
                },
                {
                  key: "vendor",
                  title: "Vendor/Employee",
                },
                {
                  key: "description",
                  title: "Brief Description",
                },
                  ]}
                  fileName="Work Order Report.csv"
                />

              <Searchbar
                placeholder="Search Table Data..."
                data={allWorkOrderReportsMap.get(selectedVendorId)}
                matchProps={[
                  { text: "ID" },
                  { text: "unitNumber" },
                  { text: "status" },
                  { date: "CreatedAt" },
                  { date: "UpdatedAt" },
                  { text: "vendor" },
                  { text: "description" },
                ]}
                setTableData={setWorkOrderTableData}
              />
            </div>

            <TableComponent
              tableId="Work Order Table"
              data={workOrderTableData}
              columns={[
                { key: "ID", title: "Work Order Id", width: "150px" },
                {
                  key: "unitDetails.unitNumber",
                  title: "Unit Number",
                  width: "150px",
                },
                {
                  key: "status",
                  title: "Status",
                  dataClassName: "font-medium",
                  getCellStyles: getStatusCellColor,
                  width: "100px",
                },
                {
                  key: "CreatedAt",
                  title: "Call Date",
                  applyMethod: (row) => GetDateInMMDDYYYY(row.CreatedAt),
                  width: "100px",
                },
                {
                  key: "startDate",
                  title: "Start Date",
                  //applyMethod: (row) => GetDateInMMDDYYYY(row.UpdatedAt),
                  width: "100px",
                },
                {
                  key: "vendor",
                  title: "Vendor/Employee",
                  width: "200px",
                },
                {
                  key: "description",
                  title: "Brief Description",
                  width: "350px",
                },
              ]}
              rowOnClick={() => {}}
              //initialSort={{ key: "ID", direction: "descending" }}
            />
          </section>
        )}
      </div>
    </>
  );
};

export default WorkOrderReport;
