import React from "react";
import { Chart } from "react-google-charts";

export const GoogleChart = (props) => {
  return (
    <Chart
      chartType={props.chartType || "PieChart"}
      width={props.width || "100%"}
      data={props.data}
      options={props.options}
    />
  );
};
