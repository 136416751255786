import { atom, selector } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist({
  key: "Yvooa-Core", // this key is using to store data in local storage
  storage: sessionStorage, // configurate which stroage will be used to store the data
});

export const userData = atom({
  key: "userData",
  default: {},
  effects_UNSTABLE: [persistAtom],
});

// Selector for isAuthenticated
export const isAuthenticated = selector({
  key: "isAuthenticated",
  get: ({ get }) => {
    const userInfo = get(userData);
    return Boolean(userInfo && userInfo.ID);
  },
});

export const accessToken = atom({
  key: "accessToken",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const idToken = atom({
  key: "idToken",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const refreshToken = atom({
  key: "refreshToken",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const profileImageUrl = atom({
  key: "profileImageUrl",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const clientId = atom({
  key: "clientId",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const companyId = atom({
  key: "companyId",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const portfolioTreeStore = atom({
  key: "portfolioTreeStore",
  default: {
    portfolio: {},
    property: {},
    floorPlan: {},
    unit: {},
    tenant: {},
    maintenance: {},
  },
  effects_UNSTABLE: [persistAtom],
});

export const vendorId = atom({
  key: "vendorId",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const bankId = atom({
  key: "bankId",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const leaseId = atom({
  key: "leaseId",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const navigatedNotif = atom({
  key: "navigatedNotif",
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const navigatedNotifIndex = atom({
  key: "navigatedNotifIndex",
  default: "",
  effects_UNSTABLE: [persistAtom],
});