import React, { useState, useEffect } from "react";
import { ButtonBlue, ButtonGrayOutline } from "../../Widgets/Buttons";
import { Dialog } from "@mui/material";
import InputField from "../../Widgets/Forms/InputFields";
import swal from "sweetalert";
import { Auth } from "aws-amplify";

const VerifyEmail = (props) => {
  const [code, setCode] = useState("");
  const [formErrors, setFormErrors] = useState("");

  useEffect(() => {}, []);

  const validate = () => {
    const errors = {};
    if (!code) {
      errors.code = "Please enter the code";
    }
    return errors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const temp = validate();
    setFormErrors(temp);
    if (Object.keys(temp).length) {
      return;
    } else {
      Auth.verifyCurrentUserAttributeSubmit("email", code)
        .then((user) => {
          //console.log("user", user);
          swal("Success", "Verification Successful", "success");
          if (props.fromLogin) {
            props.navigate();
            props.setFromLogin(false);
          } else {
            props.updateProf();
          }
          props.setTrigger(false);
        })
        .catch((e) => {
          console.log("e", e.message);
          swal("Error", "Invalid Code, Please Try Again!", "error");
        });
    }
  };

  return props.trigger ? (
    <>
      <Dialog
        open={true}
        aria-labelledby="Email Code"
        fullWidth
        maxWidth="lg"
        PaperProps={{ sx: { height: "35%", width: "25%" } }}
        scroll="body"
        className="flex flex-col justify-center w-full"
      >
        {/* 
        <span className="flex justify-center items-center bg-blue-52 text-white font-semibold text-center rounded-sm h-10">
            VERIFY EMAIL
        </span> */}

        <form className="flex flex-col justify-center items-center">
          <section className="flex flex-col gap-4 mt-2 w-88 p-4">
            <InputField
              label="Enter Code"
              name="emailCode"
              id="emailCode"
              dataCy="emailCode"
              type="text"
              placeholder="Enter Code..."
              value={code}
              onChange={(e) => setCode(e.target.value)}
              errorText={formErrors.code}
              style="!w-full"
              hintText="Please enter the verification code that has been sent to your updated email address. 
              If you do not submit the code, you will be required to verify your email before logging 
              in using your updated email address."
            />

            <div className="flex justify-center items-center gap-16 mt-2 mb-2">
              <ButtonGrayOutline
                id="cancel"
                btnName="cancel"
                dataCy="cancel"
                name="Cancel"
                onClick={() => props.setTrigger(false)}
                className="mt-6 justify-self-end"
              />
              <ButtonBlue
                id="confirm"
                btnName="confirm"
                dataCy="confirm"
                name="Confirm"
                onClick={handleSubmit}
                className="mt-6"
              />
            </div>
          </section>
        </form>
      </Dialog>
    </>
  ) : null;
};

export default VerifyEmail;
