// LoaderContext.js
import React, { createContext, useState, useContext, useEffect } from "react";

import CircleLoader from "../Widgets/Loaders/circleLoader";

const LoaderContext = createContext();

// External references for loader functions
let externalShowLoader = null;
let externalHideLoader = null;

export const LoaderProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loaderText, setLoaderText] = useState("");

  const showLoader = (text = "Loading...") => {
    setLoaderText(text);
    setIsLoading(true);
  };

  const hideLoader = () => {
    setIsLoading(false);
    setLoaderText("");
  };

  useEffect(() => {
    externalShowLoader = showLoader;
    externalHideLoader = hideLoader;
  }, []);

  return (
    <LoaderContext.Provider value={{ isLoading, loaderText }}>
      <CircleLoader />
      {children}
    </LoaderContext.Provider>
  );
};

export const useLoader = () => useContext(LoaderContext);

// Export loader functions
export const showLoader = (...args) => {
  if (externalShowLoader) {
    externalShowLoader(...args);
  }
};

export const hideLoader = (...args) => {
  if (externalHideLoader) {
    externalHideLoader(...args);
  }
};
