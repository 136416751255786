import React, { useState, useCallback } from "react";
import { WebApimanager } from "../../WebApiManager";
import { TextReviewTile } from "../../Widgets/Forms/FormReviewTiles";
import {
  ButtonBlue,
  ButtonGrayOutline,
  ButtonIcon,
} from "../../Widgets/Buttons";
import InputField, {
  InputMultiSelectField,
  InputTextArea,
  InputDateField,
  InputTimeStampField,
} from "../../Widgets/Forms/InputFields";
import { DetailsTile } from "../../Widgets/Forms/FormReviewTiles";
import { clientId, companyId, userData } from "../../atoms";
import { useRecoilValue } from "recoil";
import {
  Dialog,
  Stepper,
  StepLabel,
  Step,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import swal from "sweetalert";
import { useEffect } from "react";
import {
  InputSelectField,
  InputObjectSelectField,
} from "../../Widgets/Forms/InputFields";
import { AiOutlineCloseCircle } from "react-icons/ai";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { GetDateInMMDDYYYY } from "../../Widgets/CommonWidgets";

const AddTemplate = (props) => {
  let webApi = new WebApimanager();
  const clientIdVal = useRecoilValue(clientId);
  const companyIdVal = useRecoilValue(companyId);
  const userInfo = useRecoilValue(userData);
  const editForm = props.operation === "Edit" ? true : false;
  const [notificationName, setNotificationName] = useState(
    editForm ? props.data.notificationName : ""
  );

  const [notificationTypeOptions, setNotificationsTypeOPtions] = useState([]);
  const [selectedNotificationType, setSelectedNotificationType] = useState(
    editForm ? props.data.notificationType : ""
  );

  const [channelOptions, setChannelOptions] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState(
    editForm ? props.data.notificationChannel : []
  );
  const [email, setEmail] = useState(false);
  const [sms, setSms] = useState(false);
  const [inbox, setInbox] = useState(false);

  const [frequencyOptions, setFrequencyOptions] = useState([]);
  const [frequency, setFrequency] = useState(
    editForm ? props.data.frequency : ""
  );

  const scheduleDayOptions = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ];

  const [scheduleDay, setScheduleDay] = useState(
    editForm ? props.data.scheduleDay : ""
  );

  const [userGroupOptions, setUserGroupOptions] = useState([]);
  const [selectedUserGroups, setSelectedUserGroups] = useState(
    editForm ? props.data.userGroup : []
  );

  const [templateStatus, setTemplateStatus] = useState(
    editForm
      ? props.data.status === false
        ? "OFF"
        : props.data.status === true
        ? "ON"
        : ""
      : ""
  );
  const [statusOptions, setStatusOptions] = useState(["ON", "OFF"]);
  const [selectedStatus, setSelectedStatus] = useState(
    editForm ? props.data.status : false
  );

  const [selectedSchVal, setSelectedSchVal] = useState("");
  const [startDate, setStartDate] = useState(
    editForm ? props.data.startDate : ""
  );
  const [endDate, setEndDate] = useState(editForm ? props.data.endDate : "");
  const [openSchedulerForm, setOpenSchedulerForm] = useState(false);
  const [formErrors, setFormErrors] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [apiProcessing, setApiProcessing] = useState(false);
  const weekDays = [
    { label: "S", value: "sunday" },
    { label: "M", value: "monday" },
    { label: "T", value: "tuesday" },
    { label: "W", value: "wednesday" },
    { label: "TH", value: "thursday" },
    { label: "F", value: "friday" },
    { label: "SA", value: "saturday" },
  ];

  useEffect(() => {
    getdropdownValues();
    if (editForm) {
      handleSelectedChannels(props.data.notificationChannel);
      console.log(props.data.status, "data.status");
      console.log(templateStatus, "templateStatus");
      console.log(selectedStatus, "selectedStatus");
    }
  }, []);

  const getdropdownValues = () => {
    webApi
      .get(`/autoNotification/dropdown`)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setNotificationsTypeOPtions(res.data.notificationType);
          setUserGroupOptions(res.data.userGroup);
          setChannelOptions(res.data.notificationChannel);
          setFrequencyOptions(res.data.frequency);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          console.log(
            "Error",
            "Oops! Failed to get Notifications Types, Please try again later.",
            "error"
          );
        }
      });
  };

  const validate = () => {
    const errors = {};
    if (activeStep === 0) {
      if (!notificationName) {
        errors.notificationName = "Notification Name is required!";
      } else if (notificationName.length < 3) {
        errors.notificationName = "Name should be bigger than 3 characters";
      } else if (notificationName.length > 50) {
        errors.notificationName =
          "Name should not be bigger than 50 characters";
      }
      if (!selectedNotificationType) {
        errors.selectedNotificationType = "Notification Type is required!";
      }
      if (selectedChannels.length === 0) {
        errors.selectedChannels = "Notification Channel is required!";
      }
      if (selectedUserGroups.length === 0) {
        errors.selectedUserGroups = "User Group is required!";
      }
      if (!frequency) {
        errors.frequency = "Frequency is required!";
      }
      if (!scheduleDay) {
        errors.scheduleDay = "Schedule Day is required!";
      }
      if (!startDate) {
        errors.startDate = "Start Date is required!";
      }
      if (!templateStatus) {
        errors.templateStatus = "Status is required!";
      }
    }
    if (activeStep === 1) {
      if (templateData.length > 500) {
        errors.templateData = "Max 500 characters allowed";
      }
    }
    return errors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const temp = validate();
    setFormErrors(temp);

    if (Object.keys(temp).length) {
      return;
    } else {
      let data = {
        creatorID: userInfo.ID,
        notificationName: notificationName,
        notificationType: selectedNotificationType,
        scheduleDay: parseInt(scheduleDay),
        startDate: startDate,
        endDate: endDate,
        frequency: frequency,
        isEmail: email,
        isText: sms,
        isInbox: inbox,
        status: selectedStatus,
        userGroup: selectedUserGroups,
        notificationText: templateData,
      };
      //console.log(data,"createdata")
      if (editForm) {
        webApi
          .put(`/autoNotification/template/${props.data.ID}`, data)
          .then(async (res) => {
            if (res.data.errorCode) {
              swal("Failure", res.data.errorMessage, "error");
            } else {
              swal("Success", res.data.message, "success");
              props.setTrigger(false);
              props.getAllNotifs();
            }
          })
          .catch((error) => {
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                `Oops! Failed to Update Template, Please try again later.`,
                "error"
              );
            }
          });
      } else {
        webApi
          .post(`/autoNotification/template`, data)
          .then(async (res) => {
            if (res.data.errorCode) {
              swal("Failure", res.data.errorMessage, "error");
            } else {
              swal("Success", res.data.message, "success");
              props.setTrigger(false);
              props.getAllNotifs();
            }
          })
          .catch((error) => {
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                `Oops! Failed to Create Template, Please try again later.`,
                "error"
              );
            }
          });
      }
    }
  };

  const handleSteps = (event) => {
    event.preventDefault();
    const temp = validate();
    setFormErrors(temp);
    if (Object.keys(temp).length) {
      return;
    } else {
      if (activeStep === 0) {
        setTemplateData(defaultTemplate);
        setActiveStep((currentStep) => currentStep + 1);
      } else {
        setActiveStep((currentStep) => currentStep + 1);
      }
    }
  };

  const handleBack = (event) => {
    event.preventDefault();
    setActiveStep((currentStep) => currentStep - 1);
  };

  const handleSelectedChannels = (newValue) => {
    // Ensure newValue is always an array
    const selectedArray = Array.isArray(newValue) ? newValue : [];

    setEmail(selectedArray.includes("Email"));
    setSms(selectedArray.includes("SMS"));
    setInbox(selectedArray.includes("Inbox"));
    console.log(selectedArray);
  };

  const handleNotificationNameInputChange = (e) => {
    const inputValue = e.target.value;
    // Replace any non-alphanumeric characters (excluding spaces) with an empty string
    const sanitizedValue = inputValue.replace(/[^A-Za-z0-9 ]+/g, "");
    setNotificationName(sanitizedValue);
  };

  const [defaultTemplate, setDefaultTemplate] = useState(
    editForm
      ? props.data.notificationText
      : `
  Dear [Recipient],

  I hope this message finds you well. I am writing to request the payment of the outstanding rental amount for the 
  property located at [Address]. Despite previous reminders, the payment for the month of [Month] has not been received.

  As per our agreement, the rent is due by the [Due Date] of each month. Your prompt attention to this matter would be greatly
  appreciated. Thank you for your cooperation.`
  );

  const [templateData, setTemplateData] = useState("");

  return props.trigger ? (
    <>
      <Dialog
        open={true}
        aria-labelledby="Add Template Form"
        fullWidth
        maxWidth="lg"
        //PaperProps={{ sx: { height: "80%" } }}
        //scroll="body"
        className="flex flex-col justify-center w-5/6 mx-auto"
      >
        <div className="flex justify-center items-center bg-blue-52 text-white font-semibold rounded-sm h-10 pr-2">
          <span className="flex-grow text-center">
            {editForm ? "Edit" : "Create"} Template
          </span>
          <ButtonIcon
            title="Close Form"
            id="closeTemplateFrom"
            btnName="closeTemplateFrom"
            dataCy="closeTemplateFrom"
            onClick={(e) => props.setTrigger(false)}
            icon={<AiOutlineCloseCircle className="w-5 h-5" />}
            className="hover:!text-red-600"
          />
        </div>

        <Stepper activeStep={activeStep} alternativeLabel className="my-4">
          <Step>
            <StepLabel>Template Details</StepLabel>
          </Step>
          <Step>
            <StepLabel>Notification Text</StepLabel>
          </Step>
          <Step>
            <StepLabel>Review Details</StepLabel>
          </Step>
        </Stepper>

        <DialogContent dividers>
          <form className="flex flex-col justify-center items-center">
            {activeStep === 0 ? (
              <>
                <span className="border-b-2 border-blue-52 pb-1 mb-4 text-blue-52 w-5/6 md:gap-x-8 md:w-11/12">
                  Template Details
                </span>
                <section className="grid grid-cols-2 gap-x-16 gap-y-6 w-5/6 mb-4 md:gap-x-8 md:w-11/12">
                  <InputField
                    label="Notification Name"
                    name="notificationName"
                    id="notificationName"
                    dataCy="notificationName"
                    type="text"
                    placeholder="Enter Notification Name"
                    value={notificationName}
                    // onChange={(e) =>
                    //   setNotificationName(e.target.value)
                    // }
                    onChange={handleNotificationNameInputChange}
                    errorText={formErrors.notificationName}
                    style="!w-full"
                  />

                  <InputSelectField
                    label="Notification Type"
                    name="notificationType"
                    id="notificationType"
                    dataCy="notificationType"
                    placeholder="Select a Type"
                    value={selectedNotificationType}
                    options={notificationTypeOptions}
                    onChange={(e) => {
                      setSelectedNotificationType(e.target.value);
                    }}
                    style="!w-full"
                    errorText={formErrors.selectedNotificationType}
                  />

                  <InputMultiSelectField
                    label="Notification Channel"
                    name="notificationChannel"
                    id="notificationChannel"
                    dataCy="notificationChannel"
                    placeholder="Select a Type"
                    options={channelOptions}
                    value={selectedChannels}
                    setValue={(newValue) => {
                      setSelectedChannels(newValue);
                      handleSelectedChannels(newValue);
                    }}
                    errorText={formErrors.selectedChannels}
                    style="!w-full"
                  />

                  <InputMultiSelectField
                    label="User Groups"
                    name="userGroups"
                    id="userGroups"
                    dataCy="userGroups"
                    placeholder="Select a group"
                    options={userGroupOptions}
                    value={selectedUserGroups}
                    setValue={setSelectedUserGroups}
                    errorText={formErrors.selectedUserGroups}
                    style="!w-full"
                    notImp
                  />

                  <InputSelectField
                    label="Frequency"
                    name="frequency"
                    id="frequency"
                    dataCy="frequency"
                    placeholder="Select a Frequency"
                    value={frequency}
                    options={frequencyOptions}
                    onChange={(e) => {
                      setFrequency(e.target.value);
                    }}
                    style="!w-full"
                    errorText={formErrors.frequency}
                  />

                  <InputSelectField
                    label="Schedule Day"
                    name="scheduleDay"
                    id="scheduleDay"
                    dataCy="scheduleDay"
                    placeholder="Select a Date"
                    value={scheduleDay}
                    options={scheduleDayOptions}
                    onChange={(e) => {
                      setScheduleDay(e.target.value);
                    }}
                    style="!w-full"
                    errorText={formErrors.scheduleDay}
                    hintText="Select date of the month to send notification"
                  />

                  <InputTimeStampField
                    label="Start Date"
                    name="startDate"
                    id="startDate"
                    btnName="startDate"
                    dataCy="startDate"
                    minDate={new Date()}
                    value={startDate}
                    onChange={(value) => setStartDate(value)}
                    style="!w-full"
                    errorText={formErrors.startDate}
                  />

                  <InputTimeStampField
                    label="End Date"
                    name="endDate"
                    id="endDate"
                    btnName="endDate"
                    dataCy="endDate"
                    minDate={startDate}
                    value={endDate}
                    onChange={(value) => setEndDate(value)}
                    style="!w-full"
                    // errorText={formErrors.endDate}
                    notImp
                  />

                  {/* <InputSelectField
                    label="Schedule Event"
                    name="scheduleEvent"
                    id="scheduleEvent"
                    dataCy="scheduleEvent"
                    placeholder="Select a Event"
                    options={["Monthly on First Monday", "Custom"]}
                    value={selectedSchVal}
                    onChange={(e) => {
                      handleSelectedSchvalue(e);
                    }}
                    style="!w-full"
                  /> */}

                  <InputSelectField
                    label="Status"
                    name="status"
                    id="status"
                    dataCy="status"
                    placeholder="Select a value"
                    value={templateStatus}
                    options={statusOptions}
                    onChange={(e) => {
                      if (e.target.value === "OFF") {
                        setSelectedStatus(false);
                      } else if (e.target.value === "ON") {
                        setSelectedStatus(true);
                      }
                      setTemplateStatus(e.target.value);
                    }}
                    style="!w-full"
                    errorText={formErrors.templateStatus}
                  />

                  <div></div>

                  <ButtonGrayOutline
                    name="Cancel"
                    id="cancel"
                    btnName="cancel"
                    dataCy="cancel"
                    onClick={() => props.setTrigger(false)}
                    className="mt-6 justify-self-end"
                  />
                  <ButtonBlue
                    name="Next"
                    id="next"
                    btnName="next"
                    dataCy="next"
                    onClick={(e) => handleSteps(e)}
                    className="mt-6"
                  />
                </section>
              </>
            ) : null}

            {activeStep === 1 ? (
              <>
                <span className="border-b-2 border-blue-52 pb-1 mb-4 text-blue-52 w-5/6 md:gap-x-8 md:w-11/12">
                  Notification Text
                </span>
                <section className="flex w-5/6 mb-4 md:w-11/12">
                  <InputTextArea
                    label="Edit Details"
                    name="editDetails"
                    id="editDetails"
                    dataCy="editDetails"
                    type="text"
                    placeholder="Write Letter here..."
                    value={templateData}
                    onChange={(e) => setTemplateData(e.target.value)}
                    errorText={formErrors.templateData}
                    style="!w-full"
                    className="h-60 rounded-lg"
                    hintText="Max 500 characters allowed"
                    resize="none"
                  />
                </section>
                <div></div>
                <div className="flex justify-center items-center gap-16 mt-6 mb-4 w-3/4">
                  <ButtonGrayOutline
                    name="Back"
                    id="back"
                    btnName="back"
                    onClick={(e) => handleBack(e)}
                    className="mt-6 justify-self-end"
                  />
                  <ButtonBlue
                    name="Next"
                    id="next"
                    btnName="next"
                    onClick={(e) => handleSteps(e)}
                    className="mt-6"
                  />
                </div>
              </>
            ) : null}

            {activeStep === 2 ? (
              <>
                <span className="border-b-2 border-blue-52 pb-1 mb-4 text-blue-52 w-5/6 md:gap-x-8 md:w-11/12">
                  Review Details
                </span>
                <section className="grid grid-cols-4 gap-x-16 gap-y-6 w-5/6 justify-around items-center md:gap-x-8 md:w-11/12">
                  <TextReviewTile
                    title="Notification Name"
                    body={notificationName}
                  />
                  <TextReviewTile
                    title="Notification Type"
                    body={selectedNotificationType}
                  />
                  <TextReviewTile
                    title="Notfication Channel"
                    body={selectedChannels.join(", ")}
                  />
                  <TextReviewTile
                    title="User Group"
                    body={selectedUserGroups.join(", ")}
                  />
                  <TextReviewTile title="Frequency" body={frequency} />
                  <TextReviewTile title="Schedule Day" body={scheduleDay} />
                  <TextReviewTile
                    title="Start Date"
                    body={GetDateInMMDDYYYY(startDate)}
                  />
                  <TextReviewTile
                    title="End Date"
                    body={GetDateInMMDDYYYY(endDate)}
                  />
                  <TextReviewTile title="Status" body={templateStatus} />
                </section>

                <span className="border-b-2 border-blue-52 pb-1 text-blue-52 w-5/6 md:gap-x-8 md:w-11/12">
                  Notification Text
                </span>
                <section className="flex w-5/6 justify-around items-center md:w-11/12">
                  <pre className="p-1 rounded-lg font-sans text-base whitespace-pre-wrap w-full">
                    {templateData}
                  </pre>
                </section>

                <div className="flex justify-center items-center gap-16 mt-6 mb-4 w-3/4">
                  <ButtonGrayOutline
                    name="Back"
                    id="back"
                    btnName="back"
                    dataCy="back"
                    onClick={(e) => handleBack(e)}
                    hidden={apiProcessing}
                  />
                  <ButtonBlue
                    id="confirm"
                    btnName="confirm"
                    name="Confirm"
                    onClick={handleSubmit}
                  />
                </div>
              </>
            ) : null}

            {openSchedulerForm && (
              <Dialog
                open={true}
                aria-labelledby="Scheduler Form"
                fullWidth
                maxWidth="sm"
                //PaperProps={{ sx: { height: "80%" } }}
                //scroll="body"
                className="flex flex-col justify-center w-5/6 mx-auto"
              >
                <div className="flex justify-center items-center bg-blue-52 text-white font-semibold rounded-sm h-10 pr-2">
                  <span className="flex-grow text-center">Set Custom Time</span>
                  <ButtonIcon
                    title="Close Scheduler Form"
                    id="closeSchedulerFrom"
                    btnName="closeSchedulerFrom"
                    dataCy="closeSchedulerFrom"
                    onClick={() => setOpenSchedulerForm(false)}
                    icon={<AiOutlineCloseCircle className="w-5 h-5" />}
                    className="hover:!text-red-600"
                  />
                </div>

                <DialogContent dividers>
                  <div className="flex flex-col justify-center w-4/6 ml-20 mr-10">
                    <p className="font-semibold text-sm">Repeat Frequency</p>
                    <div className="flex flex-row">
                      <DetailsTile
                        label={<p className="flex">Times :</p>}
                        data={
                          <InputField
                            //label="Times"
                            name="times"
                            id="times"
                            dataCy="times"
                            type="number"
                            placeholder="00"
                            //value={portfolioName}
                            // onChange={(e) =>
                            //   setPortfolioName(e.target.value.replace(/[^\w\s]/gi, ""))
                            // }
                            //errorText={formErrors.portfolioName}
                            style="!w-10"
                            notImp
                          />
                        }
                        labelClassName="justify-self-end flex items-center"
                      />

                      <DetailsTile
                        label={<p className="flex">Every :</p>}
                        data={
                          <InputSelectField
                            //label="Notification Type"
                            name="every"
                            id="every"
                            dataCy="every"
                            placeholder="Select Value"
                            //value={companyName}
                            //options={notificationTypeOptions}
                            // onChange={(e) => {
                            //
                            // }}
                            style="!w-52"
                            notImp
                          />
                        }
                        labelClassName="justify-self-end flex items-center"
                      />
                    </div>

                    <p className="font-semibold text-sm mt-4">Repeat On</p>
                    <div className="flex flex-row gap-2">
                      {weekDays.map((rows, index) => (
                        <div
                          key={index}
                          className="flex justify-center items-center shadow border rounded w-10 h-10 bg-white text-black hover:bg-blue-200 transition duration-200"
                        >
                          {rows.label}
                        </div>
                      ))}
                    </div>

                    <p className="font-semibold text-sm mt-6 mb-2">
                      End Schedule On
                    </p>
                    <div className="flex items-center mr-10 mb-4">
                      <input
                        id="inline-radio1"
                        type="radio"
                        value="never"
                        name="inline-radio-group1"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                        //onChange={(e) => setPaymentType(e.target.value)}
                      />
                      <label
                        htmlFor="never"
                        className="ml-2 text-sm text-gray-900"
                      >
                        Never
                      </label>
                    </div>

                    <div className="flex flex-row mb-4">
                      <div className="flex items-center">
                        <input
                          id="inline-radio1"
                          type="radio"
                          value="on"
                          name="inline-radio-group1"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                          //onChange={(e) => setPaymentType(e.target.value)}
                        />
                        <label
                          htmlFor="on"
                          className="ml-2 text-sm text-gray-900"
                        >
                          On
                        </label>
                      </div>

                      <DetailsTile
                        label={<p className="flex">Date :</p>}
                        data={
                          <label className="items-center">
                            <DatePicker
                              className={` shadow border appearance-none rounded w-full mt-1 py-2 px-3 leading-tight focus:outline-none`}
                              id="startDate"
                              name="startDate"
                              data-cy="startDate"
                              //readOnly={reportPeriod !== "Custom" ? true : false}
                              //value={dayjs(commonReportBuilderValues.startDate)}
                              // onChange={(e) => {
                              //   handleCommonReportBuilerValuesOnChange(
                              //     dayjs(e.$d).format("MM/DD/YYYY"),
                              //     "startDate"
                              //   );
                              // }}
                              sx={styles.datePicker}
                            />
                          </label>
                        }
                        labelClassName="justify-self-end flex items-center"
                      />
                    </div>

                    <div className="flex flex-row">
                      <div className="flex items-center">
                        <input
                          id="inline-radio1"
                          type="radio"
                          value="after"
                          name="inline-radio-group1"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                          //onChange={(e) => setPaymentType(e.target.value)}
                        />
                        <label
                          htmlFor="after"
                          className="ml-2 text-sm text-gray-900"
                        >
                          After
                        </label>
                      </div>

                      <DetailsTile
                        label={<p className="flex">Times :</p>}
                        data={
                          <InputField
                            //label="Times"
                            name="times"
                            id="times"
                            dataCy="times"
                            type="number"
                            placeholder="00"
                            //value={portfolioName}
                            // onChange={(e) =>
                            //   setPortfolioName(e.target.value.replace(/[^\w\s]/gi, ""))
                            // }
                            //errorText={formErrors.portfolioName}
                            style="!w-full"
                            notImp
                          />
                        }
                        labelClassName="justify-self-end flex items-center"
                      />
                    </div>
                  </div>
                </DialogContent>
              </Dialog>
            )}
          </form>
        </DialogContent>
      </Dialog>
    </>
  ) : (
    ""
  );
};

export default AddTemplate;

const styles = {
  datePicker: {
    "& .MuiInputBase-input": {
      padding: "8px",
      fontSize: "14px",
      fontWeight: 300,
    },
    "& .MuiIconButton-root": {
      padding: "8px",
    },
    "& .MuiPickersDay-daySelected": {
      backgroundColor: "#F1F5F9",
      "&:hover": {
        backgroundColor: "#006699",
      },
    },
    "& .MuiPickersDay-current": {
      color: "#F1F5F9",
      fontWeight: 300,
    },
  },
};
