import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router";
import { WebApimanager } from "../../../WebApiManager";

import {
  TableComponent,
  GetDateInMMDDYYYY,
} from "../../../Widgets/CommonWidgets";
import { DetailsTile } from "../../../Widgets/Forms/FormReviewTiles";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import swal from "sweetalert";

import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { AiFillDollarCircle } from "react-icons/ai";

const TransactionDetails = () => {
  let webApi = new WebApimanager();
  let location = useLocation();
  let navigate = useNavigate();
  const accountDetails = location.state.data;

  const [allTransactionDetails, setAllTransactionsDetails] = useState([]);

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  useEffect(() => {
    getTransactionDetails();
  }, []);

  const getTransactionDetails = () => {
    setApiProcessing({
      loader: true,
      message: `Loading Account Transactions...`,
    });
    webApi
      .get(
        `/accounting/chartOfAccounts/transaction/${accountDetails.bankTransactionID}`
      )
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setAllTransactionsDetails(res.data);
        }
        setApiProcessing({ loader: false, message: `` });
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops! Failed to gather Account Transactions, Please try again later.",
            "error"
          );
        }
      });
  };

  return (
    <>
      <Loader open={apiProcessing.loader} message={apiProcessing.message} />

      <div className="w-5/6 m-auto">
        <section
          name="Account Transaction & Options"
          className="flex justify-between items-center bg-blue-53 h-10 mb-4"
        >
          <span className="flex gap-4">
            <BsFillArrowLeftCircleFill
              title="Back to Previous page"
              onClick={(e) => {
                navigate(-1);
              }}
              className="text-white w-6 h-6 ml-2 cursor-pointer"
            />
            <p className="font-semibold text-white">
              {accountDetails.description}
            </p>
          </span>
        </section>

        <section
          name="Searchbar and Options"
          className={
            "flex justify-between items-center gap-2 p-6 shadow-lg mb-4"
          }
        >
          <DetailsTile
            label="Amount:"
            data={"$" + accountDetails.amount.toLocaleString()}
            className="!flex !items-center !gap-2"
            labelClassName="text-gray-500"
          />

          <span className="flex w-full justify-end items-end gap-6">
            <DetailsTile
              label="Account:"
              data={accountDetails.account_name}
              className="!flex !items-center !gap-2"
              labelClassName="text-gray-500"
            />
            <DetailsTile
              label="Date:"
              data={GetDateInMMDDYYYY(accountDetails.date)}
              className="!flex !items-center !gap-2"
              labelClassName="text-gray-500"
            />
          </span>
        </section>

        <TableComponent
          tableId="transactionsTable"
          data={allTransactionDetails}
          columns={[
            {
              key: "received_from",
              title: "Received From",
            },
            { key: "account_name", title: "Account" },
            {
              key: "description",
              title: "Description",
            },
            {
              key: "payment_method",
              title: "Payment Method",
            },
            {
              key: "reference_number",
              title: "Ref No.",
            },
            {
              key: "account_status",
              title: "Amount",
              applyMethod: (row) => {
                if (row.amount) {
                  return "$" + row.amount.toLocaleString();
                } else {
                  return "-$" + row.amount.toLocaleString();
                }
              },
            },
            { key: "billable", title: "Billable" },
            { key: "customer", title: "Customer" },
            { key: "property_name", title: "Property" },
          ]}
          rowOnClick={(row) => {}}
          //initialSort={{ key: "balance", direction: "descending" }}
        />
        <DetailsTile
          label="Total:"
          data={"$" + accountDetails.amount.toLocaleString()}
          className="!flex !items-center !gap-2"
          labelClassName="text-gray-500"
        />
      </div>
    </>
  );
};

export default TransactionDetails;
