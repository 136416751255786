import React, { useState, useEffect, useRef } from "react";
import { WebApimanager } from "../../../WebApiManager";
import { useNavigate } from "react-router";
import { userData, companyId } from "../../../atoms";
import { useRecoilValue } from "recoil";
import { BsDownload } from "react-icons/bs";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import { TableComponent, Searchbar } from "../../../Widgets/CommonWidgets";
import InputField, {
  InputObjectSelectField,
  InputSelectField,
} from "../../../Widgets/Forms/InputFields";
import { IconInCircle } from "../../../Widgets/Icons";
import {
  ButtonIcon,
  ButtonGrayOutline,
  ButtonBlueOutline,
  ButtonBlue,
} from "../../../Widgets/Buttons";
import swal from "sweetalert";
import { Dialog, DialogContent } from "@mui/material";
import ExportCSV from "../../../Widgets/ExportCSV";
import { BsPlusCircle } from "react-icons/bs";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { AiOutlineDownload, AiOutlineCloseCircle } from "react-icons/ai";

const ChartofAccounts = () => {
  let navigate = useNavigate();
  let webApi = new WebApimanager();
  const userInfo = useRecoilValue(userData);
  const companyID = useRecoilValue(companyId);
  const [allAccountsData, setAllAccountsData] = useState([]);
  const [accountsTableData, setAccountsTableData] = useState([]);

  const [openAddAccountForm, setOpenAddAccountForm] = useState(false);
  const [accountTypes, setAccountTypes] = useState([]);
  const [selectedAccountType, setSelectedAccountType] = useState("");

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  useEffect(() => {
    setAccountsTableData(allAccountsData);
    getChartOfAccountTypes();
    getAccounts();
  }, []);

  const getChartOfAccountTypes = () => {
    webApi
      .get(`/accounting/chartOfAccountsTypes`)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setAccountTypes(res.data);
          setSelectedAccountType("All");
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops! Failed to gather Account Types, Please try again later.",
            "error"
          );
        }
      });
  };

  const getAccounts = () => {
    setApiProcessing({ loader: true, message: `Loading Accounts...` });
    webApi
      .get(`/accounting/chartOfAccounts/company/${companyID}`)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setAllAccountsData(res.data);
          setAccountsTableData(res.data);
          setSelectedAccountType("");
        }
        setApiProcessing({ loader: false, message: `` });
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops! Failed to gather Account Types, Please try again later.",
            "error"
          );
        }
      });
  };

  const handleAccountTypeFilter = (value) => {
    const currentAccountType = value;
    if (currentAccountType === "All") {
      setAccountsTableData(allAccountsData);
    } else {
      const filteredData = allAccountsData.filter(
        (item) => item.account_type === currentAccountType
      );
      setAccountsTableData(filteredData);
    }
    setSelectedAccountType(value);
  };

  const downloadPDF = () => {
    let data = {
      companyId : companyID
    }
    webApi
      .post("/report/chartOfAccounts/pdf", data , { loader: true })
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          const link = document.createElement('a');
          link.href = res.data.url;
          link.setAttribute('download', 'filename.pdf'); // You can set a default filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Apologies, Failed to download Chart of Account Report, Please try again later.`,
            "error"
          );
        }
      });
  }

  return (
    <>
      {openAddAccountForm && (
        <AddAccount
          trigger={openAddAccountForm}
          setTrigger={setOpenAddAccountForm}
          accountTypes={accountTypes}
          clientId={userInfo && userInfo.ID}
          getAllAccounts={(data) => getAccounts()}
        />
      )}

      <Loader open={apiProcessing.loader} message={apiProcessing.message} />

      <div className="w-5/6 m-auto">
        <span className="flex justify-center items-center bg-blue-52 text-white font-semibold text-center rounded h-10">
          Chart Of Accounts
        </span>

        <div className="flex justify-start items-center my-6">
          <IconInCircle
            icon={<FaFileInvoiceDollar className="w-9 h-9 text-blue-52" />}
            radius="4rem"
            className="shadow-xl mx-6"
          />
          <span>
            <p className="font-semibold">Genarate Quick Accounting Reports!</p>
            <p className="text-gray-400/100">
              Use the advanced Accounting System to track Chart of Accounts!
            </p>
          </span>
        </div>

        <section
          name="Searchbar and Options"
          className={"flex justify-between items-center my-4 mx-4"}
        >
          <InputObjectSelectField
            label="Account Type"
            name="accountType"
            id="accountType"
            dataCy="accountType"
            placeholder="Account Type"
            options={[
              {
                id: 0,
                account_type: "All",
              },
              ...accountTypes,
            ]}
            optionValue="account_type"
            optionName="account_type"
            value={selectedAccountType}
            onChange={(e) => handleAccountTypeFilter(e.target.value)}
            notImp
          />
          <div className=" flex w-full justify-end items-end gap-2">
            <ButtonBlueOutline
              id="addNewPortfolio"
              btnName="addNewPortfolio"
              dataCy="addNewPortfolio"
              name={
                <>
                  <BsPlusCircle className="w-5 h-5 mr-2" /> Add New
                </>
              }
              onClick={(e) => setOpenAddAccountForm(true)}
              className={`flex justify-center items-center px-2 w-fit h-10 rounded-md`}
            />

            <ExportCSV
              data={accountsTableData}
              keys={[
                {
                  key: "account_id",
                  title: "Account No.",
                  applyMethod: (row) => {
                    const maskedAccount =
                      "xxx..xxx" + row.account_id.toString().slice(-4);
                    return maskedAccount;
                  },
                },
                { key: "account_name", title: "Account Name" },
                {
                  key: "account_type",
                  title: "Account Type",
                },
                {
                  key: "cash_flow_classification",
                  title: "Cash flow Classification",
                  applyMethod: (row) => {
                    return row.cash_flow_classification ? "Yes" : "No";
                  },
                },
                {
                  key: "balance",
                  title: "Balance",
                  applyMethod: (row) => {
                    if (row.balance) {
                      return "$" + row.balance.toLocaleString();
                    } else {
                      return "-$" + row.balance.toLocaleString();
                    }
                  },
                },
                { key: "account_status", title: "Account Status" },
              ]}
              fileName="Chart of Account.csv"
            />

            <ButtonIcon
                id="Download"
                btnName="download"
                dataCy="download"
                title="Download PDF"
                onClick={downloadPDF}
                icon={<BsDownload className="w-5 h-5" />}
                className="hover:!bg-blue-50 hover:!text-white shadow-md"
              /> 

            <Searchbar
              style="ml-2"
              placeholder="Search..."
              data={allAccountsData}
              matchProps={[
                { text: "account_id" },
                { text: "account_name" },
                { text: "account_type" },
                { text: "cash_flow_classification" },
                { text: "balance" },
                { text: "account_status" },
              ]}
              setTableData={setAccountsTableData}
            />
          </div>
        </section>

        <TableComponent
          tableId="ChartOfAccountsTable"
          data={accountsTableData}
          columns={[
            {
              key: "account_id",
              title: "Account No.",
              applyMethod: (row) => {
                const maskedAccount =
                  "xxx..xxx" + row.account_id.toString().slice(-4);
                return maskedAccount;
              },
            },
            { key: "account_name", title: "Account Name" },
            {
              key: "account_type",
              title: "Account Type",
            },
            {
              key: "cash_flow_classification",
              title: "Cash flow Classification",
              applyMethod: (row) => {
                return row.cash_flow_classification ? "Yes" : "No";
              },
            },
            {
              key: "balance",
              title: "Balance",
              applyMethod: (row) => {
                if (row.balance) {
                  return "$" + row.balance.toLocaleString();
                } else {
                  return "-$" + row.balance.toLocaleString();
                }
              },
            },
            { key: "account_status", title: "Account Status" },
          ]}
          rowOnClick={(row) =>
            navigate("/accounting/chartOfAccounts/details", {
              state: { data: row },
            })
          }
          //initialSort={{ key: "balance", direction: "descending" }}
        />
      </div>
    </>
  );
};

export default ChartofAccounts;

//Add Account
export const AddAccount = (props) => {
  let webApi = new WebApimanager();

  const [accountDetails, setAccountDetails] = useState({
    accountNo: "",
    accountName: "",
    accountType: "",
    cashFlowClassification: "",
  });

  const [formErrors, setFormErrors] = useState("");

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  useEffect(() => {}, []);

  const validate = () => {
    const errors = {};
    // if (!accountDetails.accountNo.trim()) {
    //   errors.accountNo = "Account No. is required!";
    // }
    if (!accountDetails.accountName.trim()) {
      errors.accountName = "Account Name is required!";
    }
    if (!accountDetails.accountType.trim()) {
      errors.accountType = "Account Type is required!";
    }
    // if (!accountDetails.cashFlowClassification) {
    //   errors.cashFlowClassification = "Cash Flow Classification is required!";
    // }
    return errors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const temp = validate();
    setFormErrors(temp);
    if (Object.keys(temp).length) {
      return;
    } else {
      setApiProcessing({ loader: true, message: `Adding a new Account...` });

      let data = {
        account_name: accountDetails.accountName,
        account_type: accountDetails.accountType,
        cash_flow_classification: accountDetails.cashFlowClassification,
        account_status: "Active",
        client_id: props.clientId,
      };

      webApi
        .post("/accounting/chartOfAccounts", data)
        .then(async (res) => {
          if (res.data.errorCode) {
            swal("Failure", res.data.errorMessage, "error");
          } else {
            props.getAllAccounts(data);
            setApiProcessing({ loader: false, message: `` });
            swal(
              "Success",
              `New Account has been Added successfully`,
              "success"
            );
            props.setTrigger(false);
          }
        })
        .catch((error) => {
          setApiProcessing({ loader: false, message: `` });
          if (error.customErrorMessage) {
            swal("Error", error.customErrorMessage, "error");
          } else {
            swal(
              "Error",
              "Failed to Add New Account! Please try again later.",
              "error"
            );
          }
        });
    }
  };

  const handleAccountDetails = (name, value) => {
    setAccountDetails({
      ...accountDetails,
      [name]: value,
    });
  };

  return (
    props.trigger && (
      <>
        <Dialog
          open={true}
          aria-labelledby="Add New Account Form"
          fullWidth
          maxWidth="xs"
          //PaperProps={{ sx: { height: "100%" } }}
          className="flex flex-col justify-center w-full h-fit"
        >
          <Loader open={apiProcessing.loader} message={apiProcessing.message} />

          <div className="flex justify-center items-center bg-blue-52 text-white font-semibold rounded-sm h-10 pr-2">
            <span className="flex-grow text-center">Add Account</span>
            <ButtonIcon
              id="close"
              btnName="close"
              dataCy="close"
              title="Close"
              onClick={(e) => props.setTrigger(false)}
              icon={<AiOutlineCloseCircle className="w-5 h-5" />}
              className="hover:!text-red-600"
            />
          </div>

          <DialogContent dividers>
            <div className="flex flex-col items-center justify-center gap-3 mx-auto">
              {/* <InputField
                label="Account No."
                name="accountNo"
                id="accountNo"
                type="text"
                placeholder="Enter Account No."
                value={accountDetails.accountNo}
                onChange={(e) =>
                  handleAccountDetails("accountNo", e.target.value)
                }
                errorText={formErrors.accountNo}
                style="!w-full"
              /> */}

              <InputField
                label="Account Name"
                name="accountName"
                id="accountName"
                dataCy="accountName"
                type="text"
                placeholder="Enter Account Name"
                value={accountDetails.accountName}
                onChange={(e) =>
                  handleAccountDetails("accountName", e.target.value)
                }
                errorText={formErrors.accountName}
                style="!w-full"
              />

              <InputObjectSelectField
                label="Account Type"
                name="accountType"
                id="accountType"
                dataCy="accountType"
                placeholder="Choose a Account Type"
                value={accountDetails.accountType}
                onChange={(e) =>
                  handleAccountDetails("accountType", e.target.value)
                }
                options={props.accountTypes}
                optionValue="account_type"
                optionName="account_type"
                errorText={formErrors.accountType}
                style="!w-full"
              />

              <InputObjectSelectField
                label="Cash Flow Classifications"
                name="cashFlowClassifications"
                id="cashFlowClassifications"
                dataCy="cashFlowClassifications"
                placeholder="Choose a Classification"
                notImp
                value={accountDetails.cashFlowClassification.toString()}
                onChange={(e) => {
                  const status = JSON.parse(e.target.value);
                  handleAccountDetails("cashFlowClassification", status);
                }}
                options={[
                  { name: "Yes", value: "true" },
                  { name: "No", value: "false" },
                ]}
                optionName="name"
                optionValue="value"
                // errorText={formErrors.cashFlowClassification}
                style="!w-full"
              />
            </div>

            <div className="flex justify-center items-center gap-16 my-6">
              <ButtonGrayOutline
                id="cancel"
                btnName="cancel"
                dataCy="cancel"
                name="Cancel"
                title="Close the Form"
                onClick={(e) => props.setTrigger(false)}
                className="justify-self-end"
              />
              <ButtonBlue name="Confirm" onClick={handleSubmit} className="" />
            </div>
          </DialogContent>
        </Dialog>
      </>
    )
  );
};
