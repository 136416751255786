const Footer = ({ className }) => {
  return (
    <>
      <span
        className={
          className +
          ` flex justify-center items-center text-gray-500/100 w-full bg-transparent my-4`
        }
        id="footer-text"
        name="footer-text"
        data-cy="footer-text"
      >
        © YVOOA 2023 All Rights Reserved.
      </span>
    </>
  );
};

export default Footer;
