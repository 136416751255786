import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { clientId } from "../../atoms";
import { useRecoilValue } from "recoil";
import { WebApimanager } from "../../WebApiManager";
import {
  InputObjectSelectField,
  InputSelectField,
} from "../../Widgets/Forms/InputFields";
import { DetailsTile } from "../../Widgets/Forms/FormReviewTiles";
import { ButtonCustom } from "../../Widgets/Buttons";

import swal from "sweetalert";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const ReportsBuilder = (props) => {
  let webApi = new WebApimanager();
  const clientIdVal = useRecoilValue(clientId);
  let navigate = useNavigate();

  const [reportBuilderValues, setReportBuilderValues] = useState({
    portfolioId: props.builderValues.portfolioId || "",
    propertyId: props.builderValues.propertyId || "",
    unitId: props.builderValues.unitId || "",
    tenantCode: props.builderValues.tenantCode || "",
  });

  const [commonReportBuilderValues, setCommomReportBuilderValues] = useState({
    startDate: props.builderValues.startDate || "",
    endDate: props.builderValues.endDate || "",
    leaseStatus: props.builderValues.leaseStatus || "",
  });

  const [allPortfolios, setAllPortfolios] = useState([]);
  const [propertiesOptions, setPropertiesOptions] = useState([]);
  const [allPropertiesMap, setAllPropertiesMap] = useState(new Map());
  const [unitsOptions, setUnitsOptions] = useState([]);
  const [allUnitsMap, setAllUnitsMap] = useState(new Map());
  const [tenantsOptions, setTenantsOptions] = useState([]);
  const [allTenantsMap, setAllTenantsMap] = useState(new Map());
  const [reportPeriod, setReportPeriod] = useState("");
  const reportPeriodOptions = [
    "All",
    "Custom",
    "Last 3 Months",
    "Last 6 Months",
    "Last Year",
    "Year to Date",
  ];

  const [showUnit, setShowUnit] = useState(props.hideUnit ? false : true);
  const [showTenant, setShowTenant] = useState(props.hideTenant ? false : true);
  const [showLeaseStatus, setShowLeaseStatus] = useState(
    props.hideLeaseStatus ? false : true
  );
  const [showReportPeriod, setShowReportPeriod] = useState(
    props.hideReportPeriod ? false : true
  );

  useEffect(() => {
    getPortfolios();
  }, []);

  const getPortfolios = () => {
    webApi
      .get("/portfolios/client/" + clientIdVal)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setAllPortfolios(
            res.data.length > 1
              ? res.data.sort((a, b) =>
                  a.portfolioName > b.portfolioName ? 1 : -1
                )
              : res.data
          );
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops, Failed to load Portfolios, Please try again later.`,
            "error"
          );
        }
      });
  };

  const getProperties = (portfolioId) => {
    if (allPropertiesMap.has(portfolioId)) {
      setPropertiesOptions(allPropertiesMap.get(portfolioId));
      return;
    }
    webApi
      .get("/properties/portfolio/" + portfolioId)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          const sortedData =
            res.data.length > 1
              ? res.data.sort((a, b) =>
                  a.propertyName > b.propertyName ? 1 : -1
                )
              : res.data;
          setPropertiesOptions(sortedData);
          setAllPropertiesMap(allPropertiesMap.set(portfolioId, sortedData));
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops, Failed to load Properties, Please try again later.`,
            "error"
          );
        }
      });
  };

  const getAllUnits = (propertyId) => {
    if (allUnitsMap.has(propertyId)) {
      setUnitsOptions(allUnitsMap.get(propertyId));
      return;
    }
    webApi
      .get("/units/property/" + propertyId)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          const sortedData =
            res.data.length > 1
              ? res.data.sort((a, b) => (a.unitNumber > b.unitNumber ? 1 : -1))
              : res.data;
          setUnitsOptions(sortedData);
          setAllUnitsMap(allUnitsMap.set(propertyId, sortedData));
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops, Failed to load Units, Please try again later.`,
            "error"
          );
        }
      });
  };

  const getAllTenants = (unitID) => {
    if (allTenantsMap.has(unitID)) {
      setTenantsOptions(allTenantsMap.get(unitID));
      return;
    }
    webApi
      .get("/tenant/tenantCode/unit/" + unitID)
      .then((res) => {
        if (res.data !== null) {
          if (res.data.errorCode) {
            swal("Failure", res.data.errorMessage, "error");
          } else {
            const sortedData = res.data.sort();
            setTenantsOptions(sortedData);
            setAllTenantsMap(allTenantsMap.set(unitID, sortedData));
          }
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops, Failed to load Tenants, Please try again later.`,
            "error"
          );
        }
      });
  };

  const handleCommonReportBuilerValuesOnChange = (value, accessor) => {
    setCommomReportBuilderValues({
      ...commonReportBuilderValues,
      [accessor]: value,
    });
  };

  const handleClearReportBuilerClick = (e) => {
    setReportBuilderValues({
      portfolioId: "",
      propertyId: "",
      unitId: "",
      tenantCode: "",
    });
    setCommomReportBuilderValues({
      startDate: "",
      endDate: "",
      leaseStatus: "",
    });
    setReportPeriod("");
    setPropertiesOptions([]);
    setUnitsOptions([]);
    setTenantsOptions([]);
  };

  const handleReportPeriodChange = (period) => {
    const currentDate = dayjs();
    let from = "";
    let to = "";
    if (period === "Custom" || period === "All") {
      setCommomReportBuilderValues({
        ...commonReportBuilderValues,
        startDate: "",
        endDate: "",
      });
      return setReportPeriod(period);
    } else if (period === "Last 3 Months") {
      from = currentDate.subtract(3, "month").startOf("month");
      to = currentDate.endOf("day");
    } else if (period === "Last 6 Months") {
      from = currentDate.subtract(6, "month").startOf("month");
      to = currentDate.endOf("day");
    } else if (period === "Last Year") {
      from = dayjs().subtract(1, "year").startOf("year");
      to = from.endOf("year");
    } else if (period === "Year to Date") {
      from = currentDate.startOf("year");
      to = currentDate.endOf("day");
    }
    if (from && to) {
      setCommomReportBuilderValues({
        ...commonReportBuilderValues,
        startDate: from.format("MM/DD/YYYY"),
        endDate: to.format("MM/DD/YYYY"),
      });
      setReportPeriod(period);
    }
  };

  const getSelectedPortfoio = (portfolioId) => {
    const currentPort = allPortfolios.find((item) => item.ID === portfolioId);
    props.selectedPortfolio(currentPort);
  };

  const getSelectedProperty = (portfolioId, propertyId) => {
    const propsArr = allPropertiesMap.get(portfolioId);
    const currentProp = propsArr.find((item) => item.ID === propertyId);
    props.selectedProperty(currentProp);
  };

  const getSelectedUnit = (propertyId, unitId) => {
    const unitsArr = allUnitsMap.get(propertyId);
    const currentUnit = unitsArr.find((item) => item.ID === unitId);
    props.selectedUnit(currentUnit);
  };

  const getSelectedTenantCode = (unitId, tenantCode) => {
    const tenants = allTenantsMap.get(unitId);
    const currentTenant = tenants.find((item) => item === tenantCode);
    console.log("current tenant ", currentTenant);
    props.selectedTenantCode(currentTenant);
  };

  const handleReportBuilderSearchButtonClick = (e) => {
    const errors = [];
    if (!reportBuilderValues.portfolioId) {
      errors.push("Portfolio is required!");
    }
    if (props.propertyRequired && !reportBuilderValues.propertyId) {
      errors.push("Property is required!");
    }
    if (props.unitRequired && !reportBuilderValues.unitId) {
      errors.push("Unit is required!");
    }
    if (showReportPeriod && !reportPeriod) {
      errors.push("Report Period is Required!");
    }
    if (
      showReportPeriod &&
      reportPeriod &&
      reportPeriod !== "All" &&
      !commonReportBuilderValues.startDate
    ) {
      errors.push("From Date is required!");
    }
    if (
      showReportPeriod &&
      reportPeriod &&
      reportPeriod !== "All" &&
      !commonReportBuilderValues.endDate
    ) {
      errors.push("To Date is required!");
    }
    if (showLeaseStatus && !commonReportBuilderValues.leaseStatus) {
      errors.push("Lease Status is required!");
    }
    if (errors.length) {
      swal({
        title: "Error",
        text: errors.join("\n"),
        icon: "error",
        button: "ok",
      });
    } else {
      const reportValues = {
        portfolioId: reportBuilderValues.portfolioId,
        propertyId: reportBuilderValues.propertyId,
        ...(showUnit ? { unitId: reportBuilderValues.unitId } : {}),
        ...(showTenant ? { tenantCode: reportBuilderValues.tenantCode } : {}),
        ...(showReportPeriod
          ? { startDate: commonReportBuilderValues.startDate }
          : {}),
        ...(showReportPeriod
          ? { endDate: commonReportBuilderValues.endDate }
          : {}),
        ...(showLeaseStatus
          ? { leaseStatus: commonReportBuilderValues.leaseStatus }
          : {}),
      };
      console.log("Report Values ", reportValues);
      if (props.handleSearch) {
        props.handleSearch(reportValues);
      }
      if (props.selectedPortfolio) {
        getSelectedPortfoio(reportBuilderValues.portfolioId);
      }
      if (props.selectedProperty) {
        getSelectedProperty(
          reportBuilderValues.portfolioId,
          reportBuilderValues.propertyId
        );
      }
      if (props.selectedUnit && reportBuilderValues.unitId) {
        getSelectedUnit(
          reportBuilderValues.propertyId,
          reportBuilderValues.unitId
        );
      }
      if (props.selectedTenantCode && reportBuilderValues.unitId) {
        getSelectedUnit(
          reportBuilderValues.propertyId,
          reportBuilderValues.unitId
        );
      }
      if (props.selectedTenantCode && reportBuilderValues.tenantCode) {
        getSelectedTenantCode(
          reportBuilderValues.unitId,
          reportBuilderValues.tenantCode
        );
      }
    }
  };

  if (!props.show) {
    return null; // Do not render the component if show is false
  }

  return (
    <>
      <section
        name="Report builder"
        className={
          "grid grid-cols-2 items-center gap-4 px-6 py-4 mt-4 mb-8 bg-slate-100 rounded"
        }
      >
        <span className="font-semibold text-base">REPORT BUILDER:</span>
        {/*  this is a empty span to occupy one column in grid, Dont Remove */}
        <span></span>

        <DetailsTile
          label={
            <p className="flex">
              Portfolio<span className="text-red-600">*</span>
            </p>
          }
          data={
            <InputObjectSelectField
              //label="Portfolio"
              name="portfolioName"
              id="portfolioId"
              notImp
              placeholder="Select Portfolio"
              value={reportBuilderValues.portfolioId}
              onChange={(e) => {
                setPropertiesOptions([]);
                getProperties(parseInt(e.target.value));
                setReportBuilderValues({
                  ...reportBuilderValues,
                  portfolioId: parseInt(e.target.value),
                  propertyId: "",
                  unitId: "",
                  tenantCode: "",
                });
                setUnitsOptions([]);
                setTenantsOptions([]);
              }}
              options={allPortfolios}
              optionValue="ID"
              optionName="portfolioName"
              style=""
              className="w-full"
            />
          }
          labelClassName="justify-self-end flex items-center"
        />

        <DetailsTile
          label={
            <p className="flex">
              Property
              {props.propertyRequired && (
                <span className="text-red-600">*</span>
              )}
            </p>
          }
          data={
            <InputObjectSelectField
              name="property"
              id="property"
              notImp
              placeholder="Select a property"
              value={reportBuilderValues.propertyId}
              onChange={(e) => {
                setUnitsOptions([]);
                getAllUnits(parseInt(e.target.value));
                setReportBuilderValues({
                  ...reportBuilderValues,
                  propertyId: parseInt(e.target.value),
                  unitId: "",
                  tenantCode: "",
                });
                setTenantsOptions([]);
              }}
              options={propertiesOptions}
              optionValue="ID"
              optionName="propertyName"
              style=""
              className="w-full"
            />
          }
          labelClassName="justify-self-end flex items-center"
        />

        {showUnit && (
          <DetailsTile
            label={
              <p className="flex">
                Unit
                {props.unitRequired && <span className="text-red-600">*</span>}
              </p>
            }
            data={
              <InputObjectSelectField
                name="unit"
                id="unit"
                notImp
                placeholder="Select Unit"
                value={reportBuilderValues.unitId}
                onChange={(e) => {
                  setTenantsOptions([]);
                  getAllTenants(parseInt(e.target.value));
                  setReportBuilderValues({
                    ...reportBuilderValues,
                    unitId: parseInt(e.target.value),
                    tenantCode: "",
                  });
                }}
                options={unitsOptions}
                optionValue="ID"
                optionName="unitNumber"
                style=""
                className="w-full"
              />
            }
            labelClassName="justify-self-end flex items-center"
          />
        )}

        {showTenant && (
          <DetailsTile
            label="Tenant"
            data={
              <InputObjectSelectField
                name="tenantCode"
                id="tenantCode"
                notImp
                placeholder="Select Tenant"
                value={reportBuilderValues.tenantCode}
                onChange={(e) => {
                  setReportBuilderValues({
                    ...reportBuilderValues,
                    tenantCode: e.target.value,
                  });
                }}
                options={tenantsOptions}
                optionValue="tenantCode"
                optionName="tenantName"
                className="w-full"
              />
            }
            labelClassName="justify-self-end flex items-center"
          />
        )}

        {showReportPeriod && (
          <>
            <DetailsTile
              label={
                <p className="flex">
                  Report Period<span className="text-red-600">*</span>
                </p>
              }
              data={
                <InputSelectField
                  name="reportPeriods"
                  id="reportPeriods"
                  notImp
                  placeholder="Select Report Period"
                  value={reportPeriod}
                  onChange={(e) => {
                    handleReportPeriodChange(e.target.value);
                  }}
                  options={reportPeriodOptions}
                  className="w-full"
                />
              }
              labelClassName="justify-self-end flex items-center"
            />
            <span className="flex items-end gap-2">
              <label className="flex gap-[12px] items-center">
                <p className="flex">
                  From <span className="text-red-600">*</span>
                </p>

                <DatePicker
                  readOnly={reportPeriod !== "Custom" ? true : false}
                  value={dayjs(commonReportBuilderValues.startDate)}
                  onChange={(e) => {
                    handleCommonReportBuilerValuesOnChange(
                      dayjs(e.$d).format("MM/DD/YYYY"),
                      "startDate"
                    );
                  }}
                  sx={styles.datePicker}
                />
              </label>

              <label className="flex gap-[12px] items-center">
                <p className="flex">
                  To <span className="text-red-600">*</span>
                </p>
                <DatePicker
                  readOnly={reportPeriod !== "Custom" ? true : false}
                  value={dayjs(commonReportBuilderValues.endDate)}
                  onChange={(e) => {
                    handleCommonReportBuilerValuesOnChange(
                      dayjs(e.$d).format("MM/DD/YYYY"),
                      "endDate"
                    );
                  }}
                  sx={styles.datePicker}
                />
              </label>

              <button
                onClick={() => {
                  setCommomReportBuilderValues({
                    ...commonReportBuilderValues,
                    startDate: "",
                    endDate: "",
                  });
                  setReportPeriod("");
                }}
                className="underline text-blue-52 cursor-pointer items-end active:scale-95 transform transition duration-100"
              >
                Reset
              </button>
            </span>
          </>
        )}

        {showLeaseStatus && (
          <DetailsTile
            label={
              <p className="flex">
                Lease Status<span className="text-red-600">*</span>
              </p>
            }
            data={
              <InputSelectField
                name="leaseStatus"
                id="leaseStatus"
                notImp
                placeholder="Select Lease Status"
                value={commonReportBuilderValues.leaseStatus}
                onChange={(e) =>
                  handleCommonReportBuilerValuesOnChange(
                    e.target.value,
                    "leaseStatus"
                  )
                }
                options={["Active", "InActive", "Both"]}
                className="w-full"
              />
            }
            labelClassName="justify-self-end flex items-center"
          />
        )}

        {props.allignSearchButtonRight && <span></span>}

        <span className="flex justify-end items-center gap-4">
          <ButtonCustom
            id="clearAll"
            name="Clear All"
            onClick={handleClearReportBuilerClick}
            className="px-2 py-1 text-center w-24 bg-transparent rounded border-2 text-gray-600"
          />
          <ButtonCustom
            id="search"
            name="Search"
            onClick={handleReportBuilderSearchButtonClick}
            className="px-2 py-1 text-center w-24 text-white bg-[#FFAF09]"
          />
        </span>
      </section>
    </>
  );
};

export default ReportsBuilder;

const styles = {
  datePicker: {
    "& .MuiInputBase-input": {
      padding: "8px",
      fontSize: "14px",
      fontWeight: 300,
    },
    "& .MuiIconButton-root": {
      padding: "8px",
    },
    "& .MuiPickersDay-daySelected": {
      backgroundColor: "#F1F5F9",
      "&:hover": {
        backgroundColor: "#006699",
      },
    },
    "& .MuiPickersDay-current": {
      color: "#F1F5F9",
      fontWeight: 300,
    },
  },
};
