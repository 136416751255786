import React, { useState, useEffect } from "react";
import { WebApimanager } from "../../../WebApiManager";

//Reusable Components
import { Loader } from "../../../Widgets/notificationFeedbacks";
import { ButtonIcon } from "../../../Widgets/Buttons";
import InputField, {
  InputDateField,
  InputSelectField,
  InputFileField,
} from "../../../Widgets/Forms/InputFields";
import { ButtonBlue, ButtonGrayOutline } from "../../../Widgets/Buttons";
import { DateFormatForAPI } from "../../../Widgets/CommonWidgets";

//External Imports
import swal from "sweetalert";
import { Dialog, DialogContent } from "@mui/material";
import { AiOutlineCloseCircle } from "react-icons/ai";

const UpdateEvictionProcess = (props) => {
  //Routines
  let webApi = new WebApimanager();
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: ``,
  });

  //Component Specific
  const [activityOptions, setActivityOptions] = useState([]);
  const [activity, setActivity] = useState("");
  const [activityUpdateDate, setActivityUpdateDate] = useState("");
  const [description, setDescription] = useState("");
  const [files, setFiles] = useState([]);
  const [formErrors, setFormErrors] = useState("");

  //Tenant Response && Court Hearing
  const [tenantResponse, setTenantResponse] = useState("");
  const [courtDate, setCourtDate] = useState("");

  //Judgement
  const [settlementDate, setSettlementDate] = useState("");

  //Writ of Possession
  const [dateIssued, setDateIssued] = useState("");

  //Sheriff Eviction
  const [evictionDate, setEvictionDate] = useState("");
  const [attachwritOfPossession, setAttachWritOfPossession] = useState("");

  useEffect(() => {
    getActivityTypes();
  }, []);

  //Get Activty list API
  const getActivityTypes = (unitID, tenantID) => {
    setApiProcessing({
      loader: true,
      message: `Getting Activity Types...`,
    });
    webApi
      .get(`/eviction/activityTypes`)
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setActivityOptions(res.data.activityTypes);
        }
        setApiProcessing({ loader: false, message: `` });
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage)
          swal("Error", error.customErrorMessage, "error");
        else
          swal(
            "Error",
            "Oops! Unable to get Activity Types, Please try again later!",
            "error"
          );
      });
  };

  const validate = () => {
    const errors = {};

    if (!activity) {
      errors.activity = "Activity is required!";
    }
    if (!activityUpdateDate) {
      errors.activityUpdateDate = "Activity Update Date required!";
    }

    if (activity === "Tenant Response" || activity === "Court Hearing") {
      if (!tenantResponse) {
        errors.tenantResponse = "Tenant Response is required!";
      }
      if (!courtDate) {
        errors.courtDate = "CourtDate is required!";
      }
    }

    if (activity === "Judgement") {
      if (!settlementDate) {
        errors.settlementDate = "Settlement Date is required! ";
      }
    }

    if (activity === "Writ of Possession") {
      if (!dateIssued) {
        errors.dateIssued = "Date Issued is required!";
      }
    }

    if (activity === "Sheriff Eviction") {
      if (!evictionDate) {
        errors.evictionDate = "Eviction Date is required!";
      }
      if (!attachwritOfPossession) {
        errors.attachwritOfPossession = "Writ Of Possession is required!";
      }
      if (!files.length) {
        errors.files = "Eviction Photos are required!";
      }
    }

    return errors;
  };

  const handleConfirm = (e) => {
    e.preventDefault();
    const temp = validate();
    setFormErrors(temp);
    if (Object.keys(temp).length) {
      return;
    } else {
      handleUpdateEviction();
    }
  };

  const handleUpdateEviction = () => {
    setApiProcessing({
      loader: true,
      message: `Updating Eviction...`,
    });

    let tempDocsNames = [];
    for (var i = 0; i < files.length; i++) {
      tempDocsNames.push({ fileName: files[i].name });
    }

    let data = {
      tenantCode: props.tenantCode,
      evId: props.evictionID,
      activity: activity,
      activityUpdateDate: DateFormatForAPI(activityUpdateDate),
      description: description,
      documents: files.length ? tempDocsNames : [],
    };

    if (activity === "Tenant Response" || activity === "Court Hearing") {
      data.tenantResponse = tenantResponse;
      data.date = DateFormatForAPI(courtDate);
    }

    if (activity === "Judgement") {
      data.date = DateFormatForAPI(settlementDate);
    }

    if (activity === "Writ of Possession") {
      data.date = DateFormatForAPI(dateIssued);
    }

    if (activity === "Sheriff Eviction") {
      data.date = DateFormatForAPI(evictionDate);
      data.writOfPossession = attachwritOfPossession
        ? { fileName: attachwritOfPossession.name }
        : "";
    }

    webApi
      .post("/eviction/evictionUpdate", data)
      .then(async (res) => {
        if (res.data.errorCode) {
          setApiProcessing({ loader: false, message: `` });
          swal("Error", res.data.errorMessage, "error");
        } else {
          if (res.data.documents.length) {
            if (activity === "Sheriff Eviction") {
              const newResponse = {
                ...res.data,
                documents: [...res.data.documents, res.data.writOfPossession],
              };
              handleDocUpload(newResponse, [...files, attachwritOfPossession]);
            } else {
              handleDocUpload(res.data, files);
            }
          } else {
            handleEvictionUpdateSuccess(res.data);
          }
        }
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to Update ${data.activity}, Please try again later.`,
            "error"
          );
        }
      });
  };

  const handleDocUpload = (data, selectedFiles) => {
    let failedtoUploadCount = 0;
    let successUploadCount = 0;
    setApiProcessing({ loader: true, message: `Uploading Files...` });
    const uploadPromises = selectedFiles.map(async (file, i) => {
      const metaData = {
        filename: file.name,
        mediatype: file.type,
      };

      try {
        const res = await webApi.uploadFile(
          data.documents[i].prefix,
          metaData,
          file
        );
        return successUploadCount++;
      } catch (error) {
        failedtoUploadCount++;
      }
    });

    Promise.all(uploadPromises)
      .then((results) => {
        if (failedtoUploadCount !== selectedFiles.length) {
          failedtoUploadCount
            ? swal(
                "Info",
                `Successfully Uploaded ${
                  successUploadCount / selectedFiles.length
                } Files!`,
                "success"
              )
            : swal("Success", "Files Upload has been successful!", "success");
          handleEvictionUpdateSuccess(data);
        } else {
          swal(
            "Failure",
            `Failed to Upload ${failedtoUploadCount} Files, Please try again Later!`,
            "error"
          );
        }
        setApiProcessing({ loader: false, message: `` });
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
      });
  };

  const handleEvictionUpdateSuccess = (data) => {
    swal("Success", `${data.activity} Updated successfully!`, "success");
    props.handleUpdateEvictionCallback(data);
    props.setTrigger(false);
  };

  return (
    <>
      <Dialog
        open={true}
        aria-labelledby="Eviction Update Form"
        fullWidth
        maxWidth="lg"
        className="flex flex-col justify-center w-5/6 mx-auto"
      >
        <Loader open={apiProcessing.loader} message={apiProcessing.message} />

        <div className="flex justify-center items-center bg-blue-52 text-white font-semibold rounded-sm h-10 pr-2">
          <span className="flex-grow text-center">Eviction Update</span>
          <ButtonIcon
            title="Close"
            onClick={(e) => props.setTrigger(false)}
            icon={<AiOutlineCloseCircle className="w-5 h-5" />}
            className="hover:!text-red-600"
          />
        </div>
        <DialogContent dividers>
          <section
            className={"flex flex-col justify-center items-center w-full my-4"}
          >
            <span className="border-b-2 border-blue-52 pb-1 mb-4 text-blue-52 w-5/6 md:gap-x-8 md:w-11/12">
              Update Details
            </span>
            <div className="grid grid-cols-2 items-start gap-x-16 gap-y-4 w-5/6 mb-4 md:gap-x-8 md:w-11/12">
              <InputSelectField
                label="Activity"
                name="activity"
                id="propertyactivityType"
                dataCy="activity"
                placeholder="Choose a Activity"
                value={activity}
                onChange={(e) => setActivity(e.target.value)}
                options={activityOptions}
                errorText={formErrors.activity}
                style="!w-full"
              />

              <InputDateField
                label="Activity Update Date"
                name="activityUpdateDate"
                id="activityUpdateDate"
                btnName="activityUpdateDate"
                dataCy="activityUpdateDate"
                value={activityUpdateDate}
                onChange={(value) => setActivityUpdateDate(value)}
                style="!w-full"
                errorText={formErrors.activityUpdateDate}
              />

              {(activity === "Tenant Response" ||
                activity === "Court Hearing") &&
                TenantResponseField(
                  tenantResponse,
                  setTenantResponse,
                  ["Yes", "No"],
                  formErrors.tenantResponse
                )}

              {(activity === "Tenant Response" ||
                activity === "Court Hearing") &&
                DateFields(
                  "Court Date",
                  "courtDate",
                  courtDate,
                  setCourtDate,
                  formErrors.courtDate
                )}

              {activity === "Judgement" &&
                DateFields(
                  "Settlement Date",
                  "settlementDate",
                  settlementDate,
                  setSettlementDate,
                  formErrors.settlementDate
                )}

              {activity === "Writ of Possession" &&
                DateFields(
                  "Date Issued",
                  "dateIssued",
                  dateIssued,
                  setDateIssued,
                  formErrors.dateIssued
                )}

              {activity === "Sheriff Eviction" &&
                DateFields(
                  "Eviction Date",
                  "evictionDate",
                  evictionDate,
                  setEvictionDate,
                  formErrors.evictionDate
                )}

              {activity === "Sheriff Eviction" && (
                <InputFileField
                  label="Attach Writ of Possession"
                  name="file"
                  id="file"
                  dataCy="file"
                  accept=".pdf"
                  maxSizeinMB={10}
                  placeholder="Attach .pdf format only"
                  value={attachwritOfPossession}
                  setValue={setAttachWritOfPossession}
                  hintText="*File Size should be less than 10MB"
                  style="!w-full"
                  errorText={formErrors.attachwritOfPossession}
                />
              )}

              <InputField
                label="Description"
                name="description"
                id="description"
                dataCy="description"
                type="text"
                notImp
                placeholder="Enter Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                errorText={formErrors.description}
                style="!w-full"
              />

              <InputFileField
                label={
                  activity === "Sheriff Eviction"
                    ? "Attach Eviction Photos"
                    : "Attach Documents"
                }
                name="file"
                id="file"
                dataCy="file"
                accept={
                  activity === "Sheriff Eviction"
                    ? ".png, .jpg,"
                    : ".png, .jpg, .pdf"
                }
                maxSizeinMB={10}
                notImp={activity === "Sheriff Eviction" ? false : true}
                multiple
                placeholder={
                  activity === "Sheriff Eviction"
                    ? "Attach .jpg, .png format only"
                    : "Attach .png, .jpg, .pdf format only"
                }
                value={files}
                setValue={setFiles}
                hintText="*Files Size should be less than 10MB"
                style="!w-full"
                errorText={formErrors.files}
              />

              {/* {activity === "Sheriff Eviction" && (
                <InputFileField
                  label="Attach Eviction Photos"
                  name="file"
                  id="file"
                  dataCy="file"
                  accept=".png, .jpg, .jpeg"
                  maxSizeinMB={10}
                  multiple
                  placeholder="Select Photos"
                  value={files}
                  setValue={setFiles}
                  hintText="*File Size should be less than 10MB"
                  style="!w-full"
                  errorText={formErrors.files}
                />
              )} */}
            </div>
            <div className="flex justify-center items-center gap-16 md:gap-8 my-6">
              <ButtonGrayOutline
                name="Cancel"
                btnName="cancel"
                dataCy="cancel"
                onClick={() => props.setTrigger(false)}
                className="mt-6 justify-self-end"
              />
              <ButtonBlue
                name="Confirm"
                btnName="Confirm"
                dataCy="Confirm"
                onClick={(e) => handleConfirm(e)}
                className="mt-6"
              />
            </div>
          </section>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UpdateEvictionProcess;

const TenantResponseField = (data, setData, options, error) => (
  <>
    <InputSelectField
      label="Tenant Response"
      name="tenantResponse"
      id="tenantResponse"
      dataCy="tenantResponse"
      placeholder="Select"
      value={data}
      onChange={(e) => setData(e.target.value)}
      options={options}
      errorText={error}
      style="!w-full"
    />
  </>
);

const DateFields = (title, id, data, setData, error) => (
  <>
    <InputDateField
      label={title}
      name={id}
      id={id}
      btnName={id}
      dataCy={id}
      value={data}
      onChange={(value) => setData(value)}
      style="!w-full"
      errorText={error}
    />
  </>
);
