import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { WebApimanager } from "../../WebApiManager";
import { useRecoilValue } from "recoil";
import { userData } from "../../atoms";
import swal from "sweetalert";

import { Loader } from "../../Widgets/notificationFeedbacks";
import AddLeaseAmendment from "./addLeaseAmendment";
import { DetailsTile } from "../../Widgets/Forms/FormReviewTiles";
import { IconInCircle } from "../../Widgets/Icons";
import {
  GetDateInMMDDYYYY,
  GetTimeYearToMin,
  clickEffect,
} from "../../Widgets/CommonWidgets";
import { PDFViewer, UploadNewFile } from "../Documents/documents";
import { Dialog } from "@mui/material";
import {
  ButtonBlue,
  ButtonGrayOutline,
  ButtonCustom,
} from "../../Widgets/Buttons";
import {
  InputTextArea,
  InputSelectField,
} from "../../Widgets/Forms/InputFields";

import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { IoDocumentTextOutline } from "react-icons/io5";
import { PiClipboardText } from "react-icons/pi";
import { RiDeleteBin5Line } from "react-icons/ri";
import { FiDownload } from "react-icons/fi";
import { AiOutlineMessage } from "react-icons/ai";
import { SlPaperPlane } from "react-icons/sl";
import { BsExclamationOctagon } from "react-icons/bs";
import { ButtonIcon } from "../../Widgets/Buttons";
import { TiEdit } from "react-icons/ti";
import { FiPaperclip } from "react-icons/fi";
import { FileDisplay } from "../../Widgets/Forms/FormReviewTiles";

const LeaseDetails = () => {
  let navigate = useNavigate();
  let webApi = new WebApimanager();
  let location = useLocation();
  const [lease, setLease] = useState(location.state.data);
  const userInfo = useRecoilValue(userData);

  const [allComments, setAllComments] = useState([]);
  const [openLeaseAmendForm, setOpenLeaseAmendForm] = useState(false);
  const [filesURL, setFilesURL] = useState([]);

  const [openLeaseEndForm, setOpenLeaseEndForm] = useState(false);
  const [openDocUploadForm, setOpenDocUploadForm] = useState(false);

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: ``,
  });
  //Documents Loader
  const [docLoader, setDocLoader] = useState(false);
  const [pendingRequests, setPendingRequests] = useState(0);

  //Custom Conditional Checks
  const canTermiante =
    lease && lease.leaseAmendmentStatus === "Executed" ? true : false;
  const canCancel =
    lease &&
    (lease.leaseAmendmentStatus === "Created" ||
      lease.leaseAmendmentStatus === "Executing" ||
      lease.leaseAmendmentStatus === "Accepted")
      ? true
      : false;

  useEffect(() => {
    getLeaseDetails();
    getLeaseComments();
    getLeaseDocumentsPath(lease.ID);
  }, []);

  //API to get Lease Details
  const getLeaseDetails = () => {
    setApiProcessing({ loader: true, message: `Gathering Lease Details...` });
    webApi
      .get("/leaseAmendments/" + lease.ID)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setLease(res.data);
        }
        setApiProcessing({ loader: false, message: `` });
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops, unable to get Lease Details! Please try again later.",
            "error"
          );
        }
        setApiProcessing({ loader: false, message: `` });
      });
  };

  //Comments API's
  const getLeaseComments = () => {
    setApiProcessing({ loader: true, message: `` });
    webApi
      .get(`/comments/leaseAmendments/${lease.ID}`)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setAllComments(res.data);
        }
        setApiProcessing({ loader: false, message: `` });
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to get Comments, Please try again later.`,
            "error"
          );
        }
        setApiProcessing({ loader: false, message: `` });
      });
  };

  const addComment = (comment) => {
    let data = {
      leaseAmendmentID: lease.ID,
      role: userInfo.userRole,
      roleID: userInfo.businessAddress.ClientId,
      comment: comment,
    };

    webApi
      .post("/leaseAmendments/comments", data)
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setAllComments([...allComments, res.data]);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to add Comment, Please try again later.`,
            "error"
          );
        }
      });
  };

  //Cancel or Terminate Lease
  const handleCancelorTerminateLease = (request) => {
    setApiProcessing({
      loader: true,
      message:
        request.action === "Terminate"
          ? `Terminating the Lease...`
          : `Cancelling the Lease...`,
    });
    webApi
      .patch(`/leaseAmendments/renewal/action`, request)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          swal(
            `Success`,
            request.action === "Terminate"
              ? `Successfully Terminated the Lease.`
              : `Successfully Cancelled the Lease.`,
            "success"
          );
          getLeaseDetails();
        }
        setApiProcessing({ loader: false, message: `` });
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            request.action === "Terminate"
              ? `Failed to Terminate the Lease`
              : `Failed to Cancel the Lease`,
            +`, Please try again later!`,
            "error"
          );
        }
      });
  };

  //Lease Documents API's
  const handleLeaseDocuments = (file) => {
    setApiProcessing({ loader: true, message: `Preparing File to Upload...` });
    webApi
      .put(`/leaseAmendments/documents/${lease.ID}&${file.name}`)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
          setApiProcessing({ loader: false, message: `` });
        } else {
          uploadLeaseDocuments(res.data.objectKey, file);
        }
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Failed to upload file ${file.name}. Please try again later.`,
            "error"
          );
        }
      });
  };

  const uploadLeaseDocuments = (filePath, file) => {
    setApiProcessing({ loader: true, message: `Uploading Lease Agreement...` });
    const data = {
      objectKey: `${filePath}`,
      metadata: {
        filename: file.name,
        mediatype: file.type,
      },
    };

    webApi
      .uploadFile(data.objectKey, data.metadata, file)
      .then((res) => {
        if (res.status === 200) {
          //If there are pre-uploaded files then its safe to say the IsDocumentUploaded flag is set to "true".
          if (filesURL.length) {
            getLeaseDocumentsPath(lease.ID);
            swal("Success", "File Upload is successful", "success");
            setApiProcessing({ loader: false, message: `` });
          } else {
            //If no Pre-uploaded files then call the function to set the flag - IsDocumentUploaded to "true".
            updateBackendAboutLeaseAgreementUpload(file);
          }
        }
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Failed to upload file ${file.name}. Please try again later.`,
            "error"
          );
        }
      });
  };

  const updateBackendAboutLeaseAgreementUpload = (file) => {
    setApiProcessing({
      loader: true,
      message: `Adding Agreement to Amendment...`,
    });
    // let data = {
    //   ...lease,
    //   isDocumentUploaded: true,
    // };

    webApi
      .patch(`/leaseAmendments/documents/${lease.ID}`)
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
          setApiProcessing({ loader: false, message: `` });
        } else {
          getLeaseDocumentsPath(lease.ID);
          swal("Success", `Lease Amendment Updated successfully`, "success");
        }
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Failed to Update Lease Amendment! Please try again later.",
            "error"
          );
        }
      });
  };

  const getLeaseDocumentsPath = (amendmentId) => {
    setApiProcessing({ loader: true, message: `Gathering Documents ...` });
    setFilesURL([]);
    webApi
      .get(`/leaseAmendments/documents/${amendmentId}`)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          if (res.data.objectKey.length) {
            res.data.objectKey.forEach((element) => {
              getFileLink(element);
              setPendingRequests((prevCount) => prevCount + 1);
            });
          }
        }
        setApiProcessing({ loader: false, message: `` });
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else
          swal(
            "Error",
            "Oops, Unable to get the File! Please try again later.",
            "error"
          );
      });
  };

  const getFileLink = (prefix) => {
    setDocLoader(true);

    const data = {
      objectKey: `${prefix}`,
    };

    webApi
      .post(`/documents/download`, data)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setFilesURL([
            ...filesURL,
            {
              name: res.data.metadata.filename,
              objectKey: prefix,
              url: res.data.objectKey,
            },
          ]);
        }
        DocLoaderCounter();
      })
      .catch((error) => {
        DocLoaderCounter();
        if (error.customErrorMessage)
          swal("Error", error.customErrorMessage, "error");
        else swal("Error", `Oops! Unable to get document`, "error");
      });
  };

  const DocLoaderCounter = () => {
    if (pendingRequests > 1) {
      setPendingRequests((prevCount) => prevCount - 1);
    } else {
      setDocLoader(false);
    }
  };

  const handleDocDownload = (file) => {
    window.location.href = file.url;
  };

  const handleDocDelete = (file) => {
    swal({
      title: "Are you sure?",
      text: `You intend to delete the file ${file.name}!`,
      icon: "warning",
      buttons: ["Cancel", "Delete!"],
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        let data = { objectKeys: [file.objectKey] };
        webApi
          .delete(`/documents/delete`, data)
          .then((res) => {
            if (res.data[file.name] === "Document Deleted successfully") {
              getLeaseDocumentsPath(lease.ID);
              swal(
                "Success",
                `Document ${file.name} Deleted successfully`,
                "success"
              );
            } else {
              swal("Failure", res.data[file.name], "error");
            }
          })
          .catch((error) => {
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                `Failed to Delete file named ${file.name}, Please try again later!`,
                "error"
              );
            }
          });
      } else {
        return;
      }
    });
  };

  return (
    <>
      <Loader open={apiProcessing.loader} message={apiProcessing.message} />

      {openLeaseAmendForm && (
        <AddLeaseAmendment
          trigger={openLeaseAmendForm}
          setTrigger={setOpenLeaseAmendForm}
          leaseId={lease && lease.leaseId}
          operation={"Edit"}
          editData={lease}
          getAllLeaseAmendments={(data) => {
            setLease(data);
          }}
        />
      )}

      {openLeaseEndForm && (
        <TerminateCancelLease
          trigger={openLeaseEndForm}
          setTrigger={setOpenLeaseEndForm}
          amendment={lease}
          handleTerminateCancel={handleCancelorTerminateLease}
        />
      )}

      <UploadNewFile
        trigger={openDocUploadForm}
        setTrigger={setOpenDocUploadForm}
        title="Upload Document"
        accept=".pdf"
        placeholder="Attach .pdf format only"
        handleUpload={(file) => handleLeaseDocuments(file)}
      />

      <div className={"w-5/6 m-auto"}>
        <section className="h-12 bg-[#3D72BF] flex justify-between items-center text-white mb-12 px-2">
          <span className="flex items-center gap-2">
            <BsFillArrowLeftCircleFill
              id="backToPrevious"
              name="backToPrevious"
              data-cy="backToPrevious"
              title="Back to Previous Page"
              onClick={(e) => {
                navigate(-1);
              }}
              className="w-6 h-6 cursor-pointer"
            />
            Tenant : {lease.tenantName}
          </span>

          {lease.leaseAmendmentStatus === "Executing" ||
          lease.leaseAmendmentStatus === "Created" ? (
            <ButtonIcon
              title="Edit"
              onClick={(e) => setOpenLeaseAmendForm(true)}
              icon={<TiEdit className="w-4 h-4" />}
              className="hover:!text-blue-52"
            />
          ) : (
            <span></span>
          )}
        </section>

        <div className="grid grid-cols-2 gap-[10%] mb-12">
          <section className="flex flex-col justify-start">
            <div name="Info" className="flex justify-start items-center gap-2">
              <IconInCircle
                icon={<IoDocumentTextOutline className="w-9 h-9 text-white" />}
                radius="4rem"
                color="#006DBA"
              />
              <span className="gap-1">
                <p className="text-blue-52 font-semibold">
                  {lease.leaseType || "-"}
                </p>

                <DetailsTile
                  label="Lease End Date"
                  data={GetDateInMMDDYYYY(lease.leaseEndDate) || "-"}
                  dataClassName="font-medium"
                  labelClassName="text-gray-500"
                  className="gap-4"
                />

                <DetailsTile
                  label="Status"
                  data={lease.leaseAmendmentStatus || "N/A"}
                  dataClassName="font-medium"
                  labelClassName="text-gray-500"
                  className="gap-4"
                />
              </span>
            </div>

            <div name="Documents" className="mt-8  w-full">
              <Loader open={docLoader} />
              <span className="flex justify-start items-center border-b-2 border-[#42AACB] mb-4">
                Documents
              </span>

              {filesURL.map((file, index) => (
                <FileDisplay
                  key={index + file.name}
                  file={file}
                  fileNameKey="name"
                  fileUrlKey="url"
                  onDelete={handleDocDelete}
                  onDownload={handleDocDownload}
                />
              ))}

              <span className="flex justify-center">
                <ButtonCustom
                  btnName="addDocuments"
                  dataCy="addDocuments"
                  id="addDocuments"
                  name={
                    <>
                      <FiPaperclip className="mr-1" /> Add Documents
                    </>
                  }
                  onClick={(e) => setOpenDocUploadForm(true)}
                  className="flex justify-center items-center mt-2 rounded-md h-10 px-2 text-[#3D72BF] border border-[#3D72BF] font-semibold hover:bg-[#3D72BF] hover:text-white focus:bg-[#3D72BF] focus:text-white"
                />
              </span>
            </div>
            <div name="Comments" className="mt-8">
              <LeaseCommentsWidget
                borderClassName="border-[#42AACB]"
                comments={allComments}
                handleSend={addComment}
                userRole={userInfo.userRole}
              />
            </div>
          </section>

          <section className="flex flex-col justify-start">
            <div
              name="Details"
              className="border box-border h-fit px-6 overflow-hidden"
            >
              {canTermiante || canCancel ? (
                <DetailsTile
                  label="Status:"
                  data={
                    <select
                      name="cancelOptionDropDown"
                      id="cancelOptionDropDown"
                      data-cy="cancelOptionDropDown"
                      value={lease.leaseAmendmentStatus}
                      onChange={(e) => {
                        setOpenLeaseEndForm(true);
                      }}
                      className={
                        " text-sm py-2 px-4 rounded text-white font-semibold bg-blue-53 w-fit cursor-pointer"
                      }
                    >
                      <option
                        key={lease.leaseAmendmentStatus}
                        value={lease.leaseAmendmentStatus}
                        data-cy={lease.leaseAmendmentStatus}
                      >
                        {lease.leaseAmendmentStatus}
                      </option>
                      <option
                        key="cancelorTerminate"
                        value={canTermiante ? "Terminate" : "Cancel"}
                        data-cy="cancelorTerminate"
                      >
                        {canTermiante ? "Terminate" : "Cancel"}
                      </option>
                    </select>
                  }
                  className="mb-4 border-b-2 my-4 pb-2"
                />
              ) : (
                <DetailsTile
                  label="Status:"
                  data={lease.leaseAmendmentStatus}
                  className="mb-4 border-b-2 my-4 pb-2"
                  dataClassName="text-sm py-2 px-4 rounded text-white font-semibold bg-blue-53 w-fit"
                />
              )}

              <DetailsTile
                label="Lease Effective Date"
                data={GetDateInMMDDYYYY(lease.leaseStartDate)}
                className="mb-4"
              />

              <DetailsTile
                label="Lease End Date"
                data={GetDateInMMDDYYYY(lease.leaseEndDate)}
                className="mb-4"
              />

              <DetailsTile
                label="Lease Duration"
                data={lease.leaseDuration}
                className="mb-4"
              />

              <DetailsTile
                label="Deposit"
                data={"$" + lease.deposit.toLocaleString()}
                className="mb-4"
              />

              <DetailsTile
                label="Monthly Rent"
                data={"$" + lease.rent.toLocaleString()}
                className="mb-4"
              />

              <DetailsTile
                label="Pet Deposit"
                data={"$" + lease.petDeposit.toLocaleString()}
                className="mb-4"
              />

              <DetailsTile
                label="Monthly Pet Fee"
                data={"$" + lease.petFee.toLocaleString()}
                className="mb-4"
              />
            </div>

            <div
              name="Lease Activity"
              className="border box-border mt-6 h-fit px-6 overflow-hidden"
            >
              <span className="flex justify-Start items-center py-2">
                <p className="font-semibold">Lease Activity</p>
              </span>
              <LeaseStatus data={lease} />
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default LeaseDetails;

//Lease Comments Widget
const LeaseCommentsWidget = (props) => {
  const [comments, setComments] = useState(props.comments);
  const [newComment, setNewComment] = useState("");
  const [sorting, setSorting] = useState("NewestFirst");

  const setScrollBar = () => {
    const commentsWidget = document.getElementById("CommentsViewScreen");
    commentsWidget.scrollTop = commentsWidget.scrollHeight;
  };

  if (comments.length !== props.comments.length) {
    if (sorting === "NewestFirst") {
      setComments(props.comments.slice().reverse());
    } else {
      setComments(props.comments);
    }
    setScrollBar();
  }

  const handleConfirm = (event) => {
    event.preventDefault();
    if (newComment.trim() !== "") {
      props.handleSend(newComment);
    }
    setNewComment("");
  };

  const handleKeydDown = (event) => {
    if (event.key === "Enter") {
      handleConfirm(event);
    }
  };

  const handleSorting = (e) => {
    e.preventDefault();
    setSorting(e.target.value);
    if (e.target.value === "OldestFirst") {
      setComments(props.comments);
    } else {
      setComments(props.comments.slice().reverse());
    }
  };

  return (
    <>
      <span
        className={
          props.borderClassName +
          " flex justify-between items-center border-b-2 mb-2"
        }
      >
        Comments
        <select
          name="CommentsViewSort"
          id="CommentsViewSort"
          value={sorting}
          onChange={handleSorting}
          className={
            props.selectClassName +
            " text-sm bg-transparent p-2 hover:bg-slate-100 rounded"
          }
        >
          <option key="NewestFirst" value="NewestFirst">
            {" "}
            Newest First
          </option>
          <option key="OldestFirst" value="OldestFirst">
            {" "}
            Oldest First
          </option>
        </select>
      </span>
      <div className="flex flex-col items-center justify-center gap-4">
        <div
          name="CommentsViewScreen"
          id="CommentsViewScreen"
          className="overflow-y-auto overscroll-contain max-h-96 w-full"
        >
          {comments.map((comment, index) => (
            <span
              key={index}
              className="flex justify-center items-center gap-4 mb-4"
            >
              <IconInCircle
                icon={comment.role[0] || "X"}
                radius={props.iconRadius || "2rem"}
                color={comment.role === "Tenant" ? "#3661A9" : "#0A875C"}
                className="text-white text-lg"
              />
              <span className="flex flex-col w-[90%] text-base">
                <span className="flex justify-between items-center text-sm">
                  <p className="font-normal">
                    {comment.role + ": " + comment.name}
                  </p>
                  <p className="text-[#BDBABA] ml-auto">
                    {GetTimeYearToMin(comment.UpdatedAt)}
                  </p>
                </span>
                <p className="font-light bg-[#E8EAF6] rounded p-2 break-words">
                  {comment.comment}
                  {/* <MdDeleteForever
                      onClick={(e) => handleDelete(e, comment.ID)}
                      className="float-right w-4 h-4 hover:text-red-10 cursor-pointer my-auto"
                    /> */}
                </p>
              </span>
            </span>
          ))}
        </div>

        <span className="flex justify-center items-center gap-4 w-full">
          <IconInCircle
            icon={props.userRole[0] || <AiOutlineMessage />}
            radius={props.iconRadius || "2rem"}
            color={"#808080"}
            className={"text-white text-lg saturate-50"}
          />
          <span className="flex justify-end items-center w-[90%]">
            <input
              className={
                props.inputClassName +
                ` appearance-none rounded p-2 text-gray-700 leading-tight focus:outline-none border w-full pr-[35px]`
              }
              name="newComment"
              id="newComment"
              data-cy="newComment"
              type="text"
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              onKeyDown={handleKeydDown}
            />
            <SlPaperPlane
              onClick={handleConfirm}
              className="w-5 h-5 absolute cursor-pointer mr-1"
            />
          </span>
        </span>
      </div>
    </>
  );
};

// View Lease Status
const LeaseStatus = (props) => {
  let webApi = new WebApimanager();
  const [comments, setComments] = useState([]);
  const [apiProcessing, setApiProcessing] = useState(false);

  useEffect(() => {
    getLeaseInfo();
  }, []);

  const getLeaseInfo = () => {
    setApiProcessing(true);
    webApi
      .get(`/leaseAmendments/status/history/${props.data.ID}`)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setComments(res.data.reverse());
        }
        setApiProcessing(false);
      })
      .catch((error) => {
        setApiProcessing(false);
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops, unable to get Lease Activity! Please try again later.",
            "error"
          );
        }
      });
  };

  const Tile = (props) => {
    return (
      <>
        <span hidden={props.hidden} className="flex items-center gap-1">
          <p className="text-gray-500 font-semibold">{props.title || "-"}</p>
          <p className="">{props.body || "-"}</p>
        </span>
      </>
    );
  };

  return (
    <>
      <Loader open={apiProcessing} />

      <div className="overflow-y-auto overscroll-contain max-h-96 w-full">
        {comments.length > 0 ? (
          comments.map((comment, index) => (
            <React.Fragment key={index}>
              <div className="gap-1 border-t-2 pt-2">
                <span className="flex justify-end">
                  <p className="font-medium text-gray-500">
                    {GetTimeYearToMin(comment.CreatedAt) || "-"}
                  </p>
                </span>

                <Tile title="Tenant :" body={comment.tenantName || "-"} />
                <Tile title="Status :" body={comment.toStatus || "-"} />

                <p className="text-gray-500 pb-2">{comment.note || "-"}</p>
              </div>
            </React.Fragment>
          ))
        ) : (
          <div className="border-t-2 mt-2 text-gray-500">No Info to show!</div>
        )}
      </div>
    </>
  );
};

//Terminate or Cancel Lease
const TerminateCancelLease = (props) => {
  const [note, setNote] = useState("");
  const isTermination =
    props.amendment.leaseAmendmentStatus === "Active" ||
    props.amendment.leaseAmendmentStatus === "Executed"
      ? true
      : false;

  const confirm = (e) => {
    e.preventDefault();
    if (!note) {
      swal(
        "Warning",
        `Please Enter the reason for ${
          isTermination ? "Lease Termination !" : "Lease Cancellation !"
        }`,
        "warning"
      );
    } else {
      const request = {
        amendmentId: props.amendment.ID,
        action: isTermination ? "Terminate" : "Cancel",
        actorId: props.amendment.createdByID,
        note: note,
      };
      props.handleTerminateCancel(request);
      setNote("");
      props.setTrigger(false);
    }
  };

  return (
    <>
      <Dialog
        open={props.trigger}
        aria-labelledby="Lease Termination"
        fullWidth
        maxWidth="sm"
        PaperProps={{ sx: { height: "auto" } }}
        scroll="body"
        className="mx-auto w-full rounded"
      >
        <span className="flex justify-center items-center mb-6 bg-blue-52 text-white font-semibold text-center rounded-sm h-10">
          {isTermination ? "Lease Termination" : "Lease Cancellation"}
        </span>

        <section className="flex flex-col justify-center items-center">
          <p className="mx-auto">
            Do you want to {isTermination ? "Terminate" : "Cancel"} the Lease?
          </p>
          <p className="mx-auto">
            Briefly explain your reason for this{" "}
            {isTermination ? "Termination" : "Cancellation"}.
          </p>

          <InputTextArea
            //label="Note"
            name="note"
            notImp
            id="note"
            dataCy="note"
            type="text"
            placeholder="Enter Reason here..."
            value={note}
            onChange={(e) => setNote(e.target.value)}
            //errorText={errors.note}
            style="!mt-4 !mb-6"
            className="h-16 rounded-lg"
          />

          <div className="flex gap-4 items-center mb-4">
            <ButtonGrayOutline
              id="cancel"
              btnName="cancel"
              dataCy="cancel"
              name="Cancel"
              onClick={(e) => {
                setNote("");
                props.setTrigger(false);
              }}
              className="justify-self-end"
            />
            <ButtonBlue
              id="confirm"
              btnName="confirm"
              dataCy="confirm"
              name={"Confirm"}
              onClick={(e) => confirm(e)}
              className=""
            />
          </div>
        </section>
      </Dialog>
    </>
  );
};
