import React, { useState, useEffect } from "react";
import { WebApimanager } from "../../WebApiManager";
import {
  TableComponent,
  Searchbar,
  GetDateInMMDDYYYY,
} from "../../Widgets/CommonWidgets";
import { ButtonCustom, ButtonBlue } from "../../Widgets/Buttons";
import { MdUpdate } from "react-icons/md";
import { FaFileExport } from "react-icons/fa";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AiOutlineBank } from "react-icons/ai";
import { GoPencil } from "react-icons/go";
import { clientId, companyId, userData } from "../../atoms";
import { usePlaidLink } from "react-plaid-link";
import { Loader } from "../../Widgets/notificationFeedbacks";
import swal from "sweetalert";
import { useRecoilValue } from "recoil";
import dayjs from "dayjs";
import EditBankAccount from "./editBankAccount";
import { CircularProgress, useStepContext } from "@mui/material";
import InputField, {
  InputFileField,
  InputSelectField,
  InputObjectSelectField,
} from "../../Widgets/Forms/InputFields";
import {
  TablePagination,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from "@mui/material";
import { AddAccount } from "./Chart/chartofAccounts";
import { FaCaretRight } from "react-icons/fa";

const Accounting = (props) => {
  let webApi = new WebApimanager();
  const [tableData, setTableData] = useState([]);
  const [allTransactions, setAllTransactions] = useState([]);
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const clientIdVal = useRecoilValue(clientId);
  const companyIDval = useRecoilValue(companyId);
  const [linkToken, setLinkToken] = useState("");
  const [linkedBanks, setLinkedBanks] = useState([]);
  const [bankName, setBankName] = useState("");
  const [commonReportBuilderValues, setCommomReportBuilderValues] = useState({
    startDate: "",
    endDate: "",
  });
  const [openEditForm, setOpenEditForm] = useState(false);
  const [editData, setEditData] = useState("");
  const [selectedCardIndex, setSelectedCardIndex] = useState("");
  const [filterType, setFilterType] = useState();
  const [openTab, setOpenTab] = useState(1);
  const reportPeriodOptions = [
    "Custom",
    "Last 3 Months",
    "Last 6 Months",
    "Last Year",
    "Year to Date",
    "All",
  ];
  const [activeMenuItem, setActiveMenuItem] = useState("bankTransactions");
  const tabs = [{ label: "Bank Transactions", nickName: "bankTransactions" }];
  const [openAddAccountForm, setOpenAddAccountForm] = useState(false);
  const [currAccID, setCurrAccID] = useState("");
  const userInfo = useRecoilValue(userData);

  useEffect(() => {
    setExpandedRow(null);
    setApiProcessing({
      loader: true,
      message: `Gathering Accounting details...`,
    });
    getToken();
    getLinkedBanks();
    getAllVendorsTenants();
    getAllChartofAccs();
    getPortfolios();
    getChartOfAccountTypes();
  }, []);

  const getToken = () => {
    setApiProcessing({
      loader: true,
      message: `Loading...`,
    });
    webApi
      .get("/accounting/client/" + clientIdVal + "/linkToken")
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          //console.log("token api", res.data);
          setLinkToken(res.data.link_token);
        }
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops! Failed to get Token, Please try again later.",
            "error"
          );
        }
      });
  };

  const handleCommonReportBuilerValuesOnChange = (value, accessor) => {
    setCommomReportBuilderValues({
      ...commonReportBuilderValues,
      [accessor]: value,
    });
  };

  const getLinkedBanks = () => {
    setApiProcessing({
      loader: true,
      message: `Gathering linked bank details...`,
    });
    webApi
      .get("/accounting/client/" + clientIdVal + "/banksDetails")
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          //console.log("linked banks api", res.data);
          if (res.data.length) {
            // getForReviewData(
            //   res.data[res.data.length - 1].accountID
            // );
            setBankName(
              res.data[res.data.length - 1].name ||
                res.data[res.data.length - 1].accountName
            );
            setLinkedBanks(res.data.reverse());
          }
        }
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops! Failed to get linked bank details, Please try again later.",
            "error"
          );
        }
      });
  };

  const getLinkedBanksForUpdate = () => {
    setApiProcessing({
      loader: true,
      message: `Gathering linked bank details...`,
    });
    webApi
      .get("/accounting/client/" + clientIdVal + "/banksDetails")
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          //console.log("linked banks api", res.data);
          if (res.data.length) {
            setLinkedBanks(res.data.reverse());
          }
        }
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops! Failed to get linked bank details, Please try again later.",
            "error"
          );
        }
      });
  };

  const { open, ready } = usePlaidLink({
    token: linkToken,
    onSuccess: React.useCallback((public_token, metadata) => {
      // send public_token to server
      // console.log("success public",public_token)
      // console.log("metadata", metadata)
      setApiProcessing({
        loader: true,
        message: `Loading...`,
      });
      let data = {
        publicToken: public_token,
        metaData: metadata,
      };
      webApi
        .post("/accounting/client/" + clientIdVal + "/linkBank", data)
        .then((res) => {
          if (res.data.errorCode) {
            swal("Error", res.data.errorMessage, "error");
          } else {
            //console.log("successfully connected bank to user first", res.data);
            getLinkedBanks();
            getToken();
            if (res.data.length) {
              setSelectedCardIndex(0);
              getForReviewData(res.data[0].accountID);
            }
          }
          setApiProcessing({
            loader: false,
            message: `Linking Bank...`,
          });
        })
        .catch((error) => {
          setApiProcessing({
            loader: false,
            message: `Loading...`,
          });
          getToken();
          if (error.customErrorMessage) {
            swal("Error", error.customErrorMessage, "error");
          } else {
            setApiProcessing({
              loader: false,
              message: `Loading...`,
            });
            swal(
              "Error",
              `Oops! Failed to link Bank, Please try again later.`,
              "error"
            );
          }
        });
    }),
  });

  const handleSelectedBank = (rows, index) => {
    setSelectedCardIndex(index);
    setApiProcessing({
      loader: true,
      message: `Gathering Transactions...`,
    });
    setBankName(rows.name || rows.accountName);
    getForReviewData(rows.accountID);
    setCurrAccID(rows.accountID);
    setOpenTab(1);
    setFilterType("Unassigned");
  };

  const getForReviewData = (accountID) => {
    if (!accountID) {
      swal("Warning", "Please select an account to proceed!", "warning");
    } else {
      setExpandedRow(null);
      setApiProcessing({
        loader: true,
        message: `Loading...`,
      });
      setTableData([]);
      setAllTransactions([]);
      webApi
        .get(
          "/accounting/account/" + accountID + "/transactionsDetails/ForReview"
        )
        .then((res) => {
          if (res.data.errorCode) {
            swal("Info", res.data.errorMessage, "info");
            setTableData([]);
            setAllTransactions([]);
          } else {
            if (res.data.length) {
              setTableData(res.data);
              setAllTransactions(res.data);
              getLinkedBanksForUpdate();
            }
          }
          setApiProcessing({
            loader: false,
            message: `Loading...`,
          });
        })
        .catch((error) => {
          setApiProcessing({
            loader: false,
            message: `Loading...`,
          });
          if (error.customErrorMessage) {
            swal("Error", error.customErrorMessage, "error");
          } else {
            swal(
              "Error",
              "Oops! Failed to get transactions, Please try again later.",
              "error"
            );
          }
        });
    }
    // setFilterType("All");
  };

  const getCategorizedData = (accountID) => {
    if (!accountID) {
      swal("Warning", "Please select an account to proceed!", "warning");
    } else {
      setExpandedRow(null);
      setApiProcessing({
        loader: true,
        message: `Loading...`,
      });
      setTableData([]);
      setAllTransactions([]);
      webApi
        .get(
          "/accounting/account/" +
            accountID +
            "/transactionsDetails/Categorized"
        )
        .then((res) => {
          if (res.data.errorCode) {
            swal("Info", res.data.errorMessage, "info");
            setTableData([]);
            setAllTransactions([]);
          } else {
            //console.log("categorized Data", res.data);
            if (res.data.length) {
              setTableData(res.data);
              setAllTransactions(res.data);
              getLinkedBanksForUpdate();
            }
          }
          setApiProcessing({
            loader: false,
            message: `Loading...`,
          });
        })
        .catch((error) => {
          setApiProcessing({
            loader: false,
            message: `Loading...`,
          });
          if (error.customErrorMessage) {
            swal("Error", error.customErrorMessage, "error");
          } else {
            swal(
              "Error",
              "Oops! Failed to get transactions, Please try again later.",
              "error"
            );
          }
        });
    }
  };

  const getExcludedData = (accountID) => {
    if (!accountID) {
      swal("Warning", "Please select an account to proceed!", "warning");
    } else {
      setExpandedRow(null);
      setApiProcessing({
        loader: true,
        message: `Loading...`,
      });
      setTableData([]);
      setAllTransactions([]);
      webApi
        .get(
          "/accounting/account/" + accountID + "/transactionsDetails/Excluded"
        )
        .then((res) => {
          if (res.data.errorCode) {
            swal("Info", res.data.errorMessage, "info");
            setTableData([]);
            setAllTransactions([]);
          } else {
            //console.log("excluded Data", res.data);
            if (res.data.length) {
              setTableData(res.data);
              setAllTransactions(res.data);
              getLinkedBanksForUpdate();
            }
          }
          setApiProcessing({
            loader: false,
            message: `Loading...`,
          });
        })
        .catch((error) => {
          setApiProcessing({
            loader: false,
            message: `Loading...`,
          });
          if (error.customErrorMessage) {
            swal("Error", error.customErrorMessage, "error");
          } else {
            swal(
              "Error",
              "Oops! Failed to get transactions, Please try again later.",
              "error"
            );
          }
        });
    }
  };

  const handleFilter = () => {
    if (
      commonReportBuilderValues.startDate === "" ||
      commonReportBuilderValues.endDate === ""
    ) {
      swal("Warning", "Please select a date range!", "warning");
    } else if (
      commonReportBuilderValues.startDate > commonReportBuilderValues.endDate
    ) {
      swal("Error", `Please select valid date range!`, "error");
    } else {
      const filteredData = allTransactions.filter((item) => {
        // new Date(item.dateOccured) >=
        //   new Date(commonReportBuilderValues.startDate) &&
        // new Date(item.dateOccured) <=
        //   new Date(commonReportBuilderValues.endDate)

        let date = GetDateInMMDDYYYY(item.date);
        let startDate = GetDateInMMDDYYYY(commonReportBuilderValues.startDate);
        let endDate = GetDateInMMDDYYYY(commonReportBuilderValues.endDate);
        //console.log(startDate, endDate);
        return date >= startDate && date <= endDate;
      });
      //console.log(filteredData, "yyyyyyyyyyy");
      setTableData(filteredData);
    }
  };

  const handleResetFilter = () => {
    if (
      commonReportBuilderValues.startDate === "" &&
      commonReportBuilderValues.endDate === ""
    ) {
      swal("Warning", "Dates are already empty!", "warning");
    } else {
      setCommomReportBuilderValues({
        ...commonReportBuilderValues,
        startDate: "",
        endDate: "",
      });
      setTableData(allTransactions);
    }
  };

  const Edit = (e, rows) => {
    e.stopPropagation();
    setOpenEditForm(true);
    setEditData(rows);
  };

  const updateBankTransactions = (e, accountID) => {
    e.stopPropagation();
    setApiProcessing({
      loader: true,
      message: `Updating...`,
    });
    webApi
      .put("/accounting/account/" + accountID + "/updateTransactionsDetails")
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          swal("Success", "Updated successfully", "success");
          getForReviewData(accountID);
          getLinkedBanks();
        }
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to Update, Please try again later.`,
            "error"
          );
        }
      });
  };

  const updateAllbankTransactions = () => {
    setApiProcessing({
      loader: true,
      message: `Updating...`,
    });
    webApi
      .put("/accounting/client/" + clientIdVal + "/updateTransactionsDetails")
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          swal(
            "Success",
            "Updated All bank Transactions successfully",
            "success"
          );
          getLinkedBanks();
        }
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to Update, Please try again later.`,
            "error"
          );
        }
      });
  };

  const handleFilterType = (e) => {
    const type = e.target.value;
    if (type === "Unassigned") {
      setTableData(allTransactions);
    } else if (type === "Money Out") {
      const filteredData = allTransactions.filter(
        (item) => item.received === ""
      );
      setTableData(filteredData);
    } else if (type === "Money In") {
      const filteredData = allTransactions.filter((item) => item.spent === "");
      setTableData(filteredData);
    }
    setFilterType(e.target.value);
  };

  const unlinkBankAcc = (e, ID) => {
    e.stopPropagation();
    setApiProcessing({
      loader: true,
      message: `Unlinking...`,
    });
    webApi
      .put("/accounting/unlinking/bank/" + ID + "/updateBankDetails")
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          swal("Success", "Unlinked Bank successfully", "success");
          getLinkedBanksForUpdate();
        }
        setApiProcessing({
          loader: false,
          message: `Unliking...`,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: `Unliking...`,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to Unlink Bank, Please try again later.`,
            "error"
          );
        }
      });
  };

  const relinkBankAcc = (e, ID) => {
    e.stopPropagation();
    setApiProcessing({
      loader: true,
      message: `Relinking...`,
    });
    webApi
      .put("/accounting/relinking/bank/" + ID + "/updateBankDetails")
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          swal("Success", "Relinked Bank successfully", "success");
          getLinkedBanksForUpdate();
        }
        setApiProcessing({
          loader: false,
          message: `Reliking...`,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: `Reliking...`,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to Relink Bank, Please try again later.`,
            "error"
          );
        }
      });
  };

  const basicBankingTableActions = {
    rowOnClick: (row) => {},
  };

  //table configuration starts here
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [expandedRow, setExpandedRow] = useState(null);
  // const [AllChartOfAcc, setAllChartofAcc] = useState("")
  const [accountTypes, setAccountTypes] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [rotation, setRotation] = useState({}); // State to track rotation angle
  const [selectedGroup, setSelectedGroup] = useState("");

  const [vendorTenantId, setVendorTenantId] = useState("");
  const [vendorTenantVal, setVendorTenantVal] = useState("");
  const [vendorTenantOptions, setVendorTenantOptions] = useState([]);

  const [allPortfolios, setAllPortfolios] = useState([]);
  const [selectedPortfolioID, setSelectedPortfolioID] = useState("");
  const [selectedPropertyId, setSelectedPropertyId] = useState("");
  const [propertiesOptions, setPropertiesOptions] = useState([]);
  const [selectedUnitId, setSelectedUnitId] = useState("");
  const [unitOptions, setUnitOptions] = useState([]);
  const [desc, setDesc] = useState("");
  const [attachedFiles, setAttachedFiles] = useState("");
  const [hideFields, setHideFields] = useState(false);
  const [formErrors, setFormErrors] = useState("");
  const [getaccountTypes, setGetAccountTypes] = useState([]);

  // const handleSelectedAccountTypes = (e) =>{
  //   // console.log(selectedAccount, "inside the handle")

  //   // console.log("thsi is e.tahet", e.target.value)
  //   if( e.target.value === 0){
  //     setOpenAddAccForm(true)
  //   }
  // }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (key) => {
    // let direction = 'ascending';
    // if (sortConfig.key === key && sortConfig.direction === 'ascending') {
    //   direction = 'descending';
    // }
    // setSortConfig({ key, direction });
  };

  const getPropertyValue = (obj, key) => {
    const properties = key.split(".");
    let value = obj;
    for (const prop of properties) {
      value = value?.[prop];
      if (value === undefined) {
        break;
      }
    }
    return value;
  };

  const getAllVendorsTenants = () => {
    setApiProcessing({
      loader: true,
      message: `Loading...`,
    });
    webApi
      .get("/tenantAndvendor/company/" + companyIDval)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Info", res.data.errorMessage, "info");
        } else {
          // setVendorTenantOptions(res.data)
          const mergedArray = res.data.tenants.concat(res.data.vendors);

          // Update the state with extracted arrays
          setVendorTenantOptions(mergedArray);
          //console.log(mergedArray, "tenantVendors");
        }
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops! Failed to get list of Vendors and Tenants, Please try again later.",
            "error"
          );
        }
      });
  };

  const getAllChartofAccs = () => {
    setApiProcessing({
      loader: true,
      message: `Loading...`,
    });
    webApi
      .get("/accounting/chartOfAccounts/company/" + companyIDval)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Info", res.data.errorMessage, "info");
        } else {
          //setAccountTypes([["Add Account"],[res.data]])
          setAccountTypes([
            { account_id: 20, account_name: "Add Account" },
            ...res.data,
          ]);
          //console.log(res.data, "account types");
        }
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops! Failed to get list of accounts, Please try again later.",
            "error"
          );
        }
      });
  };

  const getPortfolios = () => {
    webApi
      .get("/portfolios/client/" + clientIdVal)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          const sortedData =
            res.data.length > 1
              ? res.data.sort((a, b) =>
                  a.portfolioName > b.portfolioName ? 1 : -1
                )
              : res.data;
          setAllPortfolios(sortedData);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to get Portfolios, Please try again later.`,
            "error"
          );
        }
      });
  };

  const getProperties = (portfolioId) => {
    setApiProcessing({
      loader: true,
      message: "Gathering Properties...",
    });
    webApi
      .get("/properties/portfolio/" + portfolioId)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          let sortedData =
            res.data.length > 1
              ? res.data.sort((a, b) =>
                  a.propertyName > b.propertyName ? 1 : -1
                )
              : res.data;
          setPropertiesOptions(sortedData);
        }
        setApiProcessing({
          loader: false,
          message: "Loading...",
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: "Loading...",
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to get Properties, Please try again later.`,
            "error"
          );
        }
      });
  };

  const getUnits = (propertyID) => {
    setApiProcessing({
      loader: true,
      message: "Gathering Units...",
    });
    webApi
      .get("/units/property/" + propertyID)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setUnitOptions(res.data);
        }
        setApiProcessing({
          loader: false,
          message: "Loading...",
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: "Loading...",
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops!, Unable to get Units! Please try again later.`,
            "error"
          );
        }
      });
  };

  const handleRowClick = (index) => {
    clearFields();
    setRotation((prevRotation) => ({
      ...prevRotation,
      [index]: (prevRotation[index] || 0) + 180,
    }));
    setExpandedRow(expandedRow === index ? null : index);
  };

  const validate = () => {
    const errors = {};

    if (!vendorTenantVal) {
      errors.vendorTenant = "is required!";
    }
    if (!selectedAccount) {
      errors.account = "Account is required!";
    }
    if (!selectedPortfolioID) {
      errors.portfolio = "Portfolio is required!";
    }
    if (!selectedPropertyId) {
      errors.property = "Property is required!";
    }
    if (!selectedUnitId) {
      errors.unit = "Unit is required!";
    }
    if (!desc) {
      errors.description = "Description is required!";
    }
    return errors;
  };

  const handleCategorizing = async (row) => {
    // console.log(row.ID, "rowssssssss");
    if (selectedGroup === "Categorized") {
      const temp = validate();
      setFormErrors(temp);
      if (Object.keys(temp).length) {
        return;
      } else {
        setApiProcessing({
          loader: true,
          message: `Loading...`,
        });
        let data = {
          bank_transaction_id: row.ID, //id take this backend generated id
          chart_of_account_id: selectedAccount,
          user_type: vendorTenantVal,
          portfolio_id: selectedPortfolioID,
          property_id: selectedPropertyId,
          unit_id: selectedUnitId,
          // client_id : 18,
          description: desc,
          document: attachedFiles ? attachedFiles.name : "",
          user_id: parseInt(vendorTenantId),
        };
        webApi
          .post("/accounting/chartOfAccounts/transactions", data)
          .then((res) => {
            if (res.data.errorCode) {
              swal("Error", res.data.errorMessage, "error");
            } else {
              swal("Success", res.data.message, "success");
              
              if (attachedFiles) {
                setApiProcessing({
                  loader: true,
                  message: `Uploading Files...`,
                });
                const metaData = {
                  filename: attachedFiles.name,
                  mediatype: attachedFiles.type,
                };
                webApi
                  .uploadFile(attachedFiles, metaData, attachedFiles)
                  .then((res) => {})
                  .catch((error) => {
                    setApiProcessing({ loader: true, message: `` });
                    if (error.customErrorMessage) {
                      swal("Error", error.customErrorMessage, "error");
                    } else {
                      swal(
                        "Error",
                        "Oops! Failed to Upload attached file.",
                        "error"
                      );
                    }
                  });
              }
              getForReviewData(row.accountID)
            }
            setApiProcessing({
              loader: false,
              message: `Loading...`,
            });
          })
          .catch((error) => {
            setApiProcessing({
              loader: false,
              message: `Loading...`,
            });
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                `Oops! Failed to Categorize the transaction, Please try again later.`,
                "error"
              );
            }
          });
      }
    } else if (selectedGroup === "Excluded") {
      webApi
        .post("/accounting/chartOfAccounts/excluded/" + row.ID)
        .then((res) => {
          if (res.data.errorCode) {
            swal("Error", res.data.errorMessage, "error");
          } else {
            swal("Success", res.data.message, "success");
            getForReviewData(row.accountID);
          }
          setApiProcessing({
            loader: false,
            message: `Loading...`,
          });
        })
        .catch((error) => {
          setApiProcessing({
            loader: false,
            message: `Loading...`,
          });
          if (error.customErrorMessage) {
            swal("Error", error.customErrorMessage, "error");
          } else {
            swal(
              "Error",
              `Oops! Failed to exclude the transaction, Please try again later.`,
              "error"
            );
          }
        });
    }
  };

  const getChartOfAccountTypes = () => {
    webApi
      .get(`/accounting/chartOfAccountsTypes`)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setGetAccountTypes(res.data);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops! Failed to gather Account Types, Please try again later.",
            "error"
          );
        }
      });
  };

  const clearFields = () => {
    setVendorTenantId("");
    setSelectedAccount("");
    setSelectedPortfolioID("");
    setSelectedPropertyId("");
    setSelectedUnitId("");
    setDesc("");
    setAttachedFiles("");
  };

  return (
    <>
      <Loader open={apiProcessing.loader} message={apiProcessing.message} />
      {openEditForm && (
        <EditBankAccount
          trigger={openEditForm}
          setTrigger={setOpenEditForm}
          data={editData}
          getBanks={() => getLinkedBanks()}
          setName={setBankName}
        />
      )}

      {openAddAccountForm && (
        <AddAccount
          trigger={openAddAccountForm}
          setTrigger={setOpenAddAccountForm}
          accountTypes={getaccountTypes}
          getAllAccounts={() => getAllChartofAccs()}
          clientId={userInfo && userInfo.ID}
        />
      )}

      <div className="w-5/6 m-auto">
        <section className={"flex justify-between items-center mt-4 mb-2 mx-4"}>
          <span className="font-semibold text-xl">BANK ACCOUNTS :</span>
          <span className="flex items-center gap-4">
            <ButtonBlue
              id="addAccount"
              btnName="addAccount"
              dataCy="addAccount"
              name="Add Account"
              disabled={!ready}
              onClick={() => open()}
              className="w-36 h-10 cursor-pointer rounded-xl bg-white border-blue-52 text-blue-52 hover:text-white focus:text-white"
            />

            <ButtonCustom
              id="addnew"
              btnName="addNew"
              dataCy="addNew"
              name={
                <>
                  <MdUpdate className="w-6 h-6 mr-2" /> Update
                </>
              }
              onClick={() => updateAllbankTransactions()}
              className={`flex justify-center items-center p-3 w-auto h-10 cursor-pointer rounded-xl hover:bg-blue-53 bg-white border-blue-52 text-blue-52 hover:text-white focus:text-white font-semibold`}
            />
          </span>
        </section>

        <div className="flex flex-row w-full overflow-y-auto mb-3">
          {linkedBanks && linkedBanks.length > 0
            ? linkedBanks.map((rows, index) => (
                <section name="cards">
                  <div
                    className={`w-88 h-36 border-2 border-b-4 rounded-md m-2 hover:bg-blue-53 hover:text-white cursor-pointer
                  ${
                    selectedCardIndex === index
                      ? "bg-blue-53 text-white"
                      : "bg-blue-100 text-blue-52"
                  } ${
                      rows.unlinking === true
                        ? "bg-stone-50 text-black opacity-50 hover:bg-stone-50 hover:text-black"
                        : ""
                    }`}
                    onClick={() => handleSelectedBank(rows, index)}
                  >
                    <div className="m-1">
                      <div className="flex justify-between items-center">
                        <span className="font-semibold text-xl">
                          {rows.name || rows.accountName}
                        </span>
                        <span className="flex items-center gap-2">
                          <GoPencil
                            id="editAccount"
                            name="editAccount"
                            data-cy="editAccount"
                            className="w-4 h-4 cursor-pointer"
                            onClick={(e) => Edit(e, rows)}
                            title="Edit"
                          />
                          <AiOutlineBank
                            className="w-4 h-4"
                            title={rows.institutionName}
                          />
                        </span>
                      </div>

                      <div className="flex justify-between items-center mb-6">
                        <span className="font-semibold text-xl">
                          {"**** **** " + rows.mask}
                        </span>
                        {rows.unlinking === true ? (
                          <span
                            className="underline text-sm cursor-pointer"
                            onClick={(e) => relinkBankAcc(e, rows.ID)}
                          >
                            Relink
                          </span>
                        ) : (
                          <span
                            className="underline text-sm cursor-pointer"
                            onClick={(e) => unlinkBankAcc(e, rows.ID)}
                          >
                            Unlink
                          </span>
                        )}
                      </div>

                      <div className="flex justify-between items-center">
                        <span className="font-semibold text-xl">
                          {"$" + rows.balanceAvailable.toLocaleString()}
                        </span>
                        <span
                          className="flex items-center gap-2 underline text-sm cursor-pointer"
                          onClick={(e) =>
                            updateBankTransactions(e, rows.accountID)
                          }
                        >
                          {/* {rows.isFirstFetchDone === false ||
                          rows.isUpdating === true ? (
                            <UpdateLoader />
                          ) : null} */}
                          Update
                        </span>
                      </div>

                      <div className="flex justify-between items-center">
                        <span className="font-semibold text-md">
                          Bank Balance
                        </span>
                        <span className="flex items-center gap-4 text-sm">
                          last updated{" "}
                          {InSecondsConversion(rows.LastUpdatedInSeconds)} ago
                        </span>
                      </div>
                    </div>
                  </div>
                </section>
              ))
            : null}
        </div>

        <div className="bg-white border border-gray-200 rounded-lg shadow-md mt-8 w-auto">
          <section
            name="tabs"
            className="flex w-auto justify-start shadow-md items-center h-10 font-semibold text-gray-600 cursor-pointer"
          >
            {tabs.map((tab, index) => (
              <p
                key={index}
                id={tab.nickName}
                name={tab.nickName}
                data-cy={tab.nickName}
                className={`hover:text-[#003C9A] focus:text-[#003C9A] pb-2 w-72 text-center ${
                  activeMenuItem === tab.nickName
                    ? `text-[#003C9A] border-[#003C9A] font-semibold w-72 border-b-2`
                    : `text-gray-600 border-gray-600`
                } 
                `}
                onClick={(e) => setActiveMenuItem(tab.nickName)}
              >
                {tab.label}
              </p>
            ))}
          </section>

          {activeMenuItem === "bankTransactions" ? (
            <>
              <section className="text-xl font-semibold mb-4 p-2">
                Account Transactions - {bankName}
              </section>
              <section className="flex flex-row items-center gap-4 p-2 justify-between">
                <div className="flex bg-white">
                  <span className="font-semibold text-lg mr-2 items-center mt-1">
                    {" "}
                    Select a Type :
                  </span>
                  <button
                    id="forReview"
                    name="forReview"
                    data-cy="forReview"
                    onClick={() => {
                      setOpenTab(1);
                      getForReviewData(currAccID);
                    }}
                    className={`flex-1 text-sm w-28 border border-gray-300 py-2 px-2 focus:outline-none focus:shadow-outline-blue transition-all duration-300 ${
                      openTab === 1 ? "bg-gray-400 text-white" : ""
                    }`}
                  >
                    For Review
                  </button>
                  <button
                    id="categorized"
                    name="categorized"
                    data-cy="categorized"
                    onClick={() => {
                      setOpenTab(2);
                      getCategorizedData(currAccID);
                    }}
                    className={`flex-1 text-sm w-28 border border-gray-300 py-2 px-2 focus:outline-none focus:shadow-outline-blue transition-all duration-300 ${
                      openTab === 2 ? "bg-gray-400 text-white" : ""
                    }`}
                  >
                    Categorized
                  </button>
                  <button
                    id="excluded"
                    name="excluded"
                    data-cy="excluded"
                    onClick={() => {
                      setOpenTab(3);
                      getExcludedData(currAccID);
                    }}
                    className={`flex-1 text-sm w-28 border border-gray-300 py-2 px-2 focus:outline-none focus:shadow-outline-blue transition-all duration-300 ${
                      openTab === 3 ? "bg-gray-400 text-white" : ""
                    }`}
                  >
                    Excluded
                  </button>
                </div>

                <span className="flex justify-end items-center gap-4">
                  {/* removed a filter here, can be added. if needed again */}
                  <InputSelectField
                    label="Transaction Type"
                    name="transactionType"
                    id="transactionType"
                    dataCy="transactionType"
                    placeholder="Select a Type"
                    value={filterType}
                    onChange={(e) => {
                      setFilterType(e.target.value);
                      handleFilterType(e);
                    }}
                    options={["Unassigned", "Money Out", "Money In"]}
                    notImp
                    style="!w-44 mb-4"
                  />

                  <Searchbar
                    styles="ml-2 mr-4"
                    placeholder="Search..."
                    data={allTransactions}
                    matchProps={[
                      { date: "date" },
                      { text: "name" },
                      { text: "amount" },
                      { text: "merchantName" },
                      { text: "category" },
                      { text: "spent" },
                      { text: "received" },
                    ]}
                    setTableData={setTableData}
                  />
                </span>
              </section>
              <section className="flex justify-between items-center mt-4 mb-2 p-2">
                <span className="flex flex-row justify-start gap-2">
                  <InputSelectField
                    label={
                      <span className="!font-normal text-black !text-sm mr-2">
                        Report Period<span className="text-red-600">*</span>
                      </span>
                    }
                    style="flex flex-row !w-auto items-center"
                    name="reportPeriods"
                    id="reportPeriods"
                    dataCy="reportPeriods"
                    notImp
                    placeholder="Select Report Period"
                    // value={reportPeriod}
                    // onChange={(e) => {
                    //   handleReportPeriodChange(e.target.value);
                    // }}
                    options={reportPeriodOptions}
                    className="!w-auto rounded items-start"
                  />

                  <label className="flex gap-[12px] items-center">
                    <p className="flex">
                      From <span className="text-red-600">*</span>
                    </p>

                    <DatePicker
                      id="from"
                      name="from"
                      data-cy="from"
                      //readOnly={reportPeriod !== "Custom" ? true : false}
                      value={dayjs(commonReportBuilderValues.startDate)}
                      onChange={(e) => {
                        handleCommonReportBuilerValuesOnChange(
                          dayjs(e.$d).format("MM/DD/YYYY"),
                          "startDate"
                        );
                      }}
                      sx={styles.datePicker}
                    />
                  </label>

                  <label className="flex gap-[12px] items-center">
                    <p className="flex">
                      To <span className="text-red-600">*</span>
                    </p>
                    <DatePicker
                      id="to"
                      name="to"
                      data-cy="to"
                      //readOnly={reportPeriod !== "Custom" ? true : false}
                      value={dayjs(commonReportBuilderValues.endDate)}
                      onChange={(e) => {
                        handleCommonReportBuilerValuesOnChange(
                          dayjs(e.$d).format("MM/DD/YYYY"),
                          "endDate"
                        );
                      }}
                      sx={styles.datePicker}
                    />
                  </label>
                  <button
                    id="resetBtn"
                    name="resetBtn"
                    data-cy="resetBtn"
                    onClick={() => handleResetFilter()}
                    className="text-sm underline text-blue-52 cursor-pointer items-end active:scale-95 transform transition duration-100"
                  >
                    Reset
                  </button>
                  <ButtonBlue
                    name="Submit"
                    onClick={handleFilter}
                    className="rounded-xl text-sm w-20 ml-3"
                  />
                </span>
              </section>
              {openTab === 1 ? (
                <section className={` w-full overflow-x-auto`}>
                  <div className="w-full overflow-x-scroll overflow-y-hidden">
                    <table id={"forreviewtable"} className={"w-full"}>
                      <thead className="bg-[#DFEFF4] text-sm text-gray-800 h-10 cursor-pointer">
                        <tr className="text-left">
                          <th className="pl-4">Date</th>
                          <th className="pl-4">Description</th>
                          <th className="pl-4">Category Or Match</th>
                          <th className="pl-4">Spent</th>
                          <th className="pl-4">Received</th>
                        </tr>
                      </thead>
                      {tableData.length === 0 ? (
                        <tbody>
                          <tr className="text-center">
                            <td colSpan={5} className="text-blue-52">
                              No data to show !
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          {tableData.length &&
                            tableData.map((row, index) => (
                              <React.Fragment key={index}>
                                <tr
                                  id={index}
                                  name={index}
                                  data-cy={index}
                                  className="h-10 text-left border-l-4 border-gray-600 bg-transparent hover:border-blue-50 hover:bg-[#FAFAFA] cursor-pointer w-full"
                                  onClick={() => handleRowClick(index)}
                                >
                                  <td className="pl-4">{row.date}</td>
                                  <td className="pl-4">{row.name}</td>
                                  <td className="pl-4">{row.category}</td>
                                  <td className="pl-4">{row.spent}</td>
                                  <td className="pl-4">{row.received}</td>
                                </tr>
                                {expandedRow === index && (
                                  <tr className="bg-transparent w-full">
                                    <td colSpan={5}>
                                      <Accordion>
                                        <AccordionDetails>
                                          <section className="flex flex-col">
                                            <div className="flex flex-row ml-4 mb-2">
                                              <div className="flex items-center mr-10">
                                                <input
                                                  id="inline-radio1"
                                                  type="radio"
                                                  value="Categorized"
                                                  data-cy="Categorized"
                                                  name="inline-radio-group1"
                                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                                                  onChange={(e) => {
                                                    setSelectedGroup(
                                                      e.target.value
                                                    );
                                                    setHideFields(true);
                                                  }}
                                                />
                                                <label
                                                  htmlFor="Categorized"
                                                  className="ml-2 text-md font-medium text-gray-900"
                                                >
                                                  Categorized
                                                </label>
                                              </div>

                                              <div className="flex items-center mr-10">
                                                <input
                                                  id="inline-radio1"
                                                  type="radio"
                                                  value="Excluded"
                                                  data-cy="Excluded"
                                                  name="inline-radio-group1"
                                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                                                  onChange={(e) => {
                                                    setSelectedGroup(
                                                      e.target.value
                                                    );
                                                    setHideFields(false);
                                                  }}
                                                />
                                                <label
                                                  htmlFor="Excluded"
                                                  className="ml-2 text-md font-medium text-gray-900"
                                                >
                                                  Excluded
                                                </label>
                                              </div>
                                            </div>
                                            {hideFields ? (
                                              <div className="grid grid-cols-3 gap-x-52 gap-y-4 ml-4 mr-4">
                                                {/* <InputSelectField
                                              label="Vendor/Tenant"
                                              name="Vendor/Tenant"
                                              id="Vendor/Tenant"
                                              type="text"
                                              placeholder="Vendor/Tenant"
                                              value={vendorTenantVal}
                                              onChange={(e) =>
                                                setVendorTenantVal(e.target.value)
                                              }
                                              errorText={formErrors.vendorTenant}
                                              style="!w-full"
                                              options={["VENDOR", "TENANT"]}
                                            /> */}

                                                <div className="w-full">
                                                  <label
                                                    htmlFor="Vendor/Tenant"
                                                    className={
                                                      "text-gray-700 w-full text-sm font-semibold"
                                                    }
                                                  >
                                                    Vendor/Tenant
                                                    <span className="text-red-600">
                                                      *
                                                    </span>
                                                    <select
                                                      name="Vendor/Tenant"
                                                      id="Vendor/Tenant"
                                                      data-cy="vendor/tenant"
                                                      //  required
                                                      value={vendorTenantId}
                                                      onChange={(e) => {
                                                        const selectedOption =
                                                          e.target.options[
                                                            e.target
                                                              .selectedIndex
                                                          ];
                                                        setVendorTenantId(
                                                          e.target.value
                                                        );
                                                        setVendorTenantVal(
                                                          selectedOption.getAttribute(
                                                            "data-profile"
                                                          )
                                                        );
                                                        // console.log(
                                                        //   vendorTenantId,
                                                        //   vendorTenantVal,
                                                        //   "after new field"
                                                        // );
                                                      }}
                                                      className={
                                                        " mt-1 p-2.5 w-full bg-gray-50 border rounded-md text-gray-900 text-sm focus:border-blue-52"
                                                      }
                                                    >
                                                      <option
                                                        value={
                                                          props.defaultPlaceholderValue
                                                            ? props.defaultPlaceholderValue
                                                            : ""
                                                        }
                                                        disabled
                                                      >
                                                        {"Vendor/Tenant"}
                                                      </option>
                                                      {vendorTenantOptions &&
                                                      vendorTenantOptions.length >
                                                        0 ? (
                                                        <>
                                                          {vendorTenantOptions.map(
                                                            (option, index) => (
                                                              <option
                                                                key={index}
                                                                value={
                                                                  option.id
                                                                }
                                                                data-cy={
                                                                  option.id
                                                                }
                                                                data-profile={
                                                                  option.role
                                                                } // Store profile data as data attribute
                                                              >
                                                                {option.role +
                                                                  " : " +
                                                                  option.firstName +
                                                                  " " +
                                                                  option.lastName}
                                                              </option>
                                                            )
                                                          )}
                                                        </>
                                                      ) : (
                                                        <option>
                                                          No options
                                                        </option>
                                                      )}
                                                    </select>
                                                    <span className="text-red-10 text-sm">
                                                      {formErrors.vendorTenant}
                                                    </span>
                                                  </label>
                                                </div>

                                                <InputObjectSelectField
                                                  label="Account"
                                                  name="account"
                                                  id="account"
                                                  dataCy="account"
                                                  placeholder="Account"
                                                  value={selectedAccount}
                                                  onChange={(e) => {
                                                    if (
                                                      parseInt(
                                                        e.target.value
                                                      ) === 20
                                                    ) {
                                                      //console.log(e.target.value, "came to if")
                                                      setOpenAddAccountForm(
                                                        true
                                                      );
                                                    } else {
                                                      setSelectedAccount(
                                                        parseInt(e.target.value)
                                                      );
                                                      //console.log(e.target.value, "other")
                                                    }
                                                  }}
                                                  options={accountTypes}
                                                  optionValue="account_id"
                                                  optionName="account_name"
                                                  style="!w-full"
                                                  errorText={formErrors.account}
                                                />

                                                <InputObjectSelectField
                                                  label="Portfolio"
                                                  name="portfolio"
                                                  id="portfolio"
                                                  dataCy="portfolio"
                                                  placeholder="Select a Portfolio"
                                                  value={selectedPortfolioID}
                                                  onChange={(e) => {
                                                    setSelectedPortfolioID(
                                                      parseInt(e.target.value)
                                                    );
                                                    getProperties(
                                                      parseInt(e.target.value)
                                                    );
                                                  }}
                                                  options={allPortfolios}
                                                  optionValue="ID"
                                                  optionName="portfolioName"
                                                  style="!w-full"
                                                  errorText={
                                                    formErrors.portfolio
                                                  }
                                                />

                                                <InputObjectSelectField
                                                  label="Property"
                                                  name="property"
                                                  id="property"
                                                  dataCy="property"
                                                  notImp
                                                  placeholder="Select a property"
                                                  value={selectedPropertyId}
                                                  onChange={(e) => {
                                                    setSelectedPropertyId(
                                                      parseInt(e.target.value)
                                                    );
                                                    getUnits(
                                                      parseInt(e.target.value)
                                                    );
                                                  }}
                                                  options={propertiesOptions}
                                                  optionValue="ID"
                                                  optionName="propertyName"
                                                  style="!w-full"
                                                  errorText={
                                                    formErrors.property
                                                  }
                                                />

                                                <InputObjectSelectField
                                                  label="Unit"
                                                  name="unit"
                                                  id="unit"
                                                  dataCy="unit"
                                                  placeholder="Select a Unit"
                                                  value={selectedUnitId}
                                                  onChange={(e) => {
                                                    setSelectedUnitId(
                                                      parseInt(e.target.value)
                                                    );
                                                  }}
                                                  options={unitOptions}
                                                  optionValue="ID"
                                                  optionName="unitNumber"
                                                  style="!w-full"
                                                  errorText={formErrors.unit}
                                                />

                                                <InputField
                                                  label="Description"
                                                  name="description"
                                                  id="description"
                                                  dataCy="description"
                                                  type="text"
                                                  //placeholder="Enter Email Address"
                                                  value={desc}
                                                  onChange={(e) =>
                                                    setDesc(e.target.value)
                                                  }
                                                  errorText={
                                                    formErrors.description
                                                  }
                                                  style="!w-full"
                                                  className="border-2"
                                                  //notImp
                                                />

                                                <InputFileField
                                                  label="Attach Files"
                                                  name="attachFiles"
                                                  id="attachFiles"
                                                  dataCy="attachFiles"
                                                  accept=".png, .jpg, .jpeg"
                                                  notImp
                                                  maxSizeinMB={10}
                                                  // placeholder="Featured Logo"
                                                  value={attachedFiles}
                                                  setValue={setAttachedFiles}
                                                  hintText="*File Size should be less than 10MB"
                                                  style="!w-full"
                                                />
                                              </div>
                                            ) : null}

                                            <div className="flex justify-end items-center gap-6 cursor-pointer">
                                              <p
                                                className="underline"
                                                id="clearAll"
                                                name="clearAll"
                                                data-cy="clearAll"
                                                onClick={clearFields}
                                              >
                                                Clear All
                                              </p>
                                              <ButtonBlue
                                                id="confirm"
                                                btnName="confirm"
                                                dataCy="confirm"
                                                name="Confirm"
                                                onClick={() =>
                                                  handleCategorizing(row)
                                                }
                                                className="text-sm w-20 ml-3"
                                              />
                                            </div>
                                          </section>
                                        </AccordionDetails>
                                      </Accordion>
                                    </td>
                                  </tr>
                                )}
                              </React.Fragment>
                            ))}
                        </tbody>
                      )}
                    </table>
                  </div>
                  <TablePagination
                    component="div"
                    count={tableData.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </section>
              ) : (
                <TableComponent
                  tableId="Folio/PropTable"
                  data={tableData}
                  columns={[
                    {
                      key: "date",
                      title: "Date",
                      applyMethod: (row) => GetDateInMMDDYYYY(row.date),
                    },
                    { key: "name", title: "Description" },
                    // { key: "merchantName", title: "Payee" },
                    { key: "category", title: "Category Or Match" },
                    { key: "spent", title: "Spent" },
                    {
                      key: "received",
                      title: "Received",
                    },
                  ]}
                  // initialSort={{ key: "date", direction: "descending" }}
                  actions={basicBankingTableActions}
                />
              )}
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Accounting;

const styles = {
  datePicker: {
    "& .MuiInputBase-input": {
      padding: "8px",
      fontSize: "14px",
      fontWeight: 300,
    },
    "& .MuiIconButton-root": {
      padding: "8px",
    },
    "& .MuiPickersDay-daySelected": {
      backgroundColor: "#F1F5F9",
      "&:hover": {
        backgroundColor: "#006699",
      },
    },
    "& .MuiPickersDay-current": {
      color: "#F1F5F9",
      fontWeight: 300,
    },
  },
};

//Custom Loader Component
const UpdateLoader = (props) => {
  return (
    <div>
      <CircularProgress
        size={18}
        sx={{
          color: "#F0b23a",
        }}
        // open={props.open}
      />
    </div>
  );
};

const InSecondsConversion = (input) => {
  const hours = Math.floor(input / 3600);
  if (hours >= 24) {
    const days = Math.floor(hours / 24);
    if (days >= 30) {
      const months = Math.floor(days / 30);
      return `${months} months`;
    } else {
      return `${days} days`;
    }
  } else {
    return `${hours} hours`;
  }
};
