import React from "react";

const SelectCountryCode = () => {
  //For Now this Component is Static, if any Additional states are needed in future then handle using OnChange Property
  return (
    <>
      <select
        name="countryCode"
        className="bg-gray-50 border rounded text-gray-900 text-sm focus:border-blue-52 w-fit p-2 mt-0.5"
      >
        <option value="+1"> US (+1) </option>
      </select>
    </>
  );
};

export default SelectCountryCode;

export const DollarPrefix = () => {
  return (
    <>
      <span className="bg-gray-50 border rounded text-gray-900 text-sm focus:border-blue-52 w-fit p-2 mt-0.5">
        $
      </span>
    </>
  );
};

export const TaxIdFormat = (value) => {
  // if input value is falsy eg: if the user deletes the input, then just return the value
  if (!value) return value;

  // clean the input for any non-digit values.
  const taxId = value.replace(/[^\d]/g, "");

  // taxIdLength will be used to know when to apply formatting for the Tax Id
  const taxIdLength = taxId.length;

  // no need to format it if its lessa than three digits
  if (taxIdLength < 3) return taxId;

  // finally, if the taxIdLength is greater then 2, we format and return it.
  return `${taxId.slice(0, 2)}-${taxId.slice(2, 9)}`;
};

//Phone Num Format
export const PhoneNumFormat = (value, usCountryCode) => {
  // if input value is falsy eg if the user deletes the input, then just return the value
  if (!value) return value;

  let countryCode = "";

  if (value.includes("+1")) {
    countryCode = "+1";
  }

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/^(\+1)+|[^\d]/g, "");

  // phoneNumberLength will be used to know when to apply formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // no need to format it if its lessa than four digits
  if (phoneNumberLength < 4) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7, we shall partially format and return it.
  if (phoneNumberLength < 7) {
    return `${countryCode} (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3
    )}`;
  }

  // finally, if the taxIdLength is greater then 7, we fully format and return it.
  return `${countryCode} (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};

//Phone Num Format
export const PhoneNumFormatWithCountryCode = (value) => {
  // if input value is falsy eg if the user deletes the input, then just return the value
  if (!value) return value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, "");

  // phoneNumberLength will be used to know when to apply formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // no need to format it if its lessa than four digits
  if (phoneNumberLength < 4) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7, we shall partially format and return it.
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // finally, if the taxIdLength is greater then 7, we fully format and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};

// File Size validation of 5mb.
export const FileSizeValidation = (files, maxSizeinMB) => {
  const limitInMB = parseInt(maxSizeinMB) || 10; //default 5mb
  if (files.length) {
    const ApprovedFiles = [];
    let removedFiles = 0;

    for (var i = 0; i < files.length; i++) {
      const fsize = files[i].size;
      const file = Math.round(fsize / 1024);
      if (file > limitInMB * 1024) {
        removedFiles = removedFiles + 1;
        //removedFiles++;
      } else {
        ApprovedFiles.push(files[i]);
      }
    }
    return ApprovedFiles;
  } else return [];
};

//Time Range Validation
export const TimeRangeValidation = (timeSlot, range) => {
  const timeFrom = timeSlot.from.split(/[:\s]+/);
  const timeTo = timeSlot.to.split(/[:\s]+/);
  const timeRange = range || 120; //by default it validates for two hours, if not give range value

  if (timeFrom[2] === "PM" && timeFrom[0] !== "12") {
    timeFrom[0] = parseInt(timeFrom[0]) + 12;
  }
  if (timeTo[2] === "PM" && timeTo[0] !== "12") {
    timeTo[0] = parseInt(timeTo[0]) + 12;
  }

  timeFrom[2] = parseInt(timeFrom[0]) * 60 + parseInt(timeFrom[1]);

  timeTo[2] = parseInt(timeTo[0]) * 60 + parseInt(timeTo[1]);

  const diffrence = timeTo[2] - timeFrom[2];

  if (diffrence < timeRange) {
    //Is less than 2hours
    return true;
  } else {
    return false;
  }
};

//Get Today Date
export const GetToday = () => {
  let date = new Date();
  date.setDate(date.getDate() + 1);
  return date.toISOString().split("T")[0];
};

//Get US States List
export const StatesList = [
  { value: "AL", name: "Alabama" },
  { value: "AK", name: "Alaska" },
  { value: "AZ", name: "Arizona" },
  { value: "AR", name: "Arkansas" },
  { value: "CA", name: "California" },
  { value: "CO", name: "Colorado" },
  { value: "CT", name: "Connecticut" },
  { value: "DE", name: "Delaware" },
  { value: "DC", name: "District Of Columbia" },
  { value: "FL", name: "Florida" },
  { value: "GA", name: "Georgia" },
  { value: "HI", name: "Hawaii" },
  { value: "ID", name: "Idaho" },
  { value: "IL", name: "Illinois" },
  { value: "IN", name: "Indiana" },
  { value: "IA", name: "Iowa" },
  { value: "KS", name: "Kansas" },
  { value: "KY", name: "Kentucky" },
  { value: "LA", name: "Louisiana" },
  { value: "ME", name: "Maine" },
  { value: "MD", name: "Maryland" },
  { value: "MA", name: "Massachusetts" },
  { value: "MI", name: "Michigan" },
  { value: "MN", name: "Minnesota" },
  { value: "MS", name: "Mississippi" },
  { value: "MO", name: "Missouri" },
  { value: "MT", name: "Montana" },
  { value: "NE", name: "Nebraska" },
  { value: "NV", name: "Nevada" },
  { value: "NH", name: "New Hampshire" },
  { value: "NJ", name: "New Jersey" },
  { value: "NM", name: "New Mexico" },
  { value: "NY", name: "New York" },
  { value: "NC", name: "North Carolina" },
  { value: "ND", name: "North Dakota" },
  { value: "OH", name: "Ohio" },
  { value: "OK", name: "Oklahoma" },
  { value: "OR", name: "Oregon" },
  { value: "PA", name: "Pennsylvania" },
  { value: "RI", name: "Rhode Island" },
  { value: "SC", name: "South Carolina" },
  { value: "SD", name: "South Dakota" },
  { value: "TN", name: "Tennessee" },
  { value: "TX", name: "Texas" },
  { value: "UT", name: "Utah" },
  { value: "VT", name: "Vermont" },
  { value: "VA", name: "Virginia" },
  { value: "WA", name: "Washington" },
  { value: "WV", name: "West Virginia" },
  { value: "WI", name: "Wisconsin" },
  { value: "WY", name: "Wyoming" },
];

//Check if the Given Value is Special Charcter Free or not
export const IsSpecialCharFree = (name) => {
  // Regular expression pattern to match only alphabetic characters, numbers, and spaces
  const pattern = /^[a-zA-Z0-9\s]+$/;
  // Check if the name is not empty and does not contain any special characters
  return name.trim().length > 0 && pattern.test(name);
};

//Months Short Names Array
export const MonthsShortNamesList = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

//Get Current Month In short Form like "Jan"
export const GetCurrentMonth = () => {
  const today = new Date();
  const monthIndex = today.getMonth();
  const CurrentmonthName = MonthsShortNamesList[monthIndex];
  return CurrentmonthName;
};

//Last 12 Months with Year Array
export const last12MonthsList = Array.from({ length: 12 }, (_, i) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonthIndex = currentDate.getMonth();
  const monthIndex = (currentMonthIndex + i - 11 + 12) % 12;
  const monthName = MonthsShortNamesList[monthIndex];
  return `${monthName} ${
    currentYear - (monthIndex > currentMonthIndex ? 1 : 0)
  }`;
});

//Get Complete Address
export const GetCompleteAddress = (address) => {
  if (!address || typeof address !== "object") {
    return "-";
  }

  const { address1, address2, city, state, country, zipCode } = address;
  return (
    `${address1 || ""}${address2 ? `, ${address2}` : ""}${
      city ? `, ${city}` : ""
    }${state ? `, ${state}` : ""}${country ? `, ${country}` : ""}${
      zipCode ? `, ${zipCode}` : ""
    }`.trim() || "-"
  );
};

//Get Address in two lines
export const GetFullAddressinTwoLines = (address) => {
  if (!address || typeof address !== "object") {
    return "-";
  }

  const { address1, address2, city, state, country, zipCode } = address;
  let firstLine = `${address1},${address2 && ` ${address2},`}`;
  let secondLine = `${city && `${city},`}${state && ` ${state},`}${
    country && ` ${country},`
  }${zipCode && ` ${zipCode}`}`;

  // Join the lines, ensuring there's a newline if both lines have content
  return (
    <>
      {firstLine} <br /> {secondLine}
    </>
  );
};

export const PetFeeDisclaimer = () => {
  return `Please be informed that a PET fee is applicable to tenants who bring pets into the rented premises. The PET fee is a one-time, non-refundable payment and does not exempt tenants from any responsibilities outlined in the lease agreement, including adherence to pet policies and rules. This fee is intended to cover potential damages, cleaning, and other associated costs related to the presence of pets on the property. It is important to note that the PET fee is separate from any security deposit and is not a substitute for such deposits.`;
};

export const einFormat = (value) => {
  // Convert the input value to a string if it's not already
  const strValue = value.toString();
  // Add the hyphen after the second digit
  const format = strValue.slice(0, 2) + "-" + strValue.slice(2);
  return format;
};
