import React, { useState, useCallback } from "react";
import { WebApimanager } from "../../WebApiManager";
import {
  ButtonBlue,
  ButtonGrayOutline,
  ButtonIcon,
} from "../../Widgets/Buttons";
import InputField, {
  InputSelectField,
  InputTextArea,
} from "../../Widgets/Forms/InputFields";
import SnackAlert from "../../Widgets/SnackbarAlert";
import { leaseId, userData } from "../../atoms";
import { useRecoilValue } from "recoil";
import {
  Dialog,
  Stepper,
  StepLabel,
  Step,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import swal from "sweetalert";
import { useEffect } from "react";
import { InputObjectSelectField } from "../../Widgets/Forms/InputFields";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Loader } from "../../Widgets/notificationFeedbacks";

const AddDemandLetter = (props) => {
  let webApi = new WebApimanager();
  const [letterType, setLettertype] = useState("");
  const [daysToResponse, setDaysToResponse] = useState("");
  const [template, setTemplate] = useState("");
  const userInfo = useRecoilValue(userData);
  const activeLeaseId = useRecoilValue(leaseId);
  const [formErrors, setFormErrors] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [apiProcessing, setApiProcessing] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    console.log(props.address);
  }, []);

  const validate = () => {
    const errors = {};

    if (activeStep === 0) {
      if (!letterType) {
        errors.letterType = "Letter type is required!";
      }
      if (!daysToResponse) {
        errors.daysToResponse = "Days to Response is required!";
      }
      if (!template) {
        errors.template = "Template is required!";
      }
    } else if (activeStep === 1) {
      if (!letterData) {
        errors.letterData = "Letter is required!";
      }
    }

    return errors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setApiProcessing({
      loader: true,
      message: "Submitting Demand Letter...",
    });
    let data = {
      leaseId: activeLeaseId,
      letterType: letterType,
      template: template,
      daysOfResponse: daysToResponse,
      body: letterData,
    };
    webApi
      .post(`/demandLetter/pdf`, data)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          swal("Success", res.data, "success");
          props.setTrigger(false);
          props.getLetters();
        }
        setApiProcessing({
          loader: false,
          message: "Submitting Demand Letter...",
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: "Submitting Demand Letter...",
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else
          swal(
            "Error",
            "Oops, Unable to submit Demand Letter! Please try again later.",
            "error"
          );
      });
  };

  const handleSteps = (event) => {
    event.preventDefault();
    const temp = validate();
    setFormErrors(temp);
    if (Object.keys(temp).length) {
      return;
    } else {
      if (activeStep === 0) {
        setLetterData(defaultTemplate);
        setActiveStep((currentStep) => currentStep + 1);
      } else {
        setActiveStep((currentStep) => currentStep + 1);
      }
    }
  };

  const handleBack = (event) => {
    event.preventDefault();
    setActiveStep((currentStep) => currentStep - 1);
  };

  const defaultTemplate = `
  To
  ${props.address.address1 || ""}
  ${props.address.address2 || ""}
  ${props.address.city || ""}
  ${props.address.state || ""}
  ${props.address.country || ""}
  ${(props.address && props.address.zipCode.toString()) || ""}

  Dear ${props.name},

  I am writing this letter to formally Demand the outstanding Rent for the month to the sum of ${
    "$" + props.balance.toLocaleString()
  }
  Despite Previous attempts to resolve the issue, we were not able to get a response from your side.
  As a result, I hereby demand the following:
  1.Payment of outstanding rent.
  2.A late fee of amount as specified in the Lease Agreement.

  I expect the above demands to be met within ${daysToResponse} days from the date of this letter.

  Failure to the comply with this demand within the specified timeframe will leave me with not choice but to pursue
  further legal action, including but not limited to termination of lease.
  Please consider this letter as a formal notice of my intentions, and I urge you to take prompt action to
  resolve this matter amicably.
  Thank you for the immediate attention to this matter.

  Sincerely,
  ${userInfo.firstName}`;

  const [letterData, setLetterData] = useState("");

  return props.trigger ? (
    <>
      <Dialog
        open={true}
        aria-labelledby="Add Demand Form"
        fullWidth
        maxWidth="lg"
        //PaperProps={{ sx: { height: "80%" } }}
        //scroll="body"
        className="flex flex-col justify-center w-5/6 mx-auto"
      >
        {/* <SnackAlert
          open={openSnackbar}
          close={handleSnackbarClose}
          type={snackbarType}
          message={snackbarMessage}
        /> */}

        <Loader open={apiProcessing.loader} message={apiProcessing.message} />

        <div className="flex justify-center items-center bg-blue-52 text-white font-semibold rounded-sm h-10 pr-2">
          <span className="flex-grow text-center">Demand Letter</span>
          <ButtonIcon
            title="Close Demand Form"
            id="closeDemandFrom"
            btnName="closeDemandFrom"
            dataCy="closeDemandFrom"
            onClick={(e) => props.setTrigger(false)}
            icon={<AiOutlineCloseCircle className="w-5 h-5" />}
            className="hover:!text-red-600"
          />
        </div>

        <Stepper activeStep={activeStep} alternativeLabel className="my-4">
          <Step>
            <StepLabel>Letter Details</StepLabel>
          </Step>
          <Step>
            <StepLabel>Edit Letter</StepLabel>
          </Step>
          <Step>
            <StepLabel>Review Details</StepLabel>
          </Step>
        </Stepper>

        <DialogContent dividers>
          <form className="flex flex-col justify-center items-center">
            {activeStep === 0 ? (
              <>
                <span className="border-b-2 border-blue-52 pb-1 mb-4 text-blue-52 w-5/6 md:gap-x-8 md:w-11/12">
                  Letter Details
                </span>
                <section className="grid grid-cols-2 gap-x-16 gap-y-6 w-5/6 mb-4 md:gap-x-8 md:w-11/12">
                  <InputSelectField
                    label="Demand Letter Type"
                    name="demandLeterType"
                    id="demandLeterType"
                    dataCy="demandLeterType"
                    placeholder="Choose a Type"
                    value={letterType}
                    onChange={(e) => setLettertype(e.target.value)}
                    options={["Rental"]}
                    errorText={formErrors.letterType}
                    style="!w-full"
                  />

                  <InputField
                    label="Days to Response"
                    name="daysToResponse"
                    id="daysToResponse"
                    dataCy="daysToResponse"
                    type="number"
                    placeholder="Enter Days to Response"
                    value={daysToResponse}
                    onChange={(e) =>
                      setDaysToResponse(parseInt(e.target.value))
                    }
                    errorText={formErrors.daysToResponse}
                    style="!w-full"
                  />

                  <InputSelectField
                    label="Select Template"
                    name="selectTemplate"
                    id="selectTemplate"
                    dataCy="selectTemplate"
                    placeholder="Choose a Template"
                    value={template}
                    onChange={(e) => setTemplate(e.target.value)}
                    options={["Default Template"]}
                    errorText={formErrors.template}
                    style="!w-full"
                  />

                  <div></div>

                  <ButtonGrayOutline
                    name="Cancel"
                    id="cancel"
                    btnName="cancel"
                    dataCy="cancel"
                    onClick={() => props.setTrigger(false)}
                    className="mt-6 justify-self-end"
                  />
                  <ButtonBlue
                    name="Next"
                    id="next"
                    btnName="next"
                    dataCy="next"
                    onClick={(e) => handleSteps(e)}
                    className="mt-6"
                  />
                </section>
              </>
            ) : null}

            {activeStep === 1 ? (
              <>
                <span className="border-b-2 border-blue-52 pb-1 mb-4 text-blue-52 w-5/6 md:gap-x-8 md:w-11/12">
                  Edit Letter
                </span>
                <section className="flex w-5/6 mb-4 md:w-11/12">
                  <InputTextArea
                    label="Edit Details"
                    name="editDetails"
                    id="editDetails"
                    dataCy="editDetails"
                    type="text"
                    placeholder="Write Letter here..."
                    value={letterData}
                    onChange={(e) => setLetterData(e.target.value)}
                    errorText={formErrors.letterData}
                    style="!w-full"
                    className="h-96 rounded-lg"
                  />
                </section>
                <div className="flex justify-center items-center gap-16 mt-6 mb-4 w-3/4">
                  <ButtonGrayOutline
                    name="Back"
                    id="back"
                    btnName="back"
                    onClick={(e) => handleBack(e)}
                    className="mt-6 justify-self-end"
                  />
                  <ButtonBlue
                    name="Next"
                    id="next"
                    btnName="next"
                    onClick={(e) => handleSteps(e)}
                    className="mt-6"
                  />
                </div>
              </>
            ) : null}

            {activeStep === 2 ? (
              <>
                <span className="border-b-2 border-blue-52 pb-1 mb-4 text-blue-52 w-5/6 md:gap-x-8 md:w-11/12">
                  Review Details
                </span>
                <section className="flex w-5/6 justify-around items-center md:w-11/12">
                  <pre className="p-4 rounded-lg font-sans text-base whitespace-pre-wrap w-full">
                    {letterData}
                  </pre>
                </section>

                <div className="flex justify-center items-center gap-16 mt-6 mb-4 w-3/4">
                  <ButtonGrayOutline
                    name="Back"
                    id="back"
                    btnName="back"
                    dataCy="back"
                    onClick={(e) => handleBack(e)}
                    hidden={apiProcessing}
                  />
                  <ButtonBlue
                    id="confirm"
                    btnName="confirm"
                    name="Confirm"
                    onClick={handleSubmit}
                  />
                </div>
              </>
            ) : null}
          </form>
        </DialogContent>
      </Dialog>
    </>
  ) : (
    ""
  );
};

export default AddDemandLetter;
