import { RiDeleteBin6Line } from "react-icons/ri";
import React, { useState, useCallback } from "react";
import { WebApimanager } from "../../WebApiManager";
import { TextReviewTile } from "../../Widgets/Forms/FormReviewTiles";
import {
  ButtonBlue,
  ButtonBlueOutline,
  ButtonGrayOutline,
  ButtonIcon,
} from "../../Widgets/Buttons";
import SelectCountryCode, {
  PhoneNumFormat,
  IsSpecialCharFree,
} from "../../Widgets/Forms/FieldDependants";
import InputField, {
  InputPhoneNumField,
  InputSelectField,
  InputMonthYearField,
  InputYearField,
  InputDollarsField,
} from "../../Widgets/Forms/InputFields";
import SnackAlert from "../../Widgets/SnackbarAlert";
import { clientId, companyId, userData } from "../../atoms";
import { useRecoilValue } from "recoil";
import {
  Dialog,
  Stepper,
  StepLabel,
  Step,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import swal from "sweetalert";
import { useEffect } from "react";
import { InputObjectSelectField } from "../../Widgets/Forms/InputFields";
import { AiOutlineCloseCircle, AiOutlinePlusCircle } from "react-icons/ai";

import { Loader } from "../../Widgets/notificationFeedbacks";

const AddRentalTransactions = (props) => {
  let webApi = new WebApimanager();

  const [selectedChargeCode, setSelectedChargeCode] = useState("");
  const [selectedRentalTransactionType, setSelectedRentalTransactionType] =
    useState("");
  const [forMonth, setForMonth] = useState("");
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [selectedPendingCharge, setSelectedPendingCharge] = useState("");
  const [pendingAmount, setPendingAmount] = useState("");
  const [selectedChargeCodeDescription, setSelectedChargeCodeDescription] =
    useState("");

  const [allPendingCharges, setAllPendingCharges] = useState([]);
  const [pendingChargeOptions, setPendingChargeOptions] = useState([]);
  const [allChargeCodes, setAllChargeCodes] = useState([]);

  const [formErrors, setFormErrors] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  const [extraTransacFeeDetails, setExtraTransacFeeDetails] = useState([]);
  const [extraTransacFeeErrors, setextraTransacFeeErrors] = useState([]);
  const [totalTerminAmount, setTotalTerminAmount] = useState(0);
  let holdInFirstStep = false;

  useEffect(() => {
    getChargeCodes();
    getPendingCharges();
  }, []);

  const getChargeCodes = () => {
    webApi
      .get("/ledger/chargeCodes", { loader: true })
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          const visibleRecords = res.data.filter(
            (record) => record.isVisible === true
          );
          setAllChargeCodes(visibleRecords);
        }
      })
      .catch((error) => {
        swal(
          "Error",
          `Oops!, Unable to load Charge Codes! Please try again later.`,
          "error"
        );
      });
  };

  const getPendingCharges = () => {
    webApi
      .get(`/ledger/unpaid/tenant/${props.tennatCode}`, { loader: true })
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setAllPendingCharges(res.data.ledgers);
        }
      })
      .catch((error) => {
        swal(
          "Error",
          `Oops!, Unable to load Pending Charges! Please try again later.`,
          "error"
        );
      });
  };

  const validate = () => {
    const errors = {};
    if (activeStep === 0) {
      if (!selectedRentalTransactionType) {
        errors.selectedRentalTransactionType =
          "Rental Transaction Type is required!";
      }

      if (!amount) {
        errors.amount = "Amount is required!";
      }

      if (selectedRentalTransactionType === "Charge") {
        if (!selectedChargeCode) {
          errors.selectedChargeCode = "Charge Code is required!";
        }
        if (!forMonth) {
          errors.forMonth = "Month is required!";
        }
      }

      if (
        selectedRentalTransactionType !== "Charge" &&
        selectedRentalTransactionType !== "Termination Fees"
      ) {
        if (!selectedPendingCharge) {
          errors.selectedPendingCharge = "Pending Charge is required!";
        }
      }

      if (selectedRentalTransactionType === "Termination Fees") {
        if (!selectedChargeCode) {
          errors.selectedChargeCode = "Charge Code is required!";
        }
        if (!description.trim()) {
          errors.description = "Description is required!";
        }
        if (extraTransacFeeDetails.length) {
          const errorsArr = [];
          for (var i = 0; i < extraTransacFeeDetails.length; i++) {
            let temperr = {};
            if (!extraTransacFeeDetails[i].amount) {
              temperr.amount = "Amount is required!";
            }
            if (!extraTransacFeeDetails[i].description.trim()) {
              temperr.description = "Description is required!";
            }
            if (Object.keys(temperr).length) {
              holdInFirstStep = true;
            }
            errorsArr.push(temperr);
          }
          setextraTransacFeeErrors(errorsArr);
        }
      }
    }

    return errors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (selectedRentalTransactionType === "Termination Fees") {
      handleTerminationFeeSubmit();
      return;
    }
    if (!apiProcessing.loader) {
      setApiProcessing({
        loader: true,
        message: `Adding Rental Transaction...`,
      });

      let data = {
        tenantCode: props.tennatCode,
        paymentInfo: selectedRentalTransactionType,
        paymentAmount: amount,
        chargeCode: selectedChargeCode,
        forDate: forMonth,
        parentRecord1: selectedPendingCharge,
        unitId: props.unitID,
      };

      webApi
        .post("/ledger", data)
        .then((res) => {
          if (res.data.errorCode) {
            swal("Failed", res.data.errorMessage, "error");
            setApiProcessing({ loader: false, message: `` });
          } else {
            props.getAllRentalTransactions(res.data);
            swal(
              "Success",
              "Added New Rental Transaction Successfully",
              "success"
            );
            setApiProcessing({ loader: false, message: `` });
            props.setTrigger(false);
          }
        })
        .catch((error) => {
          setApiProcessing({ loader: false, message: `` });
          if (error.customErrorMessage) {
            swal("Error", error.customErrorMessage, "error");
          } else {
            swal(
              "Error",
              `Oops! Failed to Add ${selectedRentalTransactionType} Rental Transaction, Please try again later.`,
              "error"
            );
          }
        });
    }
  };

  const getPendingAmount = (id) => {
    pendingChargeOptions.filter((item) => {
      if (item.ID === id) {
        setPendingAmount(item.pendingAmount);
      }
    });
  };

  const handleSteps = (event) => {
    event.preventDefault();
    const temp = validate();
    setFormErrors(temp);
    if (Object.keys(temp).length || holdInFirstStep) {
      return;
    } else {
      if (activeStep === 0) {
        let totalAmount = extraTransacFeeDetails.reduce(
          (sum, obj) => sum + obj.amount,
          0
        );
        setTotalTerminAmount(totalAmount + amount);
      }
      setActiveStep((currentStep) => currentStep + 1);
    }
  };

  const handleBack = (event) => {
    event.preventDefault();
    setActiveStep((currentStep) => currentStep - 1);
  };

  const handleRentalTransactionType = (type) => {
    setSelectedRentalTransactionType(type);
    setSelectedPendingCharge("");
    setPendingAmount("");
    setSelectedChargeCodeDescription("");
    setSelectedChargeCode("");
    setAmount("");
    setDescription("");

    if (type === "Waive-off") {
      setPendingChargeOptions(allPendingCharges);
    } else if (type === "Concession") {
      const typeRecords = allPendingCharges.filter(
        (record) => record.chargeCode === "rent"
      );
      setPendingChargeOptions(typeRecords);
    }
  };

  const handleSelectedChargeCode = (selectedCharge) => {
    allChargeCodes.filter((item) => {
      if (item.chargeCode === selectedCharge) {
        setSelectedChargeCodeDescription(item.chargeDescription);
      }
    });
  };

  //Form Fields
  const rentalTransactionTypeField = (concatID, disableFlag) => (
    <>
      <InputSelectField
        label="Rental Transaction Type"
        name="rentalTransactionType"
        id={"rentalTransactionType" + concatID}
        dataCy={"rentalTransactionType" + concatID}
        disabled={disableFlag}
        placeholder="Choose a Rental Type"
        value={selectedRentalTransactionType}
        onChange={(e) => handleRentalTransactionType(e.target.value)}
        options={["Charge", "Waive-off", "Concession", "Termination Fees"]}
        errorText={formErrors.selectedRentalTransactionType}
        style={`!w-full`}
        className={disableFlag && "bg-gray-200"}
      />
    </>
  );

  const chargeCodeField = (concatID, disableFlag) => (
    <>
      <InputObjectSelectField
        label="Charge Code"
        name="selectedChargeCode"
        id={"selectedChargeCode" + concatID}
        dataCy={"selectedChargeCode" + concatID}
        placeholder="Choose a Charge Code"
        value={selectedChargeCode}
        disabled={disableFlag}
        onChange={(e) => {
          setSelectedChargeCode(e.target.value);
          handleSelectedChargeCode(e.target.value);
        }}
        options={allChargeCodes}
        optionValue="chargeCode"
        optionName="chargeDescription"
        style="!w-full"
        className={disableFlag && "bg-gray-200"}
        errorText={formErrors.selectedChargeCode}
      />
    </>
  );

  const forMonthField = () => (
    <>
      <InputMonthYearField
        label="For Month"
        name="forMonth"
        id="forMonth"
        dataCy="forMonth"
        value={forMonth}
        onChange={(value) => setForMonth(value)}
        // minDate={"1500"}
        //maxDate={new Date()}
        style="!w-full"
        errorText={formErrors.forMonth}
      />
    </>
  );

  const pendingChargesField = () => (
    <>
      <InputObjectSelectField
        label="Pending Charges"
        name="pendingCharges"
        id="pendingCharges"
        dataCy="pendingCharges"
        placeholder="Choose a Pending Charge"
        value={selectedPendingCharge}
        onChange={(e) => {
          setSelectedPendingCharge(parseInt(e.target.value));
          getPendingAmount(parseInt(e.target.value));
        }}
        options={pendingChargeOptions}
        optionValue="ID"
        optionName="description"
        style="!w-full"
        errorText={formErrors.selectedPendingCharge}
      />
    </>
  );

  const pendingAmountField = () => (
    <>
      <InputDollarsField
        label="Pending Amount"
        name="pendingAmount"
        id="pendingAmount"
        dataCy="pendingAmount"
        placeholder="Pending Amount"
        notImp
        disabled
        value={pendingAmount}
        setValue={(value) => setPendingAmount(value)}
        errorText={formErrors.pendingAmount}
        style="!w-full bg-gray-50"
      />
    </>
  );

  const AmountField = (concatID) => (
    <>
      <InputDollarsField
        label="Amount"
        name="amount"
        id={"amount" + concatID}
        dataCy={"amount" + concatID}
        placeholder="Enter Amount"
        value={amount}
        setValue={(value) => setAmount(value)}
        errorText={formErrors.amount}
        style="!w-full"
      />
    </>
  );

  const descriptionField = () => (
    <>
      <InputField
        label="Description"
        name="description"
        id="description"
        dataCy="description"
        type="text"
        placeholder="Enter Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        errorText={formErrors.description}
        style="!w-full"
      />
    </>
  );

  //Transaction Fee Functions
  const handleAddNewTransactionDetail = () => {
    setExtraTransacFeeDetails([
      ...extraTransacFeeDetails,
      {
        amount: "",
        description: "",
      },
    ]);
    setextraTransacFeeErrors([
      ...extraTransacFeeErrors,
      {
        amount: "",
        description: "",
      },
    ]);
  };

  const handleDeleteTransactionDetail = (i) => {
    setExtraTransacFeeDetails(
      extraTransacFeeDetails.filter((_, index) => index !== i)
    );
    setextraTransacFeeErrors(
      extraTransacFeeErrors.filter((_, index) => index !== i)
    );
  };

  const handleExtraFeeDetails = (i, name, value) => {
    let details = [...extraTransacFeeDetails];
    details[i][name] = value;
    setExtraTransacFeeDetails(details);
  };

  const handleTerminationFeeSubmit = () => {
    let data = {
      tenantCode: props.tennatCode,
      unitId: props.unitID,
      terminationFeeCharges: [
        { amount: amount, description: description },
        ...extraTransacFeeDetails,
      ],
    };

    webApi
      .post("/ledger/terminationFee", data, { loader: true })
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failed", res.data.errorMessage, "error");
        } else {
          props.getAllRentalTransactions(res.data);
          swal(
            "Success",
            "Added New Rental Transaction Successfully",
            "success"
          );
          props.setTrigger(false);
        }
      })
      .catch((error) => {
        swal(
          "Error",
          `Oops! Failed to Add ${selectedRentalTransactionType} Rental Transaction, Please try again later.`,
          "error"
        );
      });
  };

  return props.trigger ? (
    <>
      <Dialog
        open={true}
        aria-labelledby="Add Rental Transaction"
        fullWidth
        maxWidth="lg"
        //PaperProps={{ sx: { height: "80%" } }}
        //scroll="body"
        className="flex flex-col justify-center w-5/6 mx-auto"
      >
        <Loader open={apiProcessing.loader} message={apiProcessing.message} />

        <div className="flex justify-center items-center bg-blue-52 text-white font-semibold rounded-sm h-10 pr-2">
          <span className="flex-grow text-center">Add Rental Transaction</span>
          <ButtonIcon
            id="closeTransactionForm"
            dataCy="closeTransactionForm"
            title="Close Transaction Form"
            onClick={(e) => props.setTrigger(false)}
            icon={<AiOutlineCloseCircle className="w-5 h-5" />}
            className="hover:!text-red-600"
          />
        </div>

        <Stepper activeStep={activeStep} alternativeLabel className="my-4">
          <Step>
            <StepLabel>Transaction Details</StepLabel>
          </Step>
          <Step>
            <StepLabel>Confirm Details</StepLabel>
          </Step>
        </Stepper>

        <DialogContent dividers>
          <form className="flex flex-col justify-center items-center">
            {activeStep === 0 ? (
              <>
                <span className="border-b-2 border-blue-52 pb-1 mb-4 text-blue-52 w-5/6">
                  Transaction Details
                </span>
                <section className="grid grid-cols-2 gap-x-16 gap-y-6 w-5/6 mb-4">
                  {rentalTransactionTypeField()}

                  {(selectedRentalTransactionType === "Charge" ||
                    selectedRentalTransactionType === "Termination Fees") &&
                    chargeCodeField()}

                  {selectedRentalTransactionType === "Charge" &&
                    forMonthField()}

                  {selectedRentalTransactionType &&
                    selectedRentalTransactionType !== "Charge" &&
                    selectedRentalTransactionType !== "Termination Fees" && (
                      <>
                        {pendingChargesField()}
                        {pendingAmountField()}
                      </>
                    )}

                  {AmountField()}

                  {selectedRentalTransactionType === "Termination Fees" &&
                    descriptionField()}

                  {selectedRentalTransactionType === "Termination Fees" &&
                    !extraTransacFeeDetails.length && (
                      <ButtonBlueOutline
                        id="addNewTransaction"
                        btnName="addNewTransaction"
                        dataCy="addNewTransaction"
                        name={
                          <span className="flex items-center gap-1">
                            <AiOutlinePlusCircle /> Add New Transaction Detail
                          </span>
                        }
                        onClick={(e) => handleAddNewTransactionDetail()}
                        className="mt-6 justify-self-center !w-fit col-span-2"
                      />
                    )}

                  {selectedRentalTransactionType === "Termination Fees" &&
                    extraTransacFeeDetails.map((newRecord, index) => (
                      <React.Fragment key={index}>
                        <span className="border-b-2 border-blue-52 pb-1 text-blue-52 col-span-2">
                          Additional Transaction Details {index + 1}
                        </span>
                        <section className="grid grid-cols-2 gap-x-16 gap-y-6 mb-4 col-span-2">
                          {rentalTransactionTypeField(index, true)}
                          {chargeCodeField(index, true)}

                          <InputDollarsField
                            label="Amount"
                            name="amount"
                            id={"amount" + index}
                            dataCy={"amount" + index}
                            placeholder="Enter Amount"
                            value={newRecord.amount}
                            setValue={(value) =>
                              handleExtraFeeDetails(index, "amount", value)
                            }
                            errorText={extraTransacFeeErrors[index].amount}
                            style="!w-full"
                          />

                          <InputField
                            label="Description"
                            name="description"
                            id={"description" + index}
                            dataCy={"description" + index}
                            type="text"
                            placeholder="Enter Description"
                            value={newRecord.description}
                            onChange={(e) =>
                              handleExtraFeeDetails(
                                index,
                                "description",
                                e.target.value
                              )
                            }
                            errorText={extraTransacFeeErrors[index].description}
                            style="!w-full"
                          />

                          <ButtonGrayOutline
                            id="removeTransaction"
                            btnName={"removeTransaction" + index}
                            dataCy={"removeTransaction" + index}
                            name={
                              <span className="flex items-center gap-1">
                                <RiDeleteBin6Line /> Delete Transaction Detail{" "}
                                {index + 1}
                              </span>
                            }
                            onClick={(e) =>
                              handleDeleteTransactionDetail(index)
                            }
                            className={`mt-2 !w-fit ${
                              index == extraTransacFeeDetails.length - 1
                                ? `justify-self-end`
                                : "justify-self-center col-span-2"
                            } `}
                          />

                          {index === extraTransacFeeDetails.length - 1 && (
                            <ButtonBlueOutline
                              id="addNewTransaction"
                              btnName="addNewTransaction"
                              dataCy="addNewTransaction"
                              name={
                                <span className="flex items-center gap-1">
                                  <AiOutlinePlusCircle /> Add New Transaction
                                  Detail
                                </span>
                              }
                              onClick={(e) => handleAddNewTransactionDetail()}
                              className="mt-2 !w-fit"
                            />
                          )}
                        </section>
                      </React.Fragment>
                    ))}

                  <ButtonGrayOutline
                    id="cancel"
                    btnName="cancel"
                    dataCy="cancel"
                    name="Cancel"
                    onClick={() => props.setTrigger(false)}
                    className="mt-6 justify-self-end"
                  />
                  <ButtonBlue
                    id="next"
                    btnName="next"
                    dataCy="next"
                    name="Next"
                    onClick={(e) => handleSteps(e)}
                    className="mt-6"
                  />
                </section>
              </>
            ) : null}

            {activeStep === 1 ? (
              <>
                <span className="border-b-2 border-blue-52 pb-1 mb-4 text-blue-52 w-5/6">
                  Review Details
                </span>
                <section className="grid grid-cols-2 gap-x-16 gap-y-6 w-5/6 mb-4">
                  <TextReviewTile
                    title="Rental Transaction Type"
                    body={selectedRentalTransactionType}
                  />

                  <TextReviewTile
                    title="Charge Code"
                    body={selectedChargeCodeDescription}
                    hidden={
                      selectedRentalTransactionType === "Charge" ||
                      selectedRentalTransactionType === "Termination Fees"
                        ? false
                        : true
                    }
                  />
                  <TextReviewTile
                    title="For Month"
                    body={forMonth}
                    hidden={
                      selectedRentalTransactionType !== "Charge" ? true : false
                    }
                  />

                  <TextReviewTile
                    title="Pending Charge"
                    body={selectedPendingCharge}
                    hidden={
                      selectedRentalTransactionType === "Charge" ||
                      selectedRentalTransactionType === "Termination Fees"
                        ? true
                        : false
                    }
                  />
                  <TextReviewTile
                    title="Pending Amount"
                    body={pendingAmount}
                    hidden={
                      selectedRentalTransactionType === "Charge" ||
                      selectedRentalTransactionType === "Termination Fees"
                        ? true
                        : false
                    }
                  />

                  <TextReviewTile
                    title="Description"
                    body={description}
                    hidden={
                      selectedRentalTransactionType === "Termination Fees"
                        ? false
                        : true
                    }
                  />

                  <TextReviewTile
                    title="Amount"
                    body={"$" + amount.toLocaleString()}
                  />

                  {selectedRentalTransactionType === "Termination Fees" &&
                    extraTransacFeeDetails.map((newRecord, index) => (
                      <React.Fragment key={index}>
                        <span className="border-b-2 border-blue-52 pb-1 text-blue-52 col-span-2">
                          Additional Transaction Details {index + 1}
                        </span>
                        <section className="grid grid-cols-2 gap-x-16 gap-y-4 mb-4 col-span-2">
                          <TextReviewTile
                            title="Rental Transaction Type"
                            body={selectedRentalTransactionType}
                          />

                          <TextReviewTile
                            title="Charge Code"
                            body={selectedChargeCodeDescription}
                            hidden={
                              selectedRentalTransactionType === "Charge" ||
                              selectedRentalTransactionType ===
                                "Termination Fees"
                                ? false
                                : true
                            }
                          />

                          <TextReviewTile
                            title={"Description"}
                            body={newRecord.description}
                            hidden={
                              selectedRentalTransactionType ===
                              "Termination Fees"
                                ? false
                                : true
                            }
                          />

                          <TextReviewTile
                            title={"Amount"}
                            body={"$" + newRecord.amount.toLocaleString()}
                          />
                        </section>
                      </React.Fragment>
                    ))}

                  {selectedRentalTransactionType === "Termination Fees" && (
                    <div className="grid grid-cols-2 gap-x-16 gap-y-4 mb-4 col-span-2 bg-blue-30 p-2">
                      <p className="font-semibold">TOTAL</p>
                      <p>${totalTerminAmount}</p>
                    </div>
                  )}
                </section>

                <div className="flex justify-center items-center gap-16 mt-6 mb-4 w-3/4">
                  <ButtonGrayOutline
                    id="back"
                    btnName="back"
                    dataCy="back"
                    name="Back"
                    onClick={(e) => handleBack(e)}
                    hidden={apiProcessing.loader}
                  />
                  <ButtonBlue
                    id="confirm"
                    btnName="confirm"
                    dataCy="confirm"
                    name="Confirm"
                    onClick={handleSubmit}
                  />
                </div>
              </>
            ) : null}
          </form>
        </DialogContent>
      </Dialog>
    </>
  ) : (
    ""
  );
};

export default AddRentalTransactions;
