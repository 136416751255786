import React, { useState, useEffect, useRef } from "react";
import CreateTenant from "../CreateTenant";
import CreateMaintenanceRequest from "../MaintenanceRequest/CreateMaintenanceRequest";
import { portfolioTreeStore } from "../../atoms";
import { WebApimanager } from "../../WebApiManager";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  GetDateInMMDDYYYY,
  TableComponent,
  Searchbar,
  clickEffect,
  Gallery,
} from "../../Widgets/CommonWidgets";
import CreateUnit from "../CreateUnit";
import ExportCSV from "../../Widgets/ExportCSV";
import swal from "sweetalert";
import { Snackbar, Alert } from "@mui/material";
import {
  BsThreeDotsVertical,
  BsFillArrowLeftCircleFill,
  BsPlusCircle,
} from "react-icons/bs";
import { RiDeleteBin7Line } from "react-icons/ri";
import { TiEdit, TiDelete } from "react-icons/ti";
import { FiTool } from "react-icons/fi";
import { RiArrowRightSLine } from "react-icons/ri";
import { AiOutlinePlus, AiOutlineEye, AiOutlineSearch } from "react-icons/ai";
import { TbFileArrowRight } from "react-icons/tb";
import {
  ButtonBlueOutline,
  ButtonCustom,
  ButtonIcon,
  ButtonYellowOutline,
} from "../../Widgets/Buttons";
import FloorPlanDetails from "../CreateFloorPlan/FloorPlanDetails";
import { BsDownload } from "react-icons/bs";
import { TbClipboardText, TbFileUpload } from "react-icons/tb";
import { IconInCircle } from "../../Widgets/Icons";
import { FcFolder } from "react-icons/fc";
import { CreateNewFolder, UploadNewFile } from "../Documents/documents";
import { GoogleChart } from "../../Widgets/charts";
import { ChartCard } from "../Dashboard";
import {
  PhoneNumFormat,
  last12MonthsList,
} from "../../Widgets/Forms/FieldDependants";
import { PDFViewer } from "../Documents/documents";
import { Loader } from "../../Widgets/notificationFeedbacks";
import { DetailsTile } from "../../Widgets/Forms/FormReviewTiles";
import { InputSelectField } from "../../Widgets/Forms/InputFields";

//Inspection
import AddInspection from "../Inspection/addInspection";
import InspectionDetails from "../Inspection/inspectionDetails";
import { ButtonBlue } from "../../Widgets/Buttons";

const UnitDetails = (props) => {
  let webApi = new WebApimanager();
  let navigate = useNavigate();
  let location = useLocation();
  let allFloorPlans = location.state;
  const [portfolioTree, setPortfolioTree] = useRecoilState(portfolioTreeStore);
  const handleFileInput = useRef(null);

  const [unitInfo, setUnitInfo] = useState("");
  const [floorPlanInfo, setfloorPlanInfo] = useState([]);

  const [createUnitpage, setCreateUnitpage] = useState(false);
  const [createTenantpage, setCreateTenantpage] = useState(false);
  const [tenants, setTenants] = useState([]);
  const [tenantsTableData, setTenantsTableData] = useState([]);

  const [editableData, setEditableData] = useState("");
  const [formOperation, setFormOperation] = useState("");

  const [maintenanceTickets, setMaintenanceTickets] = useState([]);
  const [maintenanceTicketsTableData, setMaintenanceTicketsTableData] =
    useState([]);
  const [openMaintenanceReqPage, setOpenMaintenanceReqPage] = useState(false);

  const [vendorsTableData, setVendorsTabledata] = useState([]);
  const [allVendors, setAllVendors] = useState([]);
  const [activeMenuItem, setActiveMenuItem] = useState("home");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [viewFloorPlanDetails, setViewFloorPlanDetails] = useState();
  const [allFloorPlanDetails, setAllFloorPlanDetails] = useState("");

  //Doucments
  const [currentPrefix, setCurrentPrefix] = useState("");
  const [allFolders, setAllFolders] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [documentsTableData, setDocumentsTableData] = useState([]);
  const [currentFolders, setCurrentFolders] = useState([]);
  const [docsSearchString, setDocsSearchString] = useState("");
  const [openFolderForm, setOpenFolderForm] = useState(false);
  const [openFileUploadForm, setOpenFileUploadForm] = useState(false);
  const [openPdf, setOpenPdf] = useState(false);
  const [openGallery, setOpenGallery] = useState(false);
  const [fileLink, setFileLink] = useState("");
  const [videoLink, setVideoLink] = useState("");

  //Inspection
  const [openInspectionForm, setOpenInspectionForm] = useState(false);
  const [inspectionTypes, setInspectionTypes] = useState([]);
  const [allInspectionRecords, setAllInspectionRecords] = useState([]);
  const [inspectionTableData, setInspectionTableData] = useState([]);
  const [inspectionFilterValue, setInspectionFilterValue] = useState("");
  const [viewInspectionDetails, setViewInspectionDetails] = useState("");

  //Home
  const [propertyRevenue, setPropertyRevenue] = useState([
    ["Annual Revenue", "Rent Collected", "Actual Rent"],
    ["Jan", 0, 0],
    ["Feb", 0, 0],
    ["Mar", 0, 0],
    ["Apr", 0, 0],
    ["May", 0, 0],
    ["Jun", 0, 0],
    ["Jul", 0, 0],
    ["Aug", 0, 0],
    ["Sep", 0, 0],
    ["Oct", 0, 0],
    ["Nov", 0, 0],
    ["Dec", 0, 0],
  ]);

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  const propertyRevenueOptions = {
    colors: ["#D96470", "#4049AC"],
    legend: {
      position: "right",
      alignment: "start",
    },
  };

  const tabs = [
    { label: "Home", nickName: "home" },
    { label: "Floor Plans", nickName: "floorPlans" },
    //{ label: "Vendor", nickName: "vendor" },
    { label: "Tenants", nickName: "tenants" },
    { label: "Documents", nickName: "documents" },
    { label: "Maintenance", nickName: "maintenance" },
    { label: "Inspection", nickName: "inspection" },
    //{ label: "Lease Renewal", nickName: "leaseRenewal" },
  ];

  useEffect(() => {
    getUnitData();
    getHomeWidgetsData(portfolioTree.unit.ID);
    getFloorPlanData();
    getAllTenants();
    getAllMaintenanceTickets();
    getVendorDetailsByUnit();
    //getActiveLeaseByUnit();
    getDocumentsByUnitId(portfolioTree.unit.ID);

    //Inspection API's
    getAllInspectionRecords();
    getInspectionTypes();
  }, []);

  const getUnitData = () => {
    webApi
      .get("/units/" + portfolioTree.unit.ID)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setPortfolioTree({
            ...portfolioTree,
            unit: res.data,
          });
          setUnitInfo(res.data);
        }
      })
      .catch((error) => {
        swal(
          "Error",
          `Oops! Failed to get Unit Details, Please try again later.`,
          "error"
        );
      });
  };

  const getHomeWidgetsData = (unitId) => {
    webApi
      .get(`/units/${unitId}/dashboard`)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          const tempRev = res.data.revenue;

          const RevenueData = [
            ["Annual Revenue", "Rent Collected", "Actual Rent"],
            ...last12MonthsList.map((month) => {
              const collectedRent = tempRev[month]?.collectedRent ?? 0;
              const actualRent = tempRev[month]?.actualRent ?? 0;
              return [month, collectedRent, actualRent];
            }),
          ];

          setPropertyRevenue(RevenueData);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to get Home Dashboard data, Please try again later.`,
            "error"
          );
        }
      });
  };

  const getFloorPlanData = () => {
    if (portfolioTree.unit.unitTypeId !== 0) {
      webApi
        .get("/unitTypes/" + portfolioTree.unit.unitTypeId)
        .then((res) => {
          if (res.data.errorCode) {
            swal("Error", res.data.errorMessage, "error");
          } else {
            const temp = [res.data];
            setfloorPlanInfo(temp);
          }
        })
        .catch((error) => {
          swal(
            "Error",
            `Oops! Failed to get Floor Plan Details, Please try again later.`,
            "error"
          );
        });
    }
  };

  const getAllTenants = () => {
    webApi
      .get("/tenant/unit/" + portfolioTree.unit.ID)
      .then((res) => {
        if (res.data !== null) {
          if (res.data.errorCode) {
            swal("Failure", res.data.errorMessage, "error");
          } else {
            setTenants(res.data);
            setTenantsTableData(res.data);
          }
        }
      })
      .catch((error) => {
        swal(
          "Error",
          `Oops! Failed to get Tenants, Please try again later.`,
          "error"
        );
      });
  };

  const getAllMaintenanceTickets = () => {
    webApi
      .get("/maintenance/tickets/unit/" + portfolioTree.unit.ID)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          if (res.data !== null) {
            setMaintenanceTickets(res.data.slice().reverse());
            setMaintenanceTicketsTableData(res.data.slice().reverse());
          }
        }
      })
      .catch((error) => {
        swal(
          "Error",
          `Oops! Failed to get Maintenance Work Orders, Please try again later.`,
          "error"
        );
      });
  };

  const getVendorDetailsByUnit = () => {
    webApi
      .get("/vendor/vendorDetails/unit/" + portfolioTree.unit.ID)
      .then((res) => {
        setVendorsTabledata(res.data);
        setAllVendors(res.data);
      })
      .catch((error) => {
        swal(
          "Error",
          `Oops! Failed to get Vendors, Please try again later.`,
          "error"
        );
      });
  };

  const validateFileTypes = (image) => {
    const allowedExtensions = ".png, .jpg, .jpeg"
      .split(",")
      .map((type) => type.trim());

    const fileExtension = image.name.split(".").pop().toLowerCase();

    if (allowedExtensions.includes("." + fileExtension)) {
      UploadUnitImage(image);
    } else {
      swal(
        "Warning",
        "Invalid File type, Please choose a file with one of the (.png, .jpg, .jpeg) file types",
        "warning"
      );
    }
  };

  const UploadUnitImage = (image) => {
    setSnackbarMessage("Uploading Unit Image");
    setSnackbarType("info");
    setOpenSnackbar(true);
    console.log("Upload image argumets", image);
    webApi
      .post("/units/image/" + portfolioTree.unit.ID + "&" + image.name)
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          const metaData = {
            filename: image.name,
            mediatype: image.type,
          };
          webApi
            .uploadFile(res.data, metaData, image)
            .then((res) => {
              getUnitData();
              swal("Success", "Successfully Updated Unit Image", "success");
            })
            .catch((error) => {
              if (error.customErrorMessage) {
                swal("Error", error.customErrorMessage, "error");
              } else {
                swal(
                  "Error",
                  "Failed to Update Unit Image. Please try again later.",
                  "error"
                );
              }
            });
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Failed to Update Unit Image. Please try again later.",
            "error"
          );
        }
      });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const buttonProps = {
    tenants: {
      id: "AddTenant",
      onClick: () => {
        setCreateTenantpage(true);
        // if (
        //   portfolioTree &&
        //   portfolioTree.property.connectedAccountId
        // ) {
        //   setCreateTenantpage(true);
        // } else {
        //   swal(
        //     "Warning",
        //     "There is no connected bank account linked with this property and you won't be able to receive any payments from the Tenant. Please link a connected bank account with this property to receive the payments.",
        //     "warning"
        //   );
        // }
      },
    },
    maintenance: {
      id: "maintenanceRequest",
      onClick: () => {
        setOpenMaintenanceReqPage(true);
      },
    },
  };

  const customActionsContentTenant = (row) => (
    <>
      <ButtonBlue
        name="Resend"
        title="Resend Invitation"
        onClick={() => resendTenantInvitation(row)}
        hidden={row.isVerified === true}
        className="text-sm font-normal w-auto h-auto cursor-pointer bg-white border-blue-52 text-blue-52 hover:text-white focus:text-white"
      />
    </>
  );

  //Resending the Tenant invitaion
  const resendTenantInvitation = (row) => {
    // console.log("resend", row.email)
    setApiProcessing({
      loader: true,
      message: `Resending Invitation...`,
    });
    webApi
      .post(`/tenant/resendTempPassword/${row.email}`)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          swal("Success", res.data.message, "success");
          getAllTenants();
        }
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Failed to send invitation. Please try again later.`,
            "error"
          );
        }
      });
  };

  const searchbarProps = {
    tenants: {
      placeholder: "Search Tenants..",
      data: tenants,
      matchProps: [
        { text: "firstName" },
        { text: "unitNumber" },
        { text: "email" },
        { text: "phoneNumber" },
        { text: "tenantStatus" },
        { text: "residentCode" },
      ],
      setTableData: setTenantsTableData,
    },
    maintenance: {
      placeholder: "Search Maintenance Requests..",
      data: maintenanceTickets,
      matchProps: [
        { text: "woid" },
        { text: "requestType" },
        { text: "category" },
        { text: "vendor" },
        { text: "taskAssigned" },
        { text: "status" },
      ],
      setTableData: setMaintenanceTicketsTableData,
    },
    vendor: {
      placeholder: "Search Vendors",
      data: allVendors,
      matchProps: [
        { text: "companyName" },
        { text: "firstName" },
        { text: "lastName" },
        { text: "vendorType" },
        { text: "contactNo" },
        { text: "email" },
        { text: "vendorType" },
      ],
      setTableData: setVendorsTabledata,
    },
  };

  const tableProps = {
    floorPlans: {
      tableId: "floorPlanTable",
      data: floorPlanInfo,
      rowOnClick: (row) => viewFloorPlan(row),
      columns: [
        { key: "unitTypeName", title: "Floor Plan Name" },
        {
          key: "unitCode",
          title: "Floor Plan Code",
        },
        // { key: "numberOfRooms", title: "No. Of Rooms" },
        { key: "numberOfBeds", title: "No. of Beds" },
        { key: "numberOfBaths", title: "No. of Bathrooms" },
      ],
      actionHeader: "Actions",
      actions: {
        eyeTitle: "View Floor Plan",
        eyeOnClick: (row) => viewFloorPlan(row),
      },
    },
    vendor: {
      tableId: "VendorsTable",
      data: [vendorsTableData],
      //rowOnClick: (row) => {},
      columns: [
        { key: "companyName", title: "Vendor Company Name" },
        {
          key: "firstName",
          title: "Vendor Contact",
          // applyMethod: (row) => {
          //   return row.firstName + " " + row.lastName;
          // },
        },
        {
          key: "contactNo",
          title: "Contact No",
          applyMethod: (row) => PhoneNumFormat(row.contactNo, true),
        },
        { key: "email", title: "Email" },
        { key: "vendorType", title: "Type" },
      ],
    },
    tenants: {
      tableId: "tenantsTable",
      data: tenantsTableData,
      // rowOnClick: () => {},
      rowOnClick: (row) => {
        setPortfolioTree({
          ...portfolioTree,
          tenant: row,
        });
        navigate("/tenants/tenantDetails");
      },
      columns: [
        { key: "name", title: "Name" },
        {
          key: "unitNumber",
          title: "Unit Number",
        },
        {
          key: "residentCode",
          title: "Resident Code",
        },
        {
          key: "email",
          title: "Email",
        },
        {
          key: "phoneNumber",
          title: "Contact No.",
          applyMethod: (row) => PhoneNumFormat(row.phoneNumber, true),
        },
        { key: "tenantStatus", title: "Tenant Status" },
      ],
      actions: {
        // deleteTitle: "Delete Tenant",
        // deleteOnClick: (row) => {},
        // eyeTitle: "View, Edit Tenant",
        // eyeOnClick: (row) => {
        //   setPortfolioTree({
        //     ...portfolioTree,
        //     tenant: row,
        //   });
        //   navigate("/tenants/tenantDetails");
        // },
        customActions: (row) => customActionsContentTenant(row),
      },
      // actionHeader: (
      //   <AiOutlinePlus
      //     title="Add Tenant"
      //     onClick={() => {
      //       setCreateTenantpage(true);
      //     }}
      //     className="w-5 h-6 cursor-pointer focus:text-blue-52 hover:text-blue-52"
      //   />
      // ),
    },
    maintenance: {
      tableId: "maintenanceTable",
      data: maintenanceTicketsTableData,
      columns: [
        { key: "woid", title: "Work Order ID" },
        { key: "requestType", title: "Request Type" },
        {
          key: "category",
          title: "Category",
        },
        {
          key: "CreatedAt",
          title: "Date of Request",
          applyMethod: (row) => GetDateInMMDDYYYY(row.CreatedAt),
        },
        {
          key: "UpdatedAt",
          title: "Last Updated",
          applyMethod: (row) => GetDateInMMDDYYYY(row.UpdatedAt),
        },
        { key: "vendor", title: "Vendor" },
        { key: "taskAssigned", title: "Assigned To" },
        {
          key: "status",
          title: "Status",
          dataClassName: "font-medium",
          // getCellStyles: (row) => {
          //   return { color: row.statusColor };
          // },
          //applyMethod: (row) => changetoUpperCase(status),
        },
      ],
      actions: {
        // deleteTitle: "Delete Maintenance",
        // deleteOnClick: (row) => {},
        // editTitle: "Edit Maintenance",
        editOnClick: (row) =>
          navigate("/maintenance/maintenanceDetails", {
            state: { ticketId: row.ID },
          }),
      },
      //initialSort: { key: "woid", direction: "descending" },
      rowOnClick: (row) =>
        navigate("/maintenance/maintenanceDetails", {
          state: { ticketId: row.ID },
        }),
      // actionHeader: (
      //   <AiOutlinePlus
      //     title="Submit a Request"
      //     onClick={() => {
      //       setOpenMaintenanceReqPage(true);
      //     }}
      //     className="w-5 h-6 cursor-pointer focus:text-blue-52 hover:text-blue-52"
      //   />
      // ),
    },
  };

  const viewFloorPlan = (row) => {
    setViewFloorPlanDetails(true);
    setAllFloorPlanDetails(row);
  };

  //Documents API's
  const getDocumentsByUnitId = (unitId) => {
    setApiProcessing({
      loader: true,
      message: ``,
    });
    webApi
      .get(`/documents/unit/${unitId}`)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setCurrentPrefix(res.data.prefix);
          setAllFolders(res.data.folders);
          setCurrentFolders(res.data.folders);
          if (res.data.files.length) {
            const transformedFiles = res.data.files.map((fileName) => ({
              file: fileName,
            }));
            setDocumentsTableData(transformedFiles);
            setDocuments(transformedFiles);
          } else {
            setDocumentsTableData([]);
            setDocuments([]);
          }
        }
        setApiProcessing({
          loader: false,
          message: ``,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: ``,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Failed to get Documents and Folders. Please try again later.",
            "error"
          );
        }
      });
  };

  const createFolder = (folderName) => {
    setApiProcessing({
      loader: true,
      message: `Creating Folder...`,
    });
    const data = {
      prefix: currentPrefix,
    };
    webApi
      .post(`/documents/newFolder/${folderName}`, data)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setCurrentPrefix(res.data.prefix);
          setAllFolders(res.data.folders);
          setCurrentFolders(res.data.folders);
          if (res.data.files.length) {
            const transformedFiles = res.data.files.map((fileName) => ({
              file: fileName,
            }));
            setDocumentsTableData(transformedFiles);
            setDocuments(transformedFiles);
          } else {
            setDocuments([]);
            setDocumentsTableData([]);
          }
        }
        setApiProcessing({
          loader: false,
          message: ``,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: ``,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Failed to Create Folder ${folderName}. Please try again later.`,
            "error"
          );
        }
      });
  };

  const handleDocumentsFileUpload = (file) => {
    setApiProcessing({
      loader: true,
      message: `Uploading File...`,
    });
    const data = {
      objectKey: `${currentPrefix}${file.name}`,
      metadata: {
        filename: file.name,
        mediatype: file.type,
      },
    };

    webApi
      .uploadFile(data.objectKey, data.metadata, file)
      .then((res) => {
        getDocumentsByPrefix(`${currentPrefix}`);
        setApiProcessing({
          loader: false,
          message: ``,
        });
        swal("Success", "File Upload is successful", "success");
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: ``,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Failed to upload file ${file.name}. Please try again later.`,
            "error"
          );
        }
      });
  };

  const handlePrefixClick = (index) => {
    const clickedFolders = currentPrefix.split("/").slice(0, index + 1);
    const updatedPrefix = clickedFolders.join("/");
    getDocumentsByPrefix(`${updatedPrefix}/`);
  };

  const getDocumentsByPrefix = (newprefix) => {
    setApiProcessing({
      loader: true,
      message: `Gathering Documents and Folders`,
    });
    const data = {
      prefix: newprefix,
    };
    webApi
      .post("/documents/prefix", data)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setCurrentPrefix(res.data.prefix);
          setAllFolders(res.data.folders);
          setCurrentFolders(res.data.folders);
          if (res.data.files.length) {
            const transformedFiles = res.data.files.map((fileName) => ({
              file: fileName,
            }));
            setDocumentsTableData(transformedFiles);
            setDocuments(transformedFiles);
          } else {
            setDocuments([]);
            setDocumentsTableData([]);
          }
        }
        setApiProcessing({
          loader: false,
          message: ``,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: ``,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops, something went wrong! Please try again later.",
            "error"
          );
        }
      });
  };

  const filesTableActions = {
    downloadTitle: "Download",
    downloadOnclick: (row) => {
      getFileLink(row.file, "download");
    },
    mailTitle: "Mail",
    mailOnclick: (row) => {
      swal(
        "Info",
        "Mail Option isn't available at the moment, Our Developers are working on it.",
        "info"
      );
    },
  };

  const handleDocumentsSearch = (event) => {
    const currentSearchString = event.target.value;
    setDocsSearchString(currentSearchString);

    if (currentSearchString === "") {
      setCurrentFolders(allFolders);
      setDocumentsTableData(documents);
    } else {
      const filteredFolders = allFolders.filter((folder) =>
        folder.toLowerCase().includes(currentSearchString.toLowerCase())
      );

      setCurrentFolders(filteredFolders);

      const filteredFiles = documents.filter((singleFile) =>
        singleFile.file
          .toLowerCase()
          .includes(currentSearchString.toLowerCase())
      );

      setDocumentsTableData(filteredFiles);
    }
  };

  const getFileLink = (fileName, action) => {
    setApiProcessing({
      loader: true,
      message: `Getting File Link...`,
    });
    setFileLink("");
    setVideoLink("");
    const data = {
      objectKey: `${currentPrefix}${fileName}`,
    };
    webApi
      .post(`/documents/download`, data)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          if (action === "view") {
            if (
              res.data.metadata.filename.split(".").pop().toLowerCase() ===
              "pdf"
            ) {
              setFileLink(res.data.objectKey);
              setOpenPdf(true);
            } else if (
              ["jpg", "jpeg", "png"].includes(
                res.data.metadata.filename.split(".").pop().toLowerCase()
              )
            ) {
              setFileLink(res.data.objectKey);
              setOpenGallery(true);
            } else {
              setVideoLink(res.data.objectKey);
              setOpenGallery(true);
            }
          } else if (action === "download") {
            window.location.href = res.data.objectKey;
            console.log("download");
          }
        }
        setApiProcessing({
          loader: false,
          message: ``,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: ``,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else
          swal(
            "Error",
            "Oops, Unable to get the File! Please try again later.",
            "error"
          );
      });
  };

  //Inspection API's

  const getInspectionTypes = () => {
    setApiProcessing({
      loader: true,
      message: `Gathering Inspection Types...`,
    });
    webApi
      .get("/inspection/types")
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setInspectionTypes(["All", ...res.data.inspectionType]);
        }
        setApiProcessing({ loader: false, message: `` });
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops, Unable to get Inspection Types. Please try again later.",
            "error"
          );
        }
      });
  };

  const getAllInspectionRecords = () => {
    setApiProcessing({
      loader: true,
      message: `Gathering Inspection Records...`,
    });
    webApi
      .get(`/inspection/unit/${portfolioTree.unit.ID}`)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setInspectionTableData(res.data);
          setAllInspectionRecords(res.data);
        }
        setApiProcessing({ loader: false, message: `` });
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops! Unable to get Inspection Records, Please try again later.",
            "error"
          );
        }
      });
  };

  const handleInspectionFilter = (e) => {
    const currentInspectionType = e.target.value;
    if (currentInspectionType === "All") {
      setInspectionTableData(allInspectionRecords);
    } else {
      const filteredData = allInspectionRecords.filter(
        (item) => item.inspectionType === currentInspectionType
      );
      setInspectionTableData(filteredData);
    }
    setInspectionFilterValue(currentInspectionType);
  };

  const inspectionTableActions = {
    mailTitle: "Mail",
    mailOnclick: (row) => {
      swal(
        "Info",
        "Mail Option isn't available at the moment, Our Developers are working on it.",
        "info"
      );
    },
    editTitle: "Edit Inspection",
    editOnClick: (row) => {
      setEditableData(row);
      setFormOperation("Edit");
      setOpenInspectionForm(true);
    },
    downloadTitle: "Download",
    downloadOnclick: (row) => {
      swal(
        "Info",
        "Download Option isn't available at the moment, Our Developers are working on it.",
        "info"
      );
    },
  };

  const downloadPDF = () => {
    let data = {
      unitId : portfolioTree.unit.ID,
      type : "Multi-Family"
    }
    webApi
      .post("/report/inspectionList/pdf", data , { loader: true })
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          const link = document.createElement('a');
          link.href = res.data.url;
          link.setAttribute('download', 'filename.pdf'); // You can set a default filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Apologies, Failed to download Inspection Report, Please try again later.`,
            "error"
          );
        }
      });
  }

  return (
    <>
      <Snackbar
        open={openSnackbar}
        onClose={handleSnackbarClose}
        autoHideDuration={5000}
      >
        <Alert
          onClose={handleSnackbarClose}
          variant="filled"
          severity={snackbarType}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {viewFloorPlanDetails ? (
        <FloorPlanDetails
          trigger={viewFloorPlanDetails}
          floorPlanDetails={allFloorPlanDetails}
          setTrigger={setViewFloorPlanDetails}
        />
      ) : null}

      {createUnitpage && (
        <CreateUnit
          openUnitFormPage={createUnitpage}
          setUnitFormPage={setCreateUnitpage}
          Property={portfolioTree.property}
          allFloorPlans={allFloorPlans}
          unitData={portfolioTree.unit}
          operation={"Edit"}
          getUnits={() => getUnitData()}
        />
      )}

      {createTenantpage ? (
        <CreateTenant
          openTenantFormPage={createTenantpage}
          allUnits={[unitInfo]}
          getTenants={() => {
            getAllTenants();
            getUnitData();
          }}
          setTenantFormPage={setCreateTenantpage}
        />
      ) : null}

      {openMaintenanceReqPage ? (
        <CreateMaintenanceRequest
          trigger={openMaintenanceReqPage}
          setTrigger={setOpenMaintenanceReqPage}
          unitId={portfolioTree.unit.ID}
          getMaintenanceTickets={(data) => {
            getAllMaintenanceTickets();
            if (data.requestType === "Turnover") {
              getUnitData();
            }
          }}
        />
      ) : null}

      <CreateNewFolder
        trigger={openFolderForm}
        setTrigger={setOpenFolderForm}
        handleCreate={(folderName) => createFolder(folderName)}
      />

      <UploadNewFile
        trigger={openFileUploadForm}
        setTrigger={setOpenFileUploadForm}
        title="Upload New File"
        accept=".png, .jpg, .jpeg, .pdf"
        placeholder="Attach .png, .jpg, .jpeg, .pdf format only"
        handleUpload={(file) => handleDocumentsFileUpload(file)}
      />

      {openGallery && (
        <Gallery
          images={[fileLink]}
          videos={[videoLink]}
          openGallery={openGallery}
          setOpenGallery={setOpenGallery}
        />
      )}

      {openInspectionForm && (
        <AddInspection
          trigger={openInspectionForm}
          setTrigger={setOpenInspectionForm}
          unitId={unitInfo && unitInfo.ID}
          tenants={tenants}
          inspectionTypes={inspectionTypes}
          operation={formOperation}
          editData={editableData}
          getAllInspections={(newRecord) => {
            getAllInspectionRecords();
            getUnitData();
            getAllTenants();
            setEditableData("");
          }}
        />
      )}

      {openPdf && (
        <PDFViewer pdfUrl={fileLink} onClose={(e) => setOpenPdf(false)} />
      )}

      <Loader open={apiProcessing.loader} message={apiProcessing.message} />

      {!viewInspectionDetails && (
        <div className="w-11/12 m-auto border">
          <section
            name="Title and Options"
            className="flex justify-between items-center bg-blue-30 h-10"
          >
            <span className="flex gap-4">
              <BsFillArrowLeftCircleFill
                title="Back to Properties Details"
                onClick={() => {
                  setPortfolioTree({
                    ...portfolioTree,
                    unit: {},
                    tenant: {},
                  });
                  navigate("/portfolio/propertyDetails");
                }}
                className="text-blue-52 w-6 h-6 ml-2"
              />
              <p className="font-semibold">
                {unitInfo && unitInfo.unitNumber} Overview
              </p>
            </span>

            <div
              name="Unit Options"
              className="flex text-gray-700 items-center gap-4 mr-4"
            >
              {/* <RiDeleteBin7Line className="w-5 h-5 hover:text-red-500 focus:text-red-500 cursor-pointer" /> */}
              <TiEdit
                id="editUnit"
                name="editUnit"
                title="Edit Unit"
                className={` w-5 h-5 cursor-pointer hover:text-blue-53`}
                onClick={() => {
                  setCreateUnitpage(true);
                }}
              />
              {/* <BsThreeDotsVertical className="w-5 h-5 mr-4" /> */}
            </div>
          </section>

          <section
            name="unitDetails"
            className="flex justify-around items-start mx-4 my-6 gap-4 md:overflow-x-auto"
          >
            <div className="w-96 h-52 border flex flex-col justify-center items-center bg-gray-50">
              {unitInfo && unitInfo.image !== "" ? (
                <img
                  id="unitImage"
                  src={unitInfo.image}
                  className="w-full h-full object-cover cursor-pointer"
                  onClick={(e) => {
                    setVideoLink("");
                    setFileLink(unitInfo.image);
                    setOpenGallery(true);
                  }}
                />
              ) : (
                <>
                  <p className="text-gray-600 text-center">
                    No Unit Image to Display
                  </p>
                  <input
                    accept=".png, .jpg, .jpeg"
                    type="file"
                    className="hidden"
                    ref={handleFileInput}
                    onChange={(e) => validateFileTypes(e.target.files[0])}
                  />
                  <button
                    id="uploadImage"
                    name="uploadImage"
                    data-cy="uploadImage"
                    onClick={() => handleFileInput.current.click()}
                    className={`text-blue-54 mt-2 p-1 rounded-md border border-blue-54 shadow hover:bg-blue-54 hover:text-white ${clickEffect}`}
                  >
                    Upload Image
                  </button>
                </>
              )}
            </div>

            <div className="grid grid-cols-2 gap-4">
              <span className="flex flex-col gap-2">
                <DetailsTile
                  label="Unit Status"
                  data={unitInfo && unitInfo.unitStatus}
                  labelClassName="text-gray-500"
                />

                <DetailsTile
                  label="Unit No."
                  data={unitInfo && unitInfo.unitNumber}
                  labelClassName="text-gray-500"
                />

                {/* <DetailsTile
                  label="No. of Rooms"
                  data={unitInfo && unitInfo.numberOfRooms}
                  labelClassName="text-gray-500"
                /> */}

                <DetailsTile
                  label="No. of Beds"
                  data={unitInfo && unitInfo.numberOfBeds}
                  labelClassName="text-gray-500"
                />

                <DetailsTile
                  label="No. of Bathrooms"
                  data={unitInfo && unitInfo.numberOfBaths}
                  labelClassName="text-gray-500"
                />

                <DetailsTile
                  label="Area (Sq.ft)"
                  data={unitInfo && unitInfo.sqFt}
                  labelClassName="text-gray-500"
                />

                <DetailsTile
                  label="Monthly Rent"
                  data={unitInfo && "$" + unitInfo.rent.toLocaleString()}
                  labelClassName="text-gray-500"
                />

                <DetailsTile
                  label="Security Deposit"
                  data={unitInfo && "$" + unitInfo.deposit.toLocaleString()}
                  labelClassName="text-gray-500"
                />
              </span>

              <span className="flex flex-col gap-2">
                <DetailsTile
                  label="Pets Allowed"
                  data={unitInfo && unitInfo.isPetsAllowed ? `Yes` : `No`}
                  labelClassName="text-gray-500"
                />
                <DetailsTile
                  label="Pet Deposit"
                  data={unitInfo && "$" + unitInfo.petDeposit.toLocaleString()}
                  labelClassName="text-gray-500"
                />
                <DetailsTile
                  label="Street Address 1"
                  data={unitInfo && unitInfo.address.address1}
                  labelClassName="text-gray-500"
                />
                <DetailsTile
                  label="Street Address 2"
                  data={
                    unitInfo && unitInfo.address.address2 !== ""
                      ? unitInfo.address.address2
                      : `-`
                  }
                  labelClassName="text-gray-500"
                />
                <DetailsTile
                  label="City"
                  data={unitInfo && unitInfo.address.city}
                  labelClassName="text-gray-500"
                />
                <DetailsTile
                  label="State"
                  data={unitInfo && unitInfo.address.state}
                  labelClassName="text-gray-500"
                />
                <DetailsTile
                  label="Country"
                  data={unitInfo && unitInfo.address.country}
                  labelClassName="text-gray-500"
                />
                <DetailsTile
                  label="ZIP Code"
                  data={unitInfo && unitInfo.address.zipCode}
                  labelClassName="text-gray-500"
                />
              </span>
            </div>
          </section>

          <section
            name="tabs"
            className="flex justify-around items-center h-10 font-semibold shadow-lg text-gray-600 cursor-pointer border-t-2 border-blue-52"
          >
            {tabs.map((tab, index) => (
              <p
                key={index}
                id={tab.nickName}
                data-cy={tab.nickName}
                className={`hover:text-[#003C9A] focus:text-[#003C9A] pb-2 w-fit text-center ${
                  activeMenuItem === tab.nickName
                    ? `text-[#003C9A] border-[#003C9A] font-semibold w-fit border-b-2`
                    : `text-gray-600 border-gray-600`
                } 
                `}
                onClick={(e) => setActiveMenuItem(tab.nickName)}
              >
                {tab.label}
              </p>
            ))}
          </section>

          {activeMenuItem === "home" && (
            <section className="m-6 grid grid-cols-3 gap-x-4 gap-y-4 justify-between items-center text-center">
              <ChartCard
                title="Property Revenue Information"
                cardClassName="col-span-3"
                chart={
                  <>
                    <GoogleChart
                      chartType="Bar"
                      width="100%"
                      data={propertyRevenue}
                      options={propertyRevenueOptions}
                    />
                  </>
                }
              />
            </section>
          )}

          {activeMenuItem !== "home" &&
            activeMenuItem !== "documents" &&
            activeMenuItem !== "inspection" && (
              <>
                <section
                  className={`mt-2 mb-2 flex justify-end gap-2 ${
                    activeMenuItem === "floorPlans" ||
                    activeMenuItem === "leaseRenewal"
                      ? `hidden`
                      : `block`
                  }`}
                >
                  <ButtonCustom
                    id="addNew"
                    btnName="addNew"
                    dataCy="addNew"
                    {...buttonProps[activeMenuItem]}
                    name={
                      <>
                        <BsPlusCircle className="w-5 h-5 mr-2" /> Add New
                      </>
                    }
                    className={`flex justify-center items-center px-2 w-36 h-10 rounded-md text-blue-52 border-blue-52 hover:text-white hover:bg-blue-52`}
                  />

                  <Searchbar style="mr-2" {...searchbarProps[activeMenuItem]} />
                </section>
                <TableComponent {...tableProps[activeMenuItem]} />
              </>
            )}

          {activeMenuItem === "documents" && (
            <section name="Documents and Folders" className="my-6">
              <div name="Prefix listing" className="overflow-x-auto">
                <div className="flex items-center mb-2 mt-2 ml-4">
                  <span className="text-gray-500">Documents</span>
                  {currentPrefix.split("/").map((folder, index, array) => (
                    <React.Fragment key={index}>
                      <span className="text-gray-500 ml-1 mr-1">
                        &nbsp;&gt;&nbsp;
                      </span>

                      <span
                        className={
                          "cursor-pointer " +
                          (index === 0
                            ? "text-gray-500"
                            : "text-blue-500 " + clickEffect)
                        }
                        onClick={() => {
                          if (!(index === 0)) {
                            handlePrefixClick(index);
                          }
                        }}
                        title={folder.charAt(0).toUpperCase() + folder.slice(1)}
                      >
                        {index >= 2 && index <= array.length - 4
                          ? "..."
                          : folder.charAt(0).toUpperCase() + folder.slice(1)}
                      </span>
                    </React.Fragment>
                  ))}
                </div>
                <div className="border-b-2 border-gray-400"></div>
              </div>

              <div className="flex justify-end items-center mb-4 mt-4 gap-4 mr-4">
                <div
                  name="Folders and Files Search"
                  className={"flex justify-end items-center w-80"}
                >
                  <input
                    className={
                      " border shadow rounded-2xl w-full focus:outline-none pr-[30px] py-2 px-3"
                    }
                    placeholder={"Search Files, Folders ..."}
                    value={docsSearchString}
                    onChange={handleDocumentsSearch}
                  />
                  {docsSearchString === "" && (
                    <AiOutlineSearch
                      size={25}
                      className="cursor-pointer absolute mr-[4px] text-blue-52"
                    />
                  )}
                  {docsSearchString !== "" && (
                    <TiDelete
                      size={25}
                      className="cursor-pointer absolute mr-[4px] text-red-500"
                      onClick={() => {
                        setDocsSearchString("");
                        setCurrentFolders(allFolders);
                        setDocumentsTableData(documents);
                      }}
                    />
                  )}
                </div>

                {/* <span
                  title="Create Folder"
                  className={
                    "p-2 shadow-lg rounded flex justify-center items-center cursor-pointer hover:bg-yellow-300 hover:text-white " +
                    clickEffect
                  }
                >
                  <AiOutlinePlus
                    className="w-5 h-5"
                    onClick={(e) => setOpenFolderForm(true)}
                  />
                </span> */}

                <ButtonBlueOutline
                  id="addNewFolder"
                  new="addNewFolder"
                  dataCy="addNewFolder"
                  title="Create Folder"
                  name={
                    <>
                      <BsPlusCircle className="w-5 h-5 mr-2" /> Add New
                    </>
                  }
                  onClick={(e) => setOpenFolderForm(true)}
                  className={`flex justify-center items-center px-2 w-fit h-10 rounded-md`}
                />
              </div>

              <div className="grid grid-cols-3 gap-4 mb-8 mx-6">
                {currentFolders.length > 0 &&
                  currentFolders.map((folder, index) => (
                    <div
                      key={folder + index}
                      id="folderItem"
                      new="folderItem"
                      dataCy={index}
                      className={
                        "flex items-center cursor-pointer h-10 p-2 border rounded-md w-auto max-w-[300px] overflow-x-scroll overflow-y-hidden focus:outline-none shadow-lg bg-gray-50 " +
                        clickEffect
                      }
                      onClick={(e) =>
                        getDocumentsByPrefix(`${currentPrefix}${folder}/`)
                      }
                      title={folder.charAt(0).toUpperCase() + folder.slice(1)}
                    >
                      <FcFolder className="w-5 h-5 mr-2" />
                      <span className="text-blue-500 overflow-hidden whitespace-nowrap overflow-ellipsis">
                        {folder.charAt(0).toUpperCase() + folder.slice(1)}
                      </span>
                    </div>
                  ))}
              </div>

              <TableComponent
                tableId="Files table"
                data={documentsTableData}
                const
                columns={[
                  {
                    key: "icon",
                    title: "",
                    applyMethod: (row) => (
                      <IconInCircle
                        icon={
                          <TbClipboardText
                            title={row.file}
                            className="w-5 h-5 text-blue-52"
                          />
                        }
                        radius="2rem"
                        className="shadow-xl"
                        color="#D8F3F7"
                      />
                    ),
                    width: "80px",
                  },

                  { key: "file", title: "Name", width: "80%" },
                ]}
                rowOnClick={(row) => {
                  getFileLink(row.file, "view");
                }}
                actionHeader={
                  <span
                    title="Upload File"
                    className={
                      "p-1 rounded flex justify-center items-center cursor-pointer hover:text-white hover:bg-[#228B22] hover:shadow" +
                      clickEffect
                    }
                  >
                    <TbFileUpload
                      className="w-6 h-6"
                      onClick={(e) => setOpenFileUploadForm(true)}
                    />
                  </span>
                }
                actions={filesTableActions}
                //initialSort={{ key: "file", direction: "descending" }}
              />
            </section>
          )}

          {activeMenuItem === "inspection" && (
            <section className="mt-2">
              <div className="flex justify-between items-end m-4 gap-4">
                <InputSelectField
                  //label="Select"
                  name="inspectionType"
                  id="inspectionType"
                  placeholder="Select a Inspection Type"
                  notImp
                  options={inspectionTypes}
                  value={inspectionFilterValue}
                  onChange={handleInspectionFilter}
                />

                <div className="flex justify-between items-center gap-2">
                  <ButtonCustom
                    id="addNewInspection"
                    btnName="addNewInspection"
                    dataCy="addNewInspection"
                    disabled={unitInfo ? false : true}
                    name={
                      <>
                        <BsPlusCircle className="w-5 h-5 mr-2" /> Add New
                      </>
                    }
                    onClick={() => {
                      setFormOperation("New");
                      setOpenInspectionForm(true);
                    }}
                    className={`flex justify-center items-center px-2 w-36 h-10 rounded-md text-blue-52 border-blue-52  hover:bg-blue-52 hover:text-white`}
                  />

                  <ExportCSV
                  data={inspectionTableData}
                  keys={[
                    { key: "inspectionCode", title: "Inspection ID" },
                  { key: "tenantName", title: "Tenant Name" },
                  { key: "inspectionType", title: "Inspection Type" },
                  {
                    key: "inspectionDate",
                    title: "Inspection Date",
                    applyMethod: (row) => GetDateInMMDDYYYY(row.inspectionDate),
                  },
                  {
                    key: "inspectionPerformedBy",
                    title: "Performed By",
                  },
                  ]}
                  fileName="Inspection.csv"
                />
                  
                  <ButtonIcon
                    id="Download"
                    btnName="download"
                    dataCy="download"
                    title="Download"
                    onClick={downloadPDF}
                    icon={<BsDownload className="w-5 h-5" />}
                    className="hover:!bg-blue-50 hover:!text-white shadow-md"
                  /> 

                  <Searchbar
                    style=""
                    placeholder="Search..."
                    data={allInspectionRecords}
                    matchProps={[
                      { text: "inspectionCode" },
                      { text: "inspectionType" },
                      { text: "inspectionDate" },
                      { text: "inspectionPerformedBy" },
                    ]}
                    setTableData={setInspectionTableData}
                  />
                </div>
              </div>

              <TableComponent
                tableId="inspectionTable"
                data={inspectionTableData}
                columns={[
                  { key: "inspectionCode", title: "Inspection ID" },
                  { key: "tenantName", title: "Tenant Name" },
                  { key: "inspectionType", title: "Inspection Type" },
                  {
                    key: "inspectionDate",
                    title: "Inspection Date",
                    applyMethod: (row) => GetDateInMMDDYYYY(row.inspectionDate),
                  },
                  {
                    key: "inspectionPerformedBy",
                    title: "Performed By",
                  },
                ]}
                actions={inspectionTableActions}
                rowOnClick={(row) => {
                  setEditableData(row);
                  setViewInspectionDetails(true);
                }}
              />
            </section>
          )}
        </div>
      )}

      {viewInspectionDetails && (
        <InspectionDetails
          trigger={viewInspectionDetails}
          setTrigger={setViewInspectionDetails}
          data={editableData}
          setData={(updatedData) => {
            setEditableData(updatedData);
            getAllInspectionRecords();
          }}
          tenants={tenants}
          inspectionTypes={inspectionTypes}
        />
      )}
    </>
  );
};

export default UnitDetails;
