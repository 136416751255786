import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BsPlusCircle } from "react-icons/bs";
import { WebApimanager } from "../../WebApiManager";
import { companyId, userData, bankId, bankAccountId } from "../../atoms";
import { useRecoilValue, useRecoilState } from "recoil";
import { ButtonCustom } from "../../Widgets/Buttons";
import { TableComponent, Searchbar } from "../../Widgets/CommonWidgets";
import { ButtonBlue } from "../../Widgets/Buttons";
import swal from "sweetalert";
import { Loader } from "../../Widgets/notificationFeedbacks";

const BankInfo = () => {
  let webApi = new WebApimanager();
  let navigate = useNavigate();
  const userInfo = useRecoilValue(userData);
  const currentCompanyId = useRecoilValue(companyId);
  const [tableData, setTableData] = useState([]);
  const [allAccounts, setAllAccounts] = useState([]);
  const [bankID, setbankID] = useRecoilState(bankId);
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  useEffect(() => {
    setApiProcessing({
      loader: true,
      message: `Gathering Bank Accounts...`,
    });
    getAllConnectedBanksByCompany();
    //getAllConnectedBanksByUserId();
  }, []);

  const bankTableActions = {
    arrowTitle: "View",
    arrowOnClick: (row) => {
      goToBankDashboard(row);
    },
    customActions: (row) => customActionsContent(row),
    deleteTitle: "Delete Bank",
    deleteOnClick: (row) => deleteBank(row),
  };

  const customActionsContent = (row) => (
    <>
      <ButtonBlue
        name="Verify"
        onClick={(e) => verifyBank(e, row)}
        hidden={row.isDetailsSubmitted === true}
        className="text-sm font-normal w-auto h-auto cursor-pointer bg-white border-blue-52 text-blue-52 hover:text-white focus:text-white"
      />
    </>
  );

  const deleteBank = (row) => {
    swal({
      title: "Are you sure?",
      text: "You want to delete this Bank/Card!",
      icon: "warning",
      buttons: ["Cancel", "Yes, delete it!"],
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        webApi
          .delete("/payment/stripe/connectAccount/" + row.ID)
          .then((res) => {
            if (res.data.errorCode !== 200) {
              swal("Failure", res.data.errorMessage, "error");
            } else {
              swal("Success", res.data.errorMessage, "success");
              getAllConnectedBanksByCompany();
            }
          })
          .catch((error) => {
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                "Failed to Delete Bank/Card, Please try again later.",
                "error"
              );
            }
          });
      } else {
        return;
      }
    });
  };

  const goToBankDashboard = (bank) => {
    if (bank.isVerified === false) {
      swal("Info", "Please Verify your Bank account to proceed!", "info");
    } else {
      setbankID(bank.ID);
      navigate("/bankDashboard");
    }
  };

  const getAllConnectedBanksByCompany = () => {
    setApiProcessing({
      loader: true,
      message: `Gathering Bank Accounts...`,
    });
    webApi
      .get("/payment/stripe/connectAccount/company/" + currentCompanyId)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setTableData(res.data);
          setAllAccounts(res.data);
        }
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops! Failed to get Connected Banks, Please try again later.",
            "error"
          );
        }
      });
  };

  const getAllConnectedBanksByUserId = () => {
    webApi
      .get("/payment/stripe/connectAccount/client/" + userInfo.ID)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setTableData(res.data);
          setAllAccounts(res.data);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops! Failed to get Connected Banks, Please try again later.",
            "error"
          );
        }
      });
  };

  const getBankUrl = () => {
    swal({
      title: "Info",
      text: "To use this feature, please ensure that pop-ups are allowed for this website and this action will add a new bank account",
      icon: "info",
      button: "Got it",
    }).then((confirmed) => {
      if (confirmed) {
        setApiProcessing({
          loader: true,
          message: `Please wait...`,
        });
        webApi
          .post(
            "/payment/stripe/connectAccount/" +
              currentCompanyId +
              "&" +
              userInfo.ID
          )
          .then((res) => {
            if (res.data.errorCode) {
              swal("Error", res.data.errorMessage, "error");
            } else {
              console.log("res data", res.data);
              window.open(res.data.url, "_blank");
            }
            setApiProcessing({
              loader: false,
              message: `Loading...`,
            });
          })
          .catch((error) => {
            setApiProcessing({
              loader: false,
              message: `Loading...`,
            });
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                "Oops! Failed to get Bank Account Link, Please try again later.",
                "error"
              );
            }
          });
      } else {
        return;
      }
    });
  };

  const verifyBank = (event, row) => {
    event.preventDefault();
    setApiProcessing({
      loader: true,
      message: `Please wait...`,
    });
    webApi
      .get("/payment/stripe/connectAccount/accountLink/" + row.ID)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          console.log("res data", res.data);
          window.open(res.data.url, "_blank");
        }
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops! Failed to verify, Please try again later.",
            "error"
          );
        }
      });
  };

  return (
    <>
      <Loader open={apiProcessing.loader} message={apiProcessing.message} />

      <div className="w-5/6 m-auto">
        <span className="flex justify-center items-center bg-blue-52 text-white font-semibold text-center rounded h-10">
          Linked Banks
        </span>

        <section
          name="Searchbar and Options"
          className={"flex justify-between items-center mt-4 mb-2 mx-4"}
        >
          <span className="font-semibold">Banks ({allAccounts.length})</span>
          <span className="flex items-center gap-4">
            <ButtonCustom
              id="addnew"
              btnName="addNew"
              dataCy="addNew"
              name={
                <>
                  <BsPlusCircle className="w-4 h-4 mr-2" /> ADD NEW
                </>
              }
              onClick={() => getBankUrl()}
              className={`flex justify-center items-center rounded-lg h-8 mr-2 px-2 w-auto font-semibold bg-transparent text-sm text-blue-52 border-blue-52  hover:bg-blue-52 hover:text-white`}
            />
            <Searchbar
              placeholder="Search Bank Name..."
              data={allAccounts}
              matchProps={[
                { text: "name" },
                { text: "last4" },
                { text: "routingNumber" },
                { text: "isVerified" },
              ]}
              setTableData={setTableData}
            />
          </span>
        </section>

        <TableComponent
          tableId="BankTable"
          data={tableData}
          columns={[
            { key: "companyName", title: "Company" },
            { key: "accountType", title: "Account Type" },
            { key: "name", title: "Bank Name/Card Type" },
            {
              key: "last4",
              title: "Account/Card Number",
              applyMethod: (row) => {
                if (row.last4) {
                  return "**** **** " + row.last4;
                } else {
                  return "-";
                }
              },
            },
            {
              key: "verificationStatus",
              title: "Status",
            },
          ]}
          rowOnClick={(row) => goToBankDashboard(row)}
          actionHeader="Actions"
          actions={bankTableActions}
          //initialSort={{ key: "accountId", direction: "descending" }}
        />
      </div>
    </>
  );
};

export default BankInfo;
