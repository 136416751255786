import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { WebApimanager } from "../../WebApiManager";
import { ButtonIcon } from "../../Widgets/Buttons";
import {
  GetDateInMMDDYYYY,
  TableComponent,
  Searchbar,
} from "../../Widgets/CommonWidgets";
import { BsDownload } from "react-icons/bs";
import ReportsBuilder from "./reportsBuilder";
import { IconInCircle } from "../../Widgets/Icons";
import {
  DetailsTile,
  DetailsTileAttached,
  MultiDetailsTile,
} from "../../Widgets/Forms/FormReviewTiles";
import swal from "sweetalert";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { FiHome } from "react-icons/fi";
import { GetFullAddressinTwoLines } from "../../Widgets/Forms/FieldDependants";
import ExportCSV from "../../Widgets/ExportCSV";

const RentRollReport = () => {
  let webApi = new WebApimanager();
  let navigate = useNavigate();

  const [reportBuilderValues, setReportBuilderValues] = useState({
    portfolioId: "",
    propertyId: "",
  });
  const [viewRentRollTable, setViewRentRollTable] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState({});
  const [allRentRollReportsMap, setAllRentRollReportsMap] = useState(new Map());
  const [rentRollTableData, setRentRollTableData] = useState([]);
  const [allRentalTotalsMap, setAllRentalToatalsMap] = useState(new Map());
  const [rentalToatalData, setRentalToatalData] = useState({});

  useEffect(() => {
    console.log("reportBuilderValues:", reportBuilderValues);
  }, [reportBuilderValues]);

  const handleReportValues = (reportValues) => {
    console.log("Acquired values ", reportValues);
    setReportBuilderValues({
      ...reportBuilderValues,
      portfolioId: reportValues.portfolioId,
      propertyId: reportValues.propertyId,
    });
    getRentRollReports(reportValues.propertyId);
  };

  const getRentRollReports = (propertyId) => {
    if (allRentRollReportsMap.has(propertyId)) {
      setRentRollTableData(allRentRollReportsMap.get(propertyId));
      setRentalToatalData(allRentalTotalsMap.get(propertyId));
      setViewRentRollTable(true);
      return;
    }
    setRentRollTableData([]);
    webApi
      .get(`/reports/rentalRoll/property/${propertyId}`)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setRentRollTableData(res.data.renatlReport);
          setRentalToatalData(res.data.renatlCounts);
          setAllRentRollReportsMap(
            allRentRollReportsMap.set(propertyId, res.data.renatlReport)
          );
          setAllRentalToatalsMap(
            allRentalTotalsMap.set(propertyId, res.data.renatlCounts)
          );
          setViewRentRollTable(true);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Apologies, Failed to genarate Rent Roll Reports, Please try again later.`,
            "error"
          );
        }
      });
  };

  const getFullAddress = (data) => {
    const { address1, address2, city, state, country, zipCode } = data.address;
    return `${address1}${address2 ? `, ${address2}` : ""}${
      city ? `, ${city}` : ""
    } ${state ? `, ${state}` : ""}${country ? `, ${country}` : ""}${
      zipCode ? `, ${zipCode}` : ""
    }`;
  };

  const showTotalInfo = (title, data) => {
    return (
      <>
        <span className="flex font-semibold items-center">
          {title} :<p className="font-normal">&nbsp;{data}</p>
        </span>
      </>
    );
  };

  const downloadPDF = () => {
    let data = {
      propertyId : reportBuilderValues.propertyId
    }
    webApi
      .post("/report/rentRoll/pdf", data , { loader: true })
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          const link = document.createElement('a');
          link.href = res.data.url;
          link.setAttribute('download', 'filename.pdf'); // You can set a default filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Apologies, Failed to download Rent Roll Report, Please try again later.`,
            "error"
          );
        }
      });
  }

  return (
    <>
      <div className="w-5/6 m-auto">
        <span className="flex justify-center items-center bg-blue-52 text-white font-semibold text-center rounded h-10">
          {!viewRentRollTable && (
            <BsFillArrowLeftCircleFill
              title="Back to Previous page"
              onClick={(e) => {
                navigate(-1);
              }}
              className="w-6 h-6 ml-2 cursor-pointer"
            />
          )}
          <span className="flex-grow">Rent Roll</span>
        </span>

        <ReportsBuilder
          show={viewRentRollTable ? false : true}
          handleSearch={handleReportValues}
          builderValues={reportBuilderValues}
          selectedProperty={setSelectedProperty}
          propertyRequired
          hideUnit
          hideTenant
          hideReportPeriod
          hideLeaseStatus
          allignSearchButtonRight
        />

        {viewRentRollTable && (
          <section>
            <section
              name="Rent Roll Table and Options"
              className="flex justify-between items-center bg-blue-30 h-10 mb-4"
            >
              <span className="flex gap-4">
                <BsFillArrowLeftCircleFill
                  title="Back to Previous page"
                  onClick={(e) => {
                    setViewRentRollTable(false);
                  }}
                  className="text-blue-52 w-6 h-6 ml-2 cursor-pointer"
                />
                <p className="font-semibold">{selectedProperty.propertyName}</p>
              </span>
            </section>

            <section
              name="Header and SearchBar"
              className="flex justify-between items-center mb-8 mx-6"
            >
              <span className="flex items-center gap-4">
                <IconInCircle
                  icon={<FiHome className="w-8 h-8 text-blue-52" />}
                  radius="3.5rem"
                  className="shadow-xl"
                  color="#BFE3ED"
                />

                <MultiDetailsTile
                  details={{
                    "Property Address :": GetFullAddressinTwoLines(
                      selectedProperty.address
                    ),
                    "Property Type :": selectedProperty.propertyType,
                    "As of :": GetDateInMMDDYYYY(new Date()),
                  }}
                />
              </span>
              <div className="flex flex-row gap-2">
              <ExportCSV
                  data={rentRollTableData}
                  keys={[
                    { key: "unitNumber", title: "Unit No." },
                  {
                    key: "numberOfBeds",
                    title: "BD/BA",
                    applyMethod: (row) => {
                      return row.numberOfBeds + "/" + row.numberOfBaths;
                    },
                  },
                  {
                    key: "name",
                    title: "Tenant Name"
                  },
                  {
                    key: "unitStatus",
                    title: "Status",
                  },
                  { key: "sqFt", title: "Sqft"},
                  {
                    key: "rent",
                    title: "Rent",
                    applyMethod: (row) => {
                      return "$" + row.rent.toLocaleString();
                    },
                  },
                  {
                    key: "deposit",
                    title: "Deposit",
                    applyMethod: (row) => {
                      return "$" + row.deposit.toLocaleString();
                    },
                  },
                  {
                    key: "leaseStartDate",
                    title: "Lease From",
                    applyMethod: (row) => GetDateInMMDDYYYY(row.leaseStartDate),
                  },
                  {
                    key: "leaseEndDate",
                    title: "Lease To",
                    applyMethod: (row) => GetDateInMMDDYYYY(row.leaseEndDate),
                  },
                  {
                    key: "moveInDate",
                    title: "Move In",
                    applyMethod: (row) => GetDateInMMDDYYYY(row.moveInDate),
                  },
                  {
                    key: "moveOutDate",
                    title: "Move Out",
                    applyMethod: (row) => GetDateInMMDDYYYY(row.moveOutDate),
                  },
                  {
                    key: "pastDue",
                    title: "Past Due",
                    applyMethod: (row) => {
                      return "$" + row.pastDue.toLocaleString();
                    },
                  },
                  { key: "lateCount", title: "Late Count"},
                  ]}
                  fileName="Rent Roll.csv"
                />
              <ButtonIcon
                id="Download"
                btnName="download"
                dataCy="download"
                title="Download PDF"
                onClick={downloadPDF}
                icon={<BsDownload className="w-5 h-5" />}
                className="hover:!bg-blue-50 hover:!text-white shadow-md"
              /> 
              <Searchbar
                placeholder="Search..."
                data={allRentRollReportsMap.get(reportBuilderValues.propertyId)}
                matchProps={[
                  { text: "unitNumber" },
                  { text: "name" },
                  { text: "numberOfBeds" },
                  { text: "numberOfBaths" },
                  { text: "unitStatus" },
                  { text: "sqFt" },
                  { text: "rent" },
                  { text: "deposit" },
                  { date: "leaseStartDate" },
                  { date: "leaseEndDate" },
                  { date: "moveInDate" },
                  { date: "moveOutDate" },
                  { text: "pastDue" },
                  { text: "lateCount" },
                ]}
                setTableData={setRentRollTableData}
              />
              </div>
            </section>

            <section>
              <TableComponent
                tableId="Rent Roll Table"
                data={rentRollTableData}
                columns={[
                  { key: "unitNumber", title: "Unit No." },
                  {
                    key: "numberOfBeds",
                    title: "BD/BA",
                    applyMethod: (row) => {
                      return row.numberOfBeds + "/" + row.numberOfBaths;
                    },
                    width: "60px",
                  },
                  {
                    key: "name",
                    title: "Tenant Name",
                    width: "150px",
                  },
                  {
                    key: "unitStatus",
                    title: "Status",
                    width: "100px",
                  },
                  { key: "sqFt", title: "Sqft", width: "80px" },
                  {
                    key: "rent",
                    title: "Rent",
                    width: "80px",
                    applyMethod: (row) => {
                      return "$" + row.rent.toLocaleString();
                    },
                  },
                  {
                    key: "deposit",
                    title: "Deposit",
                    width: "80px",
                    applyMethod: (row) => {
                      return "$" + row.deposit.toLocaleString();
                    },
                  },
                  {
                    key: "leaseStartDate",
                    title: "Lease From",
                    applyMethod: (row) => GetDateInMMDDYYYY(row.leaseStartDate),
                    width: "100px",
                  },
                  {
                    key: "leaseEndDate",
                    title: "Lease To",
                    applyMethod: (row) => GetDateInMMDDYYYY(row.leaseEndDate),
                    width: "100px",
                  },
                  {
                    key: "moveInDate",
                    title: "Move In",
                    width: "100px",
                    applyMethod: (row) => GetDateInMMDDYYYY(row.moveInDate),
                  },
                  {
                    key: "moveOutDate",
                    title: "Move Out",
                    width: "100px",
                    applyMethod: (row) => GetDateInMMDDYYYY(row.moveOutDate),
                  },
                  {
                    key: "pastDue",
                    title: "Past Due",
                    width: "100px",
                    applyMethod: (row) => {
                      return "$" + row.pastDue.toLocaleString();
                    },
                  },
                  { key: "lateCount", title: "Late Count", width: "100px" },
                ]}
                rowOnClick={() => {}}
                //initialSort={{ key: "unitNumber", direction: "descending" }}
              />

              <div
                name="Totals row"
                className="flex justify-between rounded bg-gray-200 shadow-md p-2"
              >
                <span className="text-normal text-gray-600 uppercase font-bold">
                  Totals
                </span>
                {showTotalInfo(
                  "Units",
                  (rentalToatalData && rentalToatalData.totalUnits) || "-"
                )}
                {showTotalInfo(
                  "Occupied",
                  (rentalToatalData &&
                    rentalToatalData.percentageOccupied + "%") ||
                    "-"
                )}
                {showTotalInfo(
                  "Total Rent",
                  (rentalToatalData &&
                    "$" + rentalToatalData.totalRent.toLocaleString()) ||
                    "-"
                )}
                {showTotalInfo(
                  "Deposit",
                  (rentalToatalData &&
                    "$" + rentalToatalData.totalDeposit.toLocaleString()) ||
                    "-"
                )}
                {showTotalInfo(
                  "Past Due",
                  (rentalToatalData &&
                    "$" + rentalToatalData.totalPastDue.toLocaleString()) ||
                    "-"
                )}
                {showTotalInfo(
                  "Late Count",
                  (rentalToatalData && rentalToatalData.totalLateCount) || "-"
                )}
              </div>
            </section>
          </section>
        )}
      </div>
    </>
  );
};

export default RentRollReport;
