import React, { useEffect, useState } from "react";

import { WebApimanager } from "../../WebApiManager";
import { ButtonIcon } from "../../Widgets/Buttons";
import { TableComponent } from "../../Widgets/CommonWidgets";
import { ButtonGrayOutline, ButtonBlue } from "../../Widgets/Buttons";

import swal from "sweetalert";
import { Dialog } from "@mui/material";
import { AiOutlineCloseCircle } from "react-icons/ai";
import InputField, {
  InputSelectField,
  InputTextArea,
} from "../../Widgets/Forms/InputFields";

const AddRentalCashTransactions = (props) => {
  let webApi = new WebApimanager();

  const [allPendingCharges, setAllPendingCharges] = useState([]);
  const [selectedChargeIds, setSelectedChargeIds] = useState([]);
  const [totalCharge, setTotalCharge] = useState(0);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [referenceNote, setReferenceNote] = useState("");
  const [formErrors, setFormErrors] = useState("");

  useEffect(() => {
    getPendingCharges();
    getPaymentsMethods();
  }, []);

  const getPendingCharges = () => {
    webApi
      .get(`/ledger/rentalUnpaid/tenant/${props.tennatCode}`)
      .then((res) => {
        if (res.data.errorCode) {
          swal("", res.data.errorMessage, "error");
        } else {
          setAllPendingCharges(res.data.ledgers);
        }
      })
      .catch((error) => {
        swal(
          "",
          `Oops!, Unable to load Pending Charges! Please try again later.`,
          "error"
        );
      });
  };

  const getPaymentsMethods = () => {
    webApi
      .get(`/ledger/paymentMethods`)
      .then((res) => {
        if (res.data.errorCode) {
          swal("", res.data.errorMessage, "error");
        } else {
          setPaymentMethods(res.data);
        }
      })
      .catch((error) => {
        swal(
          "",
          `Unable to load Payment Methods! Please try again later.`,
          "error"
        );
      });
  };

  const validate = () => {
    const errors = {};

    if (!selectedPaymentMethod) {
      errors.selectedPaymentMethod = "Payment Method is required!";
    }
    if (!selectedChargeIds.length) {
      errors.selectedChargeIds = "Please Select One or More Pending Charges!";
    }
    if (referenceNote.length > 120) {
      errors.referenceNote = "*Max 120 characters";
    }

    setFormErrors(errors);

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const temp = validate();
    if (!Object.keys(temp).length) {
      let requestData = {
        TenantCode: props.tennatCode,
        UnitId: props.unitID,
        note: referenceNote,
        paymentMethod: selectedPaymentMethod,
        chargeIds: selectedChargeIds,
      };

      webApi
        .post("/ledger/payment", requestData)
        .then((res) => {
          if (res.data.errorCode) {
            swal("", res.data.errorMessage, "error");
          } else {
            props.getAllRentalTransactions(res.data);
            swal("", "Payment Successfully", "success");
            props.setTrigger(false);
          }
        })
        .catch((error) => {
          swal(
            "",
            `Oops! Failed to Add Payment, Please try again later.`,
            "error"
          );
        });
    }
  };

  const handleCheckboxChange = (isChecked, row) => {
    if (isChecked) {
      setSelectedChargeIds([...selectedChargeIds, row.ID]);
      setTotalCharge(totalCharge + row.pendingAmount);
    } else {
      setSelectedChargeIds(selectedChargeIds.filter((item) => item !== row.ID));
      setTotalCharge(totalCharge - row.pendingAmount);
    }
  };

  return (
    props.trigger && (
      <>
        <Dialog
          open={true}
          aria-labelledby="Add Payment"
          fullWidth
          maxWidth="md"
          className="flex flex-col justify-center w-5/6 mx-auto"
        >
          <div className="flex justify-center items-center bg-blue-52 text-white font-semibold rounded-sm h-10 pr-2">
            <span className="flex-grow text-center">Add Payment</span>
            <ButtonIcon
              id="closeTransactionForm"
              dataCy="closeTransactionForm"
              title="Close Transaction Form"
              onClick={(e) => props.setTrigger(false)}
              icon={<AiOutlineCloseCircle className="w-5 h-5" />}
              className="hover:!text-red-600"
            />
          </div>

          <section className="flex flex-col justify-center items-center w-5/6 m-auto">
            <span className="border-b-2 border-blue-52 pb-1 mb-4 text-blue-52 w-full mt-4">
              Payment Details
            </span>
            <span className="text-red-10">{formErrors.selectedChargeIds}</span>
            <TableComponent
              tableId="Files table"
              data={allPendingCharges}
              const
              columns={[
                {
                  key: "icon",
                  title: "",
                  applyMethod: (row) => (
                    <input
                      title="Select/UnSelect"
                      className="cursor-pointer w-5 h-5"
                      name={`Charges`}
                      type="checkbox"
                      //disabled={disableCheckbox}
                      checked={selectedChargeIds.includes(row.ID)}
                      onChange={(e) =>
                        handleCheckboxChange(e.target.checked, row)
                      }
                    />
                  ),
                  width: "80px",
                },

                { key: "description", title: "Description", width: "60%" },
                {
                  key: "paymentAmount",
                  title: "Charge",
                  applyMethod: (row) => {
                    return "$" + row.paymentAmount;
                  },
                  //width: "20%"
                },
              ]}
              rowOnClick={(row) => {}}
            />
            <table className="w-full mb-11">
              <tr className="bg-blue-28 text-gray-800 h-10 font-semibold">
                <th className="w-[80px]"></th>
                <th
                  id="TotalPendingAmountTitle"
                  style={{
                    minWidth: "150px",
                  }}
                  className="pl-4 text-right w-[60%]"
                >
                  TOTAL
                </th>
                <th
                  id="TotalPendingAmount"
                  style={{
                    minWidth: "150px",
                  }}
                  className="text-left pl-4"
                >
                  ${totalCharge}
                </th>
              </tr>
            </table>

            <div className="grid grid-cols-2 gap-x-16 w-full mb-4">
              <InputSelectField
                label="Payment Method"
                name="paymentMethod"
                id={"paymentMethod"}
                dataCy={"paymentMethod"}
                placeholder="Choose a Payment Method"
                value={selectedPaymentMethod}
                onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                options={paymentMethods}
                errorText={formErrors.selectedPaymentMethod}
                style={`!w-full`}
              />

              <InputTextArea
                label="Reference Note"
                name="referenceNote"
                id="referenceNote"
                dataCy="referenceNote"
                type="text"
                notImp
                placeholder="Enter Note"
                value={referenceNote}
                onChange={(e) => setReferenceNote(e.target.value)}
                errorText={formErrors.referenceNote}
                style="!w-full"
              />
            </div>

            <div className="flex justify-center items-center gap-16 mt-6 mb-4 w-3/4">
              <ButtonGrayOutline
                id="cancel"
                btnName="cancel"
                dataCy="cancel"
                name="Cancel"
                onClick={(e) => props.setTrigger(false)}
              />
              <ButtonBlue
                id="confirm"
                btnName="confirm"
                dataCy="confirm"
                name="Confirm"
                onClick={handleSubmit}
              />
            </div>
          </section>
        </Dialog>
      </>
    )
  );
};

export default AddRentalCashTransactions;
