import React, { useState, useEffect } from "react";
import { ButtonBlue, ButtonGrayOutline } from "../../Widgets/Buttons";
import { Dialog } from "@mui/material";
import { InputPasswordField } from "../../Widgets/Forms/InputFields";
import swal from "sweetalert";
import { Auth } from "aws-amplify";

const ChangePassword = (props) => {
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [conPass, setConPass] = useState("");
  const [formErrors, setFormErrors] = useState("");

  const validate = () => {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    const errors = {};
    if (!oldPass) {
      errors.oldPass = "Old Password is required!";
    } else if (!passwordRegex.test(oldPass)) {
      errors.oldPass = "Old Password is not valid!";
    }
    if (!newPass) {
      errors.newPass = "New Password is required!";
    } else if (!passwordRegex.test(newPass)) {
      errors.newPass = "New Password is not valid!";
    } else if (oldPass === newPass) {
      errors.newPass = "Old and New Password cannot be same!";
    }
    if (!conPass) {
      errors.conPass = "Confirm Password is required!";
    } else if (!passwordRegex.test(conPass)) {
      errors.conPass = "Confirm Password is not valid!";
    } else if (newPass !== conPass) {
      errors.conPass = "New and Confirm Password must be same!";
    }
    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const temp = validate();
    setFormErrors(temp);
    if (Object.keys(temp).length) {
      return;
    } else {
      try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(user, oldPass, newPass);
        swal("Success", "Password Updated Successful", "success");
        props.setTrigger(false);
      } catch (error) {
        if (error.message === "Incorrect username or password.") {
            // Specific error for incorrect old password
            swal("Failure",'Incorrect Current Password', "error");
            // Set a specific error message for the user or update the state to show the error.
          } else{
            swal("Failure", error.message, "error");
          }
        
      }
    }
  };

  return props.trigger ? (
    <>
      <Dialog
        open={true}
        aria-labelledby="Email Code"
        fullWidth
        maxWidth="sm"
        // PaperProps={{ sx: { height: "50%", width: "25%" } }}
        // scroll="body"
        className="flex flex-col justify-center w-full"
      >
        <span id="change-password-heading" className="flex justify-center items-center bg-blue-52 text-white font-semibold text-center rounded-sm h-10">
          Change Password
        </span>

        <form className="flex flex-col justify-center items-center">
          <section className="flex flex-col gap-4 mt-2 w-88 p-4">
            <InputPasswordField
              label="Current Password"
              name="oldPass"
              id="oldPass"
              dataCy="oldPass"
              type="text"
              placeholder="Enter Old Password"
              value={oldPass}
              onChange={(e) => setOldPass(e.target.value)}
              errorText={formErrors.oldPass}
              style="w-full"
              errorTextID="oldPass"
            />

            <InputPasswordField
              label="New Password"
              name="newPass"
              id="newPass"
              dataCy="newPass"
              type="text"
              placeholder="Enter New Password"
              value={newPass}
              onChange={(e) => setNewPass(e.target.value)}
              errorText={formErrors.newPass}
              style="w-full"
              errorTextID="newPass"
            />

            <InputPasswordField
              label="Confirm Password"
              name="conPass"
              id="conPass"
              dataCy="conPass"
              type="text"
              placeholder="Enter Confirm Password"
              value={conPass}
              onChange={(e) => setConPass(e.target.value)}
              errorText={formErrors.conPass}
              style="w-full"
              errorTextID="conPass"
            />

            <div className="flex justify-center items-center gap-16 mt-2 mb-2">
              <ButtonGrayOutline
                id="cancel"
                btnName="cancel"
                dataCy="cancel"
                name="Cancel"
                onClick={() => props.setTrigger(false)}
                className="mt-6 justify-self-end"
              />
              <ButtonBlue
                id="confirm"
                btnName="confirm"
                dataCy="confirm"
                name="Confirm"
                onClick={handleSubmit}
                className="mt-6"
              />
            </div>
          </section>
        </form>
      </Dialog>
    </>
  ) : null;
};

export default ChangePassword;
