import React, { useState, useEffect } from "react";
import { WebApimanager } from "../../WebApiManager";
import AddInspection from "./addInspection";

import { IconInCircle } from "../../Widgets/Icons";
import { DetailsTile } from "../../Widgets/Forms/FormReviewTiles";
import {
  Searchbar,
  TableComponent,
  GetDateInMMDDYYYY,
} from "../../Widgets/CommonWidgets";
import {
  ButtonIcon,
  ButtonCustom,
  ButtonGrayOutline,
  ButtonBlue,
} from "../../Widgets/Buttons";
import { Loader } from "../../Widgets/notificationFeedbacks";
import InputField, {
  InputSelectField,
  InputFileField,
} from "../../Widgets/Forms/InputFields";
import { Gallery } from "../../Widgets/CommonWidgets";
import { FloorPlanDetailsTile } from "../CreateFloorPlan/FloorPlanDetails";

import swal from "sweetalert";
import { Dialog, DialogContent } from "@mui/material";
import { BsFillArrowLeftCircleFill, BsPlusCircle } from "react-icons/bs";
import { PiWarningOctagon } from "react-icons/pi";
import { TbFileArrowRight } from "react-icons/tb";
import { TiEdit } from "react-icons/ti";
import { AiOutlineCloseCircle } from "react-icons/ai";

const InspectionDetails = (props) => {
  let webApi = new WebApimanager();
  const [inspectedDetails, setInspectedDetails] = useState({});
  const [inspectionItems, setInspectionItems] = useState([]);
  const [openAddMediaForm, setOpenAddMediaForm] = useState("");
  const [openInspectionForm, setOpenInspectionForm] = useState(false);
  const [editableData, setEditableData] = useState("");
  const [formOperation, setFormOperation] = useState("");
  const [openInspectionItem, setOpenInspectionItem] = useState(false);

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  useEffect(() => {
    setInspectedDetails(props.data);
    getInspectionItems(props.data.ID);
  }, [props.data]);

  const getInspectionItems = (inspectionId) => {
    setApiProcessing({
      loader: true,
      message: `Gathering Inspection Items...`,
    });
    webApi
      .get("/inspectionAttachedMedia/inspection/" + inspectionId)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setInspectionItems(res.data);
        }
        setApiProcessing({
          loader: false,
          message: ``,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: ``,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to get Inspection Items List, Please try again later.`,
            "error"
          );
        }
      });
  };

  const inspectionItemsTableActions = {
    eyeTitle: "View",
    eyeOnClick: (row) => {
      setEditableData(row);
      setOpenInspectionItem(true);
    },
    editTitle: "Edit",
    editOnClick: (row) => {
      setEditableData(row);
      setFormOperation("Edit");
      setOpenAddMediaForm(true);
    },
    deleteTitle: "Delete",
    deleteOnClick: (row) => deleteInspectionItem(row),
  };

  const deleteInspectionItem = (inspectionItem) => {
    swal({
      title: "Are you sure?",
      text: `Do you want to delete ${inspectionItem.item} Inspection Item!`,
      icon: "warning",
      buttons: ["Cancel", "Yes, delete it!"],
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        setApiProcessing({
          loader: true,
          message: `Removing Selected Inspection Item...`,
        });
        webApi
          .delete("/inspectionAttachedMedia/" + inspectionItem.ID)
          .then((res) => {
            if (res.data.errorCode !== 200) {
              swal("Failure", res.data.errorMessage, "error");
            } else {
              const updatedInspectionItems = inspectionItems.filter(
                (item) => item !== inspectionItem
              );
              setInspectionItems(updatedInspectionItems);
              //getInspectionItems();
              swal("Success", res.data.errorMessage, "success");
            }
            setApiProcessing({
              loader: false,
              message: ``,
            });
          })
          .catch((error) => {
            setApiProcessing({
              loader: false,
              message: ``,
            });
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                `Failed to Delete selected Inspection Item, Please try again later.`,
                "error"
              );
            }
          });
      } else {
        return;
      }
    });
  };

  return (
    <>
      {openInspectionForm && (
        <AddInspection
          trigger={openInspectionForm}
          setTrigger={setOpenInspectionForm}
          unitId={inspectedDetails.unitID}
          tenants={props.tenants || []}
          inspectionTypes={props.inspectionTypes || []}
          operation={"Edit"}
          editData={inspectedDetails}
          getAllInspections={(updatedData) => {
            props.setData(updatedData);
          }}
        />
      )}

      {openAddMediaForm && (
        <AddInspectionItem
          trigger={openAddMediaForm}
          setTrigger={setOpenAddMediaForm}
          inspectionId={inspectedDetails.ID}
          editData={editableData}
          operation={formOperation}
          getAllInspectionItems={(Item) => {
            getInspectionItems(inspectedDetails.ID);
            setEditableData("");
          }}
        />
      )}

      {openInspectionItem && (
        <ViewInspectionItem
          trigger={openInspectionItem}
          setTrigger={setOpenInspectionItem}
          data={editableData}
        />
      )}

      <Loader open={apiProcessing.loader} message={apiProcessing.message} />

      <div className="w-5/6 m-auto">
        <span className="flex justify-between items-center px-2 bg-blue-52 text-white font-semibold text-center rounded h-10">
          <span className="flex justify-start items-center gap-4">
            <BsFillArrowLeftCircleFill
              id="goPrevious"
              name="goPrevious"
              data-cy="goPrevious"
              title="Back to Previous page"
              onClick={(e) => {
                props.setTrigger(false);
              }}
              className="w-6 h-6 cursor-pointer"
            />
            Inspection ID : {inspectedDetails.inspectionCode}
          </span>
          <ButtonIcon
            id="editInspection"
            btnName="editInspection"
            dataCy="editInspection"
            title="Edit Inspection Details"
            onClick={(e) => {
              setOpenInspectionForm(true);
            }}
            icon={<TiEdit className="w-5 h-5" />}
            className="hover:!bg-white hover:!text-blue-52"
          />
        </span>

        <section
          name="Inspection Details"
          className="flex items-center gap-4 my-6 mx-6"
        >
          <IconInCircle
            icon={<PiWarningOctagon className="w-8 h-8 text-blue-52" />}
            radius="3.5rem"
            className="shadow-xl"
            color="#BFE3ED"
          />
          <span>
            <DetailsTile
              label="Tenant Name :"
              data={inspectedDetails.tenantName}
              labelClassName="justify-self-start font-light"
              dataClassName="justify-self-start font-medium pl-2"
            />
            <DetailsTile
              label="Inspection Type :"
              data={inspectedDetails.inspectionType}
              labelClassName="justify-self-start font-light"
              dataClassName="justify-self-start font-medium pl-2"
            />
            <DetailsTile
              label="Inspection Date :"
              data={GetDateInMMDDYYYY(
                inspectedDetails && inspectedDetails.inspectionDate
              )}
              labelClassName="justify-self-start font-light"
              dataClassName="justify-self-start font-medium pl-2"
            />
            <DetailsTile
              label="Performed By :"
              data={inspectedDetails.inspectionPerformedBy}
              labelClassName="justify-self-start font-light"
              dataClassName="justify-self-start font-medium pl-2"
            />
          </span>
        </section>

        <section>
          <div className="flex justify-between items-end m-4 gap-4">
            {/* <InputSelectField
              label="Select:"
              name="location"
              id="location"
              placeholder="Select a Location"
              notImp
              options={[]}
              value={""}
              onChange={() => {}}
              style="!flex items-center gap-1 !font-medium"
            /> */}

            <span></span>

            <div className="flex justify-between items-center gap-2">
              <ButtonCustom
                btnName="addNewMedia"
                dataCy="addNewMedia"
                title="Add New Media"
                id="addNewInspection"
                name={
                  <>
                    <BsPlusCircle className="w-5 h-5 mr-2" /> Add New
                  </>
                }
                onClick={() => {
                  setFormOperation("New");
                  setOpenAddMediaForm(true);
                }}
                className={`flex justify-center items-center px-2 w-36 h-10 rounded-md text-blue-52 border-blue-52  hover:bg-blue-52 hover:text-white`}
              />

              <ButtonIcon
                id="export"
                btnName="export"
                dataCy="export"
                title="Export"
                onClick={(e) => {}}
                icon={<TbFileArrowRight className="w-6 h-6" />}
                className="hover:!bg-green-500 hover:!text-white shadow-md"
              />
            </div>
          </div>

          <TableComponent
            tableId="Inspection Media Table"
            data={inspectionItems}
            columns={[
              { key: "location", title: "Inspection Location" },
              {
                key: "item",
                title: "Inspection Item",
              },
              {
                key: "notes",
                title: "Notes",
                //width: "200px",
              },
              {
                key: "media",
                title: "Media",
                //width: "200px",
              },
            ]}
            actions={inspectionItemsTableActions}
            rowOnClick={(row) => {
              setEditableData(row);
              setOpenInspectionItem(true);
            }}
            //initialSort={{ key: "location", direction: "ascending" }}
          />
        </section>
      </div>
    </>
  );
};

export default InspectionDetails;

//Add or Edit Inspection Item Form
export const AddInspectionItem = (props) => {
  let webApi = new WebApimanager();
  const inspectionId = props.inspectionId;
  const editForm = props.operation === "Edit" ? true : false;

  const [inspectionMediaDetails, setInspectionMediaDetails] = useState({
    location: editForm ? props.editData.location : "",
    item: editForm ? props.editData.item : "",
    notes: editForm ? props.editData.notes : "",
    media: editForm ? props.editData.media : "",
  });
  const [selectedMedia, setSelectedMedia] = useState(
    editForm ? { name: props.editData.media } : ""
  );
  const [formErrors, setFormErrors] = useState("");

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  useEffect(() => {}, []);

  const validate = () => {
    const errors = {};
    if (!inspectionMediaDetails.location.trim()) {
      errors.location = "Location is required!";
    }
    if (!inspectionMediaDetails.item.trim()) {
      errors.item = "Item is required!";
    }
    if (!inspectionMediaDetails.notes.trim()) {
      errors.notes = "Notes is required!";
    }
    if (!selectedMedia) {
      errors.media = "Media is required!";
    }
    return errors;
  };

  const handleSubmit = (event) => {
    //event.preventDefault();
    const temp = validate();
    setFormErrors(temp);
    if (Object.keys(temp).length) {
      return;
    } else {
      setApiProcessing({
        loader: true,
        message: editForm
          ? `Updating Inspection Item Details...`
          : `Create a new Inspection Item`,
      });

      let data = {
        location: inspectionMediaDetails.location,
        item: inspectionMediaDetails.item,
        notes: inspectionMediaDetails.notes,
      };

      if (
        editForm &&
        selectedMedia !== "" &&
        selectedMedia.name !== inspectionMediaDetails.media
      ) {
        const temp = {
          media: selectedMedia.name,
          ...data,
        };
        data = temp;
      } else if (!editForm) {
        const elseTemp = {
          inspectionID: inspectionId,
          media: selectedMedia.name,
          ...data,
        };
        data = elseTemp;
      }

      if (editForm) {
        webApi
          .put("/inspectionAttachedMedia/" + props.editData.ID, data)
          .then(async (res) => handleApiResponse(res, data))
          .catch((error) => {
            setApiProcessing({
              loader: false,
              message: ``,
            });
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                "Failed to Update Inspection Item! Please try again later.",
                "error"
              );
            }
          });
      } else {
        webApi
          .post("/inspectionAttachedMedia", data)
          .then(async (res) => handleApiResponse(res, data))
          .catch((error) => {
            setApiProcessing({
              loader: false,
              message: ``,
            });
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                "Failed to Create New Inspection Item! Please try again later.",
                "error"
              );
            }
          });
      }
    }
  };

  const handleApiResponse = async (res, data) => {
    if (res.data.errorCode) {
      setApiProcessing({
        loader: false,
        message: ``,
      });
      swal("Failure", res.data.errorMessage, "error");
    } else {
      if (data.media) {
        setApiProcessing({
          loader: true,
          message: `Uploading Media...`,
        });
        const metaData = {
          filename: selectedMedia.name,
          mediatype: selectedMedia.type,
        };
        await webApi
          .uploadFile(res.data.media, metaData, selectedMedia)
          .then((res) => {})
          .catch((error) => {
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal("Error", "Oops! Failed to Upload Media!", "error");
            }
          });
      }

      props.getAllInspectionItems(res.data);
      setApiProcessing({
        loader: false,
        message: ``,
      });
      swal(
        "Success",
        editForm
          ? `Inspection Item is Updated successfully`
          : `New Inspection Item is Created successfully`,
        "success"
      );
      props.setTrigger(false);
    }
  };

  const handleInspectionItemDetails = (name, value) => {
    setInspectionMediaDetails({
      ...inspectionMediaDetails,
      [name]: value,
    });
  };

  return (
    props.trigger && (
      <>
        <Dialog
          open={true}
          aria-labelledby="Add Inspection Item Form"
          fullWidth
          maxWidth="xs"
          //PaperProps={{ sx: { height: "100%" } }}
          className="flex flex-col justify-center w-full h-fit"
        >
          <Loader open={apiProcessing.loader} message={apiProcessing.message} />

          <div className="flex justify-center items-center bg-blue-52 text-white font-semibold rounded-sm h-10 pr-2">
            <span className="flex-grow text-center">
              {editForm ? "Edit Inspection Item" : "Add Inspection Item"}
            </span>
            <ButtonIcon
              title="Close"
              onClick={(e) => props.setTrigger(false)}
              icon={<AiOutlineCloseCircle className="w-5 h-5" />}
              className="hover:!text-red-600"
            />
          </div>

          <DialogContent dividers>
            <div className="flex flex-col items-center justify-center gap-3 mx-auto">
              <InputField
                label="Inspection Location"
                name="loaction"
                id="Location"
                dataCy="location"
                type="text"
                placeholder="Enter Location"
                value={inspectionMediaDetails.location}
                onChange={(e) =>
                  handleInspectionItemDetails("location", e.target.value)
                }
                errorText={formErrors.location}
                style="!w-full"
              />

              <InputField
                label="Inspection Item"
                name="item"
                id="Inspection Item"
                dataCy="inspectionItem"
                type="text"
                placeholder="Enter Inspection Item"
                value={inspectionMediaDetails.item}
                onChange={(e) =>
                  handleInspectionItemDetails("item", e.target.value)
                }
                errorText={formErrors.item}
                style="!w-full"
              />

              <InputField
                label="Notes"
                name="notes"
                id="Notes"
                dataCy="notes"
                type="text"
                placeholder="Enter Notes"
                value={inspectionMediaDetails.notes}
                onChange={(e) =>
                  handleInspectionItemDetails("notes", e.target.value)
                }
                errorText={formErrors.notes}
                style="!w-full"
              />

              <InputFileField
                label="Attach Media"
                name="attachmedia"
                id="AttachedMedia"
                dataCy="attachedMedia"
                accept=".png, .jpg, .jpeg,"
                maxSizeinMB={10}
                placeholder="Select Media"
                value={selectedMedia}
                setValue={setSelectedMedia}
                errorText={formErrors.media}
                hintText="File should be less than 10 MB"
                style="!w-full"
              />
            </div>

            <div className="flex justify-center items-center gap-16 my-6">
              <ButtonGrayOutline
                id="cancel"
                btnName="cancel"
                dataCy="cancel"
                name="Cancel"
                title="Close the Form"
                onClick={(e) => props.setTrigger(false)}
                className="justify-self-end"
              />
              <ButtonBlue name="Confirm" onClick={handleSubmit} className="" />
            </div>
          </DialogContent>
        </Dialog>
      </>
    )
  );
};

//View Inspection Details
export const ViewInspectionItem = (props) => {
  let webApi = new WebApimanager();

  const [inspectionItem, setInspectionItem] = useState(props.data);
  const [fileUrl, setFileUrl] = useState("");
  const [openGallery, setOpenGallery] = useState(false);

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  useEffect(() => {
    setApiProcessing({
      loader: true,
      message: `Gathering File...`,
    });
    const data = {
      objectKey: `${props.data.mediaWithPrefix}`,
    };
    webApi
      .post(`/documents/download`, data)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setFileUrl(res.data.objectKey);
        }
        setApiProcessing({
          loader: false,
          message: ``,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: ``,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else
          swal(
            "Error",
            "Oops, Unable to get the File! Please try again later.",
            "error"
          );
      });
  }, []);

  return (
    props.trigger && (
      <>
        <Dialog
          open={true}
          aria-labelledby="View Inspection Item"
          fullWidth
          maxWidth="md"
          //PaperProps={{ sx: { height: "80%" } }}
          //scroll="body"
          className="flex flex-col justify-center w-4/5 mx-auto"
        >
          {openGallery && (
            <Gallery
              images={[fileUrl]}
              openGallery={openGallery}
              setOpenGallery={setOpenGallery}
            />
          )}

          <Loader open={apiProcessing.loader} message={apiProcessing.message} />

          <div className="flex justify-center items-center bg-blue-52 text-white font-semibold rounded-sm h-10 pr-2">
            <span className="flex-grow text-center">{inspectionItem.item}</span>
            <ButtonIcon
              id="close"
              btnName="close"
              dataCy="close"
              title="Close"
              onClick={(e) => props.setTrigger(false)}
              icon={<AiOutlineCloseCircle className="w-5 h-5" />}
              className="hover:!text-red-600"
            />
          </div>

          <DialogContent dividers>
            <section className="grid grid-cols-2 justify-center items-center w-full">
              <div className="border bg-gray-50 cursor-pointer rounded-xl flex flex-col items-center justify-center w-full h-80 overflow-hidden">
                {fileUrl ? (
                  <>
                    <img
                      src={fileUrl}
                      alt="Inspection Item Image"
                      className="w-full h-full"
                      onClick={() => setOpenGallery(true)}
                    />
                  </>
                ) : (
                  <p className="text-gray-600 text-center">
                    No Image available!
                  </p>
                )}
              </div>

              <div className="flex flex-col justify-center gap-4 mx-auto">
                <div className="flex items-start font-semibold gap-4">
                  <span className="font-normal">Location:</span>
                  {inspectionItem.location || "-"}
                </div>
                <div className="flex items-start font-semibold gap-4">
                  <span className="font-normal">Inspection Item:</span>
                  {inspectionItem.item || "-"}
                </div>
                <div className="flex items-start font-semibold gap-4">
                  <span className="font-normal">Notes:</span>
                  {inspectionItem.notes || "-"}
                </div>
              </div>
            </section>

            <div className="flex justify-center items-center gap-16 mt-2 mb-2">
              <ButtonGrayOutline
                name="Close"
                title="Close"
                onClick={(e) => props.setTrigger(false)}
                className="mt-2"
              />
            </div>

            {/* <div className="flex flex-col justify-center gap-3 mx-auto">
              <div className="flex justify-center">
                <img
                  src={fileUrl}
                  alt="Item Image"
                  className="justify-self-center"
                  onClick={() => setOpenGallery(true)}
                />
              </div>

              <div className="flex items-start font-semibold gap-4">
                <span className="font-normal">Location:</span>
                {inspectionItem.location || "-"}
              </div>
              <div className="flex items-start font-semibold gap-4">
                <span className="font-normal">Inspection Item:</span>
                {inspectionItem.item || "-"}
              </div>
              <div className="flex items-start font-semibold gap-4">
                <span className="font-normal">Notes:</span>
                {inspectionItem.notes || "-"}
              </div>

              <div className="flex justify-center">
                <ButtonGrayOutline
                  name="Close"
                  title="Close"
                  onClick={(e) => props.setTrigger(false)}
                  className="mt-2"
                />
              </div>
            </div> */}
          </DialogContent>
        </Dialog>
      </>
    )
  );
};
