import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { WebApimanager } from "../../WebApiManager";
import { userData } from "../../atoms";
import { useRecoilValue } from "recoil";
import { ButtonBlue, ButtonBlueOutline } from "../../Widgets/Buttons";
import { TableComponent, Searchbar } from "../../Widgets/CommonWidgets";
import { BsPlusCircle, BsFillArrowLeftCircleFill } from "react-icons/bs";
import { GetDateInMMDDYYYY } from "../../Widgets/CommonWidgets";
import { Loader } from "../../Widgets/notificationFeedbacks";
import { TextReviewTile } from "../../Widgets/Forms/FormReviewTiles";
import { ButtonGrayOutline } from "../../Widgets/Buttons";
import { Dialog, DialogContent } from "@mui/material";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { ButtonIcon } from "../../Widgets/Buttons";
import AddTemplate from "./addTemplate";
import swal from "sweetalert";

const ManageNotification = () => {
  let webApi = new WebApimanager();
  let navigate = useNavigate();
  const userInfo = useRecoilValue(userData);
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const [tableData, setTableData] = useState([]);
  const [activeMenuItem, setActiveMenuItem] = useState("notifications");
  const tabs = [
    { label: "Notifications", nickName: "notifications" },
    { label: "Alerts", nickName: "alerts" },
  ];
  const [viewTemplate, setViewTemplate] = useState(false);
  const [viewTemplateID, setViewTemplateID] = useState("");
  const [openAddTemplateForm, setOpenAddTemplateForm] = useState(false);
  const [notificationsTemplateData, setNotificationsTemplatesData] = useState(
    []
  );
  const [formOperation, setFormOperation] = useState("");
  const [editTemplateData, setEditTemplateData] = useState("");
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setApiProcessing({
      loader: true,
      message: `Getting Notifications Templates...`,
    });
    getNotificationTemplates();
  }, []);

  const getNotificationTemplates = () => {
    setApiProcessing({
      loader: true,
      message: `Getting Notifications Templates...`,
    });
    webApi
      .get(`/autoNotification/templates/client/${userInfo.ID}`)
      .then((res) => {
        if (res.data.errorCode) {
          console.log("Failure", res.data.errorMessage, "error");
        } else {
          setTableData(res.data);
          setNotificationsTemplatesData(res.data);
        }
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Unable to get the Notifications Templates, Please try again later.`,
            "error"
          );
        }
      });
  };

  const tableActions = {
    editTitle: "Edit",
    editOnClick: (row) => {
      getNotificationTemplateDetails(row.id);
      // setEditTemplateData(row);
      // setFormOperation("Edit");
      // setOpenAddTemplateForm(true);
    },
    deleteTitle: "Delete",
    deleteOnClick: (row) => {
      deleteNotificationTemplate(row.id);
    },
  };

  const getNotificationTemplateDetails = (ID) => {
    setApiProcessing({
      loader: true,
      message: `Getting Templates Details...`,
    });
    webApi
      .get(`/autoNotification/template/${ID}`)
      .then((res) => {
        if (res.data.errorCode) {
          console.log("Failure", res.data.errorMessage, "error");
        } else {
          setEditTemplateData(res.data);
          setFormOperation("Edit");
          setOpenAddTemplateForm(true);
        }
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Unable to get the Notifications Template Details, Please try again later.`,
            "error"
          );
        }
      });
  };

  const deleteNotificationTemplate = (ID) => {
    swal({
      title: "Are you sure?",
      text: "You want to delete this Notfication Template!",
      icon: "warning",
      buttons: ["Cancel", "Yes, delete it!"],
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        webApi
          .delete(`/autoNotification/template/${ID}`)
          .then((res) => {
            if (res.data.errorCode) {
              swal("Failure", res.data.errorMessage, "error");
            } else {
              swal("Success", res.data.message, "success");
              getNotificationTemplates();
            }
          })
          .catch((error) => {
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                "Failed to Delete Notification Template, Please try again later.",
                "error"
              );
            }
          });
      } else {
        return;
      }
    });
  };

  const handleToggle = (row) => {
    if (row.status) {
      changeStatus(row.id, false);
    } else {
      changeStatus(row.id, true);
    }
  };

  const changeStatus = (ID, val) => {
    setApiProcessing({
      loader: true,
      message: `Loading...`,
    });
    webApi
      .put(`/autoNotification/template/${ID}/status/${val}`)
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          swal("Success", res.data.message, "success");
          getNotificationTemplates();
        }
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to Update Template Status, Please try again later.`,
            "error"
          );
        }
      });
  };

  return (
    <>
      <Loader open={apiProcessing.loader} message={apiProcessing.message} />
      {viewTemplate && (
        <ViewTemplate
          trigger={viewTemplate}
          setTrigger={setViewTemplate}
          data={viewTemplateID}
        />
      )}

      {openAddTemplateForm && (
        <AddTemplate
          trigger={openAddTemplateForm}
          setTrigger={setOpenAddTemplateForm}
          data={editTemplateData}
          operation={formOperation}
          getAllNotifs={() => getNotificationTemplates()}
        />
      )}

      <div className="w-5/6 m-auto">
        <span className="flex justify-start items-center bg-blue-52 text-white font-semibold text-center rounded h-10 gap-2">
          <BsFillArrowLeftCircleFill
            title="Back to Properties Table"
            className="text-white w-6 h-6 ml-2"
            onClick={() => {
              navigate("/notifications");
            }}
          />
          Manage Notifications
        </span>

        <section
          name="Searchbar and Options"
          className={"flex justify-between items-center mt-4 mb-2 mx-4"}
        >
          <section
            name="tabs"
            className="flex justify-between items-center h-10 font-semibold text-gray-600 cursor-pointer border-blue-52 w-52"
          >
            {tabs.map((tab, index) => (
              <p
                key={index}
                id={tab.nickName}
                data-cy={tab.nickName}
                className={`hover:text-[#003C9A] focus:text-[#003C9A] pb-2 w-fit text-center ${
                  activeMenuItem === tab.nickName
                    ? `text-[#003C9A] border-[#003C9A] font-semibold w-fit border-b-2`
                    : `text-gray-600 border-gray-600`
                } 
                `}
                onClick={(e) => {
                  setActiveMenuItem(tab.nickName);
                }}
              >
                {tab.label}
              </p>
            ))}
          </section>
          <div className="flex flex-row gap-4 items-center">
            <ButtonBlueOutline
              id="addNewTemplate"
              btnName="addNewTemplate"
              dataCy="addNewTemplate"
              name={
                <>
                  <BsPlusCircle className="w-5 h-5 mr-2" /> Add New
                </>
              }
              onClick={(e) => {
                setOpenAddTemplateForm(true);
                setFormOperation("New");
              }}
              className={`flex justify-center items-center px-2 w-fit h-10 rounded-md`}
            />
            <Searchbar
              styles="ml-2 mr-4"
              placeholder="Search..."
              data={notificationsTemplateData}
              matchProps={[
                { text: "name" },
                { text: "heading" },
                { text: "notificationType" },
                { date: "CreatedAt" },
              ]}
              setTableData={setTableData}
            />
          </div>
        </section>

        <div>
          <TableComponent
            tableId="NotificationsTable"
            data={tableData}
            columns={[
              {
                key: "notificationName",
                title: "Notification Name",
              },
              {
                key: "notificationType",
                title: "Notification Type",
              },
              {
                key: "notificationChannel",
                title: "Notification Channel",
              },
              {
                key: "userGroup",
                title: "Receiver",
              },
              {
                key: "frequency",
                title: "Frequency",
              },
              {
                key: "status",
                title: "Status",
                applyMethod: (row) => {
                  if (row) {
                    const isActive = row.status;
                    return (
                      <div className="flex gap-2">
                        <p className="text-sm font-semibold">Off</p>
                        <button
                          onClick={(e) => {
                            e.stopPropagation(); // Stop the click event from bubbling up
                            handleToggle(row);
                          }}
                          className={`${
                            isActive ? "bg-green-500" : "bg-gray-300"
                          } w-12 h-6 rounded-full transition-all duration-300 focus:outline-none`}
                        >
                          <div
                            className={`${
                              isActive
                                ? "transform translate-x-full"
                                : "transform translate-x-0"
                            } w-6 h-6 bg-white rounded-full shadow-md transition-transform duration-300`}
                          />
                        </button>
                        <p className="text-sm font-semibold">On</p>
                      </div>
                    );
                  }
                },
              },
            ]}
            rowOnClick={(row) => {
              setViewTemplate(true);
              setViewTemplateID(row);
            }}
            actionHeader="Actions"
            actions={tableActions}
            initialSort={{ key: "createdAt", direction: "descending" }}
          />
        </div>
      </div>
    </>
  );
};

export default ManageNotification;

//View Tenplate Details
export const ViewTemplate = (props) => {
  let webApi = new WebApimanager();
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const [viewDetailsData, setViewDetailsData] = useState([]);
  useEffect(() => {
    setApiProcessing({
      loader: true,
      message: `Getting Templates Details...`,
    });
    getNotificationTemplateDetails();
  }, []);

  const getNotificationTemplateDetails = () => {
    setApiProcessing({
      loader: true,
      message: `Getting Templates Details...`,
    });
    webApi
      .get(`/autoNotification/template/${props.data.id}`)
      .then((res) => {
        if (res.data.errorCode) {
          console.log("Failure", res.data.errorMessage, "error");
        } else {
          setViewDetailsData(res.data);
        }
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Unable to get the Notifications Template Details, Please try again later.`,
            "error"
          );
        }
      });
  };

  return (
    props.trigger && (
      <>
        <Dialog
          open={true}
          aria-labelledby="View Template"
          fullWidth
          maxWidth="md"
          //PaperProps={{ sx: { height: "80%" } }}
          //scroll="body"
          className="flex flex-col justify-center w-4/5 mx-auto"
        >
          <Loader open={apiProcessing.loader} message={apiProcessing.message} />

          <div className="flex justify-center items-center bg-blue-52 text-white font-semibold rounded-sm h-10 pr-2">
            <span className="flex-grow text-center">Notification Details</span>
            <ButtonIcon
              id="close"
              btnName="close"
              dataCy="close"
              title="Close"
              onClick={(e) => props.setTrigger(false)}
              icon={<AiOutlineCloseCircle className="w-5 h-5" />}
              className="hover:!text-red-600"
            />
          </div>

          <DialogContent dividers>
            <div className="flex flex-col items-center">
              <section className="grid grid-cols-3 gap-x-16 gap-y-6 w-5/6 justify-around items-center md:gap-x-8 md:w-11/12">
                <TextReviewTile
                  title="Notification Name"
                  body={viewDetailsData.notificationName}
                />
                <TextReviewTile
                  title="Notification Type"
                  body={viewDetailsData.notificationType}
                />
                <TextReviewTile
                  title="Notfication Channel"
                  body={props.data.notificationChannel}
                />
                <TextReviewTile
                  title="User Group"
                  body={viewDetailsData.receiverGroup}
                />
                <TextReviewTile
                  title="Frequency"
                  body={viewDetailsData.frequency}
                />
                <TextReviewTile
                  title="Schedule Day"
                  body={viewDetailsData.scheduleDay}
                />
                <TextReviewTile
                  title="Start Date"
                  body={GetDateInMMDDYYYY(viewDetailsData.startDate)}
                />
                <TextReviewTile
                  title="End Date"
                  body={GetDateInMMDDYYYY(viewDetailsData.endDate)}
                />
                <TextReviewTile
                  title="Status"
                  body={viewDetailsData.status === true ? "ON" : "OFF"}
                />
              </section>

              <span className="flex mt-2 border-b-2 border-blue-52 text-blue-52 w-5/6 md:gap-x-8 md:w-11/12">
                Notification Text
              </span>
              <section className="flex w-5/6 justify-around items-center md:w-11/12">
                <pre className="rounded-lg font-sans text-base whitespace-pre-wrap w-full">
                  {viewDetailsData.notificationText}
                </pre>
              </section>

              <div className="flex justify-center items-center gap-16 mt-2 mb-2">
                <ButtonBlue
                  id="ok"
                  name="ok"
                  dataCy="ok"
                  title="OK"
                  onClick={(e) => props.setTrigger(false)}
                  className="mt-2 uppercase"
                />
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </>
    )
  );
};
