//Circle Loader Component Imports
import React from "react";
import { Backdrop, CircularProgress, Typography } from "@mui/material";

//Loader Provider
import { useLoader } from "../../Utility/loaderProvider";

const CircleLoader = () => {
  const { isLoading, loaderText } = useLoader();
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.modal + 1,
        backdropFilter: "blur(3px)", // Added backdrop blur for better visibility
        transition: "opacity 0.3s ease-in-out", // Smooth fade-in effect
        opacity: isLoading ? 1 : 0, // Adjust opacity based on loading state
      }}
      open={isLoading}
    >
      <div className="flex flex-col items-center justify-center p-5 rounded-lg bg-black bg-opacity-60">
        <CircularProgress
          size={70}
          sx={{
            color: "#025fa1",
            animation: "spin 1s linear infinite", // Spinner animation
          }}
        />
        <Typography variant="h6" className="mt-2 text-white">
          {loaderText || "Loading..."}
        </Typography>
      </div>
    </Backdrop>
  );
};

export default CircleLoader;

// Add CSS for spinner animation
const style = document.createElement("style");
style.innerHTML = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
document.head.appendChild(style);
