import React, { useState, useCallback, useEffect } from "react";
import { WebApimanager } from "../../WebApiManager";
import InputField, {
  InputSelectField,
  InputPhoneNumField,
  InputFileField,
  InputObjectSelectField,
  InputDateField,
  InputDollarsField,
  InputYearField,
} from "../../Widgets/Forms/InputFields";
import {
  GetDateInMMDDYYYY,
  DateFormatForAPI,
} from "../../Widgets/CommonWidgets";
import AddressComponent from "../../Widgets/Forms/AddressComponent";
import {
  TextReviewTile,
  FileReviewTile,
} from "../../Widgets/Forms/FormReviewTiles";
import {
  ButtonBlue,
  ButtonGrayOutline,
  ButtonIcon,
} from "../../Widgets/Buttons";
import SnackAlert from "../../Widgets/SnackbarAlert";
import { Dialog, DialogContent, Stepper, StepLabel, Step } from "@mui/material";
import swal from "sweetalert";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Loader } from "../../Widgets/notificationFeedbacks";
import dayjs from "dayjs";
import { PhoneNumFormat } from "../../Widgets/Forms/FieldDependants";
import { companyId, userData } from "../../atoms";
import { useRecoilValue } from "recoil";

const CreateProperty = (props) => {
  let webApi = new WebApimanager();
  const editForm = props.operation === "Edit" ? true : false;
  const editImport = props.operation === "Import" ? true : false;
  const portfolioId = props.portfolio.ID;
  const property = props.property;
  const userInfo = useRecoilValue(userData);
  const isClientAdmin = userInfo.userRole === "Client Admin" ? true : false;
  const [unitData, setUnitData] = useState("");
  const [UnitFailed, setUnitFailed] = useState(false);
  const currentCompanyId = useRecoilValue(companyId);

  const [propertyName, setpropertyName] = useState(
    editForm || editImport ? property.propertyName : ""
  );
  const [propertyType, setpropertyType] = useState("");

  const [email, setEmail] = useState(
    editForm || editImport ? property.email : ""
  );

  const [phoneNum, setPhoneNum] = useState(
    editForm || editImport ? property.phonenumber : ""
  );

  const [featuredPhoto, setFeaturedPhoto] = useState(
    (editForm || editImport) && property.featuredPhoto
      ? { name: property.featuredPhoto.split("/").pop() }
      : ""
  );

  const [yearBuilt, setyearBuilt] = useState(
    editForm || editImport ? property.yearBuilt : 0
  );
  const [noOfBuildings, setnoOfBuildings] = useState(
    editForm || editImport ? property.noOfBuildings : ""
  );
  const [noOfUnits, setnoOfUnits] = useState(
    editForm || editImport ? property.noOfUnits : ""
  );
  const [availableUnits, setAvailableUnits] = useState(
    editForm || editImport ? property.availableUnits : ""
  );
  const [chargeDay, setchargeDay] = useState(
    editForm || editImport ? property.chargeDay : ""
  );
  const [purchaseDate, setpurchaseDate] = useState(
    editForm || editImport ? property.purchaseDate : ""
  );
  const [onboardDate, setonboardDate] = useState(
    editForm || editImport ? property.onboardDate : ""
  );
  const [propertyStatus, setpropertyStatus] = useState(
    editForm || editImport ? property.isActive : ""
  );
  const [petsAllowed, setpetsAllowed] = useState("");
  const [petDeposit, setPetDeposit] = useState("");

  const [completeAddress, setCompleteAddress] = useState({
    address1: editForm || editImport ? property.address.address1 : "",
    address2: editForm || editImport ? property.address.address2 : "",
    city: editForm || editImport ? property.address.city : "",
    state: editForm || editImport ? property.address.state : "",
    country: editForm || editImport ? property.address.country : "",
    zipCode: editForm || editImport ? property.address.zipCode : "",
  });
  const [latitude, setLatitude] = useState(
    editForm || editImport ? property.latitude : ""
  );
  const [longitude, setLongitude] = useState(
    editForm || editImport ? property.longitude : ""
  );

  const [noOfBeds, setNoOfBeds] = useState("");
  const [noOfBathrooms, setNoOfBathrooms] = useState("");
  const [area, setArea] = useState("");
  const [rent, setRent] = useState("");
  const [deposit, setDeposit] = useState("");

  const [formErrors, setFormErrors] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState("error");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  let createdProp = "";
  const [createdUnit, setCreatedUnit] = useState("");

  const yearBuiltCustomProps = {
    min: 1700,
    max: 8000,
  };
  const totalUnitCustomProps = {
    min: 0,
  };

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  const [selectedconnectedAccId, setselectedConnectedAccId] = useState(
    editForm || editImport ? property.connectedAccountId : 0
  );
  const [selectedConnectedAccVal, setSelectedConnectedAccVal] = useState("");
  const [connectedAccountOptions, setConnectedAccountOptions] = useState([]);

  useEffect(() => {
    if (props.portfolio.portfolioType) {
      setpropertyType(props.portfolio.portfolioType);
    } else {
      swal(
        "Error",
        "Unable to retrieve the Portfolio Type. Please try again later.",
        "error"
      );
    }
    getAllConnectedBanksByCompany();
    if (editForm && props.portfolio.portfolioType === "Single Family") {
      webApi
        .get("/units/property/" + property.ID, { loader: true })
        .then((res) => {
          if (res.data.errorCode) {
            console.log("error = ", res.data.errorMessage);
          } else {
            if (res.data.length) {
              setUnitData(res.data[0]);
              setUnitDetails(res.data[0]);
            }
          }
        })
        .catch((error) => {
          swal(
            "Error",
            `Oops! Failed to Load Complete Property Details, Please try again later.`,
            "error"
          );
        });
    } else if (editImport) {
      if (props.property.unit) {
        setUnitData(props.property.unit);
        setUnitDetails(props.property.unit);
        if (props.property.unitFailed) {
          setUnitFailed(true);
        }
      }
    }
  }, [isClientAdmin]);

  const setUnitDetails = (unit) => {
    setArea(unit.sqFt);
    setNoOfBeds(unit.numberOfBeds);
    setNoOfBathrooms(unit.numberOfBaths);
    setRent(unit.rent);
    setDeposit(unit.deposit);
    setpetsAllowed(unit.isPetsAllowed);
    setPetDeposit(unit.petDeposit);
  };

  const validate = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (activeStep === 0) {
      if (!propertyName) {
        errors.propertyName = "Property name is required!";
      }
      if (!email) {
        errors.email = "Email is required!";
      } else if (!emailRegex.test(email)) {
        errors.email = "Email is not valid!";
      }
      if (!phoneNum || phoneNum.length !== 12) {
        errors.phoneNum = "Contact number is required!";
      }
      if (!completeAddress.address1) {
        errors.address1 = "Address 1 is required!";
      }
      if (!completeAddress.city) {
        errors.city = "City is required!";
      }
      if (!completeAddress.state) {
        errors.state = "State is required!";
      }
      if (!completeAddress.country) {
        errors.country = "Country is required!";
      }
      if (!completeAddress.zipCode) {
        errors.zipCode = "Zipcode is required!";
      } else if (completeAddress.zipCode.toString().length !== 5) {
        errors.zipCode = "Zipcode is not valid!";
      }
    } else if (activeStep === 1) {
      if (!yearBuilt) {
        errors.yearBuilt = "Year Built is required!";
      }
      if (!purchaseDate) {
        errors.purchaseDate = "Purchase date is required!";
      } else if (new Date(purchaseDate).getFullYear() < yearBuilt) {
        errors.purchaseDate = "Purchase date should be later than Year Built!";
      }
      if (!onboardDate) {
        errors.onboardDate = "Date is required!";
      } else if (
        purchaseDate &&
        new Date(onboardDate) < new Date(purchaseDate)
      ) {
        errors.onboardDate =
          "Onboard date should be later than the purchase date!";
      }

      if (propertyType === "Multi-Family" && !noOfBuildings) {
        errors.noOfBuildings = "Number of Buildings is required!";
      }
      if (propertyType === "Multi-Family" && !noOfUnits) {
        errors.noOfUnits = "Number of Units is required!";
      }

      if (propertyType === "Single Family" && !area) {
        errors.area = "Area is required!";
      }
      if (propertyType === "Single Family" && !rent) {
        errors.rent = "Rent is required!";
      }
      if (propertyType === "Single Family" && !deposit) {
        errors.deposit = "Deposit is required!";
      }
      if (propertyType === "Single Family" && !noOfBeds) {
        errors.noOfBeds = "No. Of Beds is required!";
      }
      if (propertyType === "Single Family" && !noOfBathrooms) {
        errors.noOfBathrooms = "No. Of Bathrooms is required!";
      }
      if (propertyType === "Single Family" && petsAllowed === "") {
        errors.petsAllowed = "Value is required!";
      }
      if (propertyType === "Single Family" && petsAllowed && !petDeposit) {
        errors.petDeposit = "Pet Deposit is required!";
      }
      if (!chargeDay) {
        errors.chargeDay = "Monthly Rent Due Date is required!";
      }
    }

    return errors;
  };

  const handleSubmitButton = (event) => {
    event.preventDefault();
    let propertyRequestData = {
      propertyName: propertyName,
      address: {
        address1: completeAddress.address1,
        address2: completeAddress.address2,
        city: completeAddress.city,
        state: completeAddress.state,
        country: completeAddress.country,
        zipCode: completeAddress.zipCode,
      },
      email: email,
      phonenumber: phoneNum,
      latitude: latitude,
      longitude: longitude,
      noOfBuildings: noOfBuildings,
      noOfUnits: noOfUnits,
      chargeDay: chargeDay,
      featuredPhoto: featuredPhoto ? featuredPhoto.name : "",
      yearBuilt: yearBuilt,
      purchaseDate: DateFormatForAPI(purchaseDate),
      onboardDate: DateFormatForAPI(onboardDate),
      portfolioId: portfolioId,
      connectedAccountId: selectedconnectedAccId,
    };

    if (
      editForm &&
      property.featuredPhoto &&
      featuredPhoto &&
      featuredPhoto.name === property.featuredPhoto.split("/").pop()
    ) {
      propertyRequestData.featuredPhoto = "";
    }

    if (propertyType === "Single Family") {
      handleSingleFamily(propertyRequestData);
    } else handleMultiFamily(propertyRequestData);
  };

  //Create Single Family
  const handleSingleFamily = (requestData) => {
    requestData.unit = {
      unitNumber: propertyName,
      sqFt: area,
      numberOfBeds: noOfBeds,
      numberOfBaths: noOfBathrooms,
      rent: rent,
      deposit: deposit,
      unitTypeId: 0,
      address: {
        address1: completeAddress.address1,
        address2: completeAddress.address2,
        city: completeAddress.city,
        state: completeAddress.state,
        country: completeAddress.country,
        zipCode: completeAddress.zipCode,
      },
      isPetsAllowed: petsAllowed,
      petDeposit: petDeposit,
      connectAccountId: selectedconnectedAccId,
    };
    if (editForm) {
      webApi
        .put(`/singleFamily/properties/${property.ID}`, requestData, {
          loader: true,
        })
        .then(async (res) => handleAPIResponse(res))
        .catch((error) => {
          swal(
            "Error",
            `Oops! Failed to Update Single Family Property ${requestData.propertyName}, Please try again later.`,
            "error"
          );
        });
    } else {
      webApi
        .post("/singleFamily/properties", requestData, { loader: true })
        .then(async (res) => handleAPIResponse(res))
        .catch((error) => {
          swal(
            "",
            `Oops! Failed to Create Single Family Property ${requestData.propertyName}, Please try again later.`,
            "error"
          );
        });
    }
  };

  //Create Multi-Family
  const handleMultiFamily = (requestData) => {
    if (editForm) {
      webApi
        .put(`/multiFamily/properties/${property.ID}`, requestData, {
          loader: true,
        })
        .then(async (res) => handleAPIResponse(res))
        .catch((error) => {
          swal(
            "",
            `Oops! Failed to Update MultiFamily Property ${requestData.propertyName}, Please try again later.`,
            "error"
          );
        });
    } else {
      webApi
        .post("/multiFamily/properties", requestData, { loader: true })
        .then(async (res) => handleAPIResponse(res))
        .catch((error) => {
          swal(
            "",
            `Oops! Failed to Create MultiFamily Property ${requestData.propertyName}, Please try again later.`,
            "error"
          );
        });
    }
  };

  const handleAPIResponse = async (res) => {
    if (res.data.errorCode) {
      swal("Error", res.data.errorMessage, "error");
    } else {
      if (res.data.featuredPhoto !== "") {
        const metaData = {
          filename: featuredPhoto.name,
          mediatype: featuredPhoto.type,
        };
        await webApi
          .uploadFile(res.data.featuredPhoto, metaData, featuredPhoto)
          .then((FileRes) => handleSuccessResponse(res.data))
          .catch((error) => {
            swal("", `Oops! ${metaData.filename} file upload failed`, "error");
            handleSuccessResponse(res.data);
          });
      } else {
        handleSuccessResponse(res.data);
      }
    }
  };

  const handleSuccessResponse = (createdProp) => {
    props.getAllProperties(createdProp);
    swal(
      "Success",
      editForm
        ? "Property updated successfully."
        : `New property ${createdProp.propertyName} created successfully.`,
      "success"
    );

    props.setTrigger(false);
  };

  const handleSteps = () => {
    const temp = validate();
    setFormErrors(temp);
    if (Object.keys(temp).length) {
      return;
    } else {
      setActiveStep((currentStep) => currentStep + 1);
    }
  };

  const handleBack = (event) => {
    event.preventDefault();
    setActiveStep((currentStep) => currentStep - 1);
  };

  const setNewAddress = useCallback((newAddress) => {
    setCompleteAddress(newAddress);
  });

  const handleSnackbar = (type, message) => {
    setOpenSnackbar(true);
    setSnackbarType(type);
    setSnackbarMessage(message);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const getAllConnectedBanksByCompany = () => {
    webApi
      .get("/payment/stripe/connectAccount/company/" + currentCompanyId, {
        loader: true,
      })
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setConnectedAccountOptions(res.data);
        }
      })
      .catch((error) => {
        swal(
          "Error",
          "Oops! Failed to get Connected Banks, Please try again later.",
          "error"
        );
      });
  };

  return props.trigger ? (
    <>
      <Dialog
        open={true}
        aria-labelledby="Create Property Form"
        fullWidth
        maxWidth="lg"
        //PaperProps={{ sx: { height: "80%" } }}
        //scroll="body"
        className="flex flex-col justify-center w-5/6 mx-auto"
      >
        <SnackAlert
          open={openSnackbar}
          close={handleSnackbarClose}
          type={snackbarType}
          message={snackbarMessage}
        />

        <Loader open={apiProcessing.loader} message={apiProcessing.message} />

        <div className="flex justify-center items-center bg-blue-52 text-white font-semibold rounded-sm h-10 pr-2">
          <span className="flex-grow text-center">
            {" "}
            {editForm ? "Edit Property" : "Add Property"}{" "}
          </span>
          <ButtonIcon
            title="Close"
            id="closeAddPropertyForm"
            onClick={(e) => props.setTrigger(false)}
            icon={<AiOutlineCloseCircle className="w-5 h-5" />}
            className="hover:!text-red-600"
          />
        </div>

        <Stepper activeStep={activeStep} alternativeLabel className="mt-6 mb-6">
          <Step>
            <StepLabel>Property Details</StepLabel>
          </Step>
          <Step>
            <StepLabel>Ancillary Details</StepLabel>
          </Step>
          <Step>
            <StepLabel>Confirm Details</StepLabel>
          </Step>
        </Stepper>

        <DialogContent dividers>
          <form className="flex flex-col justify-center items-center">
            <section
              className={
                activeStep === 0
                  ? "flex flex-col justify-center items-center w-full"
                  : "hidden"
              }
            >
              <span className="border-b-2 border-blue-52 pb-1 mb-4 text-blue-52 w-5/6 md:gap-x-8 md:w-11/12">
                Property Details
              </span>
              <div className="grid grid-cols-2 items-start gap-x-16 gap-y-4 w-5/6 mb-4 md:gap-x-8 md:w-11/12">
                <InputField
                  label="Property Name"
                  name="propertyName"
                  id="propertyName"
                  dataCy="propertyName"
                  type="text"
                  placeholder="Enter Property Name"
                  value={propertyName}
                  onChange={(e) =>
                    setpropertyName(e.target.value.replace(/[^\w\s]/gi, ""))
                  }
                  errorText={formErrors.propertyName}
                  style="!w-full"
                />

                {/* <InputSelectField
                  label="Property Type"
                  name="propertyType"
                  id="propertyType"
                  dataCy="propertyType"
                  placeholder="Choose a Property Type"
                  disabled={editForm || editImport}
                  value={propertyType}
                  onChange={(e) => setpropertyType(e.target.value)}
                  options={["Single Family", "Multi-Family"]}
                  errorText={formErrors.propertyType}
                  style="!w-full"
                  hintText={editForm || editImport ? "*Non Editable" : ""}
                /> */}

                <InputField
                  label="Email ID"
                  name="email"
                  id="email"
                  dataCy="email"
                  type="text"
                  placeholder="Enter Email ID"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  errorText={formErrors.email}
                  style="!w-full"
                />

                <InputPhoneNumField
                  label="Contact Number"
                  name="phoneNumber"
                  id="phoneNumber"
                  dataCy="phoneNumber"
                  placeholder="Enter Contact Number"
                  value={phoneNum}
                  setValue={setPhoneNum}
                  errorText={formErrors.phoneNum}
                  style="!w-full"
                />

                <AddressComponent
                  address={completeAddress}
                  setNewAddress={setNewAddress}
                  formErrors={formErrors}
                  latLong
                  latitude={latitude}
                  longitude={longitude}
                  setLatitude={setLatitude}
                  setLongitude={setLongitude}
                  style="!w-full"
                />

                <InputFileField
                  label="Featured Photo"
                  name="featuredPhoto"
                  id="featuredPhoto"
                  dataCy="featuredPhoto"
                  accept=".png, .jpg, .jpeg"
                  notImp
                  maxSizeinMB={10}
                  placeholder="Featured Logo"
                  value={featuredPhoto}
                  setValue={setFeaturedPhoto}
                  hintText="*File Size should be less than 10MB"
                  style="!w-full"
                />
              </div>
              <div className="flex justify-center items-center gap-16 mt-6 mb-4 w-3/4">
                <ButtonGrayOutline
                  name="Cancel"
                  id="cancelPropertyForm"
                  btnName="cancel"
                  dataCy="cancel"
                  onClick={() => props.setTrigger(false)}
                  className="mt-6 justify-self-end"
                />
                <ButtonBlue
                  name="Next"
                  id="goToAncillaryDetails"
                  btnName="next"
                  dataCy="next"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSteps();
                  }}
                  className="mt-6"
                />
              </div>
            </section>

            {activeStep === 1 ? (
              <>
                <span className="border-b-2 border-blue-52 pb-1 mb-4 text-blue-52 w-5/6 md:gap-x-8 md:w-11/12">
                  Ancillary Details
                </span>
                <section className="grid grid-cols-2 items-start gap-x-16 gap-y-6 w-5/6 mb-4 md:gap-x-8 md:w-11/12">
                  <InputYearField
                    label="Year Built"
                    name="yearBuilt"
                    id="yearBuilt"
                    btnName="yearBuilt"
                    dataCy="yearBuilt"
                    value={yearBuilt}
                    onChange={(value) => setyearBuilt(parseInt(value))}
                    minDate={"1500"}
                    maxDate={new Date()}
                    style="!w-full"
                    errorText={formErrors.yearBuilt}
                  />

                  <InputDateField
                    label="Purchase Date"
                    name="purchaseDate"
                    id="purchaseDate"
                    btnName="purchaseDate"
                    dataCy="purchaseDate"
                    value={purchaseDate}
                    onChange={(value) => setpurchaseDate(value)}
                    style="!w-full"
                    errorText={formErrors.purchaseDate}
                  />

                  <InputDateField
                    label="Onboard Date"
                    name="onboardDate"
                    id="onboardDate"
                    btnName="onboardDate"
                    dataCy="onboardDate"
                    value={onboardDate}
                    onChange={(value) => {
                      setonboardDate(value);
                    }}
                    style="!w-full"
                    errorText={formErrors.onboardDate}
                  />

                  {propertyType !== "Single Family" ? (
                    <>
                      <InputField
                        label="Total Units"
                        name="noOfUnits"
                        id="noOfUnits"
                        dataCy="noOfUnits"
                        type="text"
                        customProps={totalUnitCustomProps}
                        placeholder="Enter No. of Units"
                        value={noOfUnits}
                        onChange={(e) => {
                          let value = "";
                          if (e.target.value.replace(/[^\d]/g, "")) {
                            value = parseInt(
                              e.target.value.replace(/[^\d]/g, "")
                            );
                          }
                          setnoOfUnits(value);
                        }}
                        errorText={formErrors.noOfUnits}
                        style="!w-full"
                      />

                      <InputField
                        label="No. of Buildings"
                        name="noOfBuildings"
                        id="noOfBuildings"
                        dataCy="noOfBuildings"
                        type="text"
                        customProps={totalUnitCustomProps}
                        placeholder="Enter No. of Buildings"
                        value={noOfBuildings}
                        onChange={(e) => {
                          let value = "";
                          if (e.target.value.replace(/[^\d]/g, "")) {
                            value = parseInt(
                              e.target.value.replace(/[^\d]/g, "")
                            );
                          }
                          setnoOfBuildings(value);
                        }}
                        errorText={formErrors.noOfBuildings}
                        style="!w-full"
                      />
                    </>
                  ) : (
                    <>
                      <InputField
                        label="Area(Sq.ft)"
                        name="area"
                        id="area"
                        dataCy="area"
                        type="text"
                        placeholder="Enter Area in Sq.ft"
                        //customProps={totalUnitCustomProps}
                        value={area}
                        // onChange={(e) => setArea(parseFloat(e.target.value))}
                        onChange={(e) => {
                          let value = "";
                          if (e.target.value.replace(/[^\d]/g, "")) {
                            value = parseInt(
                              e.target.value.replace(/[^\d]/g, "")
                            );
                          }
                          setArea(value);
                        }}
                        errorText={formErrors.area}
                        style="!w-full"
                      />

                      <InputField
                        label="No. Of Beds"
                        name="noOfBeds"
                        id="noOfBeds"
                        dataCy="noOfBeds"
                        type="text"
                        customProps={totalUnitCustomProps}
                        placeholder="Enter No. of Beds"
                        value={noOfBeds}
                        onChange={(e) => {
                          let value = "";
                          if (e.target.value.replace(/[^\d]/g, "")) {
                            value = parseInt(
                              e.target.value.replace(/[^\d]/g, "")
                            );
                          }
                          setNoOfBeds(value);
                        }}
                        errorText={formErrors.noOfBeds}
                        style="!w-full"
                      />

                      <InputField
                        label="No. Of Bathrooms"
                        name="noOfBathrooms"
                        id="noOfBathrooms"
                        dataCy="noOfBathrooms"
                        type="text"
                        customProps={totalUnitCustomProps}
                        placeholder="Enter No. of Bathrooms"
                        value={noOfBathrooms}
                        onChange={(e) => {
                          let value = "";
                          if (e.target.value.replace(/[^\d]/g, "")) {
                            value = parseInt(
                              e.target.value.replace(/[^\d]/g, "")
                            );
                          }
                          setNoOfBathrooms(value);
                        }}
                        errorText={formErrors.noOfBathrooms}
                        style="!w-full"
                      />

                      <InputDollarsField
                        label="Monthly Rent"
                        name="rent"
                        id="rent"
                        dataCy="rent"
                        placeholder="Enter Rent Amount"
                        value={rent}
                        setValue={(value) => setRent(value)}
                        //onChange={(e) => setRent(parseInt(e.target.value))}
                        errorText={formErrors.rent}
                        style="!w-full"
                      />

                      <InputDollarsField
                        label="Minimum Security Deposit"
                        name="deposit"
                        id="deposit"
                        dataCy="securityDeposit"
                        placeholder="Enter Deposit Amount"
                        value={deposit}
                        setValue={(value) => setDeposit(value)}
                        //onChange={(e) => setDeposit(parseInt(e.target.value))}
                        errorText={formErrors.deposit}
                        style="!w-full"
                      />

                      <InputObjectSelectField
                        label="Pets Allowed"
                        name="petsAllowed"
                        id="petsAllowed"
                        dataCy="petsAllowed"
                        placeholder="Choose a value"
                        value={petsAllowed.toString()}
                        onChange={(e) => {
                          const isPetsAllowed = JSON.parse(e.target.value);
                          if (isPetsAllowed) {
                            setpetsAllowed(isPetsAllowed);
                          } else {
                            setpetsAllowed(isPetsAllowed);
                            setPetDeposit("");
                          }
                        }}
                        options={[
                          { name: "Yes", value: "true" },
                          { name: "No", value: "false" },
                        ]}
                        optionValue="value"
                        optionName="name"
                        style="!w-full"
                        errorText={formErrors.petsAllowed}
                      />

                      {petsAllowed && (
                        <InputDollarsField
                          label="Pet Deposit"
                          name="petDeposit"
                          id="petDeposit"
                          dataCy="petDeposit"
                          placeholder="Enter Pet Deposit"
                          value={petDeposit}
                          setValue={(value) => setPetDeposit(value)}
                          // onChange={(e) =>
                          //   setPetDeposit(parseInt(e.target.value))
                          // }
                          errorText={formErrors.petDeposit}
                          style="!w-full"
                        />
                      )}
                    </>
                  )}

                  {isClientAdmin && (
                    <InputObjectSelectField
                      label="Connected Bank Account"
                      name="connectedBankAccount"
                      id="connectedBankAccount"
                      data-cy="connectedBankAccount"
                      notImp
                      placeholder="Select a account"
                      value={selectedconnectedAccId}
                      onChange={(e) => {
                        const selectedOption =
                          e.target.options[e.target.selectedIndex];
                        setselectedConnectedAccId(parseInt(e.target.value));
                        setSelectedConnectedAccVal(
                          selectedOption.getAttribute("data-profile")
                        );
                      }}
                      options={connectedAccountOptions}
                      optionValue="ID"
                      optionName="last4"
                      applyMethod={(option) => {
                        return "Account No. XXXX XXXX " + option.last4;
                      }}
                      style="!w-full"
                    />
                  )}

                  <InputField
                    label="Monthly Rent Due Date"
                    name="chargeDay"
                    id="chargeDay"
                    dataCy="chargeDay"
                    type="text"
                    customProps={totalUnitCustomProps}
                    placeholder="Enter Monthly Rent Due Date"
                    value={chargeDay}
                    onChange={(e) => {
                      let value = "";
                      if (e.target.value.replace(/[^\d]/g, "")) {
                        value = Math.min(
                          Math.max(
                            1,
                            parseInt(e.target.value.replace(/[^\d]/g, ""))
                          ),
                          28
                        );
                      }
                      setchargeDay(value);
                    }}
                    errorText={formErrors.chargeDay}
                    style="!w-full"
                  />
                </section>

                <div className="flex justify-center items-center gap-16 mt-6 mb-4 w-3/4">
                  <ButtonGrayOutline
                    name="Back"
                    id="ancillaryDetailsBackButton"
                    btnName="back"
                    dataCy="back"
                    onClick={(e) => handleBack(e)}
                    className="mt-6 justify-self-end"
                  />
                  <ButtonBlue
                    name="Next"
                    id="ancillaryDetailsNextButton"
                    btnName="next"
                    dataCy="next"
                    onClick={(e) => {
                      e.preventDefault();
                      handleSteps();
                    }}
                    className="mt-6"
                  />
                </div>
              </>
            ) : null}

            {activeStep === 2 ? (
              <>
                <span className="border-b-2 border-blue-52 pb-1 mb-4 text-blue-52 w-5/6 md:gap-x-8 md:w-11/12">
                  Review Property Details
                </span>
                <div className="grid grid-cols-3 gap-x-16 gap-y-6 w-5/6 justify-around items-center md:gap-x-8 md:w-11/12">
                  <TextReviewTile title="Property Name" body={propertyName} />
                  <TextReviewTile title="Property Type" body={propertyType} />
                  <TextReviewTile title="Email" body={email} />
                  <TextReviewTile
                    title="Contact No."
                    body={PhoneNumFormat(phoneNum, true)}
                  />
                  <TextReviewTile
                    title="Address 1"
                    body={completeAddress.address1}
                  />
                  <TextReviewTile
                    title="Address 2"
                    body={completeAddress.address2 || "-"}
                  />
                  <TextReviewTile title="City" body={completeAddress.city} />
                  <TextReviewTile title="State" body={completeAddress.state} />
                  <TextReviewTile
                    title="Country"
                    body={completeAddress.country}
                  />
                  <TextReviewTile
                    title="Zipcode"
                    body={completeAddress.zipCode}
                  />

                  <FileReviewTile
                    title="Featured Photo"
                    body={featuredPhoto ? featuredPhoto.name : "N/A"}
                  />
                </div>
                <span className="border-b-2 border-blue-52 pb-1 mb-4 text-blue-52 w-5/6 mt-6 md:gap-x-8 md:w-11/12">
                  Review Ancillary Details
                </span>
                <div className="grid grid-cols-3 gap-x-16 gap-y-6 w-5/6 justify-around items-center md:gap-x-8 md:w-11/12">
                  <TextReviewTile title="Year Built" body={yearBuilt} />
                  {/* <TextReviewTile
                    title="Property Status"
                    body={propertyStatus ? "Active" : "In-Active"}
                  /> */}
                  <TextReviewTile
                    title="Purchase Date"
                    body={GetDateInMMDDYYYY(purchaseDate)}
                  />
                  <TextReviewTile
                    title="Onboard Date"
                    body={GetDateInMMDDYYYY(onboardDate)}
                  />
                  {propertyType === "Multi-Family" ? (
                    <>
                      <TextReviewTile title="Total Units" body={noOfUnits} />
                      {/* <TextReviewTile
                      title="Available Units"
                      body={availableUnits}
                    /> */}
                      <TextReviewTile
                        title="No. of Buildings"
                        body={noOfBuildings}
                      />
                    </>
                  ) : (
                    <>
                      <TextReviewTile title="Area" body={area} />
                      <TextReviewTile title="No. of Beds" body={noOfBeds} />
                      <TextReviewTile
                        title="No. of Bathrooms"
                        body={noOfBathrooms}
                      />
                      <TextReviewTile title="Monthly Rent" body={rent} />
                      <TextReviewTile
                        title="Minimum Security Deposit"
                        body={deposit}
                      />
                      <TextReviewTile
                        title="Pets Allowed"
                        body={petsAllowed ? "Yes" : "No"}
                      />
                      <TextReviewTile
                        title="Pet Deposit"
                        body={petDeposit}
                        hidden={!petsAllowed}
                      />
                    </>
                  )}
                  <TextReviewTile
                    title="Connected Account"
                    body={
                      selectedConnectedAccVal
                        ? "XXXX XXXX " + selectedConnectedAccVal
                        : "-"
                    }
                  />
                  <TextReviewTile
                    title="Monthly Rent Due Date"
                    body={chargeDay}
                  />
                </div>

                <div className="flex justify-center items-center gap-16 mt-8 mb-4 w-3/4">
                  <ButtonGrayOutline
                    name="Back"
                    id="backToAncillaryDetails"
                    btnName="back"
                    dataCy="back"
                    onClick={(e) => handleBack(e)}
                    hidden={apiProcessing.loader}
                  />
                  <ButtonBlue
                    id="confirmPropertyCreation"
                    name="Confirm"
                    onClick={handleSubmitButton}
                  />
                </div>
              </>
            ) : null}
          </form>
        </DialogContent>
      </Dialog>
    </>
  ) : null;
};

export default CreateProperty;
