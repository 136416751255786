import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Loader } from "../../Widgets/notificationFeedbacks";
import { TableComponent, Searchbar } from "../../Widgets/CommonWidgets";
import { ButtonBlueOutline } from "../../Widgets/Buttons";
import { BsPlusCircle } from "react-icons/bs";
import AddOwner from "./addOwner";
import { WebApimanager } from "../../WebApiManager";
import { companyId } from "../../atoms";
import { useRecoilValue, useRecoilState } from "recoil";
import { ButtonBlue } from "../../Widgets/Buttons";
import { PhoneNumFormat } from "../../Widgets/Forms/FieldDependants";
import { TiEdit } from "react-icons/ti";
import { RiDeleteBin7Line } from "react-icons/ri";
import swal from "sweetalert";

const OwnerDashboard = () => {
  let navigate = useNavigate();
  let webApi = new WebApimanager();
  const companyIdVal = useRecoilValue(companyId);
  const [formOperation, setFormOperation] = useState("");
  const [tableData, setTableData] = useState([]);
  const [allOwners, setAllOwners] = useState([]);
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const [openCreateOwnerForm, setOpenCreateOwnerForm] = useState(false);
  const [editOwnerData, setEditOwnerData] = useState("");

  useEffect(() => {
    setApiProcessing({
      loader: true,
      message: `Gathering Owners...`,
    });
    getOwners();
  }, []);

  const getOwners = () => {
    setApiProcessing({
      loader: true,
      message: `Gathering Owners...`,
    });
    webApi
      .get("/landlord/company/" + companyIdVal)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setAllOwners(res.data);
          setTableData(res.data);
        }
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Unable to get Owners, Please try again later.`,
            "error"
          );
        }
      });
  };

  const OwnerTableActions = {
    customActions: (row) => customActionsContentTenant(row),
  };

  const deleteOwner = (row) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete this profile?",
      icon: "warning",
      buttons: ["Cancel", "Yes, delete it!"],
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        webApi
          .delete(`/landlord/${row.landlordId}`)
          .then((res) => {
            if (res.data.status === false) {
              swal("Failure", res.data.message, "error");
            } else {
              swal("Success", res.data.message, "success");
              getOwners();
            }
          })
          .catch((error) => {
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                "Failed to Delete Owner, Please try again later.",
                "error"
              );
            }
          });
      } else {
        return;
      }
    });
  };

  const editOwner = (row) => {
    setApiProcessing({
      loader: true,
      message: `Loading...`,
    });
    webApi
      .get(`/landlord/${row.landlordId}/company/${companyIdVal}`)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setEditOwnerData(res.data);
          setFormOperation("Edit");
          setOpenCreateOwnerForm(true);
        }
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
        if (error.customErrorMessage)
          swal("Error", error.customErrorMessage, "error");
        else
          swal(
            "Error",
            `Oops! Unable to get Owner Details, Please try again later.`,
            "error"
          );
      });
  };

  const customActionsContentTenant = (row) => (
    <>
      <ButtonBlue
        name="Send Invite"
        title="Send Invite"
        onClick={() => resendOwnerInvitation(row)}
        hidden={row.isVerified === true}
        className="text-sm font-normal w-auto h-8 cursor-pointer bg-white border-blue-52 text-blue-52 hover:text-white focus:text-white"
      />

      <TiEdit
        title="Edit"
        id="editOwnerIcon"
        name="editOwnerIcon"
        data-cy="editOwnerIcon"
        onClick={() => editOwner(row)}
        className="w-4 h-5 cursor-pointer hover:text-blue-53"
      />

      <RiDeleteBin7Line
        title="Delete"
        id="deleteOwnerIcon"
        name="deleteOwnerIcon"
        data-cy="deleteOwnerIcon"
        onClick={() => deleteOwner(row)}
        className="w-4 h-5 cursor-pointer hover:text-red-500 focus:text-red-500"
      />
    </>
  );

  //Resending the Owner invitaion
  const resendOwnerInvitation = (row) => {
    // console.log("resend", row.email)
    setApiProcessing({
      loader: true,
      message: `Resending Invitation...`,
    });
    webApi
      .post(`/resendTempPassword/${row.email}&Landlord`)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          swal("Success", res.data.message, "success");
          getOwners();
        }
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Failed to send invitation. Please try again later.`,
            "error"
          );
        }
      });
  };

  return (
    <>
      {openCreateOwnerForm && (
        <AddOwner
          trigger={openCreateOwnerForm}
          setTrigger={setOpenCreateOwnerForm}
          data={editOwnerData}
          operation={formOperation}
          getOwnerData={() => getOwners()}
        />
      )}

      <Loader open={apiProcessing.loader} message={apiProcessing.message} />

      <div className="w-5/6 m-auto">
        <span className="flex justify-center items-center bg-blue-52 text-white font-semibold text-center rounded h-10">
          Ownership Directory
        </span>
        <section
          name="Searchbar and Options"
          className={"flex justify-between items-center my-4 mx-4"}
        >
          <div className="font-semibold text-md">
            Owners ({tableData.length})
          </div>

          <div className="flex">
            <ButtonBlueOutline
              id="addNewOwner"
              btnName="addNewOwner"
              dataCy="addNewOwner"
              name={
                <>
                  <BsPlusCircle className="w-5 h-5 mr-2" /> Add New
                </>
              }
              onClick={(e) => {
                setOpenCreateOwnerForm(true);
                setFormOperation("New");
              }}
              className={`flex justify-center items-center px-2 w-fit h-10 rounded-md`}
            />
            <Searchbar
              style="ml-2 w-72"
              placeholder="Search..."
              data={allOwners}
              matchProps={[
                { text: "ownerName" },
                { text: "email" },
                { text: "phoneNumber" },
                { text: "role" },
              ]}
              setTableData={setTableData}
            />
          </div>
        </section>

        <TableComponent
          tableId="OwnershipTable"
          data={tableData}
          columns={[
            { key: "ownerName", title: "Name" },
            { key: "email", title: "Email" },
            {
              key: "phoneNumber",
              title: "Contact",
              applyMethod: (row) => PhoneNumFormat(row.phoneNumber, true),
            },
            { key: "role", title: "Profile" },
          ]}
          rowOnClick={(row) =>
            navigate("/directories/owners/ownerDetails", {
              state: { landlordId: row.landlordId },
            })
          }
          //initialSort={{ key: "landlordId", direction: "descending" }}
          actions={OwnerTableActions}
        />
      </div>
    </>
  );
};

export default OwnerDashboard;
