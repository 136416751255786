import { useState } from "react";

import { GetTimeYearToMin } from "./CommonWidgets";
import { IconInCircle } from "./Icons";
import { AiOutlineMessage } from "react-icons/ai";
import { SlPaperPlane } from "react-icons/sl";

export const CommentsWidget = (props) => {
  const [comments, setComments] = useState(props.comments);
  const [newComment, setNewComment] = useState("");
  const [sorting, setSorting] = useState("NewestFirst");

  const setScrollBar = () => {
    const commentsWidget = document.getElementById("CommentsViewScreen");
    commentsWidget.scrollTop = commentsWidget.scrollHeight;
  };

  if (comments.length !== props.comments.length) {
    if (sorting === "NewestFirst") {
      setComments(props.comments.slice().reverse());
    } else {
      setComments(props.comments);
    }
    setScrollBar();
  }

  const handleConfirm = (event) => {
    event.preventDefault();
    if (newComment.trim() !== "") {
      props.handleSend(newComment);
    }
    setNewComment("");
  };

  const handleKeydDown = (event) => {
    if (event.key === "Enter") {
      handleConfirm(event);
    }
  };

  const handleSorting = (e) => {
    e.preventDefault();
    setSorting(e.target.value);
    if (e.target.value === "OldestFirst") {
      setComments(props.comments);
    } else {
      setComments(props.comments.slice().reverse());
    }
  };

  return (
    <>
      <span
        className={
          props.borderClassName +
          " flex justify-between items-center border-b-2 mb-2"
        }
      >
        Comments
        <select
          name="CommentsViewSort"
          id="CommentsViewSort"
          value={sorting}
          onChange={handleSorting}
          className={
            props.selectClassName +
            " text-sm bg-transparent p-2 hover:bg-slate-100 rounded"
          }
        >
          <option key="NewestFirst" value="NewestFirst">
            {" "}
            Newest First
          </option>
          <option key="OldestFirst" value="OldestFirst">
            {" "}
            Oldest First
          </option>
        </select>
      </span>
      <div className="flex flex-col items-center justify-center gap-4">
        <div
          name="CommentsViewScreen"
          id="CommentsViewScreen"
          className="overflow-y-auto overscroll-contain max-h-96 w-full"
        >
          {comments.map((comment, index) => (
            <span
              key={index}
              className="flex justify-center items-center gap-4 mb-4"
            >
              <IconInCircle
                icon={comment[props.commentRoleKey][0] || "X"}
                radius={props.iconRadius || "2rem"}
                color={
                  comment[props.commentRoleKey] === "Tenant"
                    ? "#3661A9"
                    : "#0A875C"
                }
                className="text-white text-lg"
              />
              <span className="flex flex-col w-[90%] text-base">
                <span className="flex justify-between items-center text-sm">
                  <p className="font-normal">
                    {comment[props.commentRoleKey] +
                      ": " +
                      comment[props.commenterName]}
                  </p>
                  <p className="text-[#BDBABA] ml-auto">
                    {GetTimeYearToMin(comment.UpdatedAt)}
                  </p>
                </span>
                <p className="font-light bg-[#E8EAF6] rounded p-2 break-words">
                  {comment[props.commentKey]}
                  {/* <MdDeleteForever
                        onClick={(e) => handleDelete(e, comment.ID)}
                        className="float-right w-4 h-4 hover:text-red-10 cursor-pointer my-auto"
                      /> */}
                </p>
              </span>
            </span>
          ))}
        </div>

        <span className="flex justify-center items-center gap-4 w-full">
          <IconInCircle
            icon={props.userRole[0] || <AiOutlineMessage />}
            radius={props.iconRadius || "2rem"}
            color={props.CircleIconColor || "#808080"}
            className={"text-white text-lg saturate-50"}
          />
          <span className="flex justify-end items-center w-[90%]">
            <input
              className={
                props.inputClassName +
                ` appearance-none rounded p-2 text-gray-700 leading-tight focus:outline-none border w-full pr-[35px]`
              }
              name="newComment"
              id="newComment"
              data-cy="newComment"
              placeholder={props.inputPlaceHolder}
              type="text"
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              onKeyDown={handleKeydDown}
            />
            <SlPaperPlane
              onClick={handleConfirm}
              className="w-5 h-5 absolute cursor-pointer mr-1"
            />
          </span>
        </span>
      </div>
    </>
  );
};
