import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import "./Widgets.css";
import {
  AiFillCloseCircle,
  AiOutlineConsoleSql,
  AiOutlineSearch,
} from "react-icons/ai";
import {
  BsArrowLeftCircleFill,
  BsArrowRightCircleFill,
  BsDownload,
} from "react-icons/bs";
import { TiDelete, TiEdit } from "react-icons/ti";
import { RiDeleteBin7Line } from "react-icons/ri";
import { TablePagination } from "@mui/material";
import { FaStar, FaRegEye } from "react-icons/fa";
import { TfiArrowCircleRight } from "react-icons/tfi";
import { CiMail } from "react-icons/ci";
import dayjs from "dayjs";

export const clickEffect =
  "cursor-pointer active:scale-95 transform transition duration-100";

//Time from year to min
export const GetTimeYearToMin = (fulldate) => {
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  var Months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const d = new Date(fulldate);

  return (
    <>
      <span className="flex items-center">
        {weekday[d.getDay()]} | {Months[d.getMonth()]}{" "}
        {d.getDate().toString().padStart(2, "0")} | {d.getFullYear()} |{" "}
        {GetTimein12HrFormat(d)}
      </span>
    </>
  );
};

//Time in 12hr format
export const GetTimein12HrFormat = (date) => {
  let time = "";
  let hours = date.getHours();
  let min = date.getMinutes();
  if (hours < 12) {
    time =
      hours.toString().padStart(2, "0") +
      ":" +
      min.toString().padStart(2, "0") +
      " AM";
  } else if (hours === 12) {
    time =
      hours.toString().padStart(2, "0") +
      ":" +
      min.toString().padStart(2, "0") +
      " PM";
  } else {
    time =
      (hours - 12).toString().padStart(2, "0") +
      ":" +
      min.toString().padStart(2, "0") +
      " PM";
  }
  return time;
};

//Date in MM/DD/YYYY
export const GetDateInMMDDYYYY = (inputDate) => {
  if (inputDate) {
    if (inputDate === "0001-01-01T00:00:00Z") {
      return "-";
    } else {
      let fulldate = dayjs(inputDate);
      const value = dayjs(fulldate.$d).format("MM/DD/YYYY");
      // const dateParts = inputDate.split("T")[0].split("-");
      // const year = dateParts[0];
      // const month = dateParts[1];
      // const day = dateParts[2];
      //return `${month}/${day}/${year}`;

      return `${value}`;
    }
  } else return "-";
};

//Gallery View
export const Gallery = ({
  images,
  videos,
  index,
  openGallery,
  setOpenGallery,
}) => {
  const [slideNumber, setSlideNumber] = useState(index || 0);
  const hasImages = Array.isArray(images) && images.length > 0;
  const hasVideos = Array.isArray(videos) && videos.length > 0;
  const galleryItems = [
    ...(hasImages ? images.filter(Boolean) : []),
    ...(hasVideos ? videos.filter(Boolean) : []),
  ];

  // Close Modal
  const handleCloseGallery = () => {
    setOpenGallery(false);
  };

  // Previous Slide
  const prevSlide = () => {
    slideNumber === 0
      ? setSlideNumber(galleryItems.length - 1)
      : setSlideNumber(slideNumber - 1);
  };

  // Next Slide
  const nextSlide = () => {
    slideNumber + 1 === galleryItems.length
      ? setSlideNumber(0)
      : setSlideNumber(slideNumber + 1);
  };

  const currentItem = galleryItems[slideNumber];
  const isVideo = hasVideos && videos.includes(currentItem);

  return (
    openGallery && (
      <div className="gallery">
        <span className="index text-2xl">
          {slideNumber + 1}/{galleryItems.length}
        </span>
        <AiFillCloseCircle
          className="btnClose w-8 h-8"
          onClick={handleCloseGallery}
        />
        <BsArrowLeftCircleFill
          className="btnPrev w-8 h-8"
          onClick={prevSlide}
        />
        <BsArrowRightCircleFill
          className="btnNext w-8 h-8"
          onClick={nextSlide}
        />
        <div className="fullScreenItem">
          {isVideo ? (
            <video src={currentItem} controls />
          ) : (
            <img src={currentItem} alt="" />
          )}
        </div>
      </div>
    )
  );
};

//Custom SearchBar
export const Searchbar = forwardRef((props, ref) => {
  const [searchString, setSearchString] = useState("");

  const handleSearch = (event) => {
    const searchString = event.target.value.toLowerCase();
    setSearchString(event.target.value);

    if (searchString === "") {
      props.setTableData(props.data);
    } else {
      const filteredData = props.data.filter((item) => {
        return searchComplexMatchProps(item, props.matchProps, searchString);
      });
      props.setTableData(filteredData);
    }
  };

  const searchComplexMatchProps = (item, complexMatchProps, searchString) => {
    for (const complexMatch of complexMatchProps) {
      for (const [type, matchProp] of Object.entries(complexMatch)) {
        if (type === "text") {
          const propValue = getNestedPropertyValue(item, matchProp);
          if (
            propValue &&
            propValue.toString().toLowerCase().includes(searchString)
          ) {
            return true;
          }
        } else if (type === "date") {
          const propValue = getNestedPropertyValue(item, matchProp);
          if (
            propValue &&
            dayjs(propValue).format("MM/DD/YYYY").includes(searchString)
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const getNestedPropertyValue = (object, propertyPath) => {
    const properties = propertyPath.split(".");
    let value = object;
    for (const prop of properties) {
      value = value[prop];
      if (value === undefined) {
        return undefined;
      }
    }
    return value;
  };

  const handleClear = () => {
    setSearchString("");
    props.setTableData(props.data);
  };

  // Expose the handleClear method to the parent component
  useImperativeHandle(ref, () => ({
    clearSearch: handleClear,
  }));

  return (
    <>
      <div
        className={
          props.style + " flex justify-end items-center w-auto max-w-sm"
        }
      >
        <input
          id={props.id || "searchBar"}
          className={
            props.className +
            " border shadow rounded-xl w-full focus:outline-none pr-[30px] py-2 px-3"
          }
          placeholder={props.placeholder}
          value={searchString}
          onChange={handleSearch}
        />
        {searchString === "" && (
          <AiOutlineSearch
            id={`${props.id}SearchIcon`}
            size={25}
            className="cursor-pointer absolute mr-[4px] text-blue-52"
          />
        )}
        {searchString !== "" && (
          <TiDelete
            id={`${props.id}ClearIcon`}
            size={25}
            className="cursor-pointer absolute mr-[4px] text-red-500"
            onClick={handleClear}
          />
        )}
      </div>
    </>
  );
});

//Custom Table Component
export const TableComponent = ({
  tableId,
  data,
  columns,
  className,
  rowOnClick,
  selectedRowIndex,
  actions,
  actionHeader,
  initialSort,
  ...props
}) => {
  const [sortConfig, setSortConfig] = useState(
    initialSort || { key: "", direction: "" }
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const starColors = {
    orange: "#FFBA5A",
    grey: "#a9a9a9",
  };

  useEffect(() => {
    setPage(0); // Reset page to 0 whenever props.data changes
  }, [data]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getPropertyValue = (obj, key) => {
    const properties = key.split(".");
    let value = obj;
    for (const prop of properties) {
      value = value?.[prop];
      if (value === undefined) {
        break;
      }
    }
    return value;
  };

  // Filter out null entries from columns array
  const filteredColumns = columns.filter((column) => column !== null);

  const sortedData =
    data.length > 1
      ? data.sort((a, b) => {
          const valueA = getPropertyValue(a, sortConfig.key);
          const valueB = getPropertyValue(b, sortConfig.key);

          if (sortConfig.direction === "ascending") {
            return valueA > valueB ? 1 : -1;
          }
          if (sortConfig.direction === "descending") {
            return valueA < valueB ? 1 : -1;
          }
          return 0;
        })
      : data;

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedData = sortedData.slice(startIndex, endIndex);

  return (
    <section className={`${className} w-full overflow-x-auto`}>
      <div className="w-full overflow-x-scroll overflow-y-hidden">
        <table id={tableId} className={"w-full mb-2"}>
          <thead className="bg-[#DFEFF4] text-sm text-gray-800 h-10 cursor-pointer">
            <tr className="text-left">
              {filteredColumns.map((column, index) => (
                <th
                  id={column.key}
                  key={column.key}
                  name={column.key}
                  data-cy={column.key}
                  className="pl-4"
                  colSpan={column.colSpan || 1}
                  style={{
                    minWidth: column.width || "150px",
                    width: column.width,
                    textAlign: column.colSpan && "center",
                    ...(column.getColumnStyles
                      ? column.getColumnStyles(column)
                      : {}),
                  }}
                  onClick={() => handleSort(column.key)}
                >
                  {column.title}
                  {sortConfig.key === column.key &&
                    (sortConfig.direction === "ascending" ? " ▲" : " ▼")}
                </th>
              ))}
              <th
                id="Actions"
                hidden={!actions}
                className={`${props.actionHeaderClassName}`}
              >
                {actionHeader || ""}
              </th>
            </tr>
            <tr className="text-left">
              {columns.map((column) =>
                column.subColumns ? (
                  column.subColumns.map((subColumn) => (
                    <th
                      id={subColumn.key}
                      key={subColumn.key}
                      className="pl-4"
                      style={{
                        minWidth: subColumn.width || "150px",
                        width: subColumn.width,
                        ...(subColumn.getSubcolumnStyles
                          ? subColumn.getSubcolumnStyles(subColumn)
                          : {}),
                      }}
                      onClick={() => handleSort(subColumn.key)}
                    >
                      {subColumn.title}
                      {sortConfig.key === subColumn.key &&
                        (sortConfig.direction === "ascending" ? " ▲" : " ▼")}
                    </th>
                  ))
                ) : (
                  <th
                    id={column.key}
                    key={column.key}
                    className="pl-4"
                    style={{ width: column.width }}
                    colSpan={column.colSpan ? column.colSpan : 1}
                  >
                    {column.subColumns ? null : ""}
                  </th>
                )
              )}
            </tr>
          </thead>

          {paginatedData.length ? (
            <tbody>
              {paginatedData.map((row, index) => (
                <tr
                  id={index}
                  key={index}
                  className={`${
                    props.rowClassName
                  } h-10 text-left border-l-4 border-gray-600 ${
                    selectedRowIndex === index
                      ? "bg-blue-100 hover:bg-blue-100"
                      : "bg-transparent" // Apply the background color conditionally
                  } hover:border-blue-50`}
                >
                  {filteredColumns.map((column) => {
                    if (column.subColumns) {
                      return column.subColumns.map((subColumn) => (
                        <td
                          id={subColumn.key}
                          key={subColumn.key}
                          data-label={subColumn.title}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (subColumn.dataOnClick) {
                              subColumn?.dataOnClick(row, index);
                            } else {
                              rowOnClick(row, index);
                            }
                          }}
                          style={{
                            minWidth: subColumn.width || "150px",
                            width: subColumn.width, // Apply custom width
                            ...(subColumn.getCellStyles
                              ? subColumn.getCellStyles(row)
                              : {}),
                          }}
                          className={`${subColumn.dataClassName} cursor-pointer pl-4 hover:scale-105 transition-all`}
                        >
                          {subColumn.applyMethod
                            ? subColumn.applyMethod(row)
                            : getPropertyValue(row, subColumn.key) || "-"}
                        </td>
                      ));
                    } else {
                      return (
                        <td
                          id={column.key}
                          key={column.key}
                          data-label={column.title}
                          onClick={(e) => {
                            e.stopPropagation();

                            if (column.dataOnClick) {
                              column?.dataOnClick(row, index);
                            } else {
                              rowOnClick(row, index);
                            }
                          }}
                          style={{
                            minWidth: column.width || "150px",
                            width: column.width, // Apply custom width
                            ...(column.getCellStyles
                              ? column.getCellStyles(row)
                              : {}),
                          }}
                          className={`${column.dataClassName} cursor-pointer pl-4 hover:scale-105 transition-all`}
                        >
                          {column.applyMethod
                            ? column.applyMethod(row)
                            : getPropertyValue(row, column.key) || "-"}
                        </td>
                      );
                    }
                  })}

                  {actions && (
                    <td
                      id="ActionsData"
                      data-label="Actions"
                      className={`px-2`}
                      hidden={!actions}
                    >
                      <div
                        className={`flex items-center gap-3 ${actions.className}`}
                      >
                        {actions && actions.eyeTitle && (
                          <FaRegEye
                            title={actions.eyeTitle}
                            onClick={(e) => actions.eyeOnClick(row)}
                            className={`${actions.eyeClassName} w-4 h-5 cursor-pointer hover:text-blue-50 focus:to-blue-50`}
                          />
                        )}

                        {actions && actions.editOnClick && (
                          <TiEdit
                            title={actions.editTitle}
                            id={actions.editId || "editIcon"}
                            disabled={actions.hideEdit?.(row) ? true : false}
                            className={`${
                              actions.editClassName
                            } w-4 h-5 cursor-pointer hover:text-blue-53 ${
                              actions.hideEdit?.(row)
                                ? "opacity-60 cursor-not-allowed"
                                : ""
                            } ${actions.hiddenEdit?.(row) && "hidden"}`}
                            onClick={(e) => {
                              if (!actions.hideEdit?.(row)) {
                                actions.editOnClick(row);
                              }
                            }}
                          />
                        )}

                        {actions && actions.deleteOnClick && (
                          <RiDeleteBin7Line
                            title={actions.deleteTitle}
                            id={actions.deleteId || "deleteIcon"}
                            disabled={
                              actions.disableDelete?.(row) ? true : false
                            }
                            className={` ${
                              actions.deleteClassName
                            } w-4 h-5 cursor-pointer hover:text-red-500 focus:text-red-500  ${
                              actions.disableDelete?.(row) &&
                              "opacity-60 cursor-not-allowed"
                            }
                            ${actions.hideDelete?.(row) && "hidden"}`}
                            onClick={() => actions.deleteOnClick(row)}
                          />
                        )}
                        {actions && actions.star && (
                          <FaStar
                            color={starColors.grey}
                            className={`${actions.starClassName} w-4 h-5`}
                          />
                        )}
                        {actions &&
                          actions.checkboxOnClick &&
                          actions.checkedData && (
                            <input
                              title={actions.checkboxTitle}
                              className={`${actions.checkboxClassName} cursor-pointer`}
                              type="checkbox"
                              id={index}
                              onClick={(e) =>
                                actions.checkboxOnClick(e.target.checked, row)
                              }
                              checked={actions.checkedData.includes(row.ID)}
                              readOnly={actions.checkboxReadOnly}
                            />
                          )}
                        {actions && actions.downloadOnclick && (
                          <BsDownload
                            title={actions.downloadTitle}
                            disabled={
                              actions.downloadDisable?.(row) ? true : false
                            }
                            onClick={(e) => {
                              if (!actions.downloadDisable?.(row)) {
                                actions.downloadOnclick(row);
                              }
                            }}
                            className={` ${actions.downloadClassName} w-4 h-5 ${
                              actions.downloadDisable?.(row)
                                ? "opacity-60 cursor-not-allowed"
                                : `hover:text-blue-50 focus:to-blue-50 cursor-pointer ${clickEffect}`
                            }`}
                          />
                        )}
                        {actions && actions.mailOnclick && (
                          <CiMail
                            title={actions.mailTitle}
                            disabled={actions.mailDisable?.(row) ? true : false}
                            onClick={(e) => {
                              if (!actions.mailDisable?.(row)) {
                                actions.mailOnclick(row);
                              }
                            }}
                            className={` ${actions.mailClassName} w-4 h-5 ${
                              actions.mailDisable?.(row)
                                ? "opacity-60 cursor-not-allowed"
                                : `hover:text-blue-50 focus:to-blue-50 cursor-pointer ${clickEffect}`
                            }`}
                          />
                        )}

                        {actions && actions.arrowOnClick && (
                          <TfiArrowCircleRight
                            title={actions.arrowTitle}
                            onClick={(e) => actions.arrowOnClick(row)}
                            className={`${actions.arrowClassName} w-4 h-5 hover:text-blue-50 focus:to-blue-50 cursor-pointer`}
                          />
                        )}

                        {actions &&
                          actions.customActions &&
                          actions.customActions(row)}
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr className="text-center">
                <td colSpan={filteredColumns.length} className="text-blue-52">
                  No data to show !
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
      <TablePagination
        component="div"
        id={`${tableId}-pagination`} // Unique static ID for the pagination component
        count={sortedData.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Rows per page"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`
        }
        backIconButtonProps={{
          id: `${tableId}-back-button`, // Unique ID for the back button
        }}
        nextIconButtonProps={{
          id: `${tableId}-next-button`, // Unique ID for the next button
        }}
        SelectProps={{
          native: true,
          inputProps: {
            id: `${tableId}-rows-per-page-select`, // Unique ID for the rows per page dropdown
          },
          children: (
            <>
              <option value={5} id={`${tableId}-rows-per-page-5`}>
                5
              </option>
              <option value={10} id={`${tableId}-rows-per-page-10`}>
                10
              </option>
              <option value={25} id={`${tableId}-rows-per-page-25`}>
                25
              </option>
            </>
          ),
        }}
      />
    </section>
  );
};

//Custom Hamburger Dropdown
export const Dropdown = ({ selector, options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="relative inline-flex items-center text-left">
      <button
        className={"rounded-md " + (isOpen ? "bg-gray-100" : "")}
        onClick={() => setIsOpen(!isOpen)}
      >
        {selector}
      </button>
      {isOpen && options ? (
        <div
          ref={dropdownRef}
          className="origin-top-right absolute right-0 top-4 z-10 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
        >
          {options.map((option) => (
            <button
              key={option.name}
              className={
                "block w-full text-left px-4 py-2 text-sm hover:bg-gray-100 focus:outline-none"
              }
              onClick={() => {
                option.onClick(option.name);
                setIsOpen(false);
              }}
            >
              {option.name}
            </button>
          ))}
        </div>
      ) : null}
    </div>
  );
};

//Add Custom days in Date
export const AddDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

//Custom Function to Send Dates for API Request
export const DateFormatForAPI = (date) => {
  if (!date || isNaN(new Date(date).getTime())) {
    return "";
  }

  const currentDate = new Date(date);
  const offsetMilliseconds = currentDate.getTimezoneOffset() * 60000;
  const adjustedDate = new Date(currentDate.getTime() - offsetMilliseconds);
  const finalDate = new Date(adjustedDate);

  return finalDate;
};

//Custom Function to Get file using File Path
export const GetFileUsingFilePath = (filePath) => {
  if (!filePath) {
    console.log("File path is required");
    return;
  }

  const fileReader = new FileReader();

  fileReader.onload = (event) => {
    // File content is available here
    const fileContent = event.target.result;
    console.log("file Contents", fileContent);
  };

  fileReader.onerror = (error) => {
    console.error("Error reading file:", error);
  };

  // Read the file
  fileReader.readAsText(filePath);
};

//Custom Function to send dollar sign in denomination
export const GetDenominationWithDollar = (denomination) => {
  if (isNaN(denomination)) {
    return "NAN";
  }

  return denomination.toString().startsWith("-")
    ? "-$" + Math.abs(denomination).toLocaleString()
    : "$" + denomination.toLocaleString();
};

//Custom Function to send dollar sign in denomination
export const GetColorByDenomination = (denomination) => {
  if (isNaN(denomination) || denomination === 0) {
    return { color: "black" };
  }

  return denomination.toString().startsWith("-")
    ? { color: "red" }
    : { color: "green" };
};
