import React from "react";
import { Navigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { isAuthenticated } from "../../atoms";

const PrivateRoute = ({ child }) => {
  const userAuthenticated = useRecoilValue(isAuthenticated);
  return userAuthenticated ? child : <Navigate to="/login" replace={true}/>
};

export default PrivateRoute;


