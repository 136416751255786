import React, { useState, useEffect } from "react";
import style from "./index.module.css";
import CreateUser from "../CreateUser";
import { BsPlusCircle } from "react-icons/bs";
import { WebApimanager } from "../../WebApiManager";
import { companyId, userData } from "../../atoms";
import { useRecoilValue } from "recoil";
import swal from "sweetalert";
import { TableComponent, Searchbar } from "../../Widgets/CommonWidgets";
import { ButtonIcon } from "../../Widgets/Buttons";
import { Loader } from "../../Widgets/notificationFeedbacks";
import { PhoneNumFormat } from "../../Widgets/Forms/FieldDependants";
import { ButtonBlueOutline } from "../../Widgets/Buttons";
import { InfoIcon } from "../../Widgets/Icons";

const UserTable = (props) => {
  let webApi = new WebApimanager();
  const companyIdVal = useRecoilValue(companyId);
  const userDataVal = useRecoilValue(userData);
  const [createUserPage, setCreateUserPage] = useState(false);
  const [tableData, setTableData] = useState([]);
  //const [blockVal, setBlockVal] = useState();
  const [allUsers, setAllUsers] = useState([]);
  const [userEditData, setUserEditData] = useState("");
  const [formOperation, setFormOperation] = useState("");

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  useEffect(() => {
    setApiProcessing({
      loader: true,
      message: "Gathering Users...",
    });
    getAllUsers();
  }, []);

  const getAllUsers = () => {
    setApiProcessing({
      loader: true,
      message: "Gathering Users...",
    });
    webApi
      .get(`/company/${companyIdVal}/get-all-client/${userDataVal.email}`)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setTableData(res.data);
          setAllUsers(res.data);
        }
        setApiProcessing({
          loader: false,
          message: "Loading...",
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: "Loading...",
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops! Something went wrong. Unable to get Users, Please try again later.",
            "error"
          );
        }
      });
  };

  // const blockUsers = (value) => {
  //   // if(value.isBlock === true){
  //   //   setBlockVal(false)
  //   // }else if (value.isBlock === false){
  //   //   setBlockVal(true)
  //   // }
  //   webApi
  //     .put("/usermanagement/client/" + value.ID + "/block/" + true)
  //     .then((res) => {
  //
  //         if (res.data.errorCode) {
  //           swal("Failure", res.data.errorMessage, "error");
  //         } else {
  //           //swal("Success", "Successfully Blocked User", "success");
  //           if (res.data.isBlock === true) {
  //             swal("Success", "Successfully Blocked User", "success");
  //           } else if (res.data.isBlock === false) {
  //             swal("Success", "Successfully UnBlocked User", "success");
  //           }
  //           console.log("block user", res.data);
  //           getAllUsers();
  //         }
  //
  //     }).catch((error) => {
  //   if (error.customErrorMessage) {
  //     swal("Error", error.customErrorMessage, "error");
  //   } else {
  //     swal(
  //       "Error",
  //       `Oops! Failed to Block User , Please try again later.`,
  //       "error"
  //     );
  //   }
  // });
  // };

  const userTableActions = {
    deleteTitle: "Delete User",
    deleteOnClick: (row) => {
      deleteUser(row);
    },
    editTitle: "Edit User",
    editOnClick: (row) => {
      setUserEditData(row);
      setFormOperation("Edit");
      setCreateUserPage(true);
    },
  };

  const deleteUser = (user) => {
    swal({
      title: "Are you sure?",
      text: `You want to delete the User ${
        user.firstName + " " + user.lastName
      }!`,
      icon: "warning",
      buttons: ["Cancel", "Yes, delete it!"],
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        setApiProcessing({
          loader: true,
          message: `Deleting User ${user.firstName + " " + user.lastName}...`,
        });
        webApi
          .delete("/clients/" + user.ID)
          .then((res) => {
            if (res.data.errorCode !== 200) {
              swal("Failure", res.data.errorMessage, "error");
            } else {
              getAllUsers();
              swal("Success", res.data.errorMessage, "success");
            }
            setApiProcessing({
              loader: false,
              message: `Loading...`,
            });
          })
          .catch((error) => {
            setApiProcessing({
              loader: false,
              message: `Loading...`,
            });
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                "Failed to Delete User, Please try again later.",
                "error"
              );
            }
          });
      } else {
        return;
      }
    });
  };

  return (
    <>
      {createUserPage ? (
        <CreateUser
          trigger={createUserPage}
          setTrigger={setCreateUserPage}
          getUsers={() => getAllUsers()}
          operation={formOperation}
          editData={userEditData}
        />
      ) : null}

      <Loader open={apiProcessing.loader} message={apiProcessing.message} />

      <div className="w-5/6 m-auto">
        <span className="flex justify-center items-center bg-blue-52 text-white font-semibold text-center rounded h-10">
          Users 
          <InfoIcon
          message="Use the User Management System to track user details, profiles, and roles efficiently!"
          />
        </span>

        <section
          name="Searchbar and Options"
          className={"flex justify-between items-center mt-4 mb-2 mx-4"}
        >
          <span className="font-semibold">Users ({allUsers.length})</span>
          <span className="flex items-center gap-4">
            <Searchbar
              styles="ml-2 mr-4"
              placeholder="Search..."
              data={allUsers}
              matchProps={[
                { text: "name" },
                { text: "email" },
                { text: "phoneNumber" },
                { text: "userRole" },
              ]}
              setTableData={setTableData}
            />

            <ButtonBlueOutline
              id="addNewUser"
              btnName="addNewUser"
              title="Add New User"
              name={
                <>
                  <BsPlusCircle className="w-5 h-5 mr-2" /> Add New
                </>
              }
              onClick={(e) => {
                setCreateUserPage(true);
                setFormOperation("New");
              }}
              className={`flex justify-center items-center px-2 w-fit h-10 rounded-md`}
            />
          </span>
        </section>

        <TableComponent
          tableId="UserTable"
          data={tableData}
          columns={[
            {
              key: "name",
              title: "Name",
            },
            { key: "email", title: "Email ID" },
            {
              key: "phoneNumber",
              title: "Contact No",
              applyMethod: (row) => PhoneNumFormat(row.phoneNumber, true),
            },
            { key: "userRole", title: "Profile" },
          ]}
          rowOnClick={() => {}}
          actionHeader="Actions"
          actions={userTableActions}
        />
      </div>
    </>
  );
};

export default UserTable;
