import React, { useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { Dialog } from "@mui/material";
import { useNavigate } from "react-router";
import { Auth } from "aws-amplify";
import { useResetRecoilState } from "recoil";
import { userData } from "../atoms";
import swal from "sweetalert";

const IdleTimeoutContainer = (props) => {
  const userReset = useResetRecoilState(userData);
  let navigate = useNavigate();
  // console.log("came to idlefile");
  // const idleTimerRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const onIdle = () => {
    console.log("user is idle");
    swal("Info", "Your session has timed out. You have been logged out...!!", "info")
    props.handleLogout();
  };

  //   const onActive = (event) => {
  //     console.log("user is active");
  //     setOpenModal(false);
  //     // Close Modal Prompt
  //     // Do some active action
  //   };

  const logout = () => {
    //   await Auth.signOut().then(() => {
    //     userReset();
    //     navigate("/login", { replace: true });
    //   });
    // props.handleLogout();
  };
  const idleTimer = useIdleTimer({ timeout: 15 * 60 * 1000, onIdle });
  return (
    <Dialog
      open={openModal}
      aria-labelledby="Create FloorPlan Form"
      fullWidth
      maxWidth="md"
      PaperProps={{ sx: { height: "20%" } }}
      scroll="body"
      className="flex justify-center items-center w-full"
    >
      <div className="flex flex-col items-center justify-items-center m-10 gap-3">
        <p className="font-bold">
          Your session has been timed out. You will be logged out..!!
        </p>
        <button
          onClick={() => logout()}
          className="m-2 bg-blue-52 text-white font-semibold px-2 w-24 h-10 border border-gray-400 rounded shadow focus:shadow-lg hover:shadow-lg hover:bg-blue-53 focus:bg-blue-53"
        >
          OK
        </button>
      </div>
    </Dialog>
  );
};

export default IdleTimeoutContainer;
