import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { WebApimanager } from "../../WebApiManager";
import { ButtonIcon } from "../../Widgets/Buttons";
import { BsDownload } from "react-icons/bs";
import {
  GetDateInMMDDYYYY,
  TableComponent,
  Searchbar,
} from "../../Widgets/CommonWidgets";
import ExportCSV from "../../Widgets/ExportCSV";
import ReportsBuilder from "./reportsBuilder";
import swal from "sweetalert";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";

const UnitDirectoryReport = () => {
  let webApi = new WebApimanager();
  let navigate = useNavigate();

  const [reportBuilderValues, setReportBuilderValues] = useState({
    portfolioId: "",
    propertyId: "",
  });
  const [viewUnitsTable, setViewUnitsTable] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState({});
  const [allUnitsMap, setAllUnitsMap] = useState(new Map());
  const [unitTableData, setUnitTableData] = useState([]);

  useEffect(() => {
    console.log("reportBuilderValues:", reportBuilderValues);
  }, [reportBuilderValues]);

  const handleReportValues = (reportValues) => {
    console.log("Acquired values ", reportValues);
    setReportBuilderValues({
      ...reportBuilderValues,
      portfolioId: reportValues.portfolioId,
      propertyId: reportValues.propertyId,
    });
    getUnitDirectoryReport(reportValues.propertyId);
  };

  const getUnitDirectoryReport = (propertyId) => {
    if (allUnitsMap.has(propertyId)) {
      setUnitTableData(allUnitsMap.get(propertyId));
      setViewUnitsTable(true);
      return;
    }
    setUnitTableData([]);
    webApi
      .get("/units/property/" + propertyId)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setUnitTableData(res.data);
          setAllUnitsMap(allUnitsMap.set(propertyId, res.data));
          setViewUnitsTable(true);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Apologies, Failed to genarate Unit Directory Report, Please try again later.`,
            "error"
          );
        }
      });
  };

  const getFullAddress = (data) => {
    const { address1, address2, city, state, country, zipCode } = data.address;
    return `${address1}${address2 ? `, ${address2}` : ""}${
      city ? `, ${city}` : ""
    } ${state ? `, ${state}` : ""}${country ? `, ${country}` : ""}${
      zipCode ? `, ${zipCode}` : ""
    }`;
  };

  const getFullAddressCSV = (data) => {
    const { address1, address2, city, state, country, zipCode } = data.address;
    return `${address1}${address2 ? ` ${address2}` : ""}${
      city ? ` ${city}` : ""
    } ${state ? ` ${state}` : ""}${country ? ` ${country}` : ""}${
      zipCode ? ` ${zipCode}` : ""
    }`;
  };

  const downloadPDF = () => {
    let data = {
      propertyId : reportBuilderValues.propertyId
    }
    webApi
      .post("/report/unitDirectory/pdf", data , { loader: true })
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          const link = document.createElement('a');
          link.href = res.data.url;
          link.setAttribute('download', 'filename.pdf'); // You can set a default filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Apologies, Failed to download Unit Directory Report, Please try again later.`,
            "error"
          );
        }
      });
  }

  return (
    <>
      <div className="w-5/6 m-auto">
        <span className="flex justify-center items-center bg-blue-52 text-white font-semibold text-center rounded h-10">
          {!viewUnitsTable && (
            <BsFillArrowLeftCircleFill
              title="Back to Previous page"
              onClick={(e) => {
                navigate(-1);
              }}
              className="w-6 h-6 ml-2 cursor-pointer"
            />
          )}
          <span className="flex-grow">Unit Directory</span>
        </span>

        <ReportsBuilder
          show={viewUnitsTable ? false : true}
          handleSearch={handleReportValues}
          builderValues={reportBuilderValues}
          selectedProperty={setSelectedProperty}
          propertyRequired
          hideUnit
          hideTenant
          hideReportPeriod
          hideLeaseStatus
          allignSearchButtonRight
        />

        {viewUnitsTable && (
          <section>
            <section
              name="Units Table and Options"
              className="flex justify-between items-center bg-blue-30 h-10 mb-4"
            >
              <span className="flex gap-4">
                <BsFillArrowLeftCircleFill
                  title="Back to Previous page"
                  onClick={(e) => {
                    setViewUnitsTable(false);
                  }}
                  className="text-blue-52 w-6 h-6 ml-2 cursor-pointer"
                />

                <span className="flex font-semibold items-center">
                  {selectedProperty.propertyName} :
                  <p className="font-normal">
                    &nbsp;{getFullAddress(selectedProperty)}
                  </p>
                </span>
              </span>
            </section>

            <div className="flex justify-end items-center mb-4 mt-4 gap-4 mr-4">
              <ExportCSV
                data={unitTableData}
                keys={[
                  { key: "unitNumber", title: "Unit Number" },
                  {
                    key: "address.address1",
                    title: "Address",
                    applyMethod: (row) => getFullAddressCSV(row),
                  },
                  { key: "floorPlan.unitTypeName", title: "Floor Plans" },
                  {
                    key: "rent",
                    title: "Rent",
                    applyMethod: (row) => {
                      return "$" + row.rent.toLocaleString();
                    },
                  },
                  {
                    key: "deposit",
                    title: "Deposit",
                    applyMethod: (row) => {
                      return "$" + row.deposit.toLocaleString();
                    },
                  },
                  { key: "sqFt", title: "Sqft" },
                  // {
                  //   key: "floorPlan.numberOfRooms",
                  //   title: "No. of Rooms",
                  //   width: "140px",
                  // },
                  {
                    key: "floorPlan.numberOfBeds",
                    title: "No. of Beds",
                  },
                  {
                    key: "floorPlan.numberOfBaths",
                    title: "No. of Baths",
                  },
                ]}
                fileName="Unit Directory.csv"
              />
              <ButtonIcon
                id="Download"
                btnName="download"
                dataCy="download"
                title="Download PDF"
                onClick={downloadPDF}
                icon={<BsDownload className="w-5 h-5" />}
                className="hover:!bg-blue-52 hover:!text-white shadow-md"
              />        
              <Searchbar
                placeholder="Search ..."
                data={allUnitsMap.get(reportBuilderValues.propertyId)}
                matchProps={[
                  { text: "unitNumber" },
                  { text: "floorPlan.unitTypeName" },
                  { text: "rent" },
                  { text: "deposit" },
                  { text: "sqFt" },
                  // {text:"floorPlan.numberOfRooms"},
                  { text: "floorPlan.numberOfBeds" },
                  { text: "floorPlan.numberOfBaths" },
                  { text: "address.address1" },
                  { text: "address.address2" },
                  { text: "address.city" },
                  { text: "address.state" },
                  { text: "address.country" },
                  { text: "address.zipCode" },
                ]}
                setTableData={setUnitTableData}
              />
            </div>

            <TableComponent
              tableId="Units Table"
              data={unitTableData}
              columns={[
                { key: "unitNumber", title: "Unit Number", width: "120px" },
                {
                  key: "address.address1",
                  title: "Address",
                  applyMethod: (row) => getFullAddress(row),
                  width: "440px",
                },
                { key: "floorPlan.unitTypeName", title: "Floor Plans" },
                {
                  key: "rent",
                  title: "Rent",
                  width: "100px",
                  applyMethod: (row) => {
                    return "$" + row.rent.toLocaleString();
                  },
                },
                {
                  key: "deposit",
                  title: "Deposit",
                  width: "100px",
                  applyMethod: (row) => {
                    return "$" + row.deposit.toLocaleString();
                  },
                },
                { key: "sqFt", title: "Sqft", width: "100px" },
                // {
                //   key: "floorPlan.numberOfRooms",
                //   title: "No. of Rooms",
                //   width: "140px",
                // },
                {
                  key: "floorPlan.numberOfBeds",
                  title: "No. of Beds",
                  width: "120px",
                },
                {
                  key: "floorPlan.numberOfBaths",
                  title: "No. of Baths",
                  width: "120px",
                },
              ]}
              rowOnClick={() => {}}
              //initialSort={{ key: "unitNumber", direction: "descending" }}
            />
          </section>
        )}
      </div>
    </>
  );
};

export default UnitDirectoryReport;
