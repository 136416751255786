import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { WebApimanager } from "../../../WebApiManager";
import ExportCSV from "../../../Widgets/ExportCSV";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import swal from "sweetalert";
import { InputSelectField } from "../../../Widgets/Forms/InputFields";
import { DetailsTile } from "../../../Widgets/Forms/FormReviewTiles";
import { IconInCircle } from "../../../Widgets/Icons";
import {
  GetDateInMMDDYYYY,
  TableComponent,
  Searchbar,
} from "../../../Widgets/CommonWidgets";
import { ChartCard } from "../../Dashboard";
import { ButtonIcon } from "../../../Widgets/Buttons";
import { DatePicker } from "@mui/x-date-pickers";
import { BsDownload } from "react-icons/bs";
import dayjs from "dayjs";
import { GoogleChart } from "../../../Widgets/charts";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { FiClipboard } from "react-icons/fi";
import { AiOutlineDownload } from "react-icons/ai";

const AccountDetails = () => {
  let webApi = new WebApimanager();
  let location = useLocation();
  let navigate = useNavigate();
  const accountDetails = location.state.data;

  const [selectedViewtype, setSelectedViewType] = useState("Classic View");

  const [allTransctionsData, setAllTransactionsData] = useState([]);
  const [transactionsTableData, setTransactionsTableData] = useState([]);

  const [reportPeriod, setReportPeriod] = useState("All");
  const [customReportPeriodValues, setCustomReportPeriodValues] = useState({
    startDate: "",
    endDate: "",
  });
  const reportPeriodOptions = [
    "All",
    "Custom",
    "Last 3 Months",
    "Last 6 Months",
    "Last Year",
    "Year to Date",
  ];

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  const [propertyRevenue, setPropertyRevenue] = useState([
    ["Amount", "Balance", "Amount"],
    ["05/03/2023", 500, 400],
    ["03/05/2023", 800, 1000],
    ["05/03/2023", 400, 600],
    ["03/05/2023", 500, 1200],
    ["05/03/2023", 1600, 1200],
    ["03/05/2023", 2000, 1800],
    ["05/03/2023", 500, 1000],
    ["03/05/2023", 400, 800],
    ["05/03/2023", 3000, 3400],
    ["03/05/2023", 1200, 2600],
    ["05/03/2023", 600, 2000],
    ["03/05/2023", 120, 800],
  ]);

  const propertyRevenueOptions = {
    colors: ["#D96470", "#4049AC"],
    legend: {
      position: "right",
      alignment: "start",
    },
  };

  useEffect(() => {
    getAccountTransactions();
  }, []);

  const getAccountTransactions = () => {
    setApiProcessing({ loader: true, message: `Loading Accounts...` });
    webApi
      .get(
        `/accounting/transactions/chartOfAccounts/${accountDetails.account_id}`
      )
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setAllTransactionsData(res.data.transactions);
          setTransactionsTableData(res.data.transactions);
        }
        setApiProcessing({ loader: false, message: `` });
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops! Failed to gather Account Transactions, Please try again later.",
            "error"
          );
        }
      });
  };

  const handleReportPeriodChange = (period) => {
    const currentDate = dayjs();
    let from = "";
    let to = "";
    if (period === "Custom" || period === "All") {
      setCustomReportPeriodValues({
        startDate: "",
        endDate: "",
      });
      setTransactionsTableData(allTransctionsData);
      return setReportPeriod(period);
    } else if (period === "Last 3 Months") {
      from = currentDate.subtract(3, "month").startOf("month");
      to = currentDate.endOf("day");
    } else if (period === "Last 6 Months") {
      from = currentDate.subtract(6, "month").startOf("month");
      to = currentDate.endOf("day");
    } else if (period === "Last Year") {
      from = dayjs().subtract(1, "year").startOf("year");
      to = from.endOf("year");
    } else if (period === "Year to Date") {
      from = currentDate.startOf("year");
      to = currentDate.endOf("day");
    }
    if (from && to) {
      setCustomReportPeriodValues({
        startDate: from.format("MM/DD/YYYY"),
        endDate: to.format("MM/DD/YYYY"),
      });
      handleTransactionsFilter(from, to);
      setReportPeriod(period);
    }
  };

  const handleTransactionsFilter = (from, to) => {
    if (from && to) {
      const filteredTransactions = allTransctionsData.filter((transaction) => {
        const transactionDate = dayjs(transaction.date, "DD/MM/YYYY");
        return transactionDate.isBetween(from, to, null, []);
      });
      setTransactionsTableData(filteredTransactions);
    }
  };

  const downloadPDF = () => {
    let data = {
        accountId : accountDetails.account_id,
        startDate : customReportPeriodValues.startDate,
        endDate : customReportPeriodValues.endDate
    }
    webApi
      .post("/report/transactions/pdf", data , { loader: true })
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          const link = document.createElement('a');
          link.href = res.data.url;
          link.setAttribute('download', 'filename.pdf'); // You can set a default filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Apologies, Failed to download Inspection Report, Please try again later.`,
            "error"
          );
        }
      });
  };

  return (
    <>
      <Loader open={apiProcessing.loader} message={apiProcessing.message} />

      <div className="w-5/6 m-auto">
        <section
          name="Account Transaction & Options"
          className="flex justify-between items-center bg-blue-53 h-10 mb-4"
        >
          <span className="flex gap-4">
            <BsFillArrowLeftCircleFill
              id="backtoPrevious"
              name="backtoPrevious"
              data-cy="backtoPrevious"
              title="Back to Previous page"
              onClick={(e) => {
                navigate(-1);
              }}
              className="text-white w-6 h-6 ml-2 cursor-pointer"
            />
            <p className="font-semibold text-white">
              {accountDetails.account_name}
            </p>
          </span>
        </section>

        <section
          name="Header and SearchBar"
          className="flex justify-between items-center mb-8 mx-6"
        >
          <span className="flex items-center gap-4">
            <IconInCircle
              icon={<FiClipboard className="w-8 h-8 text-blue-52" />}
              radius="3.5rem"
              className="shadow-xl"
              color="#BFE3ED"
            />
            <span>
              <DetailsTile
                label="Account No. :"
                data={accountDetails.account_id}
                labelClassName="justify-self-start text-gray-500"
                dataClassName="justify-self-start ml-4"
              />
              <DetailsTile
                label="Account Type :"
                data={accountDetails.account_type}
                labelClassName="justify-self-start text-gray-500"
                dataClassName="justify-self-start ml-4"
              />
              <DetailsTile
                label="Account Status :"
                data={accountDetails.account_status}
                labelClassName="justify-self-start text-gray-500"
                dataClassName="justify-self-start ml-4"
              />
            </span>
          </span>
          <InputSelectField
            name="viewType"
            id="viewType"
            dataCy="viewType"
            placeholder="Select View Type"
            options={["Classic View", "Chart View"]}
            value={selectedViewtype}
            onChange={(e) => setSelectedViewType(e.target.value)}
            notImp
          />
        </section>

        {selectedViewtype === "Classic View" && (
          <>
            <section
              name="Searchbar and Options"
              className={
                "flex justify-between items-center gap-2 my-4 md:flex-col md:items-start"
              }
            >
              {/* <p className="font-semibold text-gray-600">
                  Transactions ({allTransctionsData.length})
                </p> */}

              <div className="flex w-full gap-2">
                <InputSelectField
                  label="Report Period"
                  name="reportPeriods"
                  id="reportPeriods"
                  notImp
                  placeholder="Select Report Period"
                  value={reportPeriod}
                  onChange={(e) => {
                    handleReportPeriodChange(e.target.value);
                  }}
                  options={reportPeriodOptions}
                  className="w-full"
                />

                <div className="flex items-end gap-2">
                  <label className="w-full">
                    <p className="flex">
                      From <span className="text-red-600">*</span>
                    </p>
                    <DatePicker
                      readOnly={reportPeriod !== "Custom" ? true : false}
                      value={dayjs(customReportPeriodValues.startDate)}
                      onChange={(e) => {
                        setCustomReportPeriodValues({
                          ...customReportPeriodValues,
                          startDate: dayjs(e.$d).format("MM/DD/YYYY"),
                        });
                        handleTransactionsFilter(
                          dayjs(e.$d).format("MM/DD/YYYY"),
                          customReportPeriodValues.endDate
                        );
                      }}
                      sx={styles.datePicker}
                    />
                  </label>

                  <label className="w-full">
                    <p className="flex">
                      To <span className="text-red-600">*</span>
                    </p>
                    <DatePicker
                      readOnly={reportPeriod !== "Custom" ? true : false}
                      value={dayjs(customReportPeriodValues.endDate)}
                      onChange={(e) => {
                        setCustomReportPeriodValues({
                          ...customReportPeriodValues,
                          endDate: dayjs(e.$d).format("MM/DD/YYYY"),
                        });
                        handleTransactionsFilter(
                          customReportPeriodValues.startDate,
                          dayjs(e.$d).format("MM/DD/YYYY")
                        );
                      }}
                      sx={styles.datePicker}
                    />
                  </label>

                  <button
                    onClick={() => {
                      setReportPeriod("");
                      setCustomReportPeriodValues({
                        startDate: "",
                        endDate: "",
                      });
                    }}
                    className="underline text-blue-52 cursor-pointer items-end active:scale-95 transform transition duration-100"
                  >
                    Reset
                  </button>
                </div>
              </div>

              <span className="flex w-full justify-end items-end gap-2">
              <ExportCSV
                  data={transactionsTableData}
                  keys={[
                    {
                      key: "date",
                      title: "Date",
                      applyMethod: (row) => GetDateInMMDDYYYY(row.date),
                    },
                    { key: "transactionType", title: "Transaction Type" },
                    {
                      key: "payee",
                      title: "Name",
                    },
                    {
                      key: "description",
                      title: "Description",
                    },
                    {
                      key: "accountType",
                      title: "Account",
                    },
                    {
                      key: "clr",
                      title: "CLR",
                      width: "50px",
                    },
                    {
                      key: "amount",
                      title: "Amount",
                      applyMethod: (row) => {
                        if (row.amount) {
                          return "$" + row.amount.toLocaleString();
                        } else {
                          return "-$" + row.amount.toLocaleString();
                        }
                      },
                    },
                    {
                      key: "balance",
                      title: "Balance",
                      applyMethod: (row) => {
                        if (row.balance) {
                          return "$" + row.balance.toLocaleString();
                        } else {
                          return "-$" + row.balance.toLocaleString();
                        }
                      },
                    },
                  ]}
                  fileName="Rental Ledger.csv"
                />

                <ButtonIcon
                  id="Download"
                  btnName="download"
                  dataCy="download"
                  title="Download PDF"
                  onClick={downloadPDF}
                  icon={<BsDownload className="w-5 h-5" />}
                  className="hover:!bg-blue-50 hover:!text-white shadow-md"
                />
                <Searchbar
                  style="ml-2"
                  placeholder="Search..."
                  data={allTransctionsData}
                  matchProps={[
                    { text: "date" },
                    { text: "transactionType" },
                    { text: "payee" },
                    { text: "description" },
                    { text: "accountType" },
                    { text: "clr" },
                    { text: "amount" },
                    { text: "balance" },
                  ]}
                  setTableData={setTransactionsTableData}
                />
              </span>
            </section>

            <TableComponent
              tableId="ChartOfAccountsTable"
              data={transactionsTableData}
              columns={[
                {
                  key: "date",
                  title: "Date",
                  applyMethod: (row) => GetDateInMMDDYYYY(row.date),
                },
                { key: "transactionType", title: "Transaction Type" },
                {
                  key: "payee",
                  title: "Name",
                },
                {
                  key: "description",
                  title: "Description",
                },
                {
                  key: "accountType",
                  title: "Account",
                },
                {
                  key: "clr",
                  title: "CLR",
                  width: "50px",
                },
                {
                  key: "amount",
                  title: "Amount",
                  applyMethod: (row) => {
                    if (row.amount) {
                      return "$" + row.amount.toLocaleString();
                    } else {
                      return "-$" + row.amount.toLocaleString();
                    }
                  },
                },
                {
                  key: "balance",
                  title: "Balance",
                  applyMethod: (row) => {
                    if (row.balance) {
                      return "$" + row.balance.toLocaleString();
                    } else {
                      return "-$" + row.balance.toLocaleString();
                    }
                  },
                },
              ]}
              rowOnClick={(row) =>
                navigate("/accounting/chartOfAccounts/transactionDetails", {
                  state: { data: { ...row, ...accountDetails } },
                })
              }
              //initialSort={{ key: "balance", direction: "descending" }}
            />
          </>
        )}

        {selectedViewtype === "Chart View" && (
          <section>
            <div
              name="Account Transaction & Options"
              className="flex justify-between items-center bg-blue-50 h-10 my-6"
            >
              <p className="font-semibold text-white ml-4">
                Account Drill Down Report
              </p>
            </div>

            <div name="Total Amount" className="text-gray-500 mb-4">
              {" "}
              Total Amount
              <p className="font-semibold">$10000</p>
            </div>

            <ChartCard
              title=""
              cardClassName="col-span-3"
              chart={
                <GoogleChart
                  chartType="Bar"
                  width="100%"
                  data={propertyRevenue}
                  options={propertyRevenueOptions}
                />
              }
            />
          </section>
        )}
      </div>
    </>
  );
};

export default AccountDetails;

const styles = {
  datePicker: {
    "& .MuiInputBase-input": {
      padding: "8px",
      fontSize: "14px",
      fontWeight: 300,
    },
    "& .MuiIconButton-root": {
      padding: "8px",
    },
    "& .MuiPickersDay-daySelected": {
      backgroundColor: "#F1F5F9",
      "&:hover": {
        backgroundColor: "#006699",
      },
    },
    "& .MuiPickersDay-current": {
      color: "#F1F5F9",
      fontWeight: 300,
    },
  },
};
