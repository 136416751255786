import React, { useState, useEffect, useRef } from "react";
import { InputPhoneNumField } from "../../Widgets/Forms/InputFields";
import InputField from "../../Widgets/Forms/InputFields";
import { userData, profileImageUrl } from "../../atoms";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { WebApimanager } from "../../WebApiManager";
import swal from "sweetalert";
import { FiEdit3 } from "react-icons/fi";
import VerifyEmail from "./verifyEmail";
import { Auth } from "aws-amplify";
import { ButtonBlue, ButtonGrayOutline } from "../../Widgets/Buttons";
import { DetailsTile } from "../../Widgets/Forms/FormReviewTiles";
import ChangePassword from "./changePassword";
import { ButtonIcon } from "../../Widgets/Buttons";

const MyProfile = () => {
  let webApi = new WebApimanager();
  let navigate = useNavigate();
  const [currentUserInfo, setCurrentUserInfo] = useRecoilState(userData);
  const [profileImage, setProfileImage] = useRecoilState(profileImageUrl);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [emailId, setEmailId] = useState("");
  const [openEmailCodeForm, setOpenEmailCodeForm] = useState(false);
  const [controlSwal, setControlSwal] = useState(false);
  const [formErrors, setFormErrors] = useState("");
  const [openChangePass, setopenChangePass] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const validate = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const errors = {};
    if (!firstName) {
      errors.firstName = "First Name is required";
    }
    if (!lastName) {
      errors.lastName = "Last Name is required";
    }
    if (contactNo === "+1") {
      errors.contactNo = "Contact Number is required";
    } else if (contactNo.length < 12) {
      errors.contactNo = " Contact Number valid!";
    }
    if (!emailId) {
      errors.emailId = "Email is required!";
    } else if (!emailRegex.test(emailId)) {
      errors.emailId = "Email is not valid!";
    }
    return errors;
  };

  useEffect(() => {
    //console.log(currentUserInfo, "atoms values currentUsetInfo");
    getProfileInfo(currentUserInfo.email);
    getUserProfilePhoto(currentUserInfo.image);
  }, []);

  const getProfileInfo = (email) => {
    webApi
      .get("/clients/" + email)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setFirstName(res.data.firstName);
          setLastName(res.data.lastName);
          setEmailId(res.data.email);
          setContactNo(res.data.phoneNumber);
          setCurrentUserInfo(res.data);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to Profile Details, Please try again later.`,
            "error"
          );
        }
      });
  };

  const goToHome = () => {
    navigate("/dashboard");
  };

  //Function to update the user's email
  async function updateUserEmail(newEmail) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const attributes = {
        email: newEmail,
      };
      await Auth.updateUserAttributes(user, attributes);
      console.log("Verification Code sent successfully");
      setOpenEmailCodeForm(true);
      updateProfile();
    } catch (error) {
      swal("Error updating email", error.message);
    }
  }

  const handleFileInput = useRef(null);

  const updateProfile = () => {
    let data = {
      ID: currentUserInfo.ID,
      CreatedAt: currentUserInfo.CreatedAt,
      UpdatedAt: currentUserInfo.UpdatedAt,
      DeletedAt: currentUserInfo.DeletedAt,
      email: emailId,
      firstName: firstName,
      lastName: lastName,
      companyName: currentUserInfo.companyName,
      phoneNumber: contactNo,
      taxId: currentUserInfo.taxid,
      userRole: currentUserInfo.userRole,
      businessAddress: {
        ID: currentUserInfo.businessAddress.ID,
        CreatedAt: currentUserInfo.businessAddress.CreatedAt,
        UpdatedAt: currentUserInfo.businessAddress.UpdatedAt,
        DeletedAt: currentUserInfo.businessAddress.DeletedAt,
        address1: currentUserInfo.businessAddress.address1,
        address2: currentUserInfo.businessAddress.address2,
        city: currentUserInfo.businessAddress.city,
        state: currentUserInfo.businessAddress.state,
        country: currentUserInfo.businessAddress.country,
        zipCode: currentUserInfo.businessAddress.zipCode,
        ClientId: currentUserInfo.businessAddress.ClientId,
      },
      surveyStatus: true,
      clientID: currentUserInfo.clientID,
      isBlock: currentUserInfo.isBlock,
      companyID: currentUserInfo.companyID,
      isAddedInCognitoUserGroup: currentUserInfo.isAddedInCognitoUserGroup,
    };
    webApi
      .put("/clients/" + currentUserInfo.ID, data)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          if (controlSwal === false) {
            swal("Success", "Profile Update Successful", "success");
          }
          setCurrentUserInfo(res.data);
          setShowEdit(false);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to Update User Details, Please try again later.`,
            "error"
          );
        }
      });
  };

  const validateFileTypes = (image) => {
    const allowedExtensions = ".png, .jpg, .jpeg"
      .split(",")
      .map((type) => type.trim());

    const fileExtension = image.name.split(".").pop().toLowerCase();

    if (allowedExtensions.includes("." + fileExtension)) {
      UploadProfilePhoto(image);
    } else {
      swal(
        "Warning",
        "Invalid File type, Please choose a file with one of the (.png, .jpg, .jpeg) file types",
        "warning"
      );
    }
  };

  const UploadProfilePhoto = (image) => {
    webApi
      .post("/clients/profileImage/" + currentUserInfo.ID + "&" + image.name)
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          const metaData = {
            filename: image.name,
            mediatype: image.type,
          };
          webApi
            .uploadFile(res.data, metaData, image)
            .then((res) => {
              swal("Success", "Successfully uploaded Profile Photo", "success");
              //update image
              webApi
                .get("/clients/" + currentUserInfo.email)
                .then((res) => {
                  if (res.data.errorCode) {
                    console.log("Error", res.data.errorMessage, "error");
                  } else {
                    getUserProfilePhoto(res.data.image);
                    setCurrentUserInfo(res.data);
                  }
                })
                .catch((error) => {
                  if (error.customErrorMessage) {
                    swal("Error", error.customErrorMessage, "error");
                  } else {
                    swal(
                      "Error",
                      "Unable to get Updated Details! Please try again later.",
                      "error"
                    );
                  }
                });
            })
            .catch((error) => {
              if (error.customErrorMessage) {
                swal("Error", error.customErrorMessage, "error");
              } else {
                swal(
                  "Error",
                  "Unable to Upload Profile Photo! Please try again later.",
                  "error"
                );
              }
            });
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Unable to Upload Profile Photo, Please try again later.`,
            "error"
          );
        }
      });
  };

  const getUserProfilePhoto = (filePath) => {
    const data = {
      objectKey: `${filePath}`,
    };
    webApi
      .post(`/documents/download`, data)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setProfileImage(res.data.objectKey);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else
          swal(
            "Error",
            "Oops, Unable to get the Profile Image! Please try again later.",
            "error"
          );
      });
  };

  const handleSubmit = () => {
    const temp = validate();
    setFormErrors(temp);

    if (Object.keys(temp).length) {
      return;
    } else {
      if (currentUserInfo.email !== emailId) {
        setControlSwal(true);
        updateUserEmail(emailId);
      } else {
        updateProfile();
      }
    }
  };

  return (
    <>
      <VerifyEmail
        trigger={openEmailCodeForm}
        setTrigger={setOpenEmailCodeForm}
        updateProf={() => updateProfile()}
      />
      <ChangePassword trigger={openChangePass} setTrigger={setopenChangePass} />
      <div className="w-5/6 m-auto border">
        <span
          id="profile-heading"
          className="flex justify-center items-center bg-blue-52 text-white font-semibold text-center h-10"
        >
          PROFILE DETAILS
        </span>
        <div className="w-full flex h-44 bg-[#dce9e9] mb-20">
          <div className="relative">
            <img
              id="profileImage"
              src={
                profileImage ||
                "https://w7.pngwing.com/pngs/981/645/png-transparent-default-profile-united-states-computer-icons-desktop-free-high-quality-person-icon-miscellaneous-silhouette-symbol-thumbnail.png"
              }
              alt=""
              className="w-52 h-52 rounded-full object-cover m-16 ml-20 bg-gray-400"
            />
            <span className="absolute h-10 w-10 rounded-full bg-white border-4 top-0 right-0 m-16 ml-20 mt-52">
              <button
                id="uploadPicture"
                name="uploadPicture"
                data-cy="uploadPicture"
                onClick={() => handleFileInput.current.click()}
                className="flex justify-center items-center h-full w-full focus:outline-none"
              >
                <FiEdit3 className="text-xl text-blue-52" />
                <input
                  accept=".png, .jpg, .jpeg"
                  type="file"
                  className="hidden"
                  ref={handleFileInput}
                  onChange={(e) => validateFileTypes(e.target.files[0])}
                />
              </button>
            </span>
          </div>
        </div>
        <div className="flex-row ml-80 -mt-12">
          <div className="flex-col justify-start">
            <p id="profileName" className="font-semibold text-gray-700 text-xl">
              {currentUserInfo.firstName + " " + currentUserInfo.lastName}
            </p>
            <p id="profile-bottomline">
              Update your Photo and Personal Details!
            </p>
          </div>
          <ButtonBlue
            id="changePassword"
            btnName="changePassword"
            dataCy="changePassword"
            name="Change Password"
            onClick={() => setopenChangePass(true)}
            className="mt-1 w-fit px-2"
          />
        </div>

        <div className="flex flex-col w-full ml-6 mt-4">
          <span
            id="border-line"
            className="flex flex-row border-b-2 border-blue-52 mt-8 mb-6 text-blue-52 w-11/12 font-semibold justify-between"
          >
            <p id="personal-info-label">Personal Information</p>
            <ButtonIcon
              id="editInfo"
              name="editInfo"
              dataCy="editInfo"
              title="Edit"
              onClick={() => setShowEdit(true)}
              icon={
                <FiEdit3
                  className="text-xl text-blue-52 cursor-pointer"
                  title="Edit"
                />
              }
              className="hover:!text-red-600"
            />
          </span>

          {showEdit ? (
            <div className="flex flex-col w-96">
              <DetailsTile
                id="firstname"
                label="First Name"
                data={
                  <InputField
                    name="firstName"
                    id="firstName"
                    dataCy="firstName"
                    type="text"
                    placeholder=""
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    errorText={formErrors.firstName}
                    style="!w-96 mb-4"
                    notImp
                  />
                }
                className="!gap-2"
                dataClassName="text-gray-500"
              />

              <DetailsTile
                label="Last Name"
                data={
                  <InputField
                    name="lastName"
                    id="lastName"
                    dataCy="lastName"
                    type="text"
                    placeholder=""
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    errorText={formErrors.lastName}
                    style="!w-96 mb-4"
                    notImp
                  />
                }
                className=""
                dataClassName="text-gray-500"
              />

              <DetailsTile
                label="Contact Number"
                labelClassName="!w-52"
                data={
                  <InputPhoneNumField
                    name="contactNo"
                    id="contactNo"
                    dataCy="contactNo"
                    placeholder=""
                    value={contactNo}
                    setValue={setContactNo}
                    errorText={formErrors.contactNo}
                    style=" mb-4"
                    notImp
                  />
                }
                className="mb-3"
                dataClassName="text-gray-500 w-96"
              />

              <DetailsTile
                label="Email ID"
                data={
                  <InputField
                    name="emailId"
                    id="emailId"
                    dataCy="emailId"
                    type="text"
                    placeholder=""
                    value={emailId}
                    onChange={(e) => setEmailId(e.target.value)}
                    errorText={formErrors.emailId}
                    style="!w-96 mb-4"
                    notImp
                  />
                }
                className=""
                dataClassName="text-gray-500"
              />

              <div className="flex justify-center items-center gap-16 mt-6 mb-4 ml-12 w-full">
                <ButtonGrayOutline
                  id="cancel"
                  btnName="cancel"
                  dataCy="cancel"
                  name="Cancel"
                  onClick={() => setShowEdit(false)}
                  className="mt-1"
                />
                <ButtonBlue
                  id="Save"
                  btnName="Save"
                  dataCy="Save"
                  name="Save"
                  onClick={() => handleSubmit()}
                  className="mt-1"
                />
              </div>
            </div>
          ) : (
            <div className="flex items-start gap-x-10 gap-y-10 mb-10">
              <span className="flex flex-col justify-center items-start gap-y-8">
                <p id="firstName" className="text-gray-500">
                  First Name
                </p>
                <p id="lastName" className="text-gray-500">
                  Last Name
                </p>
                <p id="contact-no" className="text-gray-500">
                  Contact number
                </p>
                <p id="emailId" className="text-gray-500">
                  Email ID
                </p>
              </span>
              <span className="flex flex-col justify-center items-start gap-y-8">
                <p id="firstName-val">{firstName}</p>
                <p id="lastName-val">{lastName}</p>
                <p id="contact-no-val">{contactNo}</p>
                <p id="emailId-val">{emailId}</p>
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MyProfile;
