import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { WebApimanager } from "../../WebApiManager";
import { ButtonIcon } from "../../Widgets/Buttons";
import {
  GetDateInMMDDYYYY,
  TableComponent,
  Searchbar,
} from "../../Widgets/CommonWidgets";
import { BsDownload } from "react-icons/bs"; 
import ReportsBuilder from "./reportsBuilder";

import { InputObjectSelectField } from "../../Widgets/Forms/InputFields";
import ExportCSV from "../../Widgets/ExportCSV";
import swal from "sweetalert";
import { TbFileArrowRight } from "react-icons/tb";
import { AiOutlineEye } from "react-icons/ai";
import { BsFillArrowLeftCircleFill, BsThreeDotsVertical } from "react-icons/bs";

const RentalLedgerReport = () => {
  let webApi = new WebApimanager();
  let navigate = useNavigate();

  const [reportBuilderValues, setReportBuilderValues] = useState({
    portfolioId: "",
    propertyId: "",
    unitId: "",
    tenantCode: "",
    startDate: "",
    endDate: "",
    leaseStatus: "",
  });
  const [showReportBuilder, setShowReportBuilder] = useState(true);

  const [ledgerTotals, SetLedgerToatals] = useState({
    balance: 0,
    prePayment: 0,
  });

  const [propertyTableDataMap, setPropertyTableDataMap] = useState(new Map());
  const [unitsTableDataMap, setUnitsTableDataMap] = useState(new Map());
  const [tenantsLedgerOptionsMap, setTenantsLedgerOptionsMap] = useState(
    new Map()
  );
  const [rentalLedgerTableDataMap, setRentalLedgerTableDataMap] = useState(
    new Map()
  );

  const [propertyTableData, setPropertyTableData] = useState([]);
  const [unitsTableData, setUnitsTableData] = useState([]);
  const [tenantsLedgerOptions, setTenantsLedgerOptions] = useState([]);
  const [rentalLedgerTableData, setRentalLedgerTableData] = useState([]);

  const [viewPropertyTable, setViewPropertyTable] = useState(false);
  const [viewUnitsTable, setViewUnitsTable] = useState(false);
  const [viewRentalLedgerTable, setViewRentalLedgerTable] = useState(false);

  const [selectedPortfolio, setSelectedPortfolio] = useState({});
  const [selectedProperty, setSelectedProperty] = useState({});
  const [selectedUnit, setSelectedUnit] = useState({});
  const [selectedTenantCode, setSelectedTenantCode] = useState("");

  useEffect(() => {
    console.log("reportBuilderValues:", reportBuilderValues);
  }, [reportBuilderValues]);

  const getPropertyDelinquencyByPortfolioID = (
    portfolioId,
    tableView,
    requestData
  ) => {
    if (propertyTableDataMap.has(portfolioId)) {
      setPropertyTableData(propertyTableDataMap.get(portfolioId));
      setViewPropertyTable(true);
      return;
    }
    webApi
      .post("/reports/rentalLedger/portfolio/" + portfolioId, requestData)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setPropertyTableData(res.data);
          setPropertyTableDataMap(
            propertyTableDataMap.set(portfolioId, res.data)
          );
          setViewPropertyTable(tableView);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Failed to get Properties of Selected Portfolio, Please try again later.`,
            "error"
          );
        }
      });
  };

  const getUnitDelinquencyByPropertyId = (
    propertyId,
    tableView,
    requestData
  ) => {
    if (unitsTableDataMap.has(propertyId)) {
      setUnitsTableData(unitsTableDataMap.get(propertyId));
      setViewUnitsTable(tableView);
      return;
    }
    webApi
      .post("/reports/rentalLedger/property/" + propertyId, requestData)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          const tempData = [];
          if (Array.isArray(res.data) && res.data.length > 0) {
            res.data.map((unit) => {
              const addressFull = `${unit.address.address1}, ${unit.address.address2}, ${unit.address.city}, ${unit.address.state}, ${unit.address.country}, ${unit.address.zipCode}`;
              tempData.push({
                ...unit,
                addressFull,
              });
            });
          }

          setUnitsTableData(tempData);
          setUnitsTableDataMap(unitsTableDataMap.set(propertyId, tempData));
          setViewUnitsTable(tableView);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Failed to get Units of Selected Property, Please try again later.`,
            "error"
          );
        }
      });
  };

  const getTenantDelinquencyByUnitId = (unitId, tableView, requestData) => {
    if (tenantsLedgerOptionsMap.has(unitId)) {
      const tenants = tenantsLedgerOptionsMap.get(unitId);
      if (tableView) {
        setTenantsLedgerOptions(tenants);
        if (tenants.length) {
          handleTenantSelectOnChange(tenants[0].tenantCode);
        } else handleTenantSelectOnChange("");
      }
      setViewRentalLedgerTable(true);
      return;
    }
    setTenantsLedgerOptions([]);
    webApi
      .post("/reports/rentalLedger/unit/" + unitId, requestData)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          const sortedData =
            res.data.length > 1
              ? res.data.sort((a, b) => (a.tenantName > b.tenantName ? 1 : -1))
              : res.data;
          setTenantsLedgerOptions(sortedData);
          setTenantsLedgerOptionsMap(
            tenantsLedgerOptionsMap.set(unitId, sortedData)
          );

          if (tableView) {
            if (sortedData.length) {
              handleTenantSelectOnChange(sortedData[0].tenantCode);
            } else handleTenantSelectOnChange("");
          }
          setViewRentalLedgerTable(true);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Failed to get Tenants of Selected Unit, Please try again later.`,
            "error"
          );
        }
      });
  };

  const getLedgersByTenantCode = (tenantCode, tableView, requestData) => {
    if (rentalLedgerTableDataMap.has(tenantCode)) {
      setRentalLedgerTableData(rentalLedgerTableDataMap.get(tenantCode));
      setViewRentalLedgerTable(tableView);
      return;
    }
    setRentalLedgerTableData([]);
    webApi
      .post("/reports/rentalLedger/tenantCode/" + tenantCode, requestData)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          const sortedData = res.data.ledgers;
          setRentalLedgerTableData(sortedData);
          setRentalLedgerTableDataMap(
            rentalLedgerTableDataMap.set(tenantCode, sortedData)
          );

          SetLedgerToatals({
            balance: res.data.rentalBalance,
            prePayment: res.data.prepayBalance,
          });

          // if (
          //   //rentalLedgerTableData.length &&
          //   res.data[0].balance > 0
          // ) {
          //   SetLedgerToatals({
          //     balance: res.data[0].balance,
          //     prePayment: 0,
          //   });
          // } else if (
          //   res.data.length &&
          //   res.data[0].balance < 0
          // ) {
          //   SetLedgerToatals({
          //     balance: 0,
          //     prePayment: res.data[0].balance,
          //   });
          // }

          setViewRentalLedgerTable(tableView);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Failed to get Rental Ledgers of Selected Tenant, Please try again later.`,
            "error"
          );
        }
      });
  };

  const handlePropertyTableClick = (property) => {
    setUnitsTableData([]);
    setTenantsLedgerOptions([]);
    setRentalLedgerTableData([]);
    const requestData = {
      startDate: reportBuilderValues.startDate,
      endDate: reportBuilderValues.endDate,
      leaseStatus: reportBuilderValues.leaseStatus,
    };
    getUnitDelinquencyByPropertyId(
      parseInt(property.propertyId),
      true,
      requestData
    );
    setSelectedProperty(property);
    setReportBuilderValues({
      ...reportBuilderValues,
      propertyId: parseInt(property.propertyId),
      unitId: "",
      tenantCode: "",
    });
    setSelectedTenantCode("");
  };

  const handleUnitTableClick = (unit) => {
    const requestData = {
      startDate: reportBuilderValues.startDate,
      endDate: reportBuilderValues.endDate,
      leaseStatus: reportBuilderValues.leaseStatus,
    };
    getTenantDelinquencyByUnitId(parseInt(unit.unitId), true, requestData);
    setSelectedUnit(unit);
    setReportBuilderValues({
      ...reportBuilderValues,
      unitId: parseInt(unit.unitId),
      tenantCode: "",
    });
  };

  const handleTenantSelectOnChange = (tenantCode) => {
    setRentalLedgerTableData([]);
    const requestData = {
      startDate: reportBuilderValues.startDate,
      endDate: reportBuilderValues.endDate,
      leaseStatus: reportBuilderValues.leaseStatus,
    };
    getLedgersByTenantCode(tenantCode, true, requestData);
    setSelectedTenantCode(tenantCode);
    setReportBuilderValues({
      ...reportBuilderValues,
      tenantCode: tenantCode,
    });
  };

  const handleReportValues = (builderValues) => {
    setReportBuilderValues({
      portfolioId: builderValues.portfolioId,
      propertyId: builderValues.propertyId,
      unitId: builderValues.unitId,
      tenantCode: builderValues.tenantCode,
      startDate: builderValues.startDate,
      endDate: builderValues.endDate,
      leaseStatus: builderValues.leaseStatus,
    });

    const requestData = {
      startDate: builderValues.startDate,
      endDate: builderValues.endDate,
      leaseStatus: builderValues.leaseStatus,
    };

    setShowReportBuilder(!showReportBuilder);

    setPropertyTableData([]);
    setUnitsTableData([]);
    setTenantsLedgerOptions([]);
    setRentalLedgerTableData([]);
    setSelectedProperty({});
    setSelectedUnit({});

    if (builderValues.tenantCode) {
      getLedgersByTenantCode(builderValues.tenantCode, true, requestData);
      getTenantDelinquencyByUnitId(builderValues.unitId, false, requestData);

      setSelectedTenantCode(builderValues.tenantCode);

      getUnitDelinquencyByPropertyId(
        builderValues.propertyId,
        false,
        requestData
      );
      getPropertyDelinquencyByPortfolioID(
        builderValues.portfolioId,
        false,
        requestData
      );
    } else if (builderValues.unitId) {
      getTenantDelinquencyByUnitId(builderValues.unitId, true, requestData);
      getUnitDelinquencyByPropertyId(
        builderValues.propertyId,
        false,
        requestData
      );
      getPropertyDelinquencyByPortfolioID(
        builderValues.portfolioId,
        false,
        requestData
      );
    } else if (builderValues.propertyId) {
      getUnitDelinquencyByPropertyId(
        builderValues.propertyId,
        true,
        requestData
      );
      getPropertyDelinquencyByPortfolioID(
        builderValues.portfolioId,
        false,
        requestData
      );
    } else if (builderValues.portfolioId) {
      getPropertyDelinquencyByPortfolioID(
        builderValues.portfolioId,
        true,
        requestData
      );
    }
  };

  const downloadPDF = () => {
    console.log(reportBuilderValues,"report")
    let data = {
      tenantCode : reportBuilderValues.tenantCode,
      rentalRequest : {
        startDate : reportBuilderValues.startDate,
        endDate : reportBuilderValues.endDate
      }
    }
    webApi
      .post("/report/rentalLedger/pdf", data , { loader: true })
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          const link = document.createElement('a');
          link.href = res.data.url;
          link.setAttribute('download', 'filename.pdf'); // You can set a default filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Apologies, Failed to download Rental Ledger Report, Please try again later.`,
            "error"
          );
        }
      });
  }

  return (
    <>
      <div className="w-5/6 m-auto">
        <span className="flex justify-center items-center bg-blue-52 text-white font-semibold text-center rounded h-10">
          {!viewPropertyTable && !viewUnitsTable && !viewRentalLedgerTable && (
            <BsFillArrowLeftCircleFill
              title="Back to Previous page"
              onClick={(e) => {
                navigate(-1);
              }}
              className="w-6 h-6 ml-2 cursor-pointer"
            />
          )}
          <span className="flex-grow">Rental Ledger</span>
        </span>

        <ReportsBuilder
          show={
            viewPropertyTable || viewUnitsTable || viewRentalLedgerTable
              ? false
              : true
          }
          handleSearch={handleReportValues}
          builderValues={reportBuilderValues}
          selectedPortfolio={setSelectedPortfolio}
          selectedProperty={setSelectedProperty}
          selectedUnit={setSelectedUnit}
          selectedTenantCode={setSelectedTenantCode}
        />

        {viewPropertyTable && !viewUnitsTable && !viewRentalLedgerTable && (
          <section
            name="Search Result"
            //hidden={viewUnitsTable || viewTenantsTable || viewRentalLedgerTable}
            className="rounded shadow h-1/2 border "
          >
            <section
              name="Properties Table and Options"
              className="flex justify-between items-center bg-blue-30 h-10 mb-4"
            >
              <span className="flex gap-4">
                <BsFillArrowLeftCircleFill
                  title="Back to Previous page"
                  onClick={(e) => {
                    setViewPropertyTable(false);
                  }}
                  className="text-blue-52 w-6 h-6 ml-2 cursor-pointer"
                />

                <span className="flex font-semibold items-center">
                  {selectedPortfolio.portfolioName} -
                  <p className="font-normal">&nbsp;Properties Table</p>
                </span>
              </span>

              {/* <BsThreeDotsVertical className="w-5 h-5 mr-4" /> */}
            </section>

            <div className="flex justify-end items-center my-4 gap-4 mr-4">
              <Searchbar
                placeholder="Search Properties..."
                data={propertyTableDataMap.get(reportBuilderValues.portfolioId)}
                matchProps={[
                  { text: "propertyName" },
                  { text: "occupancy" },
                  { text: "numberOfUnits" },
                  { text: "delinquency" },
                ]}
                setTableData={setPropertyTableData}
              />
              {/* <TbFileArrowRight className="w-8 h-8 shadow text-green-500 rounded" /> */}
            </div>

            <TableComponent
              tableId="viewPropertyTable"
              data={propertyTableData}
              columns={[
                { key: "propertyName", title: "Property Name" },
                {
                  key: "occupancy",
                  title: "Occupancy",
                  applyMethod: (row) => {
                    return row.occupancy + "%";
                  },
                },
                { key: "numberOfUnits", title: "No. of Units" },
                {
                  key: "delinquency",
                  title: "Delinquency",
                  dataClassName: "text-[#D62B3D]",
                  applyMethod: (row) => {
                    if (row.delinquency.toString().startsWith("-")) {
                      return (
                        "-$" + row.delinquency.toLocaleString().substring(1)
                      );
                    } else {
                      return "$" + row.delinquency.toLocaleString();
                    }
                  },
                },
              ]}
              rowOnClick={handlePropertyTableClick}
              //initialSort={{ key: "numberOfUnits", direction: "descending" }}
            />
          </section>
        )}

        {viewUnitsTable && !viewRentalLedgerTable && (
          <section>
            <section
              name="Units Table and Options"
              className="flex justify-between items-center bg-blue-30 h-10 mb-4"
            >
              <span className="flex gap-4">
                <BsFillArrowLeftCircleFill
                  title="Back to Previous page"
                  onClick={(e) => {
                    setViewUnitsTable(false);
                    setViewPropertyTable(true);
                  }}
                  className="text-blue-52 w-6 h-6 ml-2 cursor-pointer"
                />

                <span className="flex font-semibold items-center">
                  {selectedPortfolio.portfolioName} &gt;{" "}
                  {selectedProperty.propertyName} -
                  <p className="font-normal">&nbsp;Units Table</p>
                </span>
              </span>

              {/* <BsThreeDotsVertical className="w-5 h-5 mr-4" /> */}
            </section>

            <div className="flex justify-end items-center mb-4 mt-4 gap-4 mr-4">
              <Searchbar
                placeholder="Search Units Table Data..."
                data={unitsTableDataMap.get(reportBuilderValues.propertyId)}
                matchProps={[
                  { text: "unitNumber" },
                  { text: "addressFull" },
                  { text: "unitStatus" },
                  { text: "delinquency" },
                ]}
                setTableData={setUnitsTableData}
              />
            </div>

            <TableComponent
              tableId="Units Table"
              data={unitsTableData}
              columns={[
                { key: "unitNumber", title: "Unit Number" },
                { key: "unitStatus", title: "Unit Status" },
                { key: "addressFull", title: "Address" },
                {
                  key: "delinquency",
                  title: "Delinquency",
                  dataClassName: "text-[#D62B3D]",
                  applyMethod: (row) => {
                    if (row.delinquency.toString().startsWith("-")) {
                      return (
                        "-$" + row.delinquency.toLocaleString().substring(1)
                      );
                    } else {
                      return "$" + row.delinquency.toLocaleString();
                    }
                  },
                },
              ]}
              rowOnClick={handleUnitTableClick}
              //initialSort={{ key: "unitNumber", direction: "descending" }}
            />
          </section>
        )}

        {viewRentalLedgerTable && (
          <section name="Report Listings">
            <section
              name="Rental Ledger Table and Options"
              className="flex justify-between items-center bg-blue-30 h-10"
            >
              <span className="flex gap-4">
                <BsFillArrowLeftCircleFill
                  title="Back to Previous page"
                  onClick={(e) => {
                    setViewRentalLedgerTable(false);
                    //setViewTenantsTable(true);
                    setViewUnitsTable(true);
                  }}
                  className="text-blue-52 w-6 h-6 ml-2 cursor-pointer"
                />

                <span className="flex font-semibold items-center">
                  {selectedPortfolio.portfolioName} &gt;{" "}
                  {selectedProperty.propertyName} &gt; {selectedUnit.unitNumber}{" "}
                  -<p className="font-normal">&nbsp;Ledger Table</p>
                </span>
              </span>

              {/* <BsThreeDotsVertical className="w-5 h-5 mr-4" /> */}
            </section>

            <span
              name="Payments Summary tab"
              className="flex justify-between items-center rounded-md h-32 mb-8 shadow-md px-14"
            >
              <span className="text-gray-400/100">
                <span className="inline-block w-2 h-2 rounded-full bg-green-500 mr-2"></span>
                Prepayment
                <p className="font-semibold text-black text-xl">
                  {ledgerTotals.prePayment.toString().startsWith("-")
                    ? "-$" +
                      ledgerTotals.prePayment.toLocaleString().substring(1)
                    : "$" + ledgerTotals.prePayment.toLocaleString()}
                </p>
              </span>

              <span className="text-gray-400/100">
                <span className="inline-block w-2 h-2 rounded-full bg-red-500 mr-2"></span>
                Balance
                <p className="font-semibold text-black text-xl">
                  {ledgerTotals.balance.toString().startsWith("-")
                    ? "-$" + ledgerTotals.balance.toLocaleString().substring(1)
                    : "$" + ledgerTotals.balance.toLocaleString()}
                </p>
              </span>
            </span>

            <span className="flex justify-between items-center gap-4 mb-4">
              <InputObjectSelectField
                label="Select Tenant"
                name="tenant"
                id="tenant"
                placeholder="Select a Tenant"
                value={selectedTenantCode}
                onChange={(e) => handleTenantSelectOnChange(e.target.value)}
                options={tenantsLedgerOptions}
                optionValue="tenantCode"
                optionName="tenantName"
                style=""
                className="w-full"
              />

              <span className="flex gap-4 items-center">
                {/* <AiOutlineEye className="w-8 h-8 shadow text-orange-600 rounded" />
                <TbFileArrowRight className="w-8 h-8 shadow text-green-500 rounded" /> */}
                <ButtonIcon
                 id="Download"
                 btnName="download"
                 dataCy="download"
                 title="Download"
                 onClick={downloadPDF}
                 icon={<BsDownload className="w-5 h-5" />}
                 className="hover:!bg-blue-50 hover:!text-white shadow-md"
              /> 
                <ExportCSV
                  data={rentalLedgerTableData}
                  keys={[
                    {
                      key: "dateOccured",
                      title: "Date",
                      //applyMethod: (row) => GetDateInMMDDYYYY(row.dateOccured),
                    },
                    { key: "description", title: "Description" },
                    {
                      key: "paymentInfo",
                      title: "Payment Type",
                    },
                    {
                      key: "paymentAmount",
                      title: "Amount",
                      applyMethod: (row) => {
                        if (
                          row.paymentInfo === "Payment" &&
                          row.paymentAmount
                        ) {
                          return "- $" + row.paymentAmount;
                        } else {
                          return "$" + row.paymentAmount;
                        }
                      },
                    },

                    {
                      key: "balance",
                      title: "Balance",
                      applyMethod: (row) => {
                        if (row.paymentInfo === "Prepayment") {
                          return `-`;
                        } else {
                          if (row.balance.toString().startsWith("-")) {
                            return "-$" + row.balance.substring(1);
                          } else {
                            return "$" + row.balance;
                          }
                        }
                      },
                    },
                  ]}
                  fileName="Rental Ledger.csv"
                />

                <Searchbar
                  placeholder="Search Ledger Records..."
                  data={
                    rentalLedgerTableDataMap.get(
                      reportBuilderValues.tenantCode
                    ) || []
                  }
                  matchProps={[
                    { date: "dateOccured" },
                    { text: "description" },
                    { text: "paymentAmount" },
                    { text: "paymentInfo" },
                    { text: "balance" },
                  ]}
                  setTableData={setRentalLedgerTableData}
                />
              </span>
            </span>

            <TableComponent
              tableId="Ledger Table"
              data={rentalLedgerTableData}
              columns={[
                {
                  key: "dateOccured",
                  title: "Date",
                  applyMethod: (row) => GetDateInMMDDYYYY(row.dateOccured),
                },
                { key: "description", title: "Description" },
                {
                  key: "paymentInfo",
                  title: "Payment Type",
                  getCellStyles: (row) => {
                    if (row.paymentInfo === "Charge") {
                      return { color: "red" };
                    }
                  },
                },
                {
                  key: "paymentAmount",
                  title: "Amount",
                  applyMethod: (row) => {
                    if (row.paymentInfo === "Payment" && row.paymentAmount) {
                      return "-$" + row.paymentAmount.toLocaleString();
                    } else {
                      return "$" + row.paymentAmount.toLocaleString();
                    }
                  },
                  getCellStyles: (row) => {
                    if (row.paymentInfo === "Charge") {
                      return { color: "red" };
                    }
                  },
                },

                {
                  key: "balance",
                  title: "Balance",
                  applyMethod: (row) => {
                    if (row.paymentInfo === "Prepayment") {
                      return `-`;
                    } else {
                      if (row.balance.toString().startsWith("-")) {
                        return "-$" + row.balance.toLocaleString().substring(1);
                      } else {
                        return "$" + row.balance.toLocaleString();
                      }
                    }
                  },
                },
              ]}
              rowOnClick={(row) => {}}
              //initialSort={{ key: "dateOccured", direction: "descending" }}
            />
          </section>
        )}
      </div>
    </>
  );
};

export default RentalLedgerReport;
